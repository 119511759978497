import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import moment from 'moment';

import Datetime from 'react-datetime';
import Filtering from './Filtering';

class Manual extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.renderManualChapter = this.renderManualChapter.bind(this);
    this.renderNorm = this.renderNorm.bind(this);

    if (props.business_units.length) {
      this.business_units = [{id: 0, name: I18n.t('no_business_units', {scope: 'components.manual_chapter'}) }, ...props.business_units];
    } else {
      this.business_units = [];
    }

    this.url = `${location}`;
    let activeFilters = {};
    this.filters = {
      business_units: props.default_business_units
    };
    if (window.sessionStorage) {
      activeFilters = JSON.parse(window.sessionStorage.getItem('manual_filters_' + props.organisationId));

      if (activeFilters !== null) {
        ['business_units'].forEach(key => this.filters[key] = activeFilters[key] || []);
      }
    }

    this.state = {
      date: moment(),
      manual_paragraphs: this.filterManualParagraphs(this.filters.business_units),
      openDateTime: false
    };
  }

  componentDidMount() {
    $('.localscroll').localScroll();
    $('[data-toggle="tooltip"]').tooltip();
    if (location.hash !== '') {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        scrollTo({ top: anchor.getBoundingClientRect().y - 105 });
      }
    }
  }

  onChange(date) {
    this.setState({ date }, () => document.forms.manualPdf.submit());
  }

  onFilterChange(name, items) {
    this.setState({
      manual_paragraphs: this.filterManualParagraphs(items)
    });
    window.sessionStorage.setItem('manual_filters_' + this.props.organisationId, JSON.stringify({ [name]: items }));
  }

  filterManualParagraphs(business_unit_ids) {
    const includeEmpty = business_unit_ids.includes(0);

    if (business_unit_ids.length) {
      return this.props.manual_paragraphs.filter(manual_paragraph => (
        manual_paragraph.text_blocks.some(text_block => (
          (includeEmpty && text_block.business_unit_ids.length === 0) || text_block.business_unit_ids.some(id => business_unit_ids.includes(id))
        ))
      ));
    } else {
      return this.props.manual_paragraphs;
    }
  }

  renderManualChapter(manual_chapter) {
    const manual_paragraphs = this.state.manual_paragraphs.filter(p => p.manual_chapter_id === manual_chapter.id);

    return(
      <ManualChapterItem
        key={manual_chapter.id}
        manual_chapter={manual_chapter}
        manual_paragraphs={manual_paragraphs}
      />
    );
  }

  renderNorm(norm) {
    const { can_read_norm } = this.props;

    if (can_read_norm) {
      return(
        <li key={norm.id}><a href={`/norms/${norm.id}`}>{norm.name}</a></li>
      );
    } else {
      return(
        <li key={norm.id}><span>{norm.name}</span></li>
      );
    }
  }

  render() {
    const { norms, manual_chapters } = this.props;
    const { date, openDateTime } = this.state;
    const options = { scope: 'components.manual' };

    return (
      <div className="row">
        <div className="col-md-9">
          <h1 className="heading-title">{I18n.t('header', options)}</h1>
          <ul className="list-links">
            {norms.map(this.renderNorm)}
            <li>
              <form id="manualPdf" action="/manual.pdf" method="get">
                <button className="btn btn-link" onClick={() => this.setState({ openDateTime: !openDateTime })} type="button">
                  <i className="far fa-clock" title={I18n.t('time_description', options)} data-toggle="tooltip" data-trigger="hover"></i>
                </button>
                <input name="date" type="hidden" value={date.format('YYYY-MM-DD')} />
                <Datetime
                  locale={I18n.locale}
                  dateFormat={'DD-MM-YYYY'}
                  timeFormat={false}
                  input={false}
                  onChange={this.onChange}
                  open={openDateTime}
                  isValidDate={currentDate => currentDate.isBefore(moment())}
                />
              </form>
            </li>
            <li>
              <a className="btn btn-link" download={I18n.t('manual_pdf', options)} title={I18n.t('download', options)} data-toggle="tooltip" data-trigger="hover" href="/manual.pdf">
                <i className="far fa-print"></i>
              </a>
            </li>
            <Filtering
              initialState={this.filters.business_units}
              onChange={this.onFilterChange}
              name='business_units'
              items={this.business_units}
            />
          </ul>
          {manual_chapters.map(this.renderManualChapter)}
        </div>
        <div className="col-md-3 hidden-sm">
          <div className="affix" data-spy="affix" data-offset-top="0">
            <h5>{I18n.t('sidebar_header', options)}</h5>
            <ul className="list-links list-links-stacked localscroll">
              {manual_chapters.map(c => (
                <li key={c.id}><a href={`#chapter-${c.position}`}>{c.number} {c.name}</a></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

class ManualChapterItem extends React.Component {
  constructor(props) {
    super(props);

    this.renderManualParagraph = this.renderManualParagraph.bind(this);
  }

  renderManualParagraph(manual_paragraph) {
    const { manual_chapter } = this.props;

    return(
      <li key={manual_paragraph.id}>
        <a href={`/manual/manual_chapters/${manual_chapter.id}#paragraph-${manual_paragraph.position}`}>
          <span className="paragraph-number">{manual_paragraph.number}</span>
          {manual_paragraph.name}
        </a>
      </li>
    );
  }

  render() {
    const { manual_chapter, manual_paragraphs } = this.props;
    let className = 'card card-task';

    if (manual_paragraphs.every(p => p.status_name === 'done')) {
      className += ' status-done';
    }

    return(
      <div className={className}>
        <h2 id={`chapter-${manual_chapter.position}`} className="alt">
          <a href={`/manual/manual_chapters/${manual_chapter.id}`}>{manual_chapter.number} {manual_chapter.name}</a>
        </h2>
        <ul className="list-links list-links-stacked list-paragraph">
          {manual_paragraphs.map(this.renderManualParagraph)}
        </ul>
      </div>
    );
  }
}

export default Manual;
