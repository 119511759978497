import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import I18n from '../../i18n-js/index.js.erb';
import moment from 'moment';

import { request } from '../../actions';
import { useUploadFile } from './upload-file';
import { renderFieldMessages } from '../form';

import AutoComplete from '../auto-complete';
import FileUpload from './file-upload';
import SelectDirectoryModal from '../text-block/drive-modal/select-directory-modal';

const graphqlSave = `
  mutation DocumentMutation($input: SaveDocumentInput!) {
    saveDocument(input: $input) {
      document {
        businessUnitIds
        classification
        driveId
        externalId
        hasRevisionTask
        id
        isFavorite
        jobTitleIds
        location
        name
        retentionPeriod
        revisionDate
        staffId
        status
        type
        updatedAt
        url
      }
      errors { field messages }
    }
  }
`;

const iconForDocType = (type, provider) => {
  switch (type) {
    case 'activestorage':
      return 'fa fa-download';
    case 'url':
      return 'fa fa-link';
    default:
      return provider === 'microsoft_graph' ? 'fab fa-microsoft' : 'fab fa-google-drive';
  }
};

const scope = { scope: 'components.document_form' };
const scopeForm = { scope: 'components.documents' };
const scopeClassifications = { scope: 'documents.classifications' };

const DocumentForm = ({ doc, errors, index, ...props }) => {
  const [isInFlight, setIsInFlight] = useState(false);
  const [file, setFile] = useState(null);

  const onSuccesFileUpload = useCallback(
    (payload) => {
      const name = doc.name || payload.name;
      props.onChange({ ...payload, name });
    },
    [props.onChange, doc.name],
  );

  const [uploadState, { startUpload, startGoogleUpload, startMicrosoftUpload }] = useUploadFile(onSuccesFileUpload);

  const onDirectorySelect = (driveItem) => {
    let { driveId } = driveItem;
    let parentId = driveItem.externalId;

    if (driveId === null) {
      driveId = parentId;
      parentId = null;
    }

    props.onChange({ driveId, parentId });
    document.querySelector(`[data-id="form-${doc.id}"]`).scrollIntoView(false);

    if (props.provider === 'google') {
      startGoogleUpload(file, parentId || driveId);
    } else {
      startMicrosoftUpload(file, driveId, parentId);
    }
  };

  const onFileChange = (file) => {
    setFile(file);

    if (file && (doc.id || !props.hasDrive)) {
      startUpload(file);
    }
  };

  const onBusinessUnitIdsChange = (businessUnitIds) => {
    props.onChange({ businessUnitIds });
  };

  const onChange = ({ target: { name, value } }) => {
    props.onChange({ [name]: value });
  };

  const onJobTitlesChange = (jobTitleIds) => {
    props.onChange({ jobTitleIds });
  };

  const onRetentionPeriodChange = (date) => {
    const retentionPeriod = typeof date === 'string' ? '' : date.format('YYYY-MM-DD');
    props.onChange({ retentionPeriod });
  };

  const onRevisionDateChange = (date) => {
    const revisionDate = typeof date === 'string' ? '' : date.format('YYYY-MM-DD');
    props.onChange({ revisionDate });
  };

  const onStaffIdChange = (staffId) => {
    props.onChange({ staffId });
  };

  const onMarkAsFinalClick = (event) => {
    onSubmit(event, true);
  };

  const onSubmit = async (event, markAsFinal = false) => {
    setIsInFlight(true);
    event.preventDefault();

    const input = {
      businessUnitIds: doc.businessUnitIds,
      classification: doc.classification,
      driveId: doc.driveId,
      externalId: doc.externalId,
      id: doc.id,
      jobTitleIds: doc.jobTitleIds,
      location: doc.location,
      markAsFinal,
      name: doc.name,
      retentionPeriod: doc.retentionPeriod ? moment(doc.retentionPeriod).format('YYYY-MM-DD') : null,
      revisionDate: doc.revisionDate ? moment(doc.revisionDate).format('YYYY-MM-DD') : null,
      signedId: doc.signedId,
      staffId: doc.staffId,
      status: markAsFinal ? 'done' : doc.status,
      textBlockId: doc.textBlockId,
      url: doc.url,
    };

    const [{ saveDocument }, error] = await request(graphqlSave, { input });
    setIsInFlight(false);
    if (error) return;

    props.onSave(saveDocument);
  };

  useEffect(() => {
    $('[data-toggle="popover"]').popover();
  }, []);

  return (
    <form data-id={`form-${doc.id}`} onSubmit={onSubmit}>
      <div className="row mb-3">
        {doc.id && (
          <div className="col-sm-6">
            <a className="btn btn-primary" href={`/documents/${doc.id}`} rel="noreferrer" target="_blank">
              <i className={iconForDocType(doc.type, props.provider)} /> {I18n.t('open_document', scopeForm)}
            </a>
          </div>
        )}

        {(doc.type === 'activestorage' ||
          (doc.externalId === undefined && ['google', 'microsoft_graph'].includes(doc.type))) && (
          <div className={`col-sm-${doc.id ? 6 : 12}`}>
            <div className="mb-3">
              <FileUpload id={`${index}_file`} onChange={onFileChange}>
                {I18n.t('add_file', scope)}
              </FileUpload>
              {renderFieldMessages(errors, 'file')}
            </div>
          </div>
        )}
      </div>

      {file && (
        <div className="row mb-3">
          <div className="col-sm-6">
            <div className="progress mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadState.progress}%` }}
                aria-valuenow={uploadState.progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {`${uploadState.progress}%`}
              </div>
            </div>
            {file.name}
          </div>
          <div className="col-sm-6">
            {props.hasDrive && doc.id === undefined && (
              <>
                <p className="mb-2">{I18n.t('upload_location', scope)}</p>
                <button
                  className="btn btn-default btn-block mb-2"
                  disabled={uploadState.isUploading}
                  onClick={() => startUpload(file)}
                  type="button"
                >
                  SmartManSys
                </button>
                <SelectDirectoryModal disabled={uploadState.isUploading} onAdd={onDirectorySelect}>
                  {props.provider === 'microsoft_graph' ? 'Sharepoint' : 'Drive'}
                </SelectDirectoryModal>
              </>
            )}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor={`${index}_name`}>{I18n.t('name', scopeForm)}</label>
            <input
              id={`${index}_name`}
              name="name"
              className="form-control"
              onChange={onChange}
              type="text"
              value={doc.name}
            />
            {renderFieldMessages(errors, 'name')}
          </div>

          {doc.type === 'url' && (
            <div className="form-group">
              <label htmlFor={`${index}_url`}>{I18n.t('url', scope)}</label>
              <input
                id={`${index}_url`}
                name="url"
                className="form-control"
                onChange={onChange}
                type="text"
                value={doc.url}
              />
              {renderFieldMessages(errors, 'url')}
            </div>
          )}

          <div className="form-group">
            <label
              data-content={I18n.t('classification_popover', scope)}
              data-placement="right"
              data-toggle="popover"
              data-trigger="hover"
              htmlFor={`${index}_classification`}
              title={I18n.t('classification', scopeForm)}
            >
              {I18n.t('classification', scopeForm)}
            </label>
            <select
              className="form-control"
              id={`${index}_classification`}
              name="classification"
              onChange={onChange}
              value={doc.classification}
            >
              {props.classifications.map((c) => (
                <option key={c} value={c}>
                  {I18n.t(c, scopeClassifications)}
                </option>
              ))}
            </select>
            {renderFieldMessages(errors, 'classification')}
          </div>

          <div className={`collapse${doc.classification === 'confidential' ? ' in' : ''}`}>
            <div className="form-group">
              <label htmlFor={`${index}_jobTitleIds`}>{I18n.t('job_title_ids', scopeForm)}</label>
              <AutoComplete
                id={`${index}_jobTitleIds`}
                items={props.jobTitles}
                multiple
                name="jobTitleIds"
                onChange={onJobTitlesChange}
                selectedItemIds={doc.jobTitleIds}
              />
              {renderFieldMessages(errors, 'jobTitleIds')}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor={`${index}_retention_period`}>{I18n.t('retention_period', scopeForm)}</label>
            <Datetime
              dateFormat="DD-MM-YYYY"
              inputProps={{
                autoComplete: 'off',
                id: `${index}_retention_period`,
                placeholder: 'DD-MM-YYYY',
              }}
              locale={I18n.locale}
              onChange={onRetentionPeriodChange}
              timeFormat={false}
              value={doc.retentionPeriod ? moment(doc.retentionPeriod).format('DD-MM-YYYY') : ''}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <label
              htmlFor={`${index}_staff_id`}
              data-trigger="hover"
              data-toggle="popover"
              title={I18n.t('staff', scopeForm)}
              data-content={I18n.t('staff_popover', scope)}
              data-placement="right"
            >
              {I18n.t('staff', scopeForm)}
            </label>
            <AutoComplete
              id={`${index}_staff_id`}
              name="staffId"
              items={props.staffs}
              onChange={onStaffIdChange}
              value={props.staffs.find((s) => s.id === doc.staffId)?.name || ''}
            />
            {renderFieldMessages(errors, 'staffId')}
          </div>

          {props.businessUnits.length > 0 && (
            <div className="form-group">
              <label htmlFor={`${index}_business_unit_ids`}>{I18n.t('business_units', scopeForm)}</label>
              <AutoComplete
                id={`${index}_business_unit_ids`}
                items={props.businessUnits}
                multiple
                name="businessUnitIds"
                onChange={onBusinessUnitIdsChange}
                selectedItemIds={doc.businessUnitIds}
              />
            </div>
          )}

          {props.showRevistionDate && (
            <div className="form-group">
              <label htmlFor={`${index}_revision_date`}>{I18n.t('revision_date', scopeForm)}</label>
              <Datetime
                dateFormat="DD-MM-YYYY"
                inputProps={{
                  autoComplete: 'off',
                  id: `${index}_revision_date`,
                  placeholder: 'DD-MM-YYYY',
                }}
                locale={I18n.locale}
                onChange={onRevisionDateChange}
                timeFormat={false}
                value={doc.revisionDate ? moment(doc.revisionDate).format('DD-MM-YYYY') : ''}
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor={`${index}_location`}>{I18n.t('location', scopeForm)}</label>
            <input
              autoComplete="off"
              className="form-control"
              id={`${index}_location`}
              name="location"
              onChange={onChange}
              type="text"
              value={doc.location || ''}
            />
          </div>
        </div>
      </div>

      {(doc.id || doc.externalId || doc.signedId || doc.type === 'url') && (
        <div className="form-group row">
          <div className="col-sm-6">
            <button className="btn btn-default" disabled={isInFlight}>
              {I18n.t('save', scope)}
            </button>
          </div>
          {props.canMarkAsFinal && (
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary" disabled={isInFlight} onClick={onMarkAsFinalClick} type="button">
                {I18n.t('mark_as_final', scope)}
              </button>
            </div>
          )}
        </div>
      )}
    </form>
  );
};

DocumentForm.defaultProps = {
  showRevistionDate: true,
};

DocumentForm.propTypes = {
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  canMarkAsFinal: PropTypes.bool.isRequired,
  classifications: PropTypes.array.isRequired,
  doc: PropTypes.shape({
    classification: PropTypes.string.isRequired,
    businessUnitIds: PropTypes.array.isRequired,
    driveId: PropTypes.string,
    externalId: PropTypes.string,
    id: PropTypes.string,
    isFavorite: PropTypes.bool.isRequired,
    jobTitleIds: PropTypes.array.isRequired,
    location: PropTypes.string,
    name: PropTypes.string,
    retentionPeriod: PropTypes.string,
    revisionDate: PropTypes.string,
    signedId: PropTypes.string,
    staffId: PropTypes.string,
    status: PropTypes.string.isRequired,
    textBlockId: PropTypes.string,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array.isRequired,
    }).isRequired,
  ).isRequired,
  hasDrive: PropTypes.bool.isRequired,
  showRevistionDate: PropTypes.bool,
  index: PropTypes.string.isRequired,
  jobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  provider: PropTypes.string,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DocumentForm;
