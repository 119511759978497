import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import SingleAutoComplete from './SingleAutoComplete/SingleAutoComplete';
import '../components/SingleAutoComplete/single-auto-complete.scss';

const ReceiverLink = ({
  onChange,
  countries,
  documents,
  receiverLink,
  ...props
}) => {
  const formName = `processing_register[receiver_links_attributes][${
    receiverLink.id || receiverLink.uid
  }]`;

  const [docSearch, setDocSearch] = useState(
    receiverLink.document_id
      ? documents.find((d) => d.id === receiverLink.document_id).name
      : '',
  );
  const [countrySearch, setCountrySearch] = useState(
    receiverLink.country_id
      ? countries.find((d) => d.id === receiverLink.country_id).name
      : '',
  );

  const filteredCountries = countries.filter((item) =>
    item.name.toLowerCase().includes(countrySearch.toLowerCase()),
  );

  const filteredDocs = documents.filter((item) =>
    item.name.toLowerCase().includes(docSearch.toLowerCase()),
  );

  const handleDocSearchValueChange = ({ target: { value } }) => {
    setDocSearch(value);
  };

  const onDocSelect = (item) => {
    onChange({ ...receiverLink, document_id: parseInt(item.id) });
    setDocSearch(item.name);
  };

  const onDocFocus = () => {
    onChange({ ...receiverLink, document_id: undefined });
    setDocSearch('');
  };

  const handleCountrySearchValueChange = ({ target: { value } }) => {
    setCountrySearch(value);
  };

  const onCountrySelect = (item) => {
    onChange({ ...receiverLink, country_id: parseInt(item.id) });
    setCountrySearch(item.name);
  };

  const onCountryFocus = () => {
    onChange({ ...receiverLink, country_id: undefined });
    setCountrySearch('');
  };

  const onDelete = () => props.onDelete(receiverLink);

  const onNameChange = ({ target: { value } }) => {
    onChange({ ...receiverLink, name: value });
  };

  const onContactInfoChange = ({ target: { value } }) => {
    onChange({ ...receiverLink, contact_info: value });
  };

  const onNonEuChange = () => {
    onChange({ ...receiverLink, non_eu: !receiverLink.non_eu });
  };

  const onGuaranteesChange = ({ target: { value } }) => {
    onChange({ ...receiverLink, guarantees: value });
  };

  const other = ![
    'model_contract',
    'corporate_rules',
    'adequacy_decision',
    undefined,
  ].includes(receiverLink.guarantees);

  return (
    <div className="row">
      <input name={`${formName}[id]`} value={receiverLink.id} type="hidden" />
      <div className="col-sm-6">
        <label>{I18n.t('processing_registers.receiver')}</label>
        <input
          className="form-control"
          name={`${formName}[name]`}
          value={receiverLink.name || ''}
          onChange={onNameChange}
        />
      </div>
      <div className="col-sm-6">
        <div className="row mb-2">
          <div className="col-sm-5">
            <label>{I18n.t('processing_registers.processor_agreement')}</label>
            <SingleAutoComplete
              items={filteredDocs}
              className="form-control"
              selectValue={receiverLink.document_id || undefined}
              onChange={handleDocSearchValueChange}
              onFocus={onDocFocus}
              onItemSelect={onDocSelect}
              searchValue={docSearch}
            ></SingleAutoComplete>
            <input
              name={`${formName}[document_id]`}
              value={receiverLink.document_id || ''}
              type="hidden"
            />
          </div>
          <div className="col-sm-5">
            <label>{I18n.t('processing_registers.non_eu')}</label>
            <div className="radio">
              <label>
                <input
                  checked={receiverLink.non_eu}
                  name={`${formName}[non_eu]`}
                  onChange={onNonEuChange}
                  type="radio"
                  value={true}
                />
                {I18n.t('processing_registers.non_eu_opts.true')}
              </label>
            </div>

            <div className="radio">
              <label>
                <input
                  checked={!receiverLink.non_eu}
                  name={`${formName}[non_eu]`}
                  onChange={onNonEuChange}
                  type="radio"
                  value={false}
                />
                {I18n.t('processing_registers.non_eu_opts.false')}
              </label>
            </div>
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-link-danger mt-2"
              onClick={onDelete}
              type="button"
            >
              <i className="far fa-times"></i>
            </button>
          </div>
        </div>
        {receiverLink.non_eu && (
          <div className="non-eu">
            <div className="row mb-2">
              <div className="col-sm-5">
                <label>{I18n.t('processing_registers.country')}</label>
                <SingleAutoComplete
                  items={filteredCountries}
                  className="form-control"
                  selectValue={receiverLink.country_id || undefined}
                  onChange={handleCountrySearchValueChange}
                  onFocus={onCountryFocus}
                  onItemSelect={onCountrySelect}
                  searchValue={countrySearch}
                ></SingleAutoComplete>
                <input
                  name={`${formName}[country_id]`}
                  value={receiverLink.country_id}
                  type="hidden"
                />
              </div>
              <div className="col-sm-5">
                <label>{I18n.t('processing_registers.guarantees')}</label>
                <div className="radio">
                  <label>
                    <input
                      checked={receiverLink.guarantees === 'model_contract'}
                      name={`${formName}[guarantees]`}
                      onChange={onGuaranteesChange}
                      type="radio"
                      value="model_contract"
                    />
                    {I18n.t(
                      'processing_registers.guarantees_opts.model_contract',
                    )}
                  </label>
                </div>

                <div className="radio">
                  <label>
                    <input
                      checked={receiverLink.guarantees === 'corporate_rules'}
                      name={`${formName}[guarantees]`}
                      onChange={onGuaranteesChange}
                      type="radio"
                      value="corporate_rules"
                    />
                    {I18n.t(
                      'processing_registers.guarantees_opts.corporate_rules',
                    )}
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      checked={receiverLink.guarantees === 'adequacy_decision'}
                      name={`${formName}[guarantees]`}
                      onChange={onGuaranteesChange}
                      type="radio"
                      value="adequacy_decision"
                    />
                    {I18n.t(
                      'processing_registers.guarantees_opts.adequacy_decision',
                    )}
                  </label>
                </div>

                <div className="radio">
                  <label>
                    <input
                      checked={other}
                      name={`${formName}[guarantees]`}
                      onChange={onGuaranteesChange}
                      type="radio"
                      value=""
                    />
                    {I18n.t('processing_registers.guarantees_opts.other')}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-5">
                <label>{I18n.t('processing_registers.contact_info')}</label>
                <input
                  className="form-control"
                  type="textarea"
                  name={`${formName}[contact_info]`}
                  value={receiverLink.contact_info || ''}
                  onChange={onContactInfoChange}
                />
              </div>
              {other && (
                <div className="col-sm-5">
                  <label>{I18n.t('processing_registers.other')}</label>
                  <input
                    className="form-control"
                    name={`${formName}[guarantees]`}
                    value={receiverLink.guarantees}
                    onChange={onGuaranteesChange}
                  />
                </div>
              )}
              <div className="col-sm-2"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.id && !props.uid) {
    return new Error(
      `One of 'id' or 'uid' is required by '${componentName}' component.`,
    );
  }
};

ReceiverLink.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  receiverLink: PropTypes.shape({
    contact_info: PropTypes.string,
    country_id: PropTypes.number,
    document_id: PropTypes.number,
    guarantees: PropTypes.string,
    id: requiredPropsCheck,
    name: PropTypes.string,
    non_eu: PropTypes.string.isRequired,
    uid: requiredPropsCheck,
  }).isRequired,
};

export default ReceiverLink;
