import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function(){
  $('#searching_form').submit(function(e){
    e.preventDefault();
    $.ajax({
      type : 'POST',
      url : '/dashboards/search',
      data : $('#searching_form').serialize(),
      dataType: 'json',
      success :function(data) {
        Rails.enableElement(e.target);

        if (typeof(data.empty_result) == 'undefined') {
          $('.content-wrapper').replaceWith(data.result);
        } else {
          $('.content-wrapper').replaceWith(data.empty_result);
        }
      }
    });
  });
});
