class Authorizations {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents() {
    this.container.on('click', '[data-action="copy_vim_url"]', this.copyVimUrlClickEvent.bind(this));
    this.container.on('click', '[data-action="copy_dataleak_url"]', this.copyDataleakUrlClickEvent.bind(this));
    this.container.on(
      'click',
      '[data-action="copy_complaint_application_url"]',
      this.copyComplaintApplicationUrlClickEvent.bind(this),
    );
  }

  copyVimUrlClickEvent(event) {
    event.preventDefault();
    let string = document.getElementById('vim').innerHTML;
    this.copyStringToClipboard(string);
  }

  copyDataleakUrlClickEvent(event) {
    event.preventDefault();
    let string = document.getElementById('data_leak').innerHTML;
    this.copyStringToClipboard(string);
  }

  copyComplaintApplicationUrlClickEvent(event) {
    event.preventDefault();
    let string = document.getElementById('complaint_application').innerHTML;
    this.copyStringToClipboard(string);
  }

  copyStringToClipboard(string) {
    navigator.clipboard.writeText(string);
  }
}

$(document).on('turbolinks:load', () => {
  $('[data-container="authorizations"]').each((_, container) => {
    new Authorizations(container);
  });
});
