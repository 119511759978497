import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import DropDownMenu from './DropDownMenu';

class ManualParagraphNormLinks extends React.Component {
  constructor(props) {
    super(props);

    this.renderNormLinkOrganisation = this.renderNormLinkOrganisation.bind(this);
    this.renderTextBlock = this.renderTextBlock.bind(this);
    this.renderNormLink = this.renderNormLink.bind(this);

    this.state = {
      norm_organisation: null
    };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({html: true, trigger: 'hover', placement: 'left'});
  }

  renderNormLinkOrganisation(norm_organisation) {
    const onClick = () => {
      this.setState(p => {
        if (p.norm_organisation === norm_organisation) {
          return { norm_organisation: null };
        } else {
          return { norm_organisation };
        }
      });
    };

    return(
      <li key={norm_organisation.id} className="norm">
        <button className={`btn btn-link ${norm_organisation.status_done ? 'status-done' : 'status-concept'}`} onClick={onClick}>
          <i className="fas fa-square"></i> {norm_organisation.norm_name}
        </button>
      </li>
    );
  }

  renderTextBlock(text_block) {
    return(
      <li key={text_block.id}>
        {
          this.props.currentMember.canManageTextBlock ?
            <a href={`${location.pathname}/manual_paragraphs/${text_block.manual_paragraph_id}/text_blocks/${text_block.id}/edit`}>
              <span className={`status-${text_block.status_name}`}>
                <i className="fa fa-bookmark"></i>
              </span> {text_block.name}
            </a>
            :
            <div>
              <span className={`status-${text_block.status_name}`}>
                <i className="fa fa-bookmark"></i>
              </span> {text_block.name}
            </div>
        }
      </li>
    );
  }

  renderNormLink(norm_link_organisation) {
    if (this.props.currentMember.accessNorms) {
      return(
        <li key={norm_link_organisation.id}>
          <a href={`/norms/${norm_link_organisation.norm_id}#paragraaf-${norm_link_organisation.norm_paragraph_id}`}>
            {norm_link_organisation.norm_paragraph_number} {norm_link_organisation.norm_paragraph_name}
          </a>
        </li>
      );
    } else {
      return(
        <li key={norm_link_organisation.id}>
          <span className="muted-text">
            {norm_link_organisation.norm_paragraph_number} {norm_link_organisation.norm_paragraph_name}
          </span>
        </li>
      );
    }
  }

  render() {
    const { norm_link_organisations, norm_organisations } = this.props;
    const { norm_organisation } = this.state;
    const options = { scope: 'components.manual_paragraph_norm_links' };

    return(
      <DropDownMenu name={I18n.t('norms', options)}>
        <h6 title={I18n.t('norm_dropdown.popover.header', options)} data-content={I18n.t('norm_dropdown.popover.content', options)} data-toggle="popover">
          {I18n.t('norm_dropdown.header', options)}
        </h6>
        {norm_organisations.map(this.renderNormLinkOrganisation)}
        {norm_organisation && <hr/>}
        {norm_organisation && norm_organisation.text_blocks.map(this.renderTextBlock)}
        <hr />
        <h6 title={I18n.t('norm_links.popover.header', options)} data-content={I18n.t('norm_links.popover.content', options)} data-toggle="popover">
          {I18n.t('norm_links.header', options)}
        </h6>
        {norm_link_organisations.map(this.renderNormLink)}
      </DropDownMenu>
    );
  }
}

export default ManualParagraphNormLinks;
