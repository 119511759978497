import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class BusinessUnitModal extends React.Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const business_unit = props.business_unit || {};

    this.state = {
      business_unit_id: business_unit.business_unit_id,
      name: business_unit.name || '',
      number: business_unit.number,
    };
  }

  componentDidMount() {
    const modal = $(this.modal.current).modal('show');
    modal.on('hidden.bs.modal', this.props.closeModal);
    modal.draggable();
  }

  componentWillUnmount() {
    $(this.modal.current).off('hidden.bs.modal', this.props.closeModal);
  }

  onChange({ target }) {
    if (target.value !== '' && target.name === 'business_unit_id') {
      this.setState({ [target.name]: parseInt(target.value) });
    } else {
      this.setState({ [target.name]: target.value });
    }
  }

  onDelete() {
    this.props.onSubmit({ ...this.props.business_unit, _destroy: true });
  }

  onSubmit(event) {
    event.preventDefault();
    this.props.onSubmit({ ...(this.props.business_unit || {}), ...this.state });
    $(this.modal.current).modal('hide');
  }

  render() {
    const options = { scope: 'components.business_unit_modal' };
    const { business_unit, business_units } = this.props;
    const { business_unit_id, name, number } = this.state;

    return(
      <div className="modal fade" tabIndex="-1" role="dialog" style={{display: 'none'}} ref={this.modal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{I18n.t('header', options)}</h4>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="name">{I18n.t('name', options)}</label>
                  <input autoComplete="off" className="form-control" id="name" name="name" onChange={this.onChange} required value={name} />
                </div>
                <div className="form-group">
                  <label htmlFor="number">{I18n.t('number', options)}</label>
                  <input autoComplete="off" className="form-control" id="number" name="number" onChange={this.onChange} value={number} placeholder={I18n.t('business_unit_placeholder', options)} />
                </div>
                <div className="form-group">
                  <label htmlFor="business_unit_id">{I18n.t('business_unit_id', options)}</label>
                  <select className="form-control" id="business_unit_id" name="business_unit_id" onChange={this.onChange} value={business_unit_id || ''}>
                    <option value></option>
                    {business_units.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                  </select>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
                {
                  business_unit !== undefined ?
                    <button className="btn btn-danger" onClick={this.onDelete} type="button" data-dismiss="modal">{I18n.t('helpers.submit.destroy', { model: I18n.t('activerecord.models.business_unit') })}</button>
                    : null
                }
                <button className="btn btn-primary" type="submit">{I18n.t('save', options)}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessUnitModal;
