import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import ManualParagraphGetter from './manual-paragraph-getter';
import SelectToggle from './select-toggle';

import TaskConstants from 'constants/task-constants';
import { taskDispatcher } from 'dispatcher/task-dispatcher';

class TaskModal {
  constructor(modal) {
    this.modal = modal;

    this.onSubmitEvent = this.onSubmitEvent.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.successEvent = this.successEvent.bind(this);

    this.modal.on('show.bs.modal', this.onShowModal);
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent);
    this.form.on(
      'click',
      '[data-action="add"]',
      this.onAddClickEvent.bind(this),
    );
    this.form.on(
      'click',
      '[data-action="delete"]',
      this.onDeleteClickEvent.bind(this),
    );
    this.form.on(
      'change',
      '[data-action="sendNotification"]',
      this.onSendToCalendarEvent.bind(this),
    );
    this.form.on(
      'input blur focus',
      '[data-action="taskStartDate"]',
      this.onSendToCalendarEvent.bind(this),
    );
    this.form.on(
      'input blur focus',
      '[data-action="taskEndDate"]',
      this.onSendToCalendarEvent.bind(this),
    );
    window.loadStaffGetter();
    window.loadReviewerGetter();
  }

  onAddClickEvent(event) {
    event.preventDefault();

    const template = $('[data-template="task_document"]')
      .html()
      .replace(/new_association/g, Date.now());
    $('[data-template="task_document"]').before(template);
    $('[data-toggle="tooltip"]').tooltip();
  }

  onDeleteClickEvent(event) {
    event.preventDefault();

    let el = $(event.target)
      .parents('div')
      .first()
      .find('[data-element="destroy"]');
    if (el.length) {
      el.val(1);
      $(event.target).parents('.form-group').hide();
    } else {
      $(event.target).parents('.form-group').remove();
    }
  }

  onSendToCalendarEvent(event) {
    event.preventDefault();

    const start = document.querySelector('[data-action="taskStartDate"]');
    const end = document.querySelector('[data-action="taskEndDate"]');
    const sendNotification = document.querySelector(
      '[data-action="sendNotification"]',
    );

    if (
      (start.value.length > 0 || end.value.length > 0) &&
      sendNotification.checked
    ) {
      $('[data-action="sendToCalendar"]').removeClass('hidden');
    } else {
      $('[data-id="checkboxCalendar"]').prop('checked', false);
      $('[data-action="sendToCalendar"]').addClass('hidden');
    }
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      type: 'POST',
      url: this.form.attr('action'),
      data: new FormData(this.form[0]),
      processData: false,
      contentType: false,
      dataType: 'json',
      success: this.successEvent,
    });
  }

  onShowModal(event) {
    if (this.form === undefined) {
      fetch('/tasks/new')
        .then((r) => r.text())
        .then((r) => {
          this.modal.find('.modal-content').html(r);
          this.form = this.modal.find('form');

          $('[data-select="toggle"]').each((_, e) => new SelectToggle(e));
          this.getter = new ManualParagraphGetter(
            event.relatedTarget.dataset.manualChapterId === undefined,
          );
          this.modal.find('.datetimepicker').each(function () {
            window.initializeDateTimePicker(this);
          });
          this.setFormValues(event);
          this.bindEvents();
        });
    } else {
      this.setFormValues(event);
    }
  }

  setFormValues(event) {
    const button = event.relatedTarget;

    if (button.dataset.name) {
      this.form.find('#task_name').val(button.dataset.name);
    }

    if (button.dataset.description) {
      this.form.find('#task_description').val(button.dataset.description);
    }

    if (button.dataset.deviationId) {
      this.form.find('#task_deviation_id').val(button.dataset.deviationId);
    }

    if (button.dataset.deviationAnswerId) {
      this.form
        .find('#task_deviation_answer_id')
        .val(button.dataset.deviationAnswerId);
    }

    if (button.dataset.measureTypeId) {
      this.form
        .find('#task_measure_type_id')
        .val(button.dataset.measureTypeId)
        .trigger('change');
    }

    if (button.dataset.manualChapterId) {
      this.getter.setIds({
        manualChapterId: button.dataset.manualChapterId,
        manualParagraphId: button.dataset.manualParagraphId,
      });
    }

    if (button.dataset.documentId) {
      this.form.find('#task_document_id').val(button.dataset.documentId);
    }
    if (button.dataset.processingRegisterId) {
      this.form
        .find('#task_processing_register_id')
        .val(button.dataset.processingRegisterId);
    }

    if (button.dataset.revisionDate) {
      const endDate = new Date(button.dataset.revisionDate);
      const startDate = new Date(button.dataset.revisionDate);
      startDate.setMonth(startDate.getMonth() - 1);

      this.form.find('#task_start_date').val(
        startDate.toLocaleDateString('nl', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      );
      this.form.find('#task_end_date').val(
        endDate.toLocaleDateString('nl', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      );
    }
  }

  successEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof data.errors !== 'undefined') {
      $('#modal-nieuwe-taak .modal-body').append(
        '<p class="alert alert-danger error">' + data.errors + '</p>',
      );
      setTimeout(function () {
        $('.error').fadeOut('slow');
      }, 4000);
    } else if (typeof data.validation_errors !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else if (typeof data.success !== 'undefined') {
      this.form[0].reset();

      taskDispatcher.handleCreateTaskAction({
        actionType: TaskConstants.TASK_CREATE,
        task: data.task,
      });
      $('.container-fluid .heading:first').after(
        '<p class="alert alert-success success">' + data.success + '</p>',
      );
      $('#modal-nieuwe-taak').modal('hide');

      setTimeout(function () {
        $('.success').fadeOut('slow');
      }, 4000);
    }
  }
}

$(document).on('turbolinks:load', () => {
  var modal = $('#modal-nieuwe-taak');

  if (modal) {
    new TaskModal(modal);
  }
});
