import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import Dropdown from '../dropdown';
import Editor from './editor';
import SelectFileModal from './drive-modal/select-file-modal';
import TextBlockVersion from './text-block-version';

import Document from './document';
import Table from './table';

import DefaultAvatar from '../../images/default-avatar.png';

import { updateTextBlockMutation, createCommentMutation } from './text-block-queries';
import { reducer } from './reducer';
import { request } from '../../actions';
import { renderFieldMessages } from '../form';

const options = { scope: 'text_blocks.edit.documents' };

const TextBlock = ({ backUrl, hasDrive, provider, ...props }) => {
  const [state, dispatch] = useReducer(reducer, {
    commentErrors: [],
    errors: [],
    isSubmiting: false,
    message: '',
    newDocument: null,
    textBlock: props.textBlock,
  });

  const status = state.textBlock.status === 'done' ? 'status-done' : 'status-default';

  const onCommentChange = ({ target: { value } }) => {
    dispatch({ type: 'updateMessage', payload: { message: value } });
  };

  const onCommitSubmit = async (event) => {
    event.preventDefault();

    const [{ createComment }] = await request(createCommentMutation, {
      input: {
        message: state.message,
        textBlockId: state.textBlock.id,
      },
    });

    if (createComment.errors.length) {
      dispatch({
        type: 'commentErrors',
        payload: { commentErrors: createComment.errors },
      });
    } else {
      dispatch({
        type: 'addComment',
        payload: { comment: createComment.comment },
      });
    }
  };

  const onAddExisitingFile = (driveItem) => {
    const { name, driveId, externalId } = driveItem;
    dispatch({
      type: 'newDocumentDrive',
      payload: {
        name,
        driveId,
        externalId,
        staffId: props.staffId.toString(),
        textBlockId: state.textBlock.id,
      },
    });
  };

  const onChange = ({ target: { name, value } }) => {
    dispatch({ type: 'changeValue', payload: { [name]: value } });
  };

  const onNewLinkClick = () => {
    dispatch({
      type: 'newDocumentLink',
      payload: {
        staffId: props.staffId.toString(),
        textBlockId: state.textBlock.id,
      },
    });
  };

  const onNewFileClick = () => {
    dispatch({
      type: 'newDocument',
      payload: {
        staffId: props.staffId.toString(),
        textBlockId: state.textBlock.id,
      },
    });
  };

  const onMarkAsFinalClick = (event) => {
    onSubmit(event, true);
  };

  const onSubmit = async (event, markAsFinal = false) => {
    event.preventDefault();
    dispatch({ type: 'startSubmit' });

    const input = {
      id: state.textBlock.id,
      name: state.textBlock.name,
      description: state.textBlock.description,
      markAsFinal,
      documents: [...state.textBlock.documents, state.newDocument]
        .filter((x) => x)
        .map((doc) => ({
          _destroy: doc._destroy,
          businessUnitIds: doc.businessUnitIds,
          classification: doc.classification,
          driveId: doc.driveId,
          externalId: doc.externalId,
          id: doc.id,
          jobTitleIds: doc.jobTitleIds,
          location: doc.location,
          name: doc.name,
          retentionPeriod: doc.retentionPeriod ? moment(doc.retentionPeriod).format('YYYY-MM-DD') : null,
          revisionDate: doc.revisionDate ? moment(doc.revisionDate).format('YYYY-MM-DD') : null,
          signedId: doc.signedId,
          staffId: doc.staffId,
          status: markAsFinal ? 'done' : doc.status,
          textBlockId: doc.textBlockId,
          url: doc.url,
        })),
      tables: state.textBlock.tables.map((table) => {
        var index = 0;
        return {
          id: table.id,
          tableRows: table.tableRows.map((tr) => (tr._destroy ? tr : { ...tr, order: index++ })),
        };
      }),
    };

    const [
      {
        updateTextBlock: { errors },
      },
    ] = await request(updateTextBlockMutation, { input });

    if (errors.length) {
      dispatch({ type: 'errors', payload: { errors } });
    } else {
      window.location = backUrl;
    }
  };

  const renderDocument = (doc) => {
    if (doc._destroy) return;

    const index = doc.id || 'newDocument';
    const attr = {
      businessUnits: props.businessUnits,
      canMarkAsFinal: props.canMarkAsFinal,
      classifications: props.classifications,
      dispatch,
      doc,
      hasDrive,
      index,
      jobTitles: props.jobTitles,
      key: index,
      provider,
      staffs: props.staffs,
    };

    return <Document {...attr} />;
  };

  const renderTable = (table) => <Table key={table.id} dispatch={dispatch} table={table} />;

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-9">
        <div className={`card card-task ${status}`}>
          <h1>
            {I18n.t('components.text_block.paragraph', {
              paragraph: state.textBlock.manualParagraph.number,
              name: state.textBlock.manualParagraph.name,
            })}
          </h1>
        </div>

        <TextBlockVersion textBlock={state.textBlock} />

        <div className={`card card-chain card-task ${status}`}>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="text_block_name">{I18n.t('activerecord.attributes.text_block.name')}:</label>
              <input
                name="name"
                type="text"
                className="form-control input-lg"
                value={state.textBlock.name}
                onChange={onChange}
              />
              {renderFieldMessages(state.errors, 'name')}
            </div>
            <div className="form-group">
              <label htmlFor="text_block_description">
                {I18n.t('activerecord.attributes.text_block.description')}:
              </label>
              <Editor content={state.textBlock.description} name="description" onChange={onChange} />
            </div>
          </form>

          {state.textBlock.tables.map(renderTable)}

          <div className="form-group">
            <label htmlFor="documents">{I18n.t('activerecord.attributes.text_block.documents')}</label>
            <div className="clearfix">
              <div className="table-responsive">
                <table className="table documents-table">
                  <thead>
                    <tr>
                      <th>{I18n.t('name', options)}</th>
                      <th>{I18n.t('owner', options)}</th>
                      <th>{I18n.t('classification', options)}</th>
                      <th>{I18n.t('revision_date', options)}</th>
                      <th>{I18n.t('actions', options)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.textBlock.documents.map(renderDocument)}
                    {state.newDocument && renderDocument(state.newDocument)}
                  </tbody>
                </table>
              </div>

              {state.newDocument === null && (
                <div className="pull-right">
                  <Dropdown
                    right={true}
                    button={(props) => (
                      <button
                        {...props}
                        className="btn btn-default"
                        title={I18n.t('add_document', options)}
                        type="button"
                      >
                        {I18n.t('components.text_block.add_document')} <FontAwesomeIcon icon={faPlus} />
                      </button>
                    )}
                  >
                    <li>
                      <Dropdown.Button onClick={onNewFileClick}>
                        {I18n.t('new_document_activestorage', options)}
                      </Dropdown.Button>
                    </li>
                    {hasDrive ? (
                      <li>
                        <SelectFileModal onAdd={onAddExisitingFile}>
                          {I18n.t('new_document_drive', {
                            ...options,
                            provider: provider === 'microsoft_graph' ? 'Sharepoint' : 'Drive',
                          })}
                        </SelectFileModal>
                      </li>
                    ) : (
                      <li>
                        <Dropdown.Button onClick={onNewLinkClick}>
                          {I18n.t('new_document_link', options)}
                        </Dropdown.Button>
                      </li>
                    )}
                  </Dropdown>
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            {props.canMarkAsFinal ? (
              <div className="btn-group">
                <button className="btn btn-primary" disabled={state.isSubmiting} onClick={onSubmit} type="button">
                  {I18n.t('helpers.submit.save')}
                </button>
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="caret"></span>
                  <span className="sr-only"></span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      disabled={state.isSubmiting}
                      className="btn btn-link"
                      onClick={onMarkAsFinalClick}
                      type="button"
                    >
                      {I18n.t('components.document_form.mark_as_final')}
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <button className="btn btn-primary" disabled={state.isSubmiting} onClick={onSubmit} type="button">
                {I18n.t('helpers.submit.save')}
              </button>
            )}
            {state.errors.length > 0 && (
              <div className="alert alert-danger mt-3">{I18n.t('components.text_block.errors')}</div>
            )}
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h3>{I18n.t('comments.header', options)}</h3>
            <hr />

            {state.textBlock.comments.length > 0 && (
              <>
                <ul className="timeline">
                  {state.textBlock.comments.map((comment) => (
                    <li key={comment.id}>
                      <span className="when">{moment(comment.createdAt).format('DD MMMM YYYY HH:mm')}</span>
                      <div className="mini-profile">
                        <img alt={comment.staff.name} className="avatar" src={comment.staff.avatar || DefaultAvatar} />
                        <span className="profile-name">{comment.staff.name}</span>
                      </div>
                      <p className="wrap mb-0">{comment.message}</p>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}

            <form onSubmit={onCommitSubmit}>
              <div className="form-group row">
                <div className="col-sm-9 col-lg-10">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    onChange={onCommentChange}
                    placeholder={I18n.t('comments.placeholder', options)}
                    value={state.message}
                  />
                  {renderFieldMessages(state.commentErrors, 'message')}
                </div>
                <div className="col-sm-3 col-lg-2">
                  <button className="btn btn-primary">{I18n.t('comments.submit', options)}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-3">
        <ul className="timeline">
          {state.textBlock.timelines.map((timeline) => (
            <li key={timeline.id} className={`status-${timeline.status}`}>
              <span className="when">{moment(timeline.createdAt).format('DD MMMM YYYY HH:mm')}</span>
              <div className="mini-profile">
                <img alt={timeline.staff.name} className="avatar" src={timeline.staff.avatar || DefaultAvatar} />
                <span className="profile-name">{timeline.staff.name}</span>
              </div>
              <p className="mb-0">{timeline.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

TextBlock.defaultProps = {
  canMarkAsFinal: false,
};

TextBlock.propTypes = {
  backUrl: PropTypes.string.isRequired,
  businessUnits: PropTypes.array.isRequired,
  canMarkAsFinal: PropTypes.bool,
  classifications: PropTypes.array.isRequired,
  hasDrive: PropTypes.bool.isRequired,
  jobTitles: PropTypes.array.isRequired,
  provider: PropTypes.string,
  staffId: PropTypes.number.isRequired,
  staffs: PropTypes.array.isRequired,
  textBlock: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        message: PropTypes.string,
        staff: PropTypes.shape({
          avatar: PropTypes.string,
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
    documents: PropTypes.array.isRequired,
    manualParagraph: PropTypes.shape({
      name: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    }).isRequired,
    tables: PropTypes.array.isRequired,
    timelines: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        description: PropTypes.string,
        staff: PropTypes.shape({
          avatar: PropTypes.string,
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default TextBlock;
