import React, { useEffect, useReducer } from 'react';
import I18n from '../../i18n-js/index.js.erb';
import moment from 'moment';
import Datetime from 'react-datetime';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

import './previous-manual.scss';

const reducer = (state, { payload, type }) => {
  switch(type) {
    case 'toggleCalendar': return { ...state, toggleCalendar: !state.toggleCalendar };
    case 'dateSelected': return { date: payload, toggleCalendar: false };
  }
};

const PreviousManual = () => {
  const [{ date, toggleCalendar }, dispatch] = useReducer(reducer, { toggleCalendar: false, date: '' });
  const options = { scope: 'components.manual' };

  useEffect(() => {
    if (date != '') {
      document.forms.previous_manual.submit();
    }
  }, [date]);

  const onChange = (date) => {
    dispatch({ type: 'dateSelected', payload: date });
  };

  const onClick = () => {
    dispatch({ type: 'toggleCalendar' });
  };

  return(
    <form className="d-inline-block" id="previous_manual" method="get" action="/manual.pdf">
      <button className="btn btn-link px-1" onClick={onClick} title={I18n.t('previous_manual', options)} type="button">
        <FontAwesomeIcon icon={faClock} />
      </button>
      <input name="date" type="hidden" value={date != '' && date.format('YYYY-MM-DD')} />
      <Datetime
        locale={I18n.locale}
        dateFormat={'DD-MM-YYYY'}
        timeFormat={false}
        input={false}
        onChange={onChange}
        open={toggleCalendar}
        isValidDate={currentDate => currentDate.isBefore(moment())}
      />
    </form>
  );
};

export default PreviousManual;
