import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'jquery';
import 'jquery-ui';
import 'eonasdan-bootstrap-datetimepicker';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-toggle';
import I18n from 'i18n-js';
import moment from 'moment';

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
import ReactRailsUJS from 'react_ujs';

// import all image files in a folder:
require.context('../images', true);

import '../src/array';
import '../src/fetch';
import '../src/object';
import '../src/smartmansys';
import '../src/javascripts';

import '../src/stylesheets/application.scss';

ReactRailsUJS.useContext(componentRequireContext);
Rails.start();
Turbolinks.start();

window.Turbolinks = Turbolinks;
ReactRailsUJS.detectEvents();
delete window.Turbolinks;

var language = document.querySelector('html').lang;
I18n.defaultLocale = language;
I18n.locale = language;

moment.updateLocale('en', {
  week : {
    dow : 1,
    doy : 4
  }
});
moment.locale(language);

$(document).on('turbolinks:visit', function() {
  window.hubspot_live_messages_running = undefined;
});
$(document).on('turbolinks:load', function() {
  $('[data-toggle="popover"]').popover({
    container: 'body',
    trigger: 'hover',
    html: true
  });
  $('[data-toggle="tooltip"]').tooltip();
  $('.spinner').on('click', function(e) { e.target.classList.add('spinning'); });
  $('.modal-dialog').draggable();
  $('input[type="checkbox"].toggle').bootstrapToggle();
});
