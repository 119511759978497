import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import DataSubjectStore from './../stores/data-subject-store';

import DataSubject from './DataSubject';
import Pagination from './Pagination';

class DataSubjects extends React.Component {
  constructor(props) {
    super(props);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onPageClick = this.onPageClick.bind(this);

    this.state = {
      page: 1,
      sort: 'name',
      sortAsc: true,
      data_subjects: [],
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.fetchDataSubjects();
    DataSubjectStore.addChangeListener(this.onStoreChange);
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  componentWillUnmount() {
    DataSubjectStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.fetchDataSubjects();
  }

  fetchDataSubjects() {
    const subjects = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    };

    this.fetching(subjects)
      .then((r) => r.json())
      .then((data) => {
        if (this.state.page <= data.query.totalPages) {
          let state = {
            data_subjects: data.query.data_subjects,
            totalPages: data.query.totalPages,
          };
          this.setState(state);
        } else {
          this.setState(
            { page: data.query.totalPages },
            this.fetchDataSubjects,
          );
        }
      });
  }

  fetching(subjects) {
    const url = new URL(
      'processing_registers/data_subjects',
      `${location.protocol}//${location.host}`,
    );

    url.searchParams.append('page', this.state.page);
    url.searchParams.append('sort', this.state.sort);
    url.searchParams.append('asc', this.state.sortAsc ? 'asc' : 'desc');

    return fetch(url, subjects);
  }

  onPageClick(page) {
    $.scrollTo($('[data-react-class="DataSubjects"]'), 75);
    this.setState({ page }, this.fetchDataSubjects);
  }

  onSortClick(event) {
    event.preventDefault();

    if (this.state.sort === event.target.dataset.sort) {
      this.setState(
        (prevState) => ({ sortAsc: !prevState.sortAsc }),
        this.fetchDataSubjects,
      );
    } else {
      this.setState(
        { sort: event.target.dataset.sort, sortAsc: true },
        this.fetchDataSubjects,
      );
    }
  }

  render() {
    const { data_subjects, totalPages, page, sort, sortAsc } = this.state;
    const { allow_create, deviationId } = this.props;
    const options = { scope: 'components.data_subjects' };

    const backClick = () => {
      const backUrl = deviationId
        ? `/data_leak_deviations/${deviationId}#edit`
        : '/processing_registers';
      Turbolinks.visit(backUrl);
    };

    return (
      <React.Fragment>
        <p>
          <button className="btn btn-link" onClick={backClick}>
            <i className="far fa-chevron-left"></i>{' '}
            {I18n.t(
              deviationId ? 'back_to_dataleak' : 'back_to_processing_registers',
              options,
            )}
          </button>
        </p>
        <div className="heading">
          <h1 className="heading-title">{I18n.t('page_title', options)}</h1>
          <ul className="list-links">
            {allow_create && (
              <button
                className="btn btn-link"
                data-toggle="modal"
                data-target="#newDataSubjectModal"
                data-title={I18n.t('modal.new.title', options)}
                type="button"
              >
                {I18n.t('new_subject', options)}
              </button>
            )}
          </ul>
        </div>

        <div className="card">
          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-4">
                  <button
                    className={`${sort === 'name' ? 'sort' : ''} ${
                      sortAsc ? 'asc' : 'desc'
                    } btn btn-link`}
                    data-sort="name"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('name', options)}
                  </button>
                </div>
                <div className="size-2">{I18n.t('in_use', options)}</div>
                <div className="size-1">{I18n.t('actions', options)}</div>
              </div>
            </div>
            <div className="body">
              {data_subjects.map((d) => (
                <DataSubject key={d.id} data_subject={d} />
              ))}
            </div>
          </div>

          <Pagination
            onPageClick={this.onPageClick}
            page={page}
            totalPages={totalPages}
          />
        </div>
      </React.Fragment>
    );
  }
}

DataSubjects.propTypes = {
  allow_create: PropTypes.bool.isRequired,
  deviationId: PropTypes.number,
};

export default DataSubjects;
