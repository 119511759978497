class SelectToggle {
  constructor(select) {
    this.select = $(select);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.bindEvents();
    this.onChangeEvent({ target: { value: this.select.val() } });
  }

  bindEvents() {
    this.select.on('change', this.onChangeEvent);
  }

  onChangeEvent({ target: { value } }) {
    $(this.select.data('target') + '[data-inverted-select-toggle]').each(
      (i, e) => {
        if (e.dataset.invertedSelectToggle.split(',').includes(value)) {
          $(e).collapse('hide');
        } else {
          $(e).collapse('show');
        }
      },
    );

    $(this.select.data('target') + '[data-select-toggle]').each((i, e) => {
      if (e.dataset.selectToggle.split(',').includes(value)) {
        $(e).collapse('show');
      } else {
        $(e).collapse('hide');
      }
    });
  }
}

export default SelectToggle;

$(document).on('turbolinks:load', () => {
  $('[data-select="toggle"]').each((i, e) => {
    new SelectToggle(e);
  });
});
