import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import KpiConstants from 'constants/kpi-constants';
import { kpiDispatcher } from 'dispatcher/kpi-dispatcher';

class KpiForm{
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      type : 'POST',
      url : this.form.attr('action'),
      data : this.form.serialize(),
      dataType: 'json',
      success: this.successEvent.bind(this)
    });
  }

  successEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof(data.errors) !== 'undefined') {
      $('#modal-nieuwe-taak .modal-body').append('<p class="alert alert-danger error">'+data.errors+'</p>');
      setTimeout(function() {
        $('.error').fadeOut('slow');
      }, 4000);
    } else if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else if (typeof(data.success) !== 'undefined') {
      this.form[0].reset();

      kpiDispatcher.handleCreateKpiAction({
        actionType: KpiConstants.KPI_CREATE,
        kpi: data.kpi
      });
      $('.container-fluid .heading:first').after('<p class="alert alert-success success">'+data.success+'</p>');
      $('#modal-nieuwe-kpi').modal('hide');

      setTimeout(function() {
        $('.success').fadeOut('slow');
      }, 4000);
    }
  }
}

$(document).on('turbolinks:load', function() {
  let form = $('#new_kpi');

  if (form.length > 0) {
    new KpiForm(form);
  }
});
