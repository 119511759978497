import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
  border: none;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  transition: all 0.15s ease-in-out;
  transition-property: background-color, border-color;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const Button = styled(Btn).attrs((props) => ({
  type: 'button',
}))`
  background-color: transparent;
  color: #262626;
  border-radius: 0;
  text-decoration: none;
  transition-property: background-color, border-color, color;
  width: 100%;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Menu = styled.ul`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  list-style: none;
  padding: 0;
  position: absolute;
  z-index: 1;
  ${({ right }) => right && 'right: 0;'}

  ${Button} {
    text-align: ${({ right }) => (right ? 'right' : 'left')};
    white-space: nowrap;
  }
`;

const Line = styled.hr`
  margin: 0;
`;

const Link = (props) => <Button as="a" {...props} />;

const Dropdown = ({ children, button, right }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => setIsOpen(!isOpen);
  const container = useRef();

  const onDocumentClick = ({ target }) => {
    if (container.current.contains(target) && container.current !== target) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  return (
    <Container ref={container}>
      {button({ onClick: onClick })}
      <Menu hidden={!isOpen} right={right}>
        {children}
      </Menu>
    </Container>
  );
};

Dropdown.Button = Button;
Dropdown.Link = Link;
Dropdown.Line = Line;

Dropdown.defaultProps = {
  right: false,
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  button: PropTypes.func,
  right: PropTypes.bool,
};

export default Dropdown;
