import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

class TaskDecline {
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();
    $.ajax({
      url: this.form[0].action,
      type: this.form[0].method,
      data: this.form.serialize(),
      dataType: 'json',
      success: this.onSuccessEvent.bind(this)
    });
  }

  onSuccessEvent(data) {
    Rails.enableElement(this.form[0]);

    if (data.success === 'OK') {
      this.form[0].reset();
      $('#modal-decline-task').modal('hide');
      window.location.reload();
    } else {
      validationErrors(this.form, data.errors);
    }
  }
}

$(document).on('turbolinks:load', () => {
  let form = $('[data-form=decline-task]');
  new TaskDecline(form);
});
