import React from 'react';

class NormCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.onParentChange = this.onParentChange.bind(this);
  }

  onParentChange(event) {
    this.props.onChange({
      actionType: 'norm',
      norm_id: +event.target.value,
      checked: event.target.checked
    });
  }

  render() {
    return(
      <div className="checkbox">
        <label>
          <input
            checked={this.props.norm_ids.includes(this.props.norm.id)}
            name={`${this.props.formName}[norm_ids][]`}
            onChange={this.onParentChange}
            value={this.props.norm.id}
            type="checkbox"
          />
          <i className={`fal ${this.props.norm.checked ? 'fa-check-square' : ''}`}></i> {this.props.norm.name}
        </label>
      </div>
    );
  }
}

export default NormCheckbox;
