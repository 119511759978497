import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

class AssignForm {
  constructor(modal) {
    this.modal = modal;
    this.form = modal.querySelector('form');

    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.submitEvent.bind(this));
  }

  submitEvent(event) {
    event.preventDefault();
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: this.form.method,
      body: $(this.form).serialize(),
      credentials: 'same-origin',
    };

    fetch(this.form.action, options)
      .then((response) => response.json())
      .then(this.succesEvent.bind(this));
  }

  succesEvent(data) {
    Rails.enableElement(this.form);

    if (data.validation_errors !== undefined) {
      validationErrors(this.form, data.validation_errors);
    } else {
      this.form.reset();
      $('.container-fluid .heading:first').after(
        `<p class="alert alert-success success">${data.success}</p>`,
      );
      $('#task_status_id').val(data.status_id);

      $(this.modal).modal('hide');
      setTimeout(() => {
        $('.success').fadeOut('slow');
      }, 4000);
    }
  }
}

$(document).on('turbolinks:load', function () {
  for (let modal of document.querySelectorAll('[id^="modal-change"]')) {
    new AssignForm(modal);
  }
});
