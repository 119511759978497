import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import { RecoilRoot, useRecoilState } from 'recoil';

import { request } from '../../actions';
import { scrollElementIntoView } from '../../utils';
import * as roles from '../../constants/roles';

import { businessUnitIdsState } from './state';
import { initialRequest } from './queries';

import Grid from '../grid';
import Filters from './filters';
import ManualChapterList from './manual-chapter-list';
import ManualParagraph from './manual-paragraph';

function storeFilters(memberId, filters) {
  sessionStorage.setItem(`manual_filters_${memberId}`, JSON.stringify({ businessUnitIds: filters }));
}

const ManualChapter = ({ id, ...props }) => {
  const options = { scope: 'components.manual_chapter' };
  const [businessUnitIds, setBusinessUnitIds] = useRecoilState(businessUnitIdsState);
  const [visible, setVisible] = useState(false);
  const [state, setResponse] = useState({
    anchor: null,
    allowLoading: false,
    currentMember: {},
    businessUnits: [],
    manualChapter: {
      manualParagraphs: [],
    },
    manualChapters: [],
    manualParagraphs: [],
    norms: [],
  });

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const [response, error] = await request(initialRequest, { manualChapterId: id }, controller);
      if (error !== null) return;

      const filter = JSON.parse(sessionStorage.getItem(`manual_filters_${response.currentMember.id}`));

      if (filter) {
        setBusinessUnitIds(filter.businessUnitIds);
      } else if (
        [roles.AUTHOR, roles.WRITER, roles.SUBSCRIBER].includes(response.currentMember.role) &&
        response.currentMember.accessibleBusinessUnitIds.length
      ) {
        let ids;

        if (props.defaultNone) {
          ids = response.currentMember.accessibleBusinessUnitIds;
        } else {
          ids = [0, ...response.currentMember.accessibleBusinessUnitIds];
        }

        setBusinessUnitIds(ids);
        storeFilters(response.currentMember.id, ids);
      }

      setResponse({
        ...response,
        norms: response.normOrganisations.map((no) => no.norm),
        anchor: location.hash,
        allowLoading: location.hash === '',
      });
    })();

    return () => controller.abort();
  }, [id, setBusinessUnitIds, props.defaultNone]);

  const onClearFilter = () => {
    setBusinessUnitIds([]);
    storeFilters(state.currentMember.id, []);
  };

  useEffect(() => {
    if (state.anchor) {
      const element = document.querySelector(state.anchor);
      element && scrollElementIntoView(element);
      setResponse((s) => ({ ...s, allowLoading: true }));
    }
  }, [state.anchor, setResponse]);

  const renderNorm = (norm) => {
    if (state.currentMember.accessNorms) {
      return (
        <a key={norm.id} className="btn btn-link px-1" href={`/norms/${norm.id}`}>
          {norm.name}
        </a>
      );
    } else {
      return (
        <span key={norm.id} className="d-inline-block mx-1 no-wrap">
          {norm.name}
        </span>
      );
    }
  };

  const renderManualChapter = (manualChapter) => {
    if (manualChapter.id === state.manualChapter.id) {
      return (
        <ManualChapterList
          key={state.manualChapter.id}
          currentMember={state.currentMember}
          manualChapter={state.manualChapter}
        />
      );
    } else {
      return (
        <li key={manualChapter.id}>
          <a href={`/manual/manual_chapters/${manualChapter.id}`}>
            <span className="paragraph-number">{manualChapter.number}</span>
            <h6 className="d-inline-block mb-0">{manualChapter.name}</h6>
          </a>
        </li>
      );
    }
  };

  const renderManualParagraph = (manualParagraph) => (
    <ManualParagraph
      key={manualParagraph.id}
      allowLoading={state.allowLoading}
      businessUnits={state.businessUnits}
      currentMember={state.currentMember}
      normOrganisations={state.normOrganisations}
      manualChapterId={state.manualChapter.id}
      manualParagraph={manualParagraph}
      manualParagraphFavorite={state.manualParagraphFavorites.find((f) => f.manualParagraphId === manualParagraph.id)}
      textBlockFavorites={state.textBlockFavorites}
      measure_type_id={props.measure_type_id}
    />
  );

  return (
    <Grid>
      <Grid.Header>
        <Grid.Header.Title
          id={`manual-chapter-${id}`}
          onClearFilter={onClearFilter}
          showClearButton={businessUnitIds.length > 0}
          hideFilter={state.businessUnits.length === 0}
          inProp={visible}
          onToggleFilter={() => setVisible(!visible)}
        >
          {state.manualChapter.number} {state.manualChapter.name}
        </Grid.Header.Title>
        <Grid.Header.Buttons>{state.norms.map(renderNorm)}</Grid.Header.Buttons>
      </Grid.Header>
      <Grid.Content>
        <Grid.Content.Filter inProp={visible}>
          {state.businessUnits.length > 0 && (
            <Filters currentMember={state.currentMember} businessUnits={state.businessUnits} />
          )}
        </Grid.Content.Filter>
        <Grid.Content.Main inProp={visible} style={{ overflow: 'hidden' }}>
          <div className="row">
            <div className="col-md-9">{state.manualChapter.manualParagraphs.map(renderManualParagraph)}</div>

            <div className="col-md-3 hidden-sm hidden-xs sidebar-menu">
              <h5>{I18n.t('sidebar_header', options)}</h5>
              <ul id="list" className="list-links list-links-stacked list-paragraph mb-0">
                {state.manualChapters.map(renderManualChapter)}
              </ul>
            </div>
          </div>
        </Grid.Content.Main>
      </Grid.Content>
    </Grid>
  );
};

ManualChapter.propTypes = {
  defaultNone: PropTypes.bool,
  id: PropTypes.string.isRequired,
  measure_type_id: PropTypes.number.isRequired,
};

const ManualChapterContainer = (props) => (
  <RecoilRoot>
    <ManualChapter {...props} />
  </RecoilRoot>
);

export default ManualChapterContainer;
