import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import AutoComplete from './AutoComplete';

class AnonymousNotificationContainer extends React.Component {
  constructor(props) {
    super(props);

    const { notifications } = props;
    const selectedStaffs = props.staffs.filter(
      (staff) => notifications.find((n) => n.staff_id === staff.id) !== undefined,
    );

    this.scope = { scope: 'components.anonymous_notification_container' };
    this.state = { notifications, selectedStaffs };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  addStaff(_, staffId) {
    const staff = this.props.staffs.find((s) => s.id === staffId);
    this.setState((p) => ({ selectedStaffs: [...p.selectedStaffs, staff] }));

    const { selectedStaffs } = this.state;
    selectedStaffs.map((staff) => {
      this.removeStaff(null, staff.id);
    });

    this.saveNotification(staff);
  }

  saveNotification(staff) {
    $.ajax({
      url: '/organisation/anonymous_notifications',
      method: 'post',
      data: {
        anonymous_notification: {
          staff_id: staff.id,
          anonymous_notification_type: this.props.deviation_type,
        },
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          notifications: this.state.notifications.concat(data.anonymous_notification),
        });
      },
    });
  }

  removeStaff(_, staffId) {
    const notification = this.state.notifications.find((n) => n.staff_id === staffId);

    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((n) => n.staff_id !== staffId),
      selectedStaffs: prevState.selectedStaffs.filter((s) => s.id !== staffId),
    }));

    $.ajax({
      url: `/organisation/anonymous_notifications/${notification.id}`,
      method: 'delete',
      data: {
        id: notification.id,
      },
      dataType: 'json',
    });
  }

  render() {
    const { deviation_type, staffs } = this.props;
    const { selectedStaffs } = this.state;

    const values = staffs.filter((s) => !selectedStaffs.find((s2) => s.id === s2.id));

    return (
      <React.Fragment>
        <p
          dangerouslySetInnerHTML={{
            __html: I18n.t(`description.${deviation_type}`, this.scope),
          }}
        />
        <div className="form-group">
          <AutoComplete
            id={null}
            selectValues={selectedStaffs}
            values={values}
            addValue={this.addStaff.bind(this)}
            removeValue={this.removeStaff.bind(this)}
          />
        </div>
      </React.Fragment>
    );
  }
}

AnonymousNotificationContainer.propTypes = {
  deviation_type: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      staff_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AnonymousNotificationContainer;
