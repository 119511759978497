class DamageAmounts {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents() {
    this.container.on('click', '[data-action="add"]', this.onAddClickEvent.bind(this));
    this.container.on('click', '[data-action="delete"]', this.onDeleteClickEvent.bind(this));
  }

  onAddClickEvent(event) {
    event.preventDefault();

    const template = $('[data-id="damgage_amount_template"]').html().replace(/new_association/g, Date.now());
    $('[data-id="damgage_amount_template"]').before(template);
  }

  onDeleteClickEvent(event) {
    event.preventDefault();
    let el =  $(event.target).parents('.form-group').parent();

    if (el.prev().data('element') === 'destroy') {
      el.prev().val(1);
    }
    el.remove();
  }
}


$(document).on('turbolinks:load', () => {
  $('[data-container="damage_amounts"]').each((_, container) => {
    new DamageAmounts(container);
  });
});
