import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import QuestionnaireConstants from 'constants/questionnaire-constants';
import { questionnaireDispatcher } from 'dispatcher/questionnaire-dispatcher';

class QuestionnaireNewForm {
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      type : 'POST',
      url : this.form.attr('action'),
      data : this.form.serialize(),
      dataType: 'json',
      success: this.onSuccessEvent.bind(this)
    });
  }

  onSuccessEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else if (typeof(data.success) !== 'undefined') {
      this.form[0].reset();

      questionnaireDispatcher.handleCreateQuestionnaireAction({
        actionType: QuestionnaireConstants.QUESTIONNAIRE_CREATE,
        questionnaire: data.questionnaire
      });
      $('.container-fluid .heading:first').after('<p class="alert alert-success success">'+data.success+'</p>');
      $('#modal-nieuwe-questionnaire').modal('hide');

      setTimeout(function() {
        $('.success').fadeOut('slow');
      }, 4000);
    }
  }
}

$(document).on('turbolinks:load', () => {
  let form = $('#new_questionnaire');

  if (form.length > 0) {
    new QuestionnaireNewForm(form);
  }
});
