import styled from 'styled-components';

const Btn = styled.button`
  border: none;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  transition: all 0.15s ease-in-out;
  transition-property: background-color, border-color;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: block;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  transition: all 0.15s ease-in-out;
  transition-property: border-color;
  width: 100%;

  ${(props) =>
    props.hasErrors &&
    `
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: var(--danger-color);
      padding-right: calc(1.5em + 0.75rem);
  `}

  &:focus {
    background-color: #fff;
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }

  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

export const Container = styled(StyledInput)`
  height: auto;
  ${({ focus }) =>
    focus &&
    `border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);`}
`;

export const List = styled.ul`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  list-style: none;
  min-width: 96%;
  max-height: calc(8 * 35px);
  margin: 3px auto 0;
  overflow: scroll;
  padding: 0;
  position: absolute;
  z-index: 1010;
`;

export const Item = styled.li`
  ${({ focus }) => focus && 'background-color: #f5f5f5;'}
  padding: 0.5rem 0.75rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Badge = styled.span`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: inline-block;
  padding: 0 0.5rem;
  margin: 0 0.2rem;
`;

export const Input = styled.input`
  border: none;
  outline: none;
`;

export const Button = styled(Btn)`
  background-color: transparent;
  padding: 0;
`;
