import React from 'react';
import PropTypes from 'prop-types';

const ManualChapter = ({ businessUnitIds, manualChapter }) => {
  const renderManualParagraph = (manualParagraph) => (
    <li key={manualParagraph.id}>
      <span className="paragraph-number">{manualParagraph.number}</span>
      <a href={`/manual/manual_chapters/${manualChapter.id}#manual-paragraph-${manualParagraph.id}`}>
        {manualParagraph.name}
      </a>
    </li>
  );

  let className = 'card card-task';

  if (manualChapter.manualParagraphs.every(p => p.textBlocks.every(t => t.status === 'done'))) {
    className += ' status-done';
  }

  const includeEmpty = businessUnitIds.includes(0);

  const filterManualParagraph = (manualParagraph) => {
    if (businessUnitIds.length) {
      return manualParagraph.textBlocks.some(t => (
        (includeEmpty && t.businessUnitIds.length === 0) || (t.businessUnitIds.some(id => businessUnitIds.includes(id)))
      ));
    } else {
      return true;
    }
  };

  return(
    <div className={className} id={`manual-chapter-${manualChapter.id}`}>
      <h2>
        <a href={`/manual/manual_chapters/${manualChapter.id}`}>
          {manualChapter.number} {manualChapter.name}
        </a>
      </h2>

      <ul className="list-links list-links-stacked list-paragraph">
        {manualChapter.manualParagraphs.filter(filterManualParagraph).map(renderManualParagraph)}
      </ul>
    </div>
  );
};

ManualChapter.propTypes = {
  businessUnitIds: PropTypes.array.isRequired,
  manualChapter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    manualParagraphs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        textBlocks: PropTypes.arrayOf(
          PropTypes.shape({
            status: PropTypes.string.isRequired,
            businessUnitIds: PropTypes.array.isRequired,
          }),
        ),
      }),
    ).isRequired,
  }),
};

export default ManualChapter;
