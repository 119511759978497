import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';
import moment from 'moment';

import DeviationStore from '../stores/deviation-store';

import DeviationTypeFilter from './DeviationTypeFilter';
import Filtering from './Filtering';
import Datetime from 'react-datetime';
import Pagination from './Pagination';

class Deviations extends React.Component {
  constructor(props) {
    super(props);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterChanges = this.onFilterChanges.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.fetchExcel = this.fetchExcel.bind(this);
    this.fetchPDF = this.fetchPDF.bind(this);
    this.clearDateRange = this.clearDateRange.bind(this);

    let activeFilters = {};
    this.filters = {
      business_unit_ids: [],
      damage_amounts: [],
      deviation_category_ids: [],
      deviation_types: [],
      staffs: [],
      statuses: props.statuses.filter((s) => s.position < 4).map((s) => s.id),
    };

    if (window.sessionStorage) {
      activeFilters = JSON.parse(window.sessionStorage.getItem('deviation_filters_' + this.props.organisationId));
      if (activeFilters === null) {
        activeFilters = {};
      } else {
        for (let key of [
          'business_unit_ids',
          'damage_amounts',
          'deviation_category_ids',
          'deviation_types',
          'staffs',
          'statuses',
        ]) {
          this.filters[key] = activeFilters[key] || [];
        }
      }
    }
    this.state = {
      page: activeFilters.page || 1,
      sort: activeFilters.sort || 'id',
      sortAsc: activeFilters.sortAsc || false,
      startDate: activeFilters.startDate,
      endDate: activeFilters.endDate,
      deviations: [],
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.fetchDeviations();
    DeviationStore.addChangeListener(this.onStoreChange);
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  componentWillUnmount() {
    DeviationStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.fetchDeviations();
  }

  fetchExcel(event) {
    event.preventDefault();
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    };
    this.fetching(options)
      .then((r) => r.blob())
      .then((blob) => {
        const filename = I18n.t('components.deviations.export_filename', {
          date: moment().format('YYYYMMDD'),
          ext: 'xlsx',
        });
        window.downloadBlob(blob, filename);
      });
  }

  fetchPDF(event) {
    event.preventDefault();
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/pdf',
      },
    };
    this.fetching(options)
      .then((r) => r.blob())
      .then((blob) => {
        const filename = I18n.t('components.deviations.export_filename', {
          date: moment().format('YYYYMMDD'),
          ext: 'pdf',
        });
        window.downloadBlob(blob, filename);
      });
  }

  fetchDeviations() {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    };
    this.fetching(options)
      .then((r) => r.json())
      .then((data) => {
        if (this.state.page <= data.query.total_pages) {
          let state = {
            deviations: data.query.deviations,
            totalPages: data.query.total_pages,
          };
          this.setState(state);
        } else {
          this.setState({ page: data.query.total_pages }, this.fetchDeviations);
        }
      });

    const filters = {
      ...this.filters,
      page: this.state.page,
      sort: this.state.sort,
      sortAsc: this.state.sortAsc,
    };
    window.sessionStorage.setItem('deviation_filters_' + this.props.organisationId, JSON.stringify(filters));
  }

  fetching(options) {
    const url = new URL('/deviations', `${location.protocol}//${location.host}`);
    url.searchParams.append('start_date', this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : '');
    url.searchParams.append('end_date', this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : '');
    url.searchParams.append('page', this.state.page);
    url.searchParams.append('sort', this.state.sort);
    url.searchParams.append('asc', this.state.sortAsc ? 'asc' : 'desc');
    this.filters.business_unit_ids.forEach((s) => url.searchParams.append('business_unit_ids[]', s));
    this.filters.damage_amounts.forEach((s) => url.searchParams.append('damage_amount_ids[]', s));
    this.filters.deviation_category_ids.forEach((s) => url.searchParams.append('deviation_category_ids[]', s));
    this.filters.deviation_types.forEach((s) => url.searchParams.append('deviation_types[]', s));
    this.filters.staffs.forEach((s) => url.searchParams.append('staff_ids[]', s));
    this.filters.statuses.forEach((s) => url.searchParams.append('statuses[]', s));

    return fetch(url, options);
  }

  onFilterChange(name, items) {
    this.filters = { ...this.filters, [name]: items };
    this.fetchDeviations();
  }

  onFilterChanges(items) {
    this.filters = { ...this.filters, ...items };
    this.fetchDeviations();
  }

  onStartDateChange(startDate) {
    if (typeof startDate !== 'string') {
      this.setState({ startDate }, this.fetchDeviations);
    }
  }

  onEndDateChange(endDate) {
    if (typeof endDate !== 'string') {
      this.setState({ endDate }, this.fetchDeviations);
    }
  }

  clearDateRange() {
    this.setState({ endDate: null, startDate: null }, this.fetchDeviations);
  }

  onSortClick(event) {
    event.preventDefault();
    if (this.state.sort === event.target.dataset.sort) {
      this.setState((prevState) => ({ sortAsc: !prevState.sortAsc }), this.fetchDeviations);
    } else {
      this.setState({ sort: event.target.dataset.sort, sortAsc: true }, this.fetchDeviations);
    }
  }

  onPageClick(page) {
    $.scrollTo($('[data-react-class="Deviations"]'), 75);
    this.setState({ page }, this.fetchDeviations);
  }

  render() {
    const options = { scope: 'components.deviations' };
    const { allow_create, business_units, damage_amounts, deviation_types, staffs, statuses } = this.props;
    const { page, sort, sortAsc, startDate, endDate, deviations, totalPages } = this.state;

    return (
      <React.Fragment>
        <div className="heading">
          <div className="header-grouping">
            <h1 className="heading-title">{I18n.t('header', options)}</h1>
            <button className="btn btn-link" data-toggle="modal" data-target="#image_modal" type="button">
              <span data-toggle="tooltip" title={I18n.t('tooltip.title', options)}>
                <i className="far fa-info-circle"></i>
              </span>
            </button>
          </div>
          <ul className="list-links">
            <li>
              <form
                className="form-inline"
                action={`${location.pathname}.pdf`}
                target="_blank"
                onSubmit={this.fetchPDF}
              >
                <button
                  type="submit"
                  className="btn btn-link"
                  data-toggle="tooltip"
                  title={I18n.t('export_pdf', options)}
                  data-placement="bottom"
                >
                  <i className="far fa-print"></i>
                </button>
              </form>
            </li>
            <li>
              <form
                className="form-inline"
                action={`${location.pathname}.xlsx`}
                target="_blank"
                onSubmit={this.fetchExcel}
              >
                <button
                  type="submit"
                  className="btn btn-link"
                  data-toggle="tooltip"
                  title={I18n.t('export_excel', options)}
                  data-placement="bottom"
                >
                  <i className="far fa-file-excel"></i>
                </button>
              </form>
            </li>
            <DeviationTypeFilter
              initialState={this.filters}
              deviation_categories={this.props.deviation_categories}
              deviation_types={this.props.deviation_types}
              onChange={this.onFilterChange}
              onReset={this.onFilterChanges}
            />
            <Filtering
              initialState={this.filters.business_unit_ids}
              onChange={this.onFilterChange}
              name="business_unit_ids"
              items={business_units}
            />
            <Filtering initialState={this.filters.staffs} onChange={this.onFilterChange} name="staffs" items={staffs} />
            <Filtering
              initialState={this.filters.statuses}
              onChange={this.onFilterChange}
              name="statuses"
              items={statuses}
            />
            <Filtering
              initialState={this.filters.damage_amounts}
              onChange={this.onFilterChange}
              name="damage_amounts"
              items={damage_amounts}
            />
            {allow_create && (
              <li className="dropdown">
                <button className="btn btn-link dropdown-toggle" data-toggle="dropdown" type="button">
                  {I18n.t('new_deviation', options)} <span className="caret"></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-right">
                  {deviation_types.sortByKey('name').map((type) => (
                    <li key={type.id}>
                      <a href={`/deviations/new?deviation_type=${encodeURIComponent(type.code)}`}>
                        {I18n.t(`deviation_types.${type.code}`)}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className="heading">
          <div className="form-inline">
            <div className="form-group">
              <label
                htmlFor="created_at"
                data-toggle="popover"
                title={I18n.t('popover.header', options)}
                data-content={I18n.t('popover.content', options)}
                data-placement="bottom"
              >
                {I18n.t('filter_created_at', options)}
              </label>
            </div>
            <div className="form-group">
              <Datetime
                dateFormat="DD-MM-YYYY"
                inputProps={{ id: 'start_date', name: 'start_date', placeholder: 'DD-MM-YYYY' }}
                locale={I18n.locale}
                onChange={this.onStartDateChange}
                timeFormat={false}
                value={startDate}
              />
            </div>
            <div className="form-group">
              <Datetime
                dateFormat="DD-MM-YYYY"
                inputProps={{ id: 'end_date', name: 'end_date', placeholder: 'DD-MM-YYYY' }}
                locale={I18n.locale}
                onChange={this.onEndDateChange}
                timeFormat={false}
                value={endDate}
              />
            </div>
            <div className="form-group">
              <button
                className="btn btn-link"
                onClick={this.clearDateRange}
                data-toggle="tooltip"
                title={I18n.t('clear_daterange_input', options)}
                data-placement="bottom"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-1">
                  <a
                    href="#"
                    className={`${sort === 'id' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="id"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('id', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'created_at' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="created_at"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('created_at', options)}
                  </a>
                </div>
                <div className="size-4">
                  <a
                    href="#"
                    className={`${sort === 'title' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="title"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('title', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'focus_area' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="focus_area"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('focus_area', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'deviation_type' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="deviation_type"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('deviation_type', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'staff' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="staff"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('staff', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'status' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="status"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('status', options)}
                  </a>
                </div>
                <div className="size-2">
                  <span>{I18n.t('risk_category', options)}</span>
                </div>
              </div>
            </div>
            <div className="body">
              {deviations.map((d) => (
                <Deviation key={d.id} deviation={d} />
              ))}
            </div>
          </div>

          <Pagination onPageClick={this.onPageClick} page={page} totalPages={totalPages} />
        </div>
      </React.Fragment>
    );
  }
}

const Deviation = ({ deviation }) => {
  const onClick = () => Turbolinks.visit(`/deviations/${deviation.id}`);

  return (
    <div data-href={`/deviations/${deviation.id}`} className="flex-row" onClick={onClick}>
      <div title={deviation.id} className="size-1 truncate">
        {deviation.id}
      </div>
      <div title={deviation.created_at} className="size-2 truncate">
        {deviation.created_at}
      </div>
      <div title={deviation.title} className="size-4 truncate">
        {deviation.title}
      </div>
      <div title={deviation.focus_area_name} className="size-2 truncate">
        {deviation.focus_area_name}
      </div>
      <div title={deviation.deviation_type_name} className="size-2 truncate">
        {deviation.deviation_type_name}
      </div>
      <div title={deviation.staff_name} className="size-2 truncate">
        {deviation.staff_name}
      </div>
      <div title={deviation.status_name} className="size-2 truncate">
        {deviation.status_name}
      </div>
      <div title={deviation.risk_category} className="size-2 truncate">
        {deviation.risk_category}
      </div>
    </div>
  );
};

Deviation.propTypes = {
  deviation: PropTypes.shape({
    created_at: PropTypes.string,
    deviation_type_name: PropTypes.string,
    focus_area_name: PropTypes.string,
    id: PropTypes.number,
    risk_category: PropTypes.string,
    staff_name: PropTypes.string,
    status_name: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

Deviations.propTypes = {
  allow_create: PropTypes.bool.isRequired,
  business_units: PropTypes.array.isRequired,
  damage_amounts: PropTypes.array.isRequired,
  deviation_categories: PropTypes.array.isRequired,
  deviation_types: PropTypes.array.isRequired,
  organisationId: PropTypes.number.isRequired,
  staffs: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default Deviations;
