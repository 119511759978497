import React from 'react';

class TableCell extends React.Component {
  constructor(props) {
    super(props);
    this.formName = `${props.formName}[table_cells_attributes][${props.cell.id}]`;
  }

  renderString() {
    return(
      <span dangerouslySetInnerHTML={{__html: this.props.cell.value}} />
    );
  }

  renderImage() {
    return(
      <img alt="cell" src={this.props.cell.image} />
    );
  }

  render() {
    return(
      <td>
        {this.props.cell.image === null ? this.renderString() : this.renderImage()}
        <div>
          {this.props.cell.id ? <input type="hidden" name={`${this.formName}[id]`} value={this.props.cell.id} /> : ''}
          <input type="hidden" name={`${this.formName}[table_column_id]`} value={this.props.cell.column_id} />
          {this.props.cell.default_answer_id ?
            <input type="hidden" name={`${this.formName}[default_answer_id]`} value={this.props.cell.default_answer_id} />
            :
            <input type="hidden" name={`${this.formName}[value]`} value={this.props.cell.value} />
          }
        </div>
      </td>
    );
  }
}

export default TableCell;
