import { dataSubjectDispatcher } from '../dispatcher/data-subject-dispatcher';
import DataSubjectConstants from '../constants/data-subject-constants';

var _subjects = [];
var _listeners = [];

class DataSubjectStore {
  static getAll() {
    return _subjects;
  }

  static setSubjects(subjects) {
    _subjects = subjects;
  }

  static addSubjects(subjects) {
    _subjects = [..._subjects, ...subjects];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners = [..._listeners, callback];
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default DataSubjectStore;

dataSubjectDispatcher.register((payload) => {
  var action = payload.action;

  switch (action.actionType) {
    case DataSubjectConstants.SUBJECT_CREATE:
      _subjects = [..._subjects, action.subject];
      DataSubjectStore.emitChange();
      break;
    case DataSubjectConstants.SUBJECT_DELETE:
      DataSubjectStore.emitChange();
      break;
  }
});
