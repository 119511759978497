import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import ClustorAnswerConstants from 'constants/clustor-answer-constants';
import { clustorAnswerDispatcher } from 'dispatcher/clustor-answer-dispatcher';

class Audit {
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      url : this.form[0].action,
      type : this.form[0].method,
      data : this.form.serialize(),
      dataType: 'json',
      success: this.successEvent.bind(this)
    });
  }

  successEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else if (typeof(data.success) !== 'undefined') {
      this.form[0].reset();

      clustorAnswerDispatcher.handleCreateClustorAnswerAction({
        actionType: ClustorAnswerConstants.CLUSTOR_ANSWER_CREATE,
        clustor_answer: data.clustor_answer
      });
      $('.container-fluid .heading:first').after('<p class="alert alert-success success">'+data.success+'</p>');
      $('#modal-antwoord-clustor').modal('hide');

      setTimeout(function() {
        $('.success').fadeOut('slow');
      }, 4000);
    }
  }
}

$(document).on('turbolinks:load', () => {
  let form = $('[data-form="new_clustor_answer"]');

  if (form.length > 0) {
    new Audit(form);
  }
});
