import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions';
import TaskStore from '../stores/task-store';

const mutation = `
  mutation ProcessingRegisterCompliantFormMutation($input: ToggleCompliantInput!) {
    toggleCompliant(input: $input) {
      processingRegister { 
        compliant
        compliancyLogs {
          id
          compliant
          createdAt
          staff { name }
        }
      }
    }
  }
`;

const ProcessingRegisterCompliantForm = ({ can_compliant, ...props }) => {
  const options = { scope: 'processing_registers' };
  const [processing_register, setProcessingRegister] = useState(props.processing_register);
  const [tasks, setTasks] = useState(props.tasks);

  const onCompliantChangeEvent = async () => {
    const [data, errors] = await request(mutation, { input: { id: props.processing_register.id } });
    if (errors != null) return;

    setProcessingRegister(data.toggleCompliant.processingRegister);
  };

  useEffect(() => {
    const onChange = () => setTasks(TaskStore.getAll());

    TaskStore.addTasks(props.tasks);
    TaskStore.addChangeListener(onChange);
    return () => TaskStore.removeChangeListener(onChange);
  }, [props.tasks]);

  return (
    <div>
      <div className="col-sm-6">
        <label>{I18n.t('compliant', options)}</label>
        {can_compliant ? (
          <div>
            <div className="radio">
              <label>
                <input
                  checked={processing_register.compliant}
                  name="processing_register[compliant]"
                  onChange={onCompliantChangeEvent}
                  type="radio"
                  value={true}
                />
                {I18n.t('compliant_opts.true', options)}
              </label>
            </div>

            <div className="radio">
              <label>
                <input
                  checked={!processing_register.compliant}
                  name="processing_register[compliant]"
                  onChange={onCompliantChangeEvent}
                  type="radio"
                  value={false}
                />
                {I18n.t('compliant_opts.false', options)}
              </label>
            </div>
          </div>
        ) : (
          <div>{I18n.t(`compliant_opts.${processing_register.compliant}`, options)}</div>
        )}

        {processing_register.compliancyLogs.length > 0 && (
          <>
            <label>{I18n.t('show.compliant.marked_by', options)}</label>
            <div className="compliancy-container">
              {processing_register.compliancyLogs.map((log) => (
                <div key={log.id}>
                  {log.staff.name} - {new Date(log.createdAt).toLocaleString()}{' '}
                  <i className={log.compliant ? 'fas fa-check text-success' : 'fas fa-times text-danger'}></i>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className="col-sm-6">
        <label>{I18n.t('show.compliant.tasks', options)}</label>
        {tasks.map((task) => (
          <div className="mb-2" key={task.id}>
            <a href={`/tasks/${task.id}`}>
              {task.name} ({task.status_name})
            </a>
          </div>
        ))}

        {processing_register.compliant === false && (
          <div>
            <a
              href="#modal-nieuwe-taak"
              data-toggle="modal"
              data-processing-register-id={processing_register.id}
              data-description={I18n.t('show.new_task.description', options)}
              data-measure-type-id="1"
            >
              {I18n.t('show.new_task.create', options)}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

ProcessingRegisterCompliantForm.propTypes = {
  can_compliant: PropTypes.bool.isRequired,
  processing_register: PropTypes.shape({
    id: PropTypes.number.isRequired,
    compliant: PropTypes.bool,
    compliancyLogs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        compliant: PropTypes.bool.isRequired,
        staff: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProcessingRegisterCompliantForm;
