import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

function closeTask({target}) {
  fetch(target.dataset.url, {
    body: JSON.stringify({
      authenticity_token: $('[name="csrf-token"]').attr('content')
    }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(() => {
    openRepeatForm();
  });
}

function openRepeatForm() {
  const modal = $('#repeat-task-modal');
  modal.modal('show');
  modal.on('click', '[type="radio"]', onRadioButtonClick);
  modal.on('submit', 'form', onSubmit);

  modal.on('hidden.bs.modal', () => {
    modal.off('click', '[type="radio"]', onRadioButtonClick);
    modal.off('submit', 'form', onSubmit);
  });
}

function onRadioButtonClick({target}) {
  const modal = $('#repeat-task-modal');

  if (target.value === '1') {
    modal.find('[data-element="show"]').show();
  } else {
    modal.find('[data-element="show"]').hide();
  }
}

function onSubmit(event) {
  event.preventDefault();
  const form = event.target;

  fetch(form.action, {
    body: new FormData(form),
    method: form.method
  }).then(r => r.json()).then(r => {
    Rails.enableElement(form);
    if (r.status === 'OK') {
      window.location = '/tasks';
    } else {
      validationErrors($(form), r.validation_errors);
    }
  });
}

$(document).on('turbolinks:load', () => {
  const button = $('[data-action="approve_task"]');

  if (button.length) {
    button.on('click', closeTask);
  }
});
