import React from 'react';

import I18n from 'i18n-js/index.js.erb';

class DeviationAnswerForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);

    this.state = {
      answer: props.deviation_answer.answer,
      errors: {},
      description: props.deviation_answer.description || ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.deviation_answer.id !== nextProps.deviation_answer.id) {
      this.setState({
        answer: nextProps.deviation_answer.answer,
        description: nextProps.deviation_answer.description || ''
      });
    }
  }

  onAnswerChange(event) {
    this.setState({
      answer: event.target.value
    });
  }

  onDescriptionChange(event) {
    this.setState({
      description: event.target.value
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const { deviation_answer, onSubmit } = this.props;
    const { answer, description } = this.state;

    const options = {
      credentials: 'same-origin',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        deviation_answer: {
          answer: answer,
          description
        }
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    fetch(`/deviation_answers/${deviation_answer.id}`, options).then(r => r.json()).then(r => {
      if (r.status === 'ok') {
        this.setState({ errors: {} });
        onSubmit({ id: deviation_answer.id, answer, description });
      } else {
        this.setState({ errors: r.validation_errors });
      }
    });
  }

  renderError(attr) {
    const { errors } = this.state;

    return(
      <div>
        {errors[attr].map(err => <div key={err.length} className="alert alert-danger">{err}</div>)}
      </div>
    );
  }

  render() {
    const { deviation_answer } = this.props;
    const { answer, errors, description } = this.state;
    const scope = { scope: 'components.deviation_answer_form' };

    return(
      <div className="deviation_answers__form">
        <form id="deviation_answer" onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>{I18n.t('question', scope)}</label>
            <p className="wrap form-control" dangerouslySetInnerHTML={{ __html: deviation_answer.question }} />
          </div>
          <div className="form-group" hidden={!deviation_answer.question_info}>
            <label>{I18n.t('question_info', scope)}</label>
            <p className="wrap form-control"
              dangerouslySetInnerHTML={{ __html: deviation_answer.question_info }} />
          </div>

          <div className="form-group">
            <div className="radio">
              <label>
                <input
                  key={deviation_answer.id}
                  id="deviation_answer_answer_yes"
                  checked={answer === 'yes'}
                  name="deviation_answer[answer]"
                  onChange={this.onAnswerChange}
                  type="radio"
                  value="yes"
                />
                {I18n.t('yes', scope)}
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  key={deviation_answer.id}
                  id="deviation_answer_answer_no"
                  checked={answer === 'no'}
                  name="deviation_answer[answer]"
                  onChange={this.onAnswerChange}
                  type="radio"
                  value="no"
                />
                {I18n.t('no', scope)}
              </label>
            </div>
            {errors.answer !== undefined ? this.renderError('answer') : null}
          </div>

          <div className="form-group">
            <label htmlFor="deviation_answer_description">Toelichting</label>
            <textarea
              key={deviation_answer.id}
              className="form-control"
              id="deviation_answer_description"
              name="deviation_answer[description]"
              onChange={this.onDescriptionChange}
              value={description}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary" type="submit">
              {I18n.t('helpers.submit.deviation_answer.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default DeviationAnswerForm;
