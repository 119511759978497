class AuditConstants {
  static get AUDIT_CREATE() {
    return 'audit-create';
  }
  static get AUDIT_UPDATE() {
    return 'audit-update';
  }
  static get AUDIT_DESTROY() {
    return 'audit-destroy';
  }
}

export default AuditConstants;
