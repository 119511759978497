import TableRowConstants from '../constants/table-row-constants';
import { tableRowDispatcher } from '../dispatcher/table-row-dispatcher';

let _table_rows = [];
let _listeners = [];

class TableRowStore {
  static getAll() {
    return _table_rows;
  }

  static addRow(table_row) {
    $.ajax({
      url: '/table_rows',
      method: 'post',
      dataType: 'json',
      data: {
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        table_row: table_row,
      },
      success: (data) => {
        _table_rows.push(data.table_row);
        TableRowStore.emitChange();
      },
    });
  }

  static addTableRows(table_rows) {
    table_rows.forEach((table_row) => {
      const index = _table_rows.map(tr => tr.id).indexOf(table_row.id);
      if (index === -1) {
        _table_rows.push(table_row);
      }
    });
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static updateRows(table_rows) {
    table_rows.forEach(table_row => this.updateRow(table_row));
  }

  static updateRow(table_row) {
    _table_rows = _table_rows.map(tr => tr.id === table_row.id ? table_row : tr);
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default TableRowStore;

tableRowDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case TableRowConstants.TABLE_ROW_CREATE:
      TableRowStore.addRow(action.table_row);
      break;
    case TableRowConstants.TABLE_ROW_UPDATE:
      TableRowStore.updateRow(action.table_row);
      TableRowStore.emitChange();
      break;
    case TableRowConstants.TABLE_ROWS_UPDATE:
      TableRowStore.updateRows(action.table_rows);
      TableRowStore.emitChange();
  }
});
