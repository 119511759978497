import Dispatcher from './dispatcher';

class ClustorAnswerDispatcher extends Dispatcher {
  handleCreateClustorAnswerAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const clustorAnswerDispatcher = new ClustorAnswerDispatcher();
