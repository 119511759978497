import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';

import useManual from '../../hooks/manual';
import { request } from '../../actions';
import { scrollToAnchor, onAnchorLinkClick } from '../../utils';

import { FormCheckbox } from '../form';
import ManualChapter from './manual-chapter';
import Grid from '../grid';
import PreviousManual from './previous-manual';

const grapql = `
  query ManualQuery {
    businessUnits { id name }
    currentMember { id accessNorms accessibleBusinessUnitIds role }
    manualChapters {
      id name number
      manualParagraphs {
        id name number
        textBlocks { id status businessUnitIds }
      }
    }
    norms { id name }
  }
`;

const Manual = (props) => {
  const options = { scope: 'components.manual' };
  const [visible, setVisible] = useState(false);
  const [state, dispatch] = useManual({
    norms: [],
    manualChapters: [],
  });

  const onNoneChange = ({ target: { checked } }) => {
    dispatch({ type: 'toggleNone', payload: { checked } });
  };

  const onBusinessUnitChange = (businessUnit) => {
    dispatch({ type: 'toggleBusinessUnit', payload: { businessUnit } });
  };

  const onClearFilter = () => {
    dispatch({ type: 'clearFilter'})
  };

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const [response, error] = await request(grapql, {}, controller);

      if (error === null) {
        dispatch({ type: 'response', payload: { defaultNone: props.defaultNone, ...response } });
      }
    })();

    return () => controller.abort();
  }, [dispatch, props.defaultNone]);

  useEffect(() => {
    if (state.loading || window.location.hash === '') return;

    scrollToAnchor(window.location.hash);
  }, [state.loading]);

  const renderNorm = (norm) => {
    if (state.currentMember.accessNorms) {
      return(
        <a key={norm.id} className="btn btn-link px-1" href={`/norms/${norm.id}`}>
          {norm.name}
        </a>
      );
    } else {
      return(
        <span key={norm.id} className="d-inline-block mx-1 no-wrap">
          {norm.name}
        </span>
      );
    }
  };

  const renderManualChapter = (manualChapter) => (
    <ManualChapter key={manualChapter.id} manualChapter={manualChapter} businessUnitIds={state.businessUnitIds} />
  );

  const renderListItem = (manualChapter) => (
    <li key={manualChapter.id}>
      <a href={`#manual-chapter-${manualChapter.id}`} onClick={onAnchorLinkClick}>
        {manualChapter.number} {manualChapter.name}
      </a>
    </li>
  );

  return(
    <Grid>
      <Grid.Header>
        <Grid.Header.Title hideFilter={state.businessUnits.length === 0} showClearButton={state.businessUnitIds.length > 0} onClearFilter={onClearFilter} inProp={visible} onToggleFilter={() => setVisible(!visible)}>
          {I18n.t('header', options)}
        </Grid.Header.Title>
        <Grid.Header.Buttons>
          {state.norms.map(renderNorm)}
          <PreviousManual />
          <a className="btn btn-link px-1" download href="/manual.pdf" title={I18n.t('download_manual', options)}>
            <FontAwesomeIcon icon={faPrint} />
          </a>
        </Grid.Header.Buttons>
      </Grid.Header>
      <Grid.Content>
        <Grid.Content.Filter inProp={visible}>
          <Grid.Content.Filter.MultiSelect
            id="businessUnits"
            items={state.businessUnits}
            label={I18n.t('business_units', options)}
            onChange={onBusinessUnitChange}
            placeholder="Filter"
            selectedIds={state.businessUnitIds}
          >
            <FormCheckbox checked={state.businessUnitIds.includes(0)} id="business_unit_none" name="businessUnitIds" onChange={onNoneChange} value="">
              {I18n.t('none', options)}
            </FormCheckbox>
          </Grid.Content.Filter.MultiSelect>
        </Grid.Content.Filter>
        <Grid.Content.Main inProp={visible}>
          <div className="row">
            <div className="col-md-9">
              {state.manualChapters.map(renderManualChapter)}
            </div>

            <div className="col-md-3 hidden-sm" style={{ position: 'sticky', top: '64px' }}>
              <div className="card">
                <h5>{I18n.t('sidebar_header', options)}</h5>
                <ul className="list-links list-links-stacked mb-0">
                  {state.manualChapters.map(renderListItem)}
                </ul>
              </div>
            </div>
          </div>
        </Grid.Content.Main>
      </Grid.Content>
    </Grid>
  );
};

Manual.propTypes = {
  defaultNone: PropTypes.bool,
};

export default Manual;
