import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class JobTitleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      job_titles: props.job_titles.map(job_title => {
        return ({
          id: job_title.id,
          name: job_title.name,
          checked: props.selected_job_titles.map((d) => d.id).indexOf(job_title.id) !== -1
        });
      })
    };
  }

  componentDidMount() {
    $('.modal-dialog').draggable();
  }

  onChangeEvent(event) {
    const job_titles = this.state.job_titles.map((job_title) => {
      if (job_title.id == event.target.value) {
        return {...job_title, ...{ checked: event.target.checked }};
      } else {
        return job_title;
      }
    });

    this.setState({ job_titles });
  }

  onSubmitEvent() {
    this.props.onSubmitEvent(this.state.job_titles);
  }

  render() {
    const id = (Math.random() * 10000).toFixed();

    const job_title = (job_title) => {
      return(
        <div className="form-group" key={job_title.id}>
          <div className="col-xs-12">
            <div className="checkbox">
              <label>
                <input type="checkbox" value={job_title.id} checked={job_title.checked} onChange={this.onChangeEvent.bind(this)} />
                {job_title.name}
              </label>
            </div>
          </div>
        </div>
      );
    };

    return(
      <div>
        <div>
          <a href={`#modal-job_title-${id}`} data-toggle="modal" data-target={`#modal-job_titles-${id}`}>{I18n.t('documents.document.add_job_title')}</a>
        </div>
        <div className="modal fade" id={`modal-job_titles-${id}`} tabIndex="-1" role="dialog" style={{display: 'none'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">{I18n.t('documents.document.header')}</h4>
              </div>
              <div className="modal-body">
                <form>
                  {this.state.job_titles.map(job_title)}
                  <div className="clearfix"></div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
                <button className="btn btn-primary" type="button" onClick={this.onSubmitEvent.bind(this)} data-dismiss="modal">{I18n.t('helpers.submit.save')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobTitleModal;
