import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.onBlurEvent = this.onBlurEvent.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.onClickEvent = this.onClickEvent.bind(this);
    this.onFocusEvent = this.onFocusEvent.bind(this);
    this.onKeyUpEvent = this.onKeyUpEvent.bind(this);
    this.onMenuItemClickEvent = this.onMenuItemClickEvent.bind(this);
    this.onRemoveClickEvent = this.onRemoveClickEvent.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderSelectedValues = this.renderSelectedValues.bind(this);

    this.state = {
      selectedFilterOption: null,
      filterOptions: props.values,
      isMenuOpen: false,
      value: ''
    };
  }

  componentWillReceiveProps(props) {
    this.setState({filterOptions: props.values});
  }

  onClickEvent({ target }) {
    if (target.tagName === 'DIV') {
      this.inputElement.focus();
    }
  }

  onFocusEvent() {
    this.setState({ isMenuOpen: true });
  }

  onBlurEvent(event) {
    this.setState({ isMenuOpen: false });
  }

  onChangeEvent({ target }) {
    const value = target.value.toLowerCase();
    const filterOptions = this.props.values.filter(v => v.name.toLowerCase().indexOf(value) !== -1);
    this.setState({
      filterOptions,
      value: target.value
    });
  }

  onKeyUpEvent(event) {
    if (event.keyCode === 13) {
      const { addValue, addNewValue, id } = this.props;

      if (this.state.selectedFilterOption !== null) {
        addValue(id, this.state.selectedFilterOption.id);
        this.setState({
          selectedFilterOption: null,
          value: ''
        });
      } else if (event.target.value !== '') {
        if (addNewValue) {
          addNewValue(id, event.target.value);
        }
        this.setState({
          selectedFilterOption: null,
          value: ''
        });
      }
    } else if (event.keyCode === 38) {
      const index = this.state.filterOptions.indexOf(this.state.selectedFilterOption);

      if (index > 0) {
        this.setState({selectedFilterOption: this.state.filterOptions[index - 1]});
      }
    } else if (event.keyCode === 40) {
      const index = this.state.filterOptions.indexOf(this.state.selectedFilterOption);

      if (index < (this.state.filterOptions.length - 1)) {
        this.setState({selectedFilterOption: this.state.filterOptions[index + 1]});
      }
    } else {
      this.setState({selectedFilterOption: null});
    }
  }

  onMenuItemClickEvent(event) {
    if (event.target.tagName === 'I') { return; }
    this.props.addValue(this.props.id, parseInt(event.currentTarget.dataset.id));
    this.setState({
      selectedFilterOption: null,
      value: ''
    });
  }

  onRemoveClickEvent(event) {
    this.props.removeValue(this.props.id, parseInt(event.currentTarget.dataset.id));
    this.setState({
      selectedFilterOption: null,
      value: ''
    });
  }

  onRemoveItemClickEvent(event) {
    if (confirm(I18n.t('organisations.resources.index.confirm_destroy'))) {
      this.props.removeResource(parseInt(event.currentTarget.dataset.id));
      this.setState({
        selectedFilterOption: null,
        value: ''
      });
    }
  }

  renderSelectedValues(selectedValue) {
    return(
      <span key={`selectedValue-${selectedValue.id}`} className="selected-value">
        {selectedValue.name}
        <a onClick={this.onRemoveClickEvent} data-id={selectedValue.id}> <i className="fa fa-times"></i></a>
      </span>
    );
  }

  renderOption(option) {
    const { selectedFilterOption } = this.state;
    const cssClass = (selectedFilterOption !== null && selectedFilterOption.id === option.id) ? 'active' : '';

    return(
      <li key={`option-${option.id}`} className={cssClass} data-id={option.id} onMouseDown={this.onMenuItemClickEvent}>
        {option.name}
        {
          this.props.removeResource ?
            <a className="pull-right" data-id={option.id} onMouseDown={this.onRemoveItemClickEvent}><i className="fa fa-times"></i></a>
            : null
        }
      </li>
    );
  }

  render() {
    const { filterOptions, isMenuOpen, value } = this.state;
    return(
      <div className="form-control auto-complete-container" onClick={this.onClickEvent}>
        {this.props.selectValues.map(this.renderSelectedValues)}
        <input
          className="auto-complete"
          onFocus={this.onFocusEvent}
          onBlur={this.onBlurEvent}
          onChange={this.onChangeEvent}
          onKeyUp={this.onKeyUpEvent}
          ref={(el) => this.inputElement = el}
          value={value}
        />
        <ul className={`list-unstyled auto-complete-menu${isMenuOpen ? ' open' : ''}`}>
          {filterOptions.map(this.renderOption)}
        </ul>
      </div>
    );
  }
}

AutoComplete.defaultProps = {
  id: null
};

export default AutoComplete;
