import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import Dropdown from '../dropdown';
import Editor from './editor';
import Modal from '../modal';

const scope = { scope: 'components.text_block.edit_table_cells' };

const EditTableCells = ({ tableColumns, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tableRow, setTableRow] = useState(props.tableRow);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setIsOpen(false);
    props.onSubmit(tableRow);
  };

  const renderColumn = (column) => {
    const cell = tableRow.tableCells.find((c) => c.tableColumnId === column.id);

    const onChange = ({ target: { name, value } }) => {
      const tableCells = tableRow.tableCells.map((c) =>
        c.id === cell.id ? { ...cell, [name]: value } : c,
      );
      setTableRow({ ...tableRow, tableCells });
    };

    if (column.defaultAnswers.length) {
      return renderSelect(column, cell, onChange);
    } else {
      return renderEditor(column, cell, onChange);
    }
  };

  const renderEditor = (column, cell, onChange) => (
    <div key={column.id} className="form-group">
      <label htmlFor="value">{column.name}</label>
      <Editor content={cell.value || ''} name="value" onChange={onChange} />
    </div>
  );

  const renderSelect = (column, cell, onChange) => (
    <div key={column.id} className="form-group">
      <label htmlFor="defaultAnswerId">{column.name}</label>
      <select
        className="form-control"
        id="defaultAnswerId"
        name="defaultAnswerId"
        onChange={onChange}
        value={cell.defaultAnswerId}
      >
        {column.defaultAnswers.map((a) => (
          <option key={a.id} value={a.id}>
            {a.name}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <>
      <Dropdown.Button onClick={() => setIsOpen(true)}>
        {I18n.t('edit', scope)}
      </Dropdown.Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <form onSubmit={onSubmit}>
          <Modal.Header>
            <Modal.Header.Title>{I18n.t('title', scope)}</Modal.Header.Title>
            <Modal.Header.Close
              onClick={onClose}
              aria-label="Close"
              type="button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Modal.Header.Close>
          </Modal.Header>
          <Modal.Body>{tableColumns.map(renderColumn)}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-default" onClick={onClose} type="button">
              {I18n.t('cancel', scope)}
            </button>
            <button className="btn btn-primary">{I18n.t('ok', scope)}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

EditTableCells.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tableRow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    tableCells: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        defaultAnswer: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    ).isRequired,
  }).isRequired,
};

export default EditTableCells;
