import Dispatcher from './dispatcher';

class DataSubjectDispatcher extends Dispatcher {
  handleCreateDataSubjectAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }

  handleDeleteDataSubjectAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const dataSubjectDispatcher = new DataSubjectDispatcher();
