import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import DeviationCommentStore from 'stores/deviation-comment-store';
import DeviationCommentConstants from 'constants/deviation-comment-constants';
import { deviationCommentDispatcher } from 'dispatcher/deviation-comment-dispatcher';

import { validationErrors } from '../src/utils';
import DefaultAvatar from '../images/default-avatar.png';

class DeviationComments extends React.Component {
  constructor(props) {
    super(props);

    this.action = '/deviations/' + props.deviation_id + '/deviation_comments';

    this.bindChangeEvent = this.onChange.bind(this);
    this.state = {
      deviation_comments: props.deviation_comments,
    };
  }

  componentDidMount() {
    DeviationCommentStore.setDeviationComments(this.props.deviation_comments);
    DeviationCommentStore.addChangeListener(this.bindChangeEvent);
  }

  componentWillUnmount() {
    DeviationCommentStore.removeChangeListener(this.bindChangeEvent);
  }

  onChange() {
    this.setState({deviation_comments: DeviationCommentStore.getAll()});
  }

  handleSubmit(event) {
    event.preventDefault();
    let form = event.target;

    $.ajax({
      url: form.action,
      method: form.method,
      data: $(form).serialize(),
      dataType: 'json',
      success: (data) => {
        if (data.success === 'OK') {
          form.reset();
          deviationCommentDispatcher.handleCreateDeviationCommentAction({
            actionType: DeviationCommentConstants.TASK_COMMENT_CREATE,
            deviationComment: data.deviation_comment,
          });
        } else {
          validationErrors($(form), data.errors);
        }
      },
    });
  }

  render() {
    return(
      <div className="card">
        <div className="card-body">
          <h3>{I18n.t('deviations.deviation_comments.header')}</h3>
          <hr/>
          <ul className="timeline">
            {this.state.deviation_comments.map((c, i) => <Comment comment={c} key={i} />)}
          </ul>
          <hr/>
          {
            this.props.allow_create && (
              <form action={this.action} method="post" onSubmit={this.handleSubmit} id="form_deviation_comment">
                <div className="row">
                  <div className="col-sm-9 col-lg-19">
                    <div className="form-group">
                      <textarea id="deviation_comment_message" name="deviation_comment[message]" className="form-control" placeholder={I18n.t('activerecord.placeholders.deviation_comment.message')} />
                    </div>
                    <div className="col-sm-3 col-lg-2">
                      <div className="form-group">
                        <input type="submit" className="btn btn-default btn-block" value={I18n.t('helpers.submit.deviation_comment.create')} />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )
          }
        </div>
      </div>
    );
  }
}

const Comment = ({ comment }) => (
  <li>
    <span className="when">{comment.created_at}</span>
    <div className="mini-profile">
      <img alt={comment.staff_name} className="avatar" src={comment.avatar || DefaultAvatar} />
      <div className="profile-name">{comment.staff_name}</div>
    </div>
    <p className="wrap">{comment.message}</p>
  </li>
);

export default DeviationComments;
