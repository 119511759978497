import Dispatcher from './dispatcher';

class KpiDispatcher extends Dispatcher {
  handleCreateKpiAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const kpiDispatcher = new KpiDispatcher();
