import React from 'react';
import Turbolinks from 'turbolinks';

class TableGrid extends React.Component {
  constructor(props) {
    super(props);

    this.onSortByClick = this.onSortByClick.bind(this);

    this.state = {
      sortBy: props.sortBy || props.columns[0].sortBy || props.columns[0].accessor,
      asc: props.asc ? true : false
    };
  }

  onSortByClick(event) {
    event.preventDefault();

    const column = this.props.columns.find(c => c.header === event.target.innerText);
    const asc = this.state.asc ? this.state.sortBy !== (column.sortBy || column.accessor) : !this.state.asc;

    this.setState({
      sortBy: column.sortBy || column.accessor,
      asc: asc
    });
  }

  sortRecords() {
    if (this.state.sortBy) {
      const nullRecords = this.props.records.filter((record) => {
        return record[this.state.sortBy] === null;
      });
      let records = this.props.records.filter((record) => {
        return record[this.state.sortBy] !== null;
      });
      records = records.sortByKey(this.state.sortBy, !this.state.asc);

      if (this.state.asc) {
        return records.concat(nullRecords);
      } else {
        return nullRecords.concat(records);
      }
    } else {
      return this.props.records;
    }
  }

  renderColumn(column, index) {
    const className = this.state.sortBy === column.accessor ? (this.state.asc ? 'sort asc' : 'sort desc') : '';
    return(
      <th key={index} className={column.accessor === 'id' ? 'id' : null}>
        <button onClick={this.onSortByClick} className={`btn btn-link font-weight-bold ${className}`} type="button">
          {column.header}
        </button>
      </th>
    );
  }

  renderRow(record, index) {
    const { columns } = this.props;
    return(
      <TableGridRow
        key={record.id || index}
        index={record.id || index}
        columns={columns}
        record={record}
      />
    );
  }

  render() {
    return(
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {this.props.columns.map(this.renderColumn.bind(this))}
            </tr>
          </thead>
          <tbody>
            {this.sortRecords().map(this.renderRow.bind(this))}
          </tbody>
        </table>
      </div>
    );
  }
}

class TableGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.renderCell = this.renderCell.bind(this);
  }

  onRowClickEvent(event) {
    Turbolinks.visit(event.target.parentElement.dataset.href);
  }

  renderCell(column, index) {
    const { record } = this.props;
    return(
      <td key={`${this.props.index}-${index}`}>
        {typeof(column.accessor) === 'function' ? column.accessor(record) : record[column.accessor]}
      </td>
    );
  }

  render() {
    const { columns, record } = this.props;
    return(
      <tr data-href={record.url} onClick={this.onRowClickEvent}>
        {columns.map(this.renderCell)}
      </tr>
    );
  }
}

export default TableGrid;
