import React, { useReducer, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions/index';
import { reducer } from '../reducer/index';

import DataOptionLink from './DataOptionLink';

const graphql = `mutation DataOption($name: String!) {
  createDataOption(input: { name: $name }) {
    dataOption { id name }
    errors { field messages }
  }
}`;

const DataOptionLinks = ( props ) => {
  const dataOptionLinks = props.dataOptionLinks.length ? props.dataOptionLinks : [{ uid: Date.now() }];

  const container = useRef(null);
  const [name, setName] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    dataOptionLinks,
    dataOptions: props.dataOptions,
    errors: [],
    addMode: false,
  });

  const onAdd = () => {
    dispatch({ type: 'update', payload: { dataOptionLinks: [...state.dataOptionLinks, { uid: Date.now() }] } });
  };

  const onChange = (dataOptionLink) => {
    const mapFn = (p) => (
      (p.id !== undefined && p.id === dataOptionLink.id) || (p.uid !== undefined && p.uid === dataOptionLink.uid) ? dataOptionLink : p );
    dispatch({ type: 'update' , payload: { dataOptionLinks: state.dataOptionLinks.map(mapFn) } });
  };

  const onDelete = (dataOptionLink) => {
    const filterFn = (p) => (p.uid === undefined || p.uid !== dataOptionLink.uid);
    const mapFn = (p) => (p.id !== undefined && p.id === dataOptionLink.id ? { ...p, destroy: true } : p);

    dispatch({ type: 'update' , payload: { dataOptionLinks: state.dataOptionLinks.filter(filterFn).map(mapFn) } });
  };

  const toggleAddMode = () => {
    dispatch({ type: 'update' , payload: { addMode: true } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const [response] = await request(graphql, { name });
    const { dataOption, errors } = response.createDataOption;


    if (errors.length) {
      dispatch({ type: 'update', payload: { errors } });
    } else {
      const dataOptionLink = { uid: Date.now(), data_option_id: dataOption.id };

      dispatch({
        type: 'update',
        payload: {
          dataOptionLinks: [...state.dataOptionLinks.filter(d => d.data_option_id !== undefined), dataOptionLink],
          dataOptions: [...state.dataOptions, dataOption],
          errors: [],
          addMode: false,
        },
      });
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const renderDataOptionLink = (dataOptionLink) => {
    if (dataOptionLink.destroy) {
      const formName = `processing_register[data_option_links_attributes][${dataOptionLink.id}]`;

      return(
        <div>
          <input name={`${formName}[id]`} value={dataOptionLink.id} type="hidden" />
          <input name={`${formName}[_destroy]`} value={dataOptionLink.destroy} type="hidden" />
        </div>
      );
    }

    return(
      <DataOptionLink
        key={dataOptionLink.id || dataOptionLink.uid}
        dataOptions={state.dataOptions}
        onChange={onChange}
        dataOptionLink={dataOptionLink}
        onDelete={onDelete}
      />
    );
  };

  return(
    <div ref={container}>
      <label>{I18n.t('processing_registers.description_data_name')}</label>
      <div>
        {state.dataOptionLinks.map(renderDataOptionLink)}

        {state.addMode && (
          <form onSubmit={onSubmit}>
            <div className='d-flex form-group justify-content-between'>
              <input
                className='form-control'
                name='name'
                onChange={e => setName(e.target.value)}
                placeholder={I18n.t('processing_registers.placeholders.new_option')}
                value={state.name}
                type='text'
              />
              <button className='btn btn-primary ml-2'>
                {I18n.t('processing_registers.save')}
              </button>
            </div>
            {state.errors.length > 0 && <div className='alert alert-danger'>{state.errors[0].messages[0]}</div>}
          </form>
        )}
        <div className='d-flex justify-content-between'>
          <button className='btn btn-default' onClick={onAdd} type="button">
            <i className="far fa-plus"></i> {I18n.t('processing_registers.add_data_option_link')}
          </button>
          <button className="btn btn-default" data-toggle='tooltip' title={I18n.t('processing_registers.add_data_option')} onClick={toggleAddMode} type="button">
            <i className="far fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

DataOptionLinks.propTypes = {
  dataOptionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      data_option_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dataOptions: PropTypes.array.isRequired,
};

export default DataOptionLinks;
