import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import Filter from '../Filter';

function sortItems(items) {
  return items.sortByKey('name').sortByKey('active', true);
}

const BusinessUnitTextBlock = (props) => {
  const [businessUnits, setBusinessUnits] = React.useState(
    sortItems(props.businessUnits.map(item => (
      { ...item, active: props.businessUnitIds.includes(item.id) }
    ))),
  );

  React.useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const onFilterChange = (id) => {
    const bos = sortItems(businessUnits.map(i => i.id === id ? {...i, active: !i.active } : i));
    setBusinessUnits(bos);

    const body = {
      authenticity_token: document.querySelector('[name="csrf-token"]').content,
      text_block: {
        business_unit_ids: bos.filter(b => b.active).map(b => b.id),
      },
    };
    const options = {
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    };
    fetch(`/text_blocks/${props.id}`, options);
  };

  const options = { scope: 'components.business_unit_text_block' };

  return(
    <ul className="list-links d-inline-block">
      <Filter
        items={businessUnits}
        name="business_units"
        onChange={onFilterChange}
      >
        <i className="far fa-sitemap" title={I18n.t('description', options)} data-toggle="tooltip" data-trigger="hover"></i>
      </Filter>
    </ul>
  );
};

BusinessUnitTextBlock.propTypes = {
  id: PropTypes.string.isRequired,
  businessUnitIds: PropTypes.array.isRequired,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default BusinessUnitTextBlock;
