import Dispatcher from './dispatcher';

class DeviationCommentDispatcher extends Dispatcher {
  handleCreateDeviationCommentAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const deviationCommentDispatcher = new DeviationCommentDispatcher();
