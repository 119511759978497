class QuestionnaireEditForm {
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    $(this.form).find('select').on('change', this.onChangeEvent.bind(this));
  }

  onChangeEvent() {
    $.ajax({
      type: this.form.method,
      url: this.form.action,
      data: $(this.form).serialize(),
      dataType: 'json'
    });
  }
}

$(document).on('turbolinks:load', () => {
  let form = document.querySelector('[data-form="question_edit_form"]');

  if (form) {
    new QuestionnaireEditForm(form);
  }
});
