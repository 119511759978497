import React from 'react';
import Turbolinks from 'turbolinks';

import KpiStore from 'stores/kpi-store';

class Kpis extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.state = {
      direction: 'desc',
      filterKey: props.bsc_name === null ? '' : 'bsc_name',
      filterValue: [props.bsc_name],
      kpis: props.kpis
    };
  }

  componentDidMount() {
    KpiStore.setKpis(this.props.kpis);
    KpiStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    KpiStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({ kpis: KpiStore.getAll() });
  }

  onSortByClickEvent(event) {
    event.preventDefault();
    var sortBy = event.target.search.replace('?sort_by=', '');

    if (this.state.sortBy === sortBy) {
      if (this.state.direction === 'asc') {
        this.setState({direction: 'desc'});
      } else {
        this.setState({direction: 'asc'});
      }
    } else {
      this.setState({sortBy: sortBy, direction: 'asc'});
    }
  }

  onFilterChange(event) {
    event.preventDefault();

    this.setState({
      filterKey: event.target.dataset.filterKey,
      filterValue: event.target.dataset.filterValue === '' ? [] : event.target.dataset.filterValue.split('&').map((v) => {return v.trim();})
    });
  }

  sortAndFilter(kpis) {
    if (this.state.filterValue.length > 0) {
      kpis = kpis.filter((kpi) => {
        var bool = false;
        this.state.filterValue.forEach((value) => {
          if (!bool) {
            bool = kpi[this.state.filterKey] == value;
          }
        });
        return bool;
      });
    }
    return kpis.sortByKey(this.state.sortBy, this.state.direction === 'desc');
  }

  onRowClickEvent(event) {
    event.preventDefault();
    Turbolinks.visit(event.target.parentElement.dataset.href);
  }

  render() {
    var kpiRow = (kpi) => {
      return(
        <tr key={kpi.id} data-href={kpi.url} onClick={this.onRowClickEvent.bind(this)}>
          <td>{kpi.name}</td>
          <td>{kpi.goal}</td>
          <td>{kpi.bsc_name}</td>
        </tr>
      );
    };

    return(
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="heading">
              <h1 className="heading-title">KPI's</h1>
              <ul className="list-links">
                <FilterList name="BSC Onderdelen" allItems="Alle BSC" items={this.props.bsc_types} filterKey="bsc_name" onClickHandler={this.onFilterChange.bind(this)} />
                <li>
                  <a href="#modal-nieuwe-kpi" data-toggle="modal" data-target="#modal-nieuwe-kpi">+ Nieuwe KPI</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th><a href="?sort_by=name" onClick={this.onSortByClickEvent.bind(this)}>Naam</a></th>
                  <th><a href="?sort_by=goal" onClick={this.onSortByClickEvent.bind(this)}>Doel</a></th>
                  <th><a href="?sort_by=bsc_name" onClick={this.onSortByClickEvent.bind(this)}>BSC Onderdeel</a></th>
                </tr>
              </thead>
              <tbody>
                {this.sortAndFilter(this.state.kpis).map(kpiRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Kpis;
