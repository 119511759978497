export const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'onBlur':
      return { ...state, focusItem: {}, focus: false };
    case 'onFocus':
      return { ...state, focus: true };
    case 'moveUp': {
      const index = state.filteredItems.findIndex(
        (i) => i.id === state.focusItem.id,
      );

      if (index !== 0) {
        const item =
          index > 0
            ? state.filteredItems[index - 1]
            : state.filteredItems[state.filteredItems.length - 1];
        return { ...state, focusItem: item };
      } else {
        return state;
      }
    }
    case 'moveDown': {
      const index = state.filteredItems.findIndex(
        (i) => i.id === state.focusItem.id,
      );

      if (index < state.filteredItems.length - 1) {
        const item =
          index >= 0 ? state.filteredItems[index + 1] : state.filteredItems[0];
        return { ...state, focusItem: item };
      } else {
        return state;
      }
    }
    case 'inputChange':
      return { ...state, focusItem: {}, ...payload };
    default:
      throw new Error(`Unknown action: ${type}`);
  }
};
