import { useReducer } from 'react';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'addDirectory': {
      const item = {
        ...state.item,
        children: [...state.item.children, payload.driveItem],
      };
      return {
        ...state,
        item,
        tree: findAndUpdateItem(
          state.tree,
          state.path.slice(0, state.path.length - 1),
          item,
        ),
      };
    }
    case 'close': {
      return {
        ...state,
        isOpen: false,
        item: state.tree,
        selectedItem: null,
        path: [],
      };
    }
    case 'directoryUp': {
      const path = state.path.slice(0, state.path.length - 1);
      const item = findChild(state.tree, [...path]);
      return {
        ...state,
        item,
        selectedItem: null,
        path,
      };
    }
    case 'open':
      return { ...state, isOpen: true };
    case 'setTree': {
      const item = { children: payload.driveItems };
      return { ...state, tree: item, item };
    }
    case 'setAndUpdateItem': {
      const { item, selectedItem } = payload;
      return {
        ...state,
        item,
        selectedItem: selectedItem || null,
        tree: findAndUpdateItem(state.tree, [...state.path], item),
        path: [...state.path, item.externalId].filter(onlyUnique),
      };
    }
    case 'setItem': {
      const { item, selectedItem } = payload;
      return {
        ...state,
        item,
        selectedItem: selectedItem || null,
        path: [...state.path, item.externalId],
      };
    }
    case 'setSelectedItem':
      return { ...state, selectedItem: payload.item };
  }
};

function findChild(tree, path) {
  if (path.length) {
    const externalId = path.shift();
    const child = tree.children.find((c) => c.externalId == externalId);
    return findChild(child, path);
  } else {
    return tree;
  }
}

function findAndUpdateItem(tree, externalIds, item) {
  if (externalIds.length) {
    const externalId = externalIds.shift();
    const child = tree.children.find(
      (child) => child.externalId === externalId,
    );

    return updateTree(tree, findAndUpdateItem(child, externalIds, item));
  } else {
    return updateTree(tree, item);
  }
}

function updateTree(tree, item) {
  return {
    ...tree,
    children: tree.children.map((child) =>
      child.externalId === item.externalId ? item : child,
    ),
  };
}

export const useDrive = () => {
  const [state, dispatch] = useReducer(reducer, {
    isOpen: false,
    item: null,
    path: [],
    selectedItem: null,
    tree: null,
  });

  return [state, dispatch];
};
