export const query = `
  query SelectDocumentQuery($driveId: String, $parentId: String) {
    driveItems(driveId: $driveId, parentId: $parentId) {
      name driveId externalId isDirectory
    }
  }
`;

export const mutation = `
  mutation CreateDirectoryMutation($input: CreateDriveDirectoryInput!) {
    createDriveDirectory(input: $input) {
      driveItem {
        name driveId externalId isDirectory
      }
    }
  }
`;
