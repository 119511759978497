import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';

const Table = ({ table }) => (
  <div className="table-responsive">
    <table className="table">
      <thead>
        <tr>
          {table.tableColumns.map(c => <th key={c.id} style={{ width: c.widthPercentage ? `${c.widthPercentage}%` : 'auto'}}>{c.name}</th>)}
        </tr>
      </thead>
      <tbody>
        {
          table.tableRows.map(r => (
            <tr key={r.id}>
              {r.tableCells.map((c, i) => (
                c.defaultAnswer ?
                  <td key={c.id || i}>
                    <Image alt={c.defaultAnswer.name} src={c.defaultAnswer.url} height={c.defaultAnswer.height} width={c.defaultAnswer.width} />
                  </td>
                  :
                  <td key={c.id || i} dangerouslySetInnerHTML={{__html: c.value}} />
              ))}
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
);

Table.propTypes = {
  table: PropTypes.shape({
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        widthPercentage: PropTypes.number,
      }),
    ).isRequired,
    tableRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        tableCells: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.string,
            defaultAnswer: PropTypes.shape({
              height: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
              width: PropTypes.number.isRequired,
            }),
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
};

export default Table;
