import DeviationConstants from 'constants/deviation-constants';
import { deviationDispatcher } from 'dispatcher/deviation-dispatcher';

let _deviations = [];
let _deviationListeners = [];

class DeviationStore {
  static getAll() {
    return _deviations;
  }

  static setDeviations(deviations) {
    _deviations = deviations;
  }

  static addDeviations(deviations) {
    _deviations = [..._deviations, ...deviations];
  }

  static emitChange() {
    _deviationListeners.forEach((callback) => {
      callback();
    });
  }

  static addChangeListener(callback) {
    _deviationListeners.push(callback);
  }

  static removeChangeListener(callback) {
    _deviationListeners = _deviationListeners.filter(l => l !== callback);
  }
}

export default DeviationStore;

deviationDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case DeviationConstants.DEVIATION_CREATE:
      _deviations.push(action.deviation);
      DeviationStore.emitChange();
  }
});
