import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

const Filter = ({ children, items, name, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const container = useRef();

  const options = { scope: 'components.filtering' };

  const onDocumentClick = ({ target }) => {
    if (container.current !== null && !container.current.contains(target) && container.current !== target) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  const filter = (item) => (
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return(
    <li className="filtering right" ref={container}>
      <button className="btn btn-link" onClick={() => setIsOpen(!isOpen)} type="button">
        {children}
      </button>
      {isOpen && (
        <ul className="filtering__menu">
          {props.onReset !== undefined && (
            <>
              <li className="filtering__menu__item">
                <button className="btn btn-link" onClick={props.onReset} type="button">
                  <span className="check"></span>
                  <span className="name">{I18n.t(`${name}_reset`, options)}</span>
                </button>
              </li>
              <li className="divider"></li>
            </>
          )}
          {items.length > 12 && (
            <>
              <li className="px-3">
                <div className="form-group">
                  <input className="form-control" onChange={(e) => setSearchValue(e.target.value)} placeholder={I18n.t('placeholder', options)} value={searchValue} />
                </div>
              </li>
              <li className="divider"></li>
            </>
          )}
          <div className="items">
            {items.filter(filter).map(s => <FilterItem key={s.id} item={s} onClick={props.onChange} />)}
          </div>
        </ul>
      )}
    </li>
  );
};

Filter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
};

export default Filter;

const FilterItem = ({ item, onClick }) => (
  <li className="filtering__menu__item">
    <button className="btn btn-link" onClick={() => onClick(item.id)} type="button">
      <span className="check">{item.active && <i className="fa fa-check"></i>}</span>
      <span className="name">{item.name}</span>
    </button>
  </li>
);

FilterItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    active: PropTypes.bool,
  }),
  onClick: PropTypes.func.isRequired,
};
