import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class ApiTokens extends React.Component {
  constructor(props) {
    super(props);

    this.onAddApiTokenClick = this.onAddApiTokenClick.bind(this);
    this.onApiTokenChange = this.onApiTokenChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDestroyClick = this.onDestroyClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderApiToken = this.renderApiToken.bind(this);

    this.state = {
      apiTokens: props.apiTokens,
      errors: {},
      newApiTokenName: '',
      showNewForm: false
    };
  }

  onAddApiTokenClick() {
    this.setState({ showNewForm: true, newApiTokenName: '' });
  }

  onCancelClick() {
    this.setState({ showNewForm: false });
  }

  onChange({ target: { name, value }}) {
    this.setState({ [name]: value });
  }

  onDestroyClick(apiToken) {
    fetch(`/organisation/api_tokens/${apiToken.id}`, {
      credentials: 'same-origin',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'DELETE'
    }).then(r => r.json()).then((response) => {
      this.setState(p => ({
        apiTokens: p.apiTokens.filter(a => a.id !== apiToken.id)
      }));
    });
  }

  onApiTokenChange(apiToken) {
    this.setState(p => ({
      apiTokens: p.apiTokens.map(a => a.id === apiToken.id ? apiToken : a)
    }));
  }

  onSubmit(event) {
    event.preventDefault();

    fetch('/organisation/api_tokens', {
      credentials: 'same-origin',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        api_token: {
          name: this.state.newApiTokenName
        }
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }).then(r => r.json()).then((response) => {
      if (response.status === 'OK') {
        this.setState(p => ({
          apiTokens: [...p.apiTokens, response.api_token],
          showNewForm: false
        }));
      } else {
        this.setState(p => ({ errors: response.validation_errors }));
      }
    });
  }

  renderApiToken(apiToken) {
    return(
      <ApiToken
        key={apiToken.id}
        apiToken={apiToken}
        onDestroyClick={this.onDestroyClick}
        onChange={this.onApiTokenChange}
      />
    );
  }

  render() {
    const { apiTokens, errors, newApiTokenName, showNewForm } = this.state;
    const scope = { scope: 'components.api_tokens' };

    return(
      <React.Fragment>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{I18n.t('name', scope)}</th>
              <th>{I18n.t('token', scope)}</th>
              <th>{I18n.t('actions', scope)}</th>
            </tr>
          </thead>
          <tbody>
            {apiTokens.map(this.renderApiToken)}
          </tbody>
        </table>

        {
          showNewForm ?
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="new_api_token_name">{I18n.t('activemodel.attributes.api_token.name')}</label>
                <input className="form-control" id="new_api_token_name" name="newApiTokenName" onChange={this.onChange} value={newApiTokenName} type="text" />
                {errors.name !== undefined ? <div className="alert alert-danger">{errors.name.join(', ')}</div> : null}
              </div>
              <div>
                <button className="btn btn-primary mr-3">
                  {I18n.t('helpers.submit.create', { model: I18n.t('activerecord.models.api_token')})}
                </button>
                <button className="btn btn-default" onClick={this.onCancelClick} type="button">{I18n.t('helpers.submit.cancel')}</button>
              </div>
            </form>
            :
            <button className="btn btn-default" onClick={this.onAddApiTokenClick} type="button">
              <i className="far fa-plus"></i> {I18n.t('add_api_token', scope)}
            </button>
        }

      </React.Fragment>
    );
  }
}

class ApiToken extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDestroyClick = this.onDestroyClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      errors: {},
      name: props.apiToken.name,
      showForm: false
    };
  }

  onChange({ target: { name, value }}) {
    this.setState({ [name]: value });
  }

  onDestroyClick() {
    if(confirm(I18n.t('components.api_tokens.confirm_destroy'))) {
      this.props.onDestroyClick(this.props.apiToken);
    }
  }

  onEditClick() {
    this.setState({ showForm: true });
  }

  onSubmit(event) {
    event.preventDefault();

    fetch(`/organisation/api_tokens/${this.props.apiToken.id}`, {
      credentials: 'same-origin',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        api_token: {
          name: this.state.name
        }
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    }).then(r => r.json()).then((response) => {
      if (response.status === 'OK') {
        this.setState({ showForm: false });
        this.props.onChange(response.api_token);
      } else {
        this.setState(p => ({ errors: response.validation_errors }));
      }
    });
  }

  renderForm() {
    const { errors, name } = this.state;
    return(
      <form className="inline-form" onSubmit={this.onSubmit}>
        <input className="form-control" name="name" onChange={this.onChange} value={name} />
        {errors.name !== undefined ? <div className="alert alert-danger">{errors.name.join(', ')}</div> : null}
      </form>
    );
  }

  render() {
    const { apiToken } = this.props;
    const { showForm } = this.state;

    return(
      <tr>
        <td>
          {showForm ?  this.renderForm() : apiToken.name}
        </td>
        <td>{apiToken.token}</td>
        <td>
          <button className="btn btn-link" onClick={this.onEditClick} title="edit" type="button">
            <i className="fa fa-edit"></i>
          </button>
          <button className="btn btn-link" onClick={this.onDestroyClick} title="delete" type="button">
            <i className="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    );
  }
}

export default ApiTokens;
