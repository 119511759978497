class AuditAnswerConstants {
  static get AUDIT_ANSWER_UPDATE() {
    return 'audit-answer-update';
  }

  static get AUDIT_ANSWER_DESTROY() {
    return 'audit-answer-destroy';
  }
}

export default AuditAnswerConstants;
