import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import moment from 'moment';

import TableGrid from './TableGrid';
import DpiaQuestionnaireStore from '../stores/dpia-questionnaire-store';

class DpiaQuestionnaires extends React.Component {
  constructor(props) {
    super(props);

    this.scope = 'components.dpia_questionnaires';
    this.bindChangeEvent = this.onChange.bind(this);

    this.state = {
      dpia_questionnaires: this.props.dpia_questionnaires,
    };
  }

  componentDidMount() {
    DpiaQuestionnaireStore.set(this.state.dpia_questionnaires);
    DpiaQuestionnaireStore.addChangeListener(this.bindChangeEvent);
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    DpiaQuestionnaireStore.removeChangeListener(this.bindChangeEvent);
  }

  onChange() {
    this.setState({ dpia_questionnaires: DpiaQuestionnaireStore.getAll() });
  }

  column() {
    return [
      {
        header: I18n.t('activemodel.attributes.dpia_questionnaire.id'),
        accessor: 'id',
      },
      {
        header: I18n.t('activemodel.attributes.dpia_questionnaire.name'),
        accessor: 'name',
      },
      {
        header: I18n.t('activemodel.attributes.dpia_questionnaire.description'),
        accessor: 'description',
      },
      {
        header: I18n.t('activerecord.attributes.dpia_questionnaire.created_at'),
        accessor: (record) => moment(record.created_at).format('DD-MM-YYYY'),
      },
      {
        header: I18n.t('activemodel.attributes.dpia_questionnaire.progress'),
        accessor: (dpia_questionnaire) => {
          return (
            <div className="progress mb-0">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={dpia_questionnaire.progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: dpia_questionnaire.progress + '%' }}
              >
                {dpia_questionnaire.progress}%
              </div>
            </div>
          );
        },
      },
      {
        header: I18n.t('action', { scope: this.scope }),
        accessor: (dpia_questionnaire) => {
          return (
            <div>
              {dpia_questionnaire.progress == 100 ? (
                <a
                  href={`/dpia_questionnaires/${dpia_questionnaire.id}/edit`}
                  data-toggle="tooltip"
                  title={I18n.t('edit', { scope: this.scope })}
                >
                  <i className="fa fa-pencil"></i>
                </a>
              ) : (
                <a
                  href={`/dpia_questionnaires/${dpia_questionnaire.id}/`}
                  data-toggle="tooltip"
                  title={I18n.t('compliance', { scope: this.scope })}
                >
                  <i className="far fa-bullhorn"></i>
                </a>
              )}
              &nbsp; &nbsp;
              <a
                href={`/dpia_questionnaires/${dpia_questionnaire.id}/`}
                data-toggle="tooltip"
                title={I18n.t('destroy', { scope: this.scope })}
                data-confirm={I18n.t('confirm', { scope: this.scope })}
                data-method="delete"
              >
                <i className="fa fa-trash"></i>
              </a>
            </div>
          );
        },
      },
    ];
  }

  render() {
    return (
      <div className="card">
        <TableGrid
          columns={this.column()}
          records={this.state.dpia_questionnaires}
        />
      </div>
    );
  }
}

DpiaQuestionnaires.propTypes = {
  dpia_questionnaires: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
    }),
  ),
};

export default DpiaQuestionnaires;
