function onDeleteClick(event) {
  addHiddenDestroyInput(event.currentTarget, 'deviation[attachments_attachments_attributes');
}

function onDeleteAnalysisClick(event) {
  addHiddenDestroyInput(event.currentTarget, 'deviation_analyse_form[analysis_attachments_attachments_attributes]');
}

function addHiddenDestroyInput(currentTarget, name) {
  const { id } = currentTarget.dataset;
  const input = document.createElement('input');
  input.name = `${name}][${id}][id]`;
  input.type = 'hidden';
  input.value = id;
  const inputDestroy = document.createElement('input');
  inputDestroy.name = `${name}][${id}][_destroy]`;
  inputDestroy.type = 'hidden';
  inputDestroy.value = true;

  currentTarget.parentElement.replaceWith(inputDestroy);
  inputDestroy.parentElement.insertBefore(input, inputDestroy);
}

$(document).on('turbolinks:load', () => {
  for(let button of document.querySelectorAll('[data-action="delete_attachment"]')) {
    button.addEventListener('click', onDeleteClick);
  }
  for(let button of document.querySelectorAll('[data-action="delete_analysis_attachment"]')) {
    button.addEventListener('click', onDeleteAnalysisClick);
  }
});
