import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';
import moment from 'moment';

import QuestionnaireStore from '../stores/questionnaire-store';

class Questionnaires extends React.Component {
  constructor(props) {
    super(props);
    QuestionnaireStore.setQuestionnaires(props.questionnaires);

    this.onChange = this.onChange.bind(this);
    this.onRowClickEvent = this.onRowClickEvent.bind(this);
    this.renderRow = this.renderRow.bind(this);

    this.state = {
      questionnaires: props.questionnaires,
    };
  }

  componentDidMount() {
    QuestionnaireStore.addChangeListener(this.onChange);
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    QuestionnaireStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({ questionnaires: [].concat(QuestionnaireStore.getAll()) });
  }

  onRowClickEvent(event) {
    event.preventDefault();
    if (this.props.allow_update) {
      Turbolinks.visit(event.target.parentElement.dataset.link);
    }
  }

  renderRow(questionnaire) {
    return (
      <tr
        key={questionnaire.id}
        data-link={this.props.allow_update ? questionnaire.url : ''}
        onClick={this.onRowClickEvent}
      >
        <td>{questionnaire.id}</td>
        <td>{questionnaire.staff}</td>
        <td>{questionnaire.start_date ? moment(questionnaire.start_date).format('DD-MM-YYYY HH:mm') : ''}</td>
        <td>{questionnaire.end_date ? moment(questionnaire.end_date).format('DD-MM-YYYY HH:mm') : ''}</td>
        <td>{questionnaire.interviewee}</td>
        <td>
          <div className="progress mb-0">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={questionnaire.progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: questionnaire.progress + '%' }}
            >
              {questionnaire.progress}%
            </div>
          </div>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div className="card">
        <ul className="list-links pull-right">
          <li>
            <a
              className="btn btn-link"
              data-toggle="tooltip"
              download={`${this.props.audit.name}.xlsx`}
              href={`/audits/${this.props.audit.id}.xlsx`}
              title={I18n.t('audits.questionnaires.export_excel')}
            >
              <i className="far fa-file-excel"></i>
            </a>
          </li>
        </ul>
        <h2>{I18n.t('audits.questionnaires.header')}</h2>
        <div className="table-responsive">
          <table className={`table table-striped ${this.props.allow_update ? 'table-hover' : ''}`}>
            <thead>
              <tr>
                <th className="id">{I18n.t('audits.questionnaires.id')}</th>
                <th>{I18n.t('audits.questionnaires.staff')}</th>
                <th>{I18n.t('audits.questionnaires.start_date')}</th>
                <th>{I18n.t('audits.questionnaires.end_date')}</th>
                <th>{I18n.t('audits.questionnaires.interviewee')}</th>
                <th>{I18n.t('audits.questionnaires.progress')}</th>
              </tr>
            </thead>
            <tbody>{this.state.questionnaires.map(this.renderRow)}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

Questionnaires.propTypes = {
  allow_update: PropTypes.bool.isRequired,
  audit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  questionnaires: PropTypes.array.isRequired,
};

export default Questionnaires;
