import React from 'react';
import PropTypes from 'prop-types';

const ButtonRole = React.forwardRef(({ as, children, onClick, ...props }, ref) => {
  const onKeyUp = (event) => event.key === 'Enter' && onClick(event);
  const Component = as;
  return(
    <Component onClick={onClick} onKeyUp={onKeyUp} ref={ref} tabIndex="0" role="button" {...props}>
      {children}
    </Component>
  );
});
ButtonRole.displayName = 'ButtonRole';
ButtonRole.defaultProps = {
  as: 'div',
};
ButtonRole.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(PropTypes.node),
  ]),
  onClick: PropTypes.func.isRequired,
};

export default ButtonRole;
