export const validationErrors = function(form, errors) {
  var form_id = $(form).attr('id');
  var form_name = form_id.substring(form_id.indexOf('_') + 1).replace(/_\d+$/, '');

  $(form).find('.alert').remove();

  $.each(errors, function(key, values){
    $.each(values, function(k, v){
      if (key === 'base') {
        $(form).find('.form-group').first().before('<p class="alert alert-danger danger error">'+v+'</p>');
      } else {
        var inputEl = $(form).find('#'+form_name+'_'+key);
        if (inputEl.parent().hasClass('input-group')) {
          inputEl.parent().after('<p class="alert alert-danger danger error">'+v+'</p>');
        } else {
          inputEl.after('<p class="alert alert-danger danger error">'+v+'</p>');
        }
      }
    });

    setTimeout(function() {
      $('.error').fadeOut('slow', function() { this.remove(); });
    }, 1500);
  });
};
