import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class DeviationAnswerTask extends React.Component {
  constructor(props) {
    super(props);

    this.scope = { scope: 'components.deviation_answer_task' };
    this.onChange = this.onChange.bind(this);

    this.state = {
      compliance_answer: props.deviation_answer.compliance_answer === null ? '' : props.deviation_answer.compliance_answer
    };
  }

  onChange(event) {
    this.setState({ compliance_answer: event.target.value === '1' }, this.submit);
  }

  submit() {
    const { deviation_answer } = this.props;
    const { compliance_answer } = this.state;

    const options = {
      credentials: 'same-origin',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        deviation_answer: {
          compliance_answer: compliance_answer
        }
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    fetch(`/deviation_answers/${deviation_answer.id}/compliance_answer`, options);
  }

  stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  renderTaskLink() {
    const { can_update, deviation, deviation_answer, task } = this.props;
    const { compliance_answer } = this.state;

    if (task) {
      return(
        <a href={`/deviations/${deviation.id}/tasks/${task.id}`}>{task.name}</a>
      );
    } else if (can_update && compliance_answer === false) {
      return (
        <a
          data-description={this.stripHtml(deviation_answer[`${deviation_answer.answer}_measure`])}
          data-deviation-id={deviation.id}
          data-deviation-answer-id={deviation_answer.id}
          data-toggle="modal"
          data-target="#modal-nieuwe-taak"
          href="#modal-nieuwe-taak"
        >
          + {I18n.t('new_task', this.scope)}
        </a>
      );
    }
  }

  render() {
    const { can_update, deviation_answer, task } = this.props;
    const { compliance_answer } = this.state;

    return(
      <div>
        {I18n.t('compliant', this.scope)}
        <div className="radio">
          <label>
            <input
              checked={compliance_answer}
              disabled={!can_update || task}
              name={`deviation_answer[${deviation_answer.id}][compliance_answer]`}
              onChange={this.onChange}
              value="1"
              type="radio"
            />
            {I18n.t('yes', this.scope)}
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              checked={compliance_answer === false}
              disabled={!can_update || task}
              name={`deviation_answer[${deviation_answer.id}][compliance_answer]`}
              onChange={this.onChange}
              value="0"
              type="radio"
            />
            {I18n.t('no', this.scope)}
          </label>
        </div>
        {this.renderTaskLink()}
      </div>
    );
  }
}

export default DeviationAnswerTask;
