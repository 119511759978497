import React from 'react';

class StaffSelector extends React.Component {
  constructor(props) {
    super(props);

    this.onFormReset = this.onFormReset.bind(this);
    this.onJobTitleClick = this.onJobTitleClick.bind(this);

    this.state = {
      job_titleIds: []
    };
  }

  componentDidMount() {
    this.form = document.forms.new_questionnaire;
    this.form.addEventListener('reset', this.onFormReset);
    this.job_titleSelectors = document.querySelectorAll('[data-staffselector="job_titles"]');

    [].forEach.call(this.job_titleSelectors, (selector) => {
      selector.addEventListener('click', this.onJobTitleClick);
    });
  }

  componentWillUnmount() {
    this.form.removeEventListener('reset', this.onFormReset);
    [].forEach.call(this.job_titleSelectors, (selector) => {
      selector.removeEventListener('click', this.onJobTitleClick);
    });
  }

  onFormReset() {
    this.setState({ job_titleIds: [] });
  }

  onJobTitleClick(event) {
    let job_titleIds = [];

    if (this.state.job_titleIds.includes(parseInt(event.target.value))) {
      job_titleIds = this.state.job_titleIds.filter((d) => { return d !== parseInt(event.target.value); });
    } else {
      job_titleIds = [].concat(this.state.job_titleIds, parseInt(event.target.value));
    }

    this.setState({ job_titleIds });
  }

  selectedStaffs() {
    return this.props.staffs.filter((staff) => {
      return this.state.job_titleIds.every((id) => { return staff.job_title_ids.includes(id); });
    });
  }

  renderOption(staff) {
    return(
      <option key={staff.id} value={staff.id}>{staff.name}</option>
    );
  }

  render() {
    return(
      <select name={this.props.name} className="form-control">
        <option></option>
        {this.selectedStaffs().map(this.renderOption)}
      </select>
    );
  }
}

export default StaffSelector;
