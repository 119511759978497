import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import ClustorAnswerStore from 'stores/clustor-answer-store';

class ClustorAnswers extends React.Component {
  constructor(props) {
    super(props);

    this.bindChangeEvent = this.onChange.bind(this);
    this.state = {
      direction: 'desc',
      filterKey: '',
      filterValue: [],
      clustor_answers: props.clustor_answers
    };
  }

  componentDidMount() {
    ClustorAnswerStore.setClustorAnswers(this.props.clustor_answers);
    ClustorAnswerStore.addChangeListener(this.bindChangeEvent);
  }

  componentWillUnmount() {
    ClustorAnswerStore.removeChangeListener(this.bindChangeEvent);
  }

  onChange() {
    this.setState({clustor_answers: ClustorAnswerStore.getAll()});
  }

  onSortByClickEvent(event) {
    event.preventDefault();
    var sortBy = event.target.search.replace('?sort_by=', '');

    if (this.state.sortBy === sortBy) {
      if (this.state.direction === 'asc') {
        this.setState({direction: 'desc'});
      } else {
        this.setState({direction: 'asc'});
      }
    } else {
      this.setState({sortBy: sortBy, direction: 'asc'});
    }
  }

  onFilterChange(event) {
    event.preventDefault();

    this.setState({
      filterKey: event.target.dataset.filterKey,
      filterValue: event.target.dataset.filterValue === '' ? [] : event.target.dataset.filterValue.split('&').map((v) => {return v.trim();})
    });
  }

  sortAndFilter(clustor_answers) {
    if (this.state.filterValue.length > 0) {
      clustor_answers = clustor_answers.filter((clustor_answer) => {
        var bool = false;
        this.state.filterValue.forEach((value) => {
          if (!bool) {
            bool = clustor_answer[this.state.filterKey] == value;
          }
        });
        return bool;
      });
    }
    return clustor_answers.sortByKey(this.state.sortBy, this.state.direction === 'desc');
  }

  onRowClickEvent(event) {
    event.preventDefault();
    Turbolinks.visit(event.target.parentElement.dataset.href);
  }

  render() {
    var clustorAnswerRow = (clustor_answer) => {
      return(
        <tr key={clustor_answer.id} data-href={clustor_answer.url} onClick={this.onRowClickEvent.bind(this)}>
          <td>{clustor_answer.id}</td>
          <td>{clustor_answer.name}</td>
          <td>{clustor_answer.rating}</td>
        </tr>
      );
    };

    return(
      <div>
        <div className="card">
          <h2>{I18n.t('audits.clustor_answers.index.clustors')}</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className="id"><a href="?sort_by=id" onClick={this.onSortByClickEvent.bind(this)}>Nr.</a></th>
                  <th><a href="?sort_by=name" onClick={this.onSortByClickEvent.bind(this)}>Naam</a></th>
                  <th><a href="?sort_by=rating" onClick={this.onSortByClickEvent.bind(this)}>Beoordeling</a></th>
                </tr>
              </thead>
              <tbody>
                {this.sortAndFilter(this.state.clustor_answers).map(clustorAnswerRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ClustorAnswers;
