import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions';
import { ADMIN, AUTHOR, WRITER, EDITOR } from '../constants/roles';

const graphql = `
  query ChangeReviewerOnTaskQuery($businessUnitId: ID, $roles: [String!] $statuses: [String!]) {
    staffs(businessUnitId: $businessUnitId, roles: $roles, statuses: $statuses) { id name }
  }
`;

const ChangeReviewerOnTask = ({ businessUnits, ...props }) => {
  const [businessUnitId, setBusinessUnitId] = useState(props.businessUnitId === null ? '' : props.businessUnitId);
  const [reviewerId, setReviewerId] = useState(props.reviewerId || '');
  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const variables = {
        businessUnitId,
        roles: [ADMIN, AUTHOR, EDITOR],
        statuses: ['active', 'pending'],
      };
      const [response, error] = await request(graphql, variables, controller);

      if (error === null) setStaffs(response.staffs);
    })();

    return () => controller.abort();
  }, [businessUnitId]);

  return (
    <>
      {businessUnits.length > 0 && (
        <div className="form-group">
          <label className="control-label col-sm-3" htmlFor="task_business_unit_id">
            {I18n.t('activerecord.attributes.task.reviewer_business_unit_id')}
          </label>
          <div className="col-sm-9">
            <select
              className="form-control"
              id="task_business_unit_id"
              name="task[reviewer_business_unit_id]"
              onChange={({ target: { value } }) => setBusinessUnitId(value)}
              value={businessUnitId}
            >
              <option value=""></option>
              {businessUnits.map((bU) => (
                <option key={bU.id} value={bU.id}>
                  {bU.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div className="form-group">
        <label className="control-label col-sm-3" htmlFor="task_reviewer_id">
          {I18n.t('activerecord.attributes.task.reviewer_id')}
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            id="task_reviewer_id"
            name="task[reviewer_id]"
            onChange={({ target: { value } }) => setReviewerId(value)}
            value={reviewerId}
          >
            <option value=""></option>
            {staffs.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

ChangeReviewerOnTask.propTypes = {
  businessUnitId: PropTypes.number,
  reviewerId: PropTypes.number,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default ChangeReviewerOnTask;
