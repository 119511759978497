import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { Transition } from 'react-transition-group';

import {
  Backdrop,
  Body,
  Content,
  Dialog,
  Footer,
  Header,
  duration,
} from './components';

const Modal = ({ children, isOpen, onClose }) => {
  const backdrop = React.useRef();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    }
    return () => document.body.classList.remove('modal-open');
  }, [isOpen]);

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) onClose();
  };

  const onOutSideClick = ({ target }) => {
    if (backdrop.current === target) onClose();
  };

  return createPortal(
    <Transition
      in={isOpen}
      timeout={{ enter: 0, exit: duration }}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <FocusTrap>
          <Dialog
            open
            onClick={onOutSideClick}
            onKeyDown={onKeyDown}
            role="dialog"
          >
            <Content state={state}>{children}</Content>
            <Backdrop ref={backdrop} state={state} />
          </Dialog>
        </FocusTrap>
      )}
    </Transition>,
    document.body,
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
