import React from 'react';
import Turbolinks from 'turbolinks';
import I18n from '../i18n-js/index.js.erb';

import AuditStore from 'stores/audit-store';

class Audits extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onRowClickEvent = this.onRowClickEvent.bind(this);

    this.state = {
      direction: 'desc',
      filterKey: '',
      filterValue: [],
      audits: props.audits,
    };
  }

  componentDidMount() {
    AuditStore.setAudits(this.props.audits);
    AuditStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    AuditStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({ audits: AuditStore.getAll() });
  }

  onSortByClickEvent(event) {
    event.preventDefault();
    var sortBy = event.target.search.replace('?sort_by=', '');

    if (this.state.sortBy === sortBy) {
      if (this.state.direction === 'asc') {
        this.setState({ direction: 'desc' });
      } else {
        this.setState({ direction: 'asc' });
      }
    } else {
      this.setState({ sortBy, direction: 'asc' });
    }
  }

  onFilterChange(event) {
    event.preventDefault();

    this.setState({
      filterKey: event.target.dataset.filterKey,
      filterValue:
        event.target.dataset.filterValue === '' ? [] : event.target.dataset.filterValue.split('&').map((v) => v.trim()),
    });
  }

  sortAndFilter(audits) {
    if (this.state.filterValue.length > 0) {
      audits = audits.filter((audit) => {
        var bool = false;
        this.state.filterValue.forEach((value) => {
          if (!bool) {
            bool = audit[this.state.filterKey] == value;
          }
        });
        return bool;
      });
    }
    return audits.sortByKey(this.state.sortBy, this.state.direction === 'desc');
  }

  onRowClickEvent(event) {
    event.preventDefault();
    Turbolinks.visit(event.target.parentElement.dataset.href);
  }

  render() {
    const options = { scope: 'components.audits' };

    const auditRow = (audit) => {
      return (
        <tr key={audit.id} data-href={`/audits/${audit.id}`} onClick={this.onRowClickEvent}>
          <td className="id">{audit.id}</td>
          <td>{audit.name}</td>
          <td>{audit.description}</td>
          <td>{audit.scope}</td>
        </tr>
      );
    };

    return (
      <div className="card">
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="id">
                  <a href="?sort_by=id" onClick={this.onSortByClickEvent.bind(this)}>
                    {I18n.t('id', options)}
                  </a>
                </th>
                <th>
                  <a href="?sort_by=name" onClick={this.onSortByClickEvent.bind(this)}>
                    {I18n.t('name', options)}
                  </a>
                </th>
                <th>
                  <a href="?sort_by=description" onClick={this.onSortByClickEvent.bind(this)}>
                    {I18n.t('description', options)}
                  </a>
                </th>
                <th>
                  <a href="?sort_by=scope" onClick={this.onSortByClickEvent.bind(this)}>
                    {I18n.t('scope', options)}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>{this.sortAndFilter(this.state.audits).map(auditRow)}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Audits;
