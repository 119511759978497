import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import ReactDOM from 'react-dom';

class OperationDeviationFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.renderPrimary = this.renderPrimary.bind(this);
    this.renderSecondary = this.renderSecondary.bind(this);
    this.renderTertiary = this.renderTertiary.bind(this);

    const {
      operational_deviation_id, operational_deviation_second_id, operational_deviation_third_id
    } = props.initialState;
    const id = (operational_deviation_third_id || operational_deviation_second_id || operational_deviation_id);

    this.state = {
      activeItem: props.items.find(i => i.id === id) || '',
      primary_id: '',
      secondary_id: '',
      tertiary_id: '',
      open: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state;
    if (open && prevState.open !== open) {
      document.addEventListener('click', this.handleDocumentClick, true);
    } else if (!open && prevState.open !== open) {
      document.removeEventListener('click', this.handleDocumentClick, true);
    }
  }

  handleDocumentClick(e) {
    const container = ReactDOM.findDOMNode(this);
    if (container.contains(e.target) && container !== e.target) {
      return;
    }
    this.setState({ open: false });
  }

  toggleOpen() {
    this.setState(p => ({ open: !p.open }));
  }

  onResetClick() {
    this.setState({
      activeItem: '',
      primary_id: '',
      secondary_id: '',
      tertiary_id: ''
    });
    this.props.onChange({
      operational_deviation_id: null,
      operational_deviation_second_id: null,
      operational_deviation_third_id: null
    });
  }

  renderPrimary(item) {
    const { primary_id } = this.state;
    const onClick = () => {
      this.setState({
        activeItem: item,
        primary_id: item.id,
        secondary_id: '',
        tertiary_id: ''
      });
      this.props.onChange({
        operational_deviation_id: item.id,
        operational_deviation_second_id: null,
        operational_deviation_third_id: null
      });
    };
    return this.renderItem(item, primary_id, onClick);
  }

  renderSecondary(item) {
    const { secondary_id } = this.state;
    const onClick = () => {
      this.setState({
        activeItem: item,
        secondary_id: item.id,
        tertiary_id: ''
      });
      this.props.onChange({
        operational_deviation_second_id: item.id,
        operational_deviation_third_id: null
      });
    };
    return this.renderItem(item, secondary_id, onClick);
  }

  renderTertiary(item) {
    const { tertiary_id } = this.state;
    const onClick = () => {
      this.setState({
        activeItem: item,
        tertiary_id: item.id
      });
      this.props.onChange({
        operational_deviation_third_id: item.id
      });
    };
    return this.renderItem(item, tertiary_id, onClick);
  }

  renderItem(item, id, onClick) {
    return(
      <li key={item.id} className="filtering__menu__item">
        <button className="btn btn-link" onClick={onClick} type="button">
          <span className="check">{id === item.id ? <i className="fa fa-check"></i> : null}</span>
          <span className="name">{item.name}</span>
        </button>
      </li>
    );
  }

  render() {
    const { items } = this.props;
    const { activeItem, open, primary_id, secondary_id } = this.state;
    const options = { scope: 'components.operational_deviation_filtering' };

    const secondaryItems = items.filter(i => i.operational_deviation_id === primary_id);
    const tertiraryItems = items.filter(i => i.operational_deviation_id === secondary_id);

    return(
      <li className="filtering right">
        <button className="btn btn-link" onClick={this.toggleOpen} type="button">
          {activeItem ? activeItem.name : I18n.t('name', options)} <span className="caret"></span>
        </button>
        <ul className={`filtering__menu ${open ? '': ' hidden'}`}>
          <li className="filtering__menu__item">
            <button className="btn btn-link" onClick={this.onResetClick}>
              <span className="check"></span>
              <span className="name">{I18n.t('reset', options)}</span>
            </button>
          </li>
          <div className="divider"></div>
          {items.filter(i => i.operational_deviation_id === null).map(this.renderPrimary)}
          {
            secondaryItems.length > 0 && (
              <React.Fragment>
                <div className="divider"></div>
                {secondaryItems.map(this.renderSecondary)}
              </React.Fragment>
            )
          }
          {
            tertiraryItems.length > 0 && (
              <React.Fragment>
                <div className="divider"></div>
                {tertiraryItems.map(this.renderTertiary)}
              </React.Fragment>
            )
          }
        </ul>
      </li>
    );
  }
}

export default OperationDeviationFilter;
