import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';

class Coordinator extends React.Component {
  constructor(props) {
    super(props);

    this.addCoordinator = this.addCoordinator.bind(this);
    this.removeCoordinator = this.removeCoordinator.bind(this);

    this.state = {
      coordinators: props.coordinators,
      members: props.members
    };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true
    });
  }

  addCoordinator(_, memberId) {
    fetch(
      '/organisation/coordinators',
      {
        credentials: 'same-origin',
        body: JSON.stringify({
          authenticity_token: document.querySelector('[name="csrf-token"]').content,
          coordinator: {
            member_id: memberId,
            deviation_type: this.props.deviation_type
          }
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }
    ).then(r => r.json()).then(response => {
      this.setState(p => ({
        coordinators: [...p.coordinators, response.coordinator]
      }));
    });
  }

  removeCoordinator(_, memberId) {
    const coordinator = this.state.coordinators.find(c => c.member_id === memberId);
    this.setState(p => ({ coordinators: p.coordinators.filter(c => c.id !== coordinator.id) }));

    fetch(
      `/organisation/coordinators/${coordinator.id}`,
      {
        body: JSON.stringify({
          authenticity_token: document.querySelector('[name="csrf-token"]').content,
        }),
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'DELETE'
      }
    );
  }

  formatMembers(member) {
    return {
      id: member.id,
      name: member.staff.name
    };
  }

  render() {
    const { deviation_type } = this.props;
    const { coordinators, members } = this.state;
    const scope = { scope: 'components.coordinator' };

    return (
      <div className="col-sm-12">
        <label>{I18n.t(`title.${deviation_type}`, scope)}</label>
        <div className="form-group">
          <AutoComplete
            addValue={this.addCoordinator}
            removeValue={this.removeCoordinator}
            selectValues={members.filter(m => coordinators.find(c => c.member_id === m.id)).map(this.formatMembers)}
            values={members.filter(m => coordinators.find(c => c.member_id === m.id) === undefined).map(this.formatMembers)}
          />
        </div>
      </div>
    );
  }
}

export default Coordinator;
