class TableRowConstants {
  static get TABLE_ROW_CREATE() {
    return 'table-row-create';
  }
  static get TABLE_ROW_UPDATE() {
    return 'table-row-update';
  }
  static get TABLE_ROWS_UPDATE() {
    return 'table-rows-update';
  }
}

export default TableRowConstants;
