import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class NormExplanationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: props.norm_explanation.content,
      has_deviated: props.norm_explanation.has_deviated
    };
  }

  componentDidMount() {
    $('.modal-dialog').draggable();
  }

  onCloseClickEvent(event) {
    this.props.event({
      id: this.props.norm_explanation.id,
      content: this.state.content,
      has_deviated: this.state.has_deviated
    });
  }

  onContentChangeEvent(event) {
    this.setState({
      content: event.target.value
    });
  }

  onHasDeviatedChangEvent(event) {
    this.setState({
      has_deviated: event.target.checked
    });
  }

  render() {
    return (
      <div>
        <a href={`#norm_explanation_modal_${this.props.norm_explanation.id}`} data-toggle="modal"><i className="fa fa-pencil"></i></a>
        <div id={`norm_explanation_modal_${this.props.norm_explanation.id}`} className="modal fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">{I18n.t('reference_tables.show.norm_explanation_modal.header')}</h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="norm_explanation_content">Toelichting</label>
                  <textarea
                    className="form-control"
                    id="norm_explanation_content"
                    name={`${this.props.formName}][content]`}
                    onChange={this.onContentChangeEvent.bind(this)}
                    rows="20"
                    value={this.state.content}
                  />
                </div>

                <div className="form-group">
                  <div className="checkbox">
                    <label>
                      <input type="hidden" name={`${this.props.formName}][has_deviated]`} value="0" />
                      <input
                        type="checkbox"
                        name={`${this.props.formName}][has_deviated]`}
                        onChange={this.onHasDeviatedChangEvent.bind(this)}
                        value="1"
                        checked={this.state.has_deviated}
                      />
                      Afwijkend
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.onCloseClickEvent.bind(this)}
                  data-dismiss="modal"
                >{I18n.t('helpers.submit.save')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NormExplanationModal;
