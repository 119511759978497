import React, { useCallback, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';

import { request } from '../../actions';

import SortLink from '../ui/sort-link';

const options = { scope: 'components.self_assessment' };
const graphql = `
  query AddQuestionnaireQuery($businessUnitIds: [ID!], $jobTitleIds: [ID!], $name: String, $sortBy: String, $sortDirection: String) {
    members(businessUnitIds: $businessUnitIds, jobTitleIds: $jobTitleIds, name: $name, sortBy: $sortBy, sortDirection: $sortDirection, statusses: ["active", "pending"]) {
      staff { id name }
    }
  }
`;
const mutation = `
  mutation AddSaQuestionnnaireMutation($input: AddSaQuestionnaireInput!) {
    addSaQuestionnaire(input: $input) {
      saQuestionnaires { id }
    }
  }
`;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'addAvailable': {
      const staffs = payload.members.map((m) => m.staff);
      const available = staffs.filter(
        (m) =>
          !(
            payload.excludeStaffs.findIndex((x) => x.id === m.id) >= 0 ||
            state.selected.findIndex((x) => x.id === m.id) >= 0
          ),
      );
      return { ...state, available };
    }
    case 'moveToSelected': {
      return {
        available: state.available.filter((a) => a.id !== payload.id),
        selected: [...state.selected, payload],
      };
    }
    case 'moveToAvailable': {
      return {
        available: [...state.available, payload],
        selected: state.selected.filter((a) => a.id !== payload.id),
      };
    }
    case 'added':
      return { ...state, selected: [] };
  }
};

const AddQuestionanire = ({ excludeStaffs, selfAssessmentId, refreshCallback }) => {
  const [{ businessUnits, jobTitles }, setState] = useState({
    businessUnits: [],
    jobTitles: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sendInvite, setSendInvite] = useState(true);
  const [staffs, dispatch] = useReducer(reducer, {
    available: [],
    selected: [],
  });
  const [filtering, setFiltering] = useState({
    name: '',
    businessUnitId: '',
    jobTitleId: '',
    sortBy: 'name',
    sortDirection: 'asc',
  });

  const onChange = ({ target: { name, value } }) => setFiltering({ ...filtering, [name]: value });
  const onSort = (sortBy, sortDirection) => setFiltering({ ...filtering, sortBy, sortDirection });
  const toggleIsOpen = useCallback(() => setIsOpen((i) => !i), []);

  const onInvite = async () => {
    setIsSubmitting(true);
    const variables = {
      input: {
        selfAssessmentId,
        sendInvite,
        staffIds: staffs.selected.map((s) => s.id),
      },
    };
    await request(mutation, variables);
    refreshCallback();
    dispatch({ type: 'added' });
    setIsOpen(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const graphql = `
        query {
          businessUnits { id name }
          jobTitles { id name }
        }
      `;
      const [response, error] = await request(graphql, {}, controller);

      if (error === null) {
        setState(response);
      }
    })();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    const controller = new AbortController();

    (async () => {
      const variables = {
        name: filtering.name,
        businessUnitIds: filtering.businessUnitId !== '' ? [filtering.businessUnitId] : null,
        jobTitleIds: filtering.jobTitleId !== '' ? [filtering.jobTitleId] : null,
        sortBy: filtering.sortBy,
        sortDirection: filtering.sortDirection,
      };
      const [response, error] = await request(graphql, variables, controller);

      if (error === null) {
        dispatch({
          type: 'addAvailable',
          payload: { ...response, excludeStaffs },
        });
      }
    })();

    return () => controller.abort();
  }, [dispatch, excludeStaffs, filtering, isOpen]);

  useEffect(() => {
    const button = document.querySelector('[data-action="addQuesionnaire"]');
    if (button === null) return;

    button.addEventListener('click', toggleIsOpen);
    return () => button.removeEventListener('click', toggleIsOpen);
  }, [toggleIsOpen]);

  if (!isOpen) return null;

  const renderStaff = (staff) => (
    <div className="flex-row" key={staff.id}>
      <div className="size-1">{staff.name}</div>
      <div className="size-1">
        <button
          className="btn btn-default pull-right"
          onClick={() => dispatch({ type: 'moveToSelected', payload: staff })}
          type="button"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );

  const renderSelectedStaff = (staff) => (
    <tr key={staff.id}>
      <td>
        <button
          className="btn btn-default"
          onClick={() => dispatch({ type: 'moveToAvailable', payload: staff })}
          type="button"
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>{' '}
        {staff.name}
      </td>
    </tr>
  );

  return (
    <div className="card">
      <div className="row">
        <div className="col-xs-8">
          <div className="py-3">
            <b>{I18n.t('select', options)}</b>
          </div>
          <form className="form-inline mb-2">
            <div className="form-group mr-3">
              <input
                autoComplete="off"
                className="form-control"
                name="name"
                placeholder={I18n.t('employee', options)}
                onChange={onChange}
                type="text"
                value={filtering.name}
              />
            </div>
            <div className="form-group mr-3">
              <select
                className="form-control"
                name="businessUnitId"
                onChange={onChange}
                value={filtering.businessUnitIds}
              >
                <option value="">{I18n.t('business_units', options)}</option>
                {businessUnits.map((bu) => (
                  <option key={bu.id} value={bu.id}>
                    {bu.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <select className="form-control" name="jobTitleId" onChange={onChange} value={filtering.jobTitleIds}>
                <option value="">{I18n.t('job_titles', options)}</option>
                {jobTitles.map((j) => (
                  <option key={j.id} value={j.id}>
                    {j.name}
                  </option>
                ))}
              </select>
            </div>
          </form>

          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-1">
                  <SortLink
                    sorting="name"
                    onSort={onSort}
                    sortBy={filtering.sortBy}
                    sortDirection={filtering.sortDirection}
                  >
                    {I18n.t('activerecord.attributes.staff.name')}
                  </SortLink>
                </div>
              </div>
            </div>
            <div className="body" style={{ maxHeight: 'calc(3 * 62px)', overflow: 'scroll' }}>
              {staffs.available.map(renderStaff)}
            </div>
          </div>
        </div>

        <div className="col-xs-4">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{I18n.t('selected', options)}</th>
              </tr>
            </thead>
            <tbody>{staffs.selected.map(renderSelectedStaff)}</tbody>
          </table>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  checked={sendInvite}
                  name="sendInvite"
                  onChange={() => setSendInvite(!sendInvite)}
                  type="checkbox"
                />
                {I18n.t('send_invite', options)}
              </label>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary mr-3"
              disabled={staffs.selected.length === 0 || isSubmitting}
              onClick={onInvite}
            >
              {I18n.t('invite', options)}
            </button>
            <button className="btn btn-default" onClick={toggleIsOpen} type="button">
              {I18n.t('helpers.submit.cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddQuestionanire.propTypes = {
  excludeStaffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selfAssessmentId: PropTypes.number.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

export default AddQuestionanire;
