import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import Dropdown from '../dropdown';

const options = { scope: 'components.filtering' };

const DropdownFilter = ({
  children,
  ids,
  items,
  onClick,
  onResetClick,
  textReset,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('');

  const onChange = ({ target: { value } }) => setSearchValue(value);

  const filter = (item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase());

  return (
    <Dropdown {...props}>
      {onResetClick && (
        <>
          <li>
            <Dropdown.Button onClick={onResetClick}>
              {textReset}
            </Dropdown.Button>
          </li>
          <hr className="my-0" />
        </>
      )}
      {items.length > 12 && (
        <>
          <li className="px-3">
            <div className="form-group my-2">
              <input
                className="form-control"
                onChange={onChange}
                placeholder={I18n.t('placeholder', options)}
                value={searchValue}
              />
            </div>
          </li>
          <hr className="my-0" />
        </>
      )}
      {children}
      {items.filter(filter).map((item) => (
        <li key={item.id}>
          <Dropdown.Button onClick={onClick.bind(null, item.id)}>
            <Item ids={ids} item={item} />
          </Dropdown.Button>
        </li>
      ))}
    </Dropdown>
  );
};

DropdownFilter.propTypes = {
  button: PropTypes.func.isRequired,
  children: PropTypes.node,
  ids: PropTypes.array.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func,
  textReset: PropTypes.string,
};

const Item = ({ ids, item }) => (
  <div className="d-flex justify-content-between">
    <div className="mr-2" style={{ width: '16px' }}>
      {ids.includes(item.id) && <i className="fa fa-check"></i>}
    </div>
    <span>{item.name}</span>
  </div>
);

Item.propTypes = {
  ids: PropTypes.array.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

DropdownFilter.Item = Item;

export default DropdownFilter;
