import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import I18n from '../../i18n-js/index.js.erb';

import { faFilter, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { FormGroup, FormCheckbox } from '../form';

import './styles.scss';

const timeout = {
  appear: 0,
  enter: 0,
  exit: 200,
};

const Grid = ({ children }) => (
  children
);

Grid.Header = ({ children }) => (
  <div className="grid__header">{children}</div>
);

const Title = ({ children, id, inProp, hideFilter, onToggleFilter, showClearButton, onClearFilter }) => {
  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.target.tagName === 'BODY' && event.key === 's') {
        onToggleFilter();
      }
    };
    if (!hideFilter) {
      document.addEventListener('keyup', onKeyUp);
      return () => document.removeEventListener('keyup', onKeyUp);
    }
  }, [hideFilter, onToggleFilter]);

  return(
    <CSSTransition in={inProp} timeout={timeout} classNames="grid__header__title">
      <div className="grid__header__title">
        {!hideFilter && (
          <>
            <h2 className="filter-title">Filter</h2>
            <button className="btn btn-default" onClick={onToggleFilter} title="Filter(s)" type="button">
              <FontAwesomeIcon icon={faFilter} />
            </button>
            {showClearButton && (
              <button className="btn btn-default ml-2" onClick={onClearFilter} type="button">
                <FontAwesomeIcon icon={faTimes} className='text-danger'/> {I18n.t('components.manual.clear_filter')}
              </button>
            )}
          </>
        )}
        <h1 id={id} className="grid-title">{children}</h1>
      </div>
    </CSSTransition>
  );
};

Grid.Header.Title = Title;
Grid.Header.Title.defaultProps = {
  hideFilter: false,
};

Grid.Header.Buttons = ({ children }) => (
  <div className="grid__header_buttons">{children}</div>
);

Grid.Content = ({ children }) => (
  <div className="grid__content">{children}</div>
);

Grid.Content.Main = ({ children, inProp, ...props }) => (
  <CSSTransition in={inProp} timeout={timeout} classNames="main" {...props}>
    <div className="main">
      {children}
    </div>
  </CSSTransition>
);

Grid.Content.Filter = ({ children, inProp }) => (
  <CSSTransition in={inProp} timeout={timeout} classNames="filter" unmountOnExit>
    <div className="filter">
      <div className="card">
        {children}
      </div>
    </div>
  </CSSTransition>
);

Grid.Content.Filter.Search = (props) => {
  const onChange = ({ target: { value } }) => props.onChange(value);

  return (
    <FormGroup id={props.id} label="name" onChange={onChange} type="search" />
  );
};

Grid.Content.Filter.Search.defaultProps = {
  id: 'search',
};

Grid.Content.Filter.Search.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

Grid.Content.Filter.DateBetween = () => {
  return(
    <div></div>
  );
};

const filterSearch = (value) => i => i.name.toLowerCase().includes(value.toLowerCase());

Grid.Content.Filter.MultiSelect = ({ children, items, onChange, selectedIds, ...props }) => {
  const [value, setValue] = useState('');

  const renderItem = (item) => (
    <FormCheckbox key={item.id} checked={selectedIds.includes(item.id)} id={item.name} onChange={() => onChange(item)}>{item.name}</FormCheckbox>
  );

  return(
    <div>
      <FormGroup onChange={(e) => setValue(e.target.value)} {...props} />
      <div className="multi-select-box">
        {children}
        {items.filter(filterSearch(value)).map(renderItem)}
      </div>
    </div>
  );
};

Grid.Content.Filter.MultiSelect.defaultProps = {
  selectedIds: [],
};

Grid.Content.Filter.MultiSelect.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Grid;
