import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { request } from '../../actions';

const options = { scope: 'components.self_assessment' };
const graphql = `
  mutation DestroySaQuestionnaireMutation($input: DestroySaQuestionnaireInput!) {
    destroySaQuestionnaire(input: $input) {
      saQuestionnaire { id }
    }
  }
`;

const DeleteButton = ({ id, onDelete }) => {
  const [isInFlight, setIsInFlight] = useState(false);

  const onClick = async (event) => {
    event.stopPropagation();

    if (confirm(I18n.t('confirm_destroy', options))) {
      setIsInFlight(true);
      await request(graphql, { input: { id } });
      setIsInFlight(false);
    }

    onDelete();
  };

  React.useEffect(() => { $('[data-toggle="tooltip"]').tooltip(); });

  return(
    <button className="btn btn-link py-0" data-toggle="tooltip" disabled={isInFlight} onClick={onClick} title={I18n.t('delete', options)} type="button">
      <FontAwesomeIcon icon={faTrashAlt} />
    </button>
  );
};

DeleteButton.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;
