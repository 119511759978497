import React from 'react';
import PropTypes from 'prop-types';

export const FormGroup = ({ label, ...props}) => (
  <div className="form-group">
    <label htmlFor={props.id}>{label}</label>
    <input className="form-control" {...props} />
  </div>
);

FormGroup.defaultProps = {
  type: 'text',
};

FormGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export const FormCheckbox = ({ children, valid, messages, ...props }) => {
  return (
    <div className="checkbox">
      <label htmlFor={props.id}>
        <input {...props} />
        {children}
      </label>
      {renderError(messages)}
    </div>
  );
};

FormCheckbox.defaultProps = {
  type: 'checkbox',
  valid: true,
};

FormCheckbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  messages: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  valid: PropTypes.bool,
};

export const renderError = (errors) => {
  if (errors === undefined) return;
  return errors.map((error, i) => <div key={i} className="alert alert-danger">{error}</div>);
};

export const renderFieldMessages = (errors, attribute) => {
  const error = errors.find(e => e.field === attribute);
  if (error === undefined) return;
  return renderError(error.messages);
};
