class SessionTimeout {
  constructor() {
    document.cookie = 'SessionActive=' + new Date().getTime() + ';path=/;';
    this.logoutTimout = 60000 * 30; // 30 minutes
    this.interval;
  }

  inactiveTime() {
    var inactiveCookieTime = new Date(+document.cookie.replace(/(?:(?:^|.*;\s*)SessionActive\s*=\s*([^;]*).*$)|^.*$/, '$1'));
    return new Date().getTime() - inactiveCookieTime.getTime();
  }

  endSession(){
    $.ajax({
      url: '/sessions',
      type: 'delete',
      dataType: 'json',
      data: { authenticity_token: document.querySelector('[name="csrf-token"]').content },
      complete: () => {
        clearInterval(this.interval);
      },
      success: ({ location }) => {
        window.location = location;
      },
      error: () => {
        window.location = '/';
      }
    });
  }

  check(){
    var inactiveTime = this.inactiveTime();

    if(inactiveTime > this.logoutTimout){
      this.endSession();
    }
  }

  setSessionsInterval(){
    this.interval = setInterval(this.check.bind(this), 5000);
  }
}

$(document).on('turbolinks:load', () => {
  if(window.location.pathname != '/sessions/new'){
    const session = new SessionTimeout();

    $(document).mousemove(function (e) {
      document.cookie = 'SessionActive=' + new Date().getTime() + ';path=/;';
    });

    $(document).keypress(function (e) {
      document.cookie = 'SessionActive=' + new Date().getTime() + ';path=/;';
    });

    session.setSessionsInterval();
  }
});
