import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block/text-block';

import { graphql, textBlockQuery } from '../text-block/text-block-queries';
import { request } from '../../actions';

const query = `
  query TaskQuery($id: ID!) {
   ${graphql}
    task(id: $id) {
      textBlockId
      textBlock { ${textBlockQuery} }
    }
  }
`;

const TaskTextBlock = ({ id, ...props }) => {
  const [{ task, ...state }, setState] = React.useState({});

  React.useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const [response, error] = await request(query, { id }, controller);
      setState({
        ...response,
        task: {
          ...response.task,
          textBlock: {
            ...response.task.textBlock,
            documents: response.task.textBlock.documents.map((d) => ({
              ...d,
              errors: [],
            })),
          },
        },
      });
    })();

    return () => controller.abort();
  }, [id]);

  if (task === undefined) return null;

  return <TextBlock textBlock={task.textBlock} {...state} {...props} />;
};

TaskTextBlock.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TaskTextBlock;
