import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

const DataSubjectLink = ({ onChange, dataSubjectLink, dataSubjects, ...props }) => {
  const dataSubject = dataSubjects.find(d => d.id === dataSubjectLink.data_subject_id);
  const formName = `processing_register[data_subject_links_attributes][${dataSubjectLink.id || dataSubjectLink.uid}]`;
  const onDelete = () => props.onDelete(dataSubjectLink);

  const onDataSubjectChange = ({ target: { value }}) => {
    onChange({ ...dataSubjectLink, data_subject_id: parseInt(value) });
  };

  return (
    <div>
      <div className='d-flex form-group justify-content-between' >
        <select
          className='form-control'
          name={`${formName}[data_subject_id]`}
          onChange={onDataSubjectChange}
          value={dataSubject ? dataSubject.id : ''}
        >
          <option disabled={true} value=''>{I18n.t('processing_registers.select_subject')}</option>
          {dataSubjects.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
        </select>
        <input name={`${formName}[id]`} value={dataSubjectLink.id} type="hidden" />
        <button className="btn btn-link-danger" onClick={onDelete} type="button">
          <i className="far fa-times"></i>
        </button>
      </div>
    </div>
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.id && !props.uid) {
    return new Error(`One of 'id' or 'uid' is required by '${componentName}' component.`);
  }
};

DataSubjectLink.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dataSubjectLink: PropTypes.shape({
    id: requiredPropsCheck,
    uid: requiredPropsCheck,
    data_subject_id: PropTypes.number,
  }).isRequired,
  dataSubjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DataSubjectLink;
