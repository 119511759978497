$(document).on('turbolinks:before-cache', function () {
  for (var name in window.CKEDITOR.instances) {
    window.CKEDITOR.instances[name].destroy();
  }
});

$(document).on('turbolinks:load', function () {
  if ($('#text_block_description').length === 0) { return; }

  if (window.CKEDITOR.instances['text_block_description']) {
    window.CKEDITOR.instances['text_block_description'].destroy();
  }
  window.CKEDITOR.replace('text_block_description', {
    language: 'nl',
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles'] },
      { name: 'paragraph',   groups: ['list', 'indent'] },
      { name: 'links,',      groups: ['links'] },
    ],
    removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript',
    removeDialogTabs: 'link:advanced',
  });

  $('[data-action=remove-image]').on('click', function(event) {
    event.preventDefault();
    var el = $(this).parents('[data-element="document"]');
    el.fadeOut();
    el.find('[data-element=destroy]').val('1');
  });

  $('[data-action="mark_as_final"]').on('click', function(event) {
    event.preventDefault();
    $('[name="mark_as_final"]').val('1');
    $('.form').submit();
  });
});
