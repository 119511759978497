import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

$(document).on('turbolinks:load', function () {
  var form = $('#new_comment_form');

  form.submit(function(e){
    e.preventDefault();

    $.ajax({
      type : form.attr('method'),
      url : form.attr('action'),
      data : form.serialize(),
      dataType: 'json',
      success: onSuccessEvent
    });
  });

  var onSuccessEvent = function(data) {
    Rails.enableElement(this.form[0]);

    if (data.errors) {
      validationErrors(form, data.errors);
    } else {
      $('ul.timeline#comments').append('<li><span class="when">Net nu</span><div class="mini-profile"><img class="avatar" alt="Default avatar" src="'+data.avatar.thumb.url+'"><div class="profile-name">'+data.staff_name+'</div></div><p>'+data.message+'</p></li>');
      $('ul.timeline#right_block').append('<li class="status-review"><span class="when">Net nu</span><div class="mini-profile"><img class="avatar" alt="Default avatar" src="'+data.avatar.thumb.url+'"><div class="profile-name">'+data.staff_name+'</div></div><p>Reactie geschreven</p></li>');
      form[0].reset();
    }
  };
});
