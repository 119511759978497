import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import Dropdown from '../dropdown';
import EditTableCells from './edit-table-cells';

const options = { scope: 'text_blocks.edit.tables' };

const arrayMoveTo = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

const TableRow = ({ dispatch, index, table, tableRow }) => {
  const onChange = (tableRow) => {
    const tableRows = table.tableRows.map((row) => (row.id === tableRow.id ? tableRow : row));

    dispatch({
      type: 'updateTable',
      payload: { table: { ...table, tableRows } },
    });
  };

  const onDestroyClick = () => {
    const tableRows = table.tableRows.map((row) => (row.id === tableRow.id ? { ...tableRow, _destroy: true } : row));

    dispatch({
      type: 'updateTable',
      payload: { table: { ...table, tableRows } },
    });
  };

  const onDownClick = () => {
    const tableRows = arrayMoveTo(table.tableRows, index, index + 1).map((row, i) => ({ ...row, order: i }));

    dispatch({
      type: 'updateTable',
      payload: { table: { ...table, tableRows } },
    });
  };

  const onUpClick = () => {
    const tableRows = arrayMoveTo(table.tableRows, index, index - 1).map((row, i) => ({ ...row, order: i }));

    dispatch({
      type: 'updateTable',
      payload: { table: { ...table, tableRows } },
    });
  };

  const renderCell = (cell) => {
    if (cell.defaultAnswerId) {
      const { defaultAnswers } = table.tableColumns.find((c) => c.id === cell.tableColumnId);
      const defaultAnswer = defaultAnswers.find((a) => a.id === cell.defaultAnswerId);

      return (
        <td key={cell.id}>
          <img alt="cell" src={defaultAnswer.url} />
        </td>
      );
    } else {
      return <td key={cell.id} dangerouslySetInnerHTML={{ __html: cell.value }} />;
    }
  };

  return (
    <tr>
      <td>
        <Dropdown
          button={(props) => (
            <button className="btn btn-default" {...props} type="button">
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
          )}
        >
          <li>
            <EditTableCells onSubmit={onChange} tableColumns={table.tableColumns} tableRow={tableRow} />
          </li>
          <li>
            <Dropdown.Button disabled={tableRow.order === 0} onClick={onUpClick}>
              {I18n.t('up', options)}
            </Dropdown.Button>
          </li>
          <li>
            <Dropdown.Button disabled={tableRow.order === table.tableRows.length - 1} onClick={onDownClick}>
              {I18n.t('down', options)}
            </Dropdown.Button>
          </li>
          <Dropdown.Line />
          <li>
            <Dropdown.Button onClick={onDestroyClick}>{I18n.t('delete', options)}</Dropdown.Button>
          </li>
        </Dropdown>
      </td>
      {tableRow.tableCells.map(renderCell)}
    </tr>
  );
};

TableRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  table: PropTypes.shape({
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    tableRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  tableRow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    tableCells: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        defaultAnswer: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    ).isRequired,
  }).isRequired,
};

export default TableRow;
