import Rails from '@rails/ujs';
import DeviationConstants from 'constants/deviation-constants';
import { deviationDispatcher } from 'dispatcher/deviation-dispatcher';
import { validationErrors } from '../utils';

class DeviationModal {
  constructor(modal) {
    this.modal = modal;
    this.form = modal.find('form');
    this.bindEvents();
  }

  bindEvents() {
    this.modal.on('show.bs.modal', this.onModelShow.bind(this));
    this.form.on('submit', this.onSubmitEvent.bind(this));
    this.form.on('click', '[data-action="add"]', this.onAddClickEvent.bind(this));
    this.form.on('click', '[data-action="delete"]', this.onDeleteClickEvent.bind(this));
  }

  onModelShow(event) {
    const button = event.relatedTarget;

    if (button.dataset.deviationType) {
      this.modal.find('#deviation_deviation_type').val(button.dataset.deviationType);
      this.modal.find('#deviation_deviation_type').change();
    }

    if (button.dataset.deviationFocusArea) {
      this.modal.find('#focus_area').val(button.dataset.focusArea);
    }

    if (button.dataset.dpiaAnswerId) {
      this.modal.find('#deviation_dpia_answer_id').val(button.dataset.dpiaAnswerId);
    }

    if (button.dataset.saQuestionId) {
      this.modal.find('#deviation_sa_question_id').val(button.dataset.saQuestionId);
    }

    if (button.dataset.description) {
      this.modal.find('#deviation_description').val(button.dataset.description);
    }
  }

  onSubmitEvent(event) {
    event.preventDefault();
    $.ajax({
      url: this.form[0].action,
      type: this.form[0].method,
      data: new FormData(this.form[0]),
      processData: false,
      contentType: false,
      dataType: 'json',
      success: this.onSuccessEvent.bind(this)
    });
  }

  onAddClickEvent(event) {
    event.preventDefault();

    const template = $('[data-template="deviation_document"]').html().replace(/new_association/g, Date.now());
    $('[data-template="deviation_document"]').before(template);
  }

  onDeleteClickEvent(event) {
    event.preventDefault();

    let el = $(event.target).parents('div').first().find('[data-element="destroy"]');
    if (el.length) {
      el.val(1);
      $(event.target).parents('.form-group').hide();
    } else {
      $(event.target).parents('.form-group').remove();
    }
  }

  onSuccessEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else if (typeof(data.success) !== 'undefined') {
      this.form[0].reset();

      deviationDispatcher.handleCreateDeviationAction({
        actionType: DeviationConstants.DEVIATION_CREATE,
        deviation: data.deviation
      });

      $('.container-fluid .heading:first').after(`<p class="alert alert-success success">${data.success}</p>`);
      this.modal.modal('hide');
    }
  }
}

$(document).on('turbolinks:load', () => {
  const modal = $('#new-deviation-modal');

  if (modal.length) {
    new DeviationModal(modal);
  }
});
