import Dispatcher from './dispatcher';

class TableRowDispatcher extends Dispatcher {
  handleChangeTableRowAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const tableRowDispatcher = new TableRowDispatcher();
