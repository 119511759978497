import React from 'react';

import JobTitleModal from './JobTitleModal';

class DocumentJobTitles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_job_titles: props.job_titles.filter(j => props.selected_job_title_ids.includes(j.id))
    };
  }

  onSubmitEvent(job_titles) {
    const selected_job_title_ids = job_titles.filter(j => j.checked).map(j => j.id);
    const selected_job_titles = this.props.job_titles.filter(j => selected_job_title_ids.includes(j.id));

    this.setState({
      selected_job_titles: selected_job_titles
    }, this.onChange);
  }

  onChange() {
    const { onChange } = this.props;

    if (onChange) {
      const job_title_ids = this.state.selected_job_titles.map(j => j.id);
      onChange({ job_title_ids });
    }
  }

  render() {
    let jobTitleLabel = (job_title) => {
      return(
        <span key={job_title.id} className="label label-default">{job_title.name}</span>
      );
    };

    let jobTitleInput = (job_title) => {
      return(
        <input key={job_title.id} type="hidden" name={this.props.name} value={job_title.id} />
      );
    };

    return(
      <div>
        <div className="form-group label-group">
          {this.state.selected_job_titles.map(jobTitleLabel)}
        </div>
        <input type="hidden" name={this.props.name} value="" />
        {this.state.selected_job_titles.map(jobTitleInput)}
        <JobTitleModal job_titles={this.props.job_titles} selected_job_titles={this.state.selected_job_titles} onSubmitEvent={this.onSubmitEvent.bind(this)} />
      </div>
    );
  }
}

export default DocumentJobTitles;
