const resetMaxHeight = () => {
  const questions = document.querySelector('[data-element="dpia-questions-list"]');

  if (questions) {
    const dpiaAnswer = document.querySelector('[data-element="dpia-answer"]');
    questions.style.maxHeight = `${dpiaAnswer.getBoundingClientRect().height - 113}px`;
  }
};
document.addEventListener('turbolinks:load', resetMaxHeight);
window.addEventListener('resize', resetMaxHeight);
