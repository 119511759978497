import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AuditAnswerStore from 'stores/audit-answer-store';

import AuditAnswerForm from './AuditAnswerForm';
import QuestionnaireQuestion from './QuestionnaireQuestion';

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuccessEvent = this.onSuccessEvent.bind(this);
    this.saveSummary = this.saveSummary.bind(this);
    this.toggleQuestionBlock = this.toggleQuestionBlock.bind(this);
    this.toggleSummaryBlock = this.toggleSummaryBlock.bind(this);
    this.visibleAuditAnswer = this.visibleAuditAnswer.bind(this);
    this.visibleNextAuditAnswer = this.visibleNextAuditAnswer.bind(this);
    this.visiblePreviousAuditAnswer = this.visiblePreviousAuditAnswer.bind(this);
    this.renderAuditAnswerForm = this.renderAuditAnswerForm.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);

    AuditAnswerStore.addAuditAnswers(props.audit_answers);

    const audit_answer = props.audit_answers.find((ia) => ia.answer === '');
    const visible_audit_answer_id = audit_answer
      ? audit_answer.id
      : props.audit_answers.length
        ? props.audit_answers[0].id
        : 0;

    this.state = {
      audit_answers: props.audit_answers,
      showQuestionBlock: true,
      showSummaryBlock: false,
      summary: this.props.summary || '',
      visible_audit_answer_id,
    };
  }

  componentDidMount() {
    AuditAnswerStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    AuditAnswerStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.setState({
      audit_answers: [...AuditAnswerStore.getAll()],
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  visibleNextAuditAnswer(audit_answer_id) {
    const index = this.state.audit_answers.findIndex((ia) => ia.id === audit_answer_id);

    if (this.state.audit_answers.length === index + 1) {
      this.setState({
        visible_audit_answer_id: null,
      });
    } else {
      audit_answer_id = this.state.audit_answers[index + 1].id;
      this.visibleAuditAnswer(audit_answer_id);
    }
  }

  visiblePreviousAuditAnswer(audit_answer_id) {
    const index = this.state.audit_answers.findIndex((ia) => ia.id === audit_answer_id);

    if (index) {
      audit_answer_id = this.state.audit_answers[index - 1].id;
      this.visibleAuditAnswer(audit_answer_id);
    }
  }

  visibleAuditAnswer(audit_answer_id) {
    this.setState({
      visible_audit_answer_id: audit_answer_id,
    });
  }

  saveSummary() {
    const url = this.props.audit_path + '/questionnaires/' + this.props.questionnaire_id;

    $.ajax({
      url: url,
      method: 'PUT',
      data: {
        questionnaire: {
          summary: this.state.summary,
        },
      },
      dataType: 'json',
      success: this.onSuccessEvent,
    });
  }

  onSuccessEvent(response) {
    if (response.status === 'OK') {
      $('[data-role="summarySaved"]').removeClass('hidden');
      setTimeout(() => {
        $('[data-role="summarySaved"]').addClass('hidden');
      }, 4000);
    }
  }

  toggleSummaryBlock() {
    this.setState((p) => ({ showSummaryBlock: !p.showSummaryBlock }));
  }

  toggleQuestionBlock() {
    this.setState((p) => ({ showQuestionBlock: !p.showQuestionBlock }));
  }

  renderAuditSummary() {
    return (
      <>
        <div data-role="summarySaved" className="alert alert-success hidden">
          {I18n.t('audits.questionnaires.edit.summary_saved')}
        </div>
        <textarea
          className="form-control summary-textarea"
          name="summary"
          placeholder={I18n.t('audits.questionnaires.edit.summary_placeholder')}
          onChange={this.onChange}
          rows="5"
          value={this.state.summary}
          onBlur={this.saveSummary}
        ></textarea>
        <button className="btn btn-primary" onClick={this.saveSummary} type="button">
          {I18n.t('audits.questionnaires.edit.save')}
        </button>
      </>
    );
  }

  renderAuditAnswerFormList() {
    return (
      <>
        {this.state.audit_answers.map(this.renderAuditAnswerForm)}
        {this.state.visible_audit_answer_id === null && this.renderFinaleButton()}
      </>
    );
  }

  renderAuditAnswerForm(audit_answer, index) {
    return (
      <AuditAnswerForm
        key={`audit-answer-form-${audit_answer.id}`}
        audit_answer={audit_answer}
        active={this.state.visible_audit_answer_id === audit_answer.id}
        visibleNextAuditAnswer={this.visibleNextAuditAnswer}
        visiblePreviousAuditAnswer={this.visiblePreviousAuditAnswer}
        questionNumber={index}
      />
    );
  }

  renderFinaleButton() {
    return (
      <div className="card">
        <a href={this.props.audit_path} className="btn btn-primary">
          {I18n.t('audits.questionnaires.edit.completed.link')}
        </a>
      </div>
    );
  }

  renderQuestion(audit_answer, index) {
    return (
      <QuestionnaireQuestion
        key={audit_answer.id}
        audit_answer={audit_answer}
        active={this.state.visible_audit_answer_id === audit_answer.id}
        visibleAuditAnswer={this.visibleAuditAnswer}
        questionNumber={index}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="card block">
          <div className="mb-1">
            <button className="btn btn-link px-0" onClick={this.toggleQuestionBlock} type="button">
              <i className={`fa fa-angle-${this.state.showQuestionBlock ? 'up' : 'down'}`}></i>{' '}
              {I18n.t('audits.questionnaires.edit.question')}
            </button>
          </div>
          {this.state.showQuestionBlock && this.renderAuditAnswerFormList()}
        </div>

        <div className="card block">
          <div className="mb-1">
            <button className="btn btn-link px-0" onClick={this.toggleSummaryBlock} type="button">
              <i className={`fa fa-angle-${this.state.showSummaryBlock ? 'up' : 'down'}`}></i>{' '}
              {I18n.t('audits.questionnaires.edit.summary')}
            </button>
          </div>
          {this.state.showSummaryBlock && this.renderAuditSummary()}
        </div>

        <div className="card block">
          <h2>{I18n.t('audits.questionnaires.edit.questions')}</h2>
          <ul className="list-question list-unstyled">{this.state.audit_answers.map(this.renderQuestion)}</ul>
        </div>
      </div>
    );
  }
}

export default Questionnaire;
