import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from '../../i18n-js/index.js.erb';
import { useRecoilValue } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';

import BusinessUnitTextBlock from './business-unit-text-block';
import Image from '../image';
import OpenDocument from '../open-document';
import TextBlockFavorite from '../TextBlockFavorite';
import Table from './table';

let maxWidth;
if (window.innerWidth < 768) {
  maxWidth = window.innerWidth - 72;
} else if (window.innerWidth < 992) {
  maxWidth = window.innerWidth * 0.8 - 82;
} else {
  maxWidth = (window.innerWidth * 0.9 - 30) * 0.75 - 92;
}

const options = { scope: 'components.manual_paragraph' };

const TextBlock = ({
  businessUnits,
  currentMember,
  loadTextBlock,
  manualChapterId,
  manualParagraph,
  onTextBlockChange,
  textBlock,
  textBlockFavorite,
  ...props
}) => {
  const ref = useRef();

  const onBusinessUnitChange = (businessUnitIds) => {
    onTextBlockChange({ ...textBlock, businessUnitIds });
  };

  const onDestroy = (id) => {
    onTextBlockChange({
      ...textBlock,
      documents: textBlock.documents.filter((d) => d.id !== id),
    });
  };

  const onDoubleClick = (event) => {
    if (currentMember.canManageTextBlock) {
      event.preventDefault();
      window.location = `/manual/manual_chapters/${manualChapterId}/manual_paragraphs/${manualParagraph.id}/text_blocks/${textBlock.id}/edit`;
    }
  };

  const observer = useRef();

  useEffect(() => {
    if (!props.allowLoading || textBlock.tables !== undefined) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new window.IntersectionObserver((entries) => {
      entries[0].isIntersecting && loadTextBlock(textBlock);
    });
    observer.current.observe(ref.current);
    return () => observer.current.disconnect();
  }, [props.allowLoading, loadTextBlock, textBlock]);

  const renderTable = (table) => <Table key={table.id} table={table} />;

  const renderImage = (image) => (
    <p id={`document-${image.id}`} key={image.id}>
      <Image
        src={`/documents/${image.id}`}
        alt={image.name}
        height={image.height}
        maxWidth={maxWidth}
        width={image.width}
      />
    </p>
  );

  const renderDocument = (doc) => (
    <tr key={doc.id} id={`document-${doc.id}`}>
      <td className={`status-${doc.status}`}>
        {doc.status && (
          <>
            <i className="fas fa-circle"></i>{' '}
            {doc.status === 'done' ? (
              <OpenDocument doc={doc} onDelete={onDestroy}>
                {doc.name}
              </OpenDocument>
            ) : (
              doc.name
            )}
          </>
        )}
      </td>
      <td>{doc.staff && doc.staff.name}</td>
      <td>{doc.classification && I18n.t(`documents.classifications.${doc.classification}`)}</td>
      <td>{doc.revisionDate && moment(doc.revisionDate).format('L')}</td>
    </tr>
  );

  const filterDocument = (doc) => !doc.isImage;
  const filterImage = (doc) => doc.isImage;
  const documents = textBlock.documents ? textBlock.documents.filter(filterDocument) : [];

  return (
    <div id={`text-block-${textBlock.id}`} onDoubleClick={onDoubleClick} ref={ref} className="text-block">
      {textBlock.showName && (
        <div className="d-flex justify-content-between">
          <h3>{textBlock.name}</h3>
          <div>
            <TextBlockFavorite textBlockId={textBlock.id} favoriteId={textBlockFavorite.id} />
            {businessUnits.length > 0 && currentMember.canManageTextBlock && (
              <BusinessUnitTextBlock
                businessUnits={businessUnits}
                businessUnitIds={textBlock.businessUnitIds}
                id={textBlock.id}
                onChange={onBusinessUnitChange}
              />
            )}
            <a
              className="btn btn-link px-1 mr-2"
              href={`/manual/manual_chapters/${manualChapterId}/manual_paragraphs/${manualParagraph.id}/text_blocks/${textBlock.id}.pdf`}
              rel="noopener noreferrer"
              target="_blank"
              title={I18n.t('download_pdf', options)}
            >
              <i className="far fa-print"></i>
            </a>
            {textBlock.status === 'concept' && (
              <span style={{ color: '#ff6212' }}>
                <FontAwesomeIcon icon={faBookmark} />
              </span>
            )}
          </div>
        </div>
      )}
      <p dangerouslySetInnerHTML={{ __html: textBlock.description }}></p>
      {textBlock.documents && textBlock.documents.filter(filterImage).map(renderImage)}
      {textBlock.tables && textBlock.tables.map(renderTable)}

      {documents.length > 0 && (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>{I18n.t('name', options)}</th>
                <th>{I18n.t('owner', options)}</th>
                <th>{I18n.t('classification', options)}</th>
                <th>{I18n.t('revision_date', options)}</th>
              </tr>
            </thead>
            <tbody>{documents.map(renderDocument)}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

TextBlock.defaultProps = {
  textBlockFavorite: {},
};

TextBlock.propTypes = {
  allowLoading: PropTypes.bool,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentMember: PropTypes.shape({
    canManageTextBlock: PropTypes.bool.isRequired,
  }),
  loadTextBlock: PropTypes.func.isRequired,
  manualChapterId: PropTypes.string.isRequired,
  manualParagraph: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onTextBlockChange: PropTypes.func.isRequired,
  textBlock: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    showName: PropTypes.bool.isRequired,
    businessUnitIds: PropTypes.array.isRequired,
    documents: PropTypes.array,
    tables: PropTypes.array,
  }),
  textBlockFavorite: PropTypes.shape({
    id: PropTypes.string,
    textBlockId: PropTypes.string,
  }),
};

export default TextBlock;
