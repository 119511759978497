import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';

class ConfidentialDeviations extends React.Component {
  constructor(props) {
    super(props);

    this.toggleCondifentialDeviations = this.toggleCondifentialDeviations.bind(this);

    this.state = {
      members: props.members
    };
  }

  toggleCondifentialDeviations(_, id) {
    const member = this.state.members.find(m => m.id === id);
    this.setState(p => ({
      members: p.members.map(m => m.id === id ? { ...m, confidential_deviations: !m.confidential_deviations } : m)
    }));

    fetch(
      `/organisation/members/${member.id}`,
      {
        credentials: 'same-origin',
        body: JSON.stringify({
          authenticity_token: document.querySelector('[name="csrf-token"]').content,
          member: {
            confidential_deviations: !member.confidential_deviations
          }
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PATCH'
      }
    );
  }

  formatMembers(member) {
    return {
      id: member.id,
      name: member.staff.name
    };
  }

  render() {
    const { members } = this.state;

    return(
      <div className="col-sm-12">
        <div className="form-group">
          <AutoComplete
            selectValues={members.filter(m => m.confidential_deviations).map(this.formatMembers)}
            values={members.filter(m => !m.confidential_deviations).map(this.formatMembers)}
            addValue={this.toggleCondifentialDeviations}
            removeValue={this.toggleCondifentialDeviations}
          />
        </div>
      </div>
    );
  }
}

export default ConfidentialDeviations;
