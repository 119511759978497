import React from 'react';

import AutoComplete from './AutoComplete';

class BusinessUnitChart extends React.Component {
  constructor(props) {
    super(props);

    this.addValue = this.addValue.bind(this);
    this.removeValue = this.removeValue.bind(this);
    this.renderHiddenField = this.renderHiddenField.bind(this);

    this.state = {
      business_unit_ids: props.business_unit_ids.filter(x => x).map(x => parseInt(x))
    };
  }

  addValue(_, business_unit_id) {
    this.setState(p => ({
      business_unit_ids: [...p.business_unit_ids, business_unit_id]
    }), this.onChange);
  }

  removeValue(_, business_unit_id) {
    this.setState(p => ({
      business_unit_ids: p.business_unit_ids.filter(b => b !== business_unit_id)
    }), this.onChange);
  }

  onChange() {
    const { onChange } = this.props;

    if (onChange) {
      const { business_unit_ids } = this.state;
      onChange({ business_unit_ids });
    }
  }

  renderHiddenField(id) {
    const { form_name } = this.props;
    return(
      <input key={id} name={form_name} type="hidden" value={id} />
    );
  }

  render() {
    const { business_units, form_name } = this.props;
    const { business_unit_ids } = this.state;
    return(
      <div>
        <input name={form_name} type="hidden" value="" />
        {business_unit_ids.map(this.renderHiddenField)}
        <AutoComplete
          addValue={this.addValue}
          addNewValue={() => {}}
          removeValue={this.removeValue}
          selectValues={business_units.filter(b => business_unit_ids.includes(b.id))}
          values={business_units.filter(b => !business_unit_ids.includes(b.id))}
        />
      </div>
    );
  }
}

export default BusinessUnitChart;
