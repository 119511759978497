import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const duration = 250;

const Container = styled.div`
  height: 0;
  overflow: ${({ state }) => (state === 'entered' ? 'initial' : 'hidden')};
  transition: height ease ${duration}ms;
`;

export const styles = {
  /* Styles applied to the outer wrapper element. */
  wrapper: {
    // Hack to get children with a negative margin to not falsify the height computation.
    display: 'flex',
  },
  /* Styles applied to the inner wrapper element. */
  wrapperInner: {
    width: '100%',
  },
};

const Collapse = (props) => {
  const {
    children,
    collapsedHeight: collapsedHeightProp = '0px',
    in: inProp,
    timeout = duration,
    ...other
  } = props;
  const timer = useRef();
  const wrapperRef = useRef(null);
  const autoTransitionDuration = useRef();
  const collapsedHeight =
    typeof collapsedHeightProp === 'number'
      ? `${collapsedHeightProp}px`
      : collapsedHeightProp;

  const nodeRef = useRef(null);

  const handleEnter = (node) => {
    node.style.height = collapsedHeight;
  };

  const handleEntering = (node) => {
    const wrapperHeight = wrapperRef.current
      ? wrapperRef.current.clientHeight
      : 0;
    node.style.height = `${wrapperHeight}px`;
  };

  const handleEntered = (node, isAppearing) => {
    node.style.height = 'auto';
  };

  const handleExit = (node) => {
    const wrapperHeight = wrapperRef.current
      ? wrapperRef.current.clientHeight
      : 0;
    node.style.height = `${wrapperHeight}px`;
  };

  const handleExiting = (node) => {
    node.style.height = collapsedHeight;
  };

  return (
    <Transition
      in={inProp}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExiting}
      timeout={duration}
    >
      {(state) => (
        <Container state={state}>
          <div style={styles.wrapper} ref={wrapperRef}>
            <div style={styles.wrapperInner}>{children}</div>
          </div>
        </Container>
      )}
    </Transition>
  );
};

Collapse.propTypes = {
  children: PropTypes.node,
  collapsedHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  in: PropTypes.bool,
  timeout: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
};

export default Collapse;
