import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block/text-block';

import { graphql, textBlockQuery } from '../text-block/text-block-queries';
import { request } from '../../actions';

const query = `
  query TextBlockQuery($id: ID!) {
   ${graphql}
    textBlock(id: $id) {
      ${textBlockQuery}
    }
  }
`;

const ManualParagraphTextBlock = ({ id, ...props }) => {
  const [state, setState] = React.useState({});

  React.useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const [response, error] = await request(query, { id }, controller);
      setState({
        ...response,
        textBlock: {
          ...response.textBlock,
          documents: response.textBlock.documents.map((d) => ({
            ...d,
            errors: [],
          })),
        },
      });
    })();

    return () => controller.abort();
  }, [id]);

  if (state.textBlock === undefined) return null;

  return <TextBlock {...state} {...props} />;
};

ManualParagraphTextBlock.propTypes = {
  canMarkAsFinal: PropTypes.bool,
  id: PropTypes.number.isRequired,
};

export default ManualParagraphTextBlock;
