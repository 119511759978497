import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { dataSubjectDispatcher } from '../dispatcher/data-subject-dispatcher';
import DataSubjectConstants from '../constants/data-subject-constants';

const DataSubject = ({ data_subject }) => {
  const options = { scope: 'components.data_subjects' };

  const onDeleteClick = () => {
    if (confirm(I18n.t('confirm_destroy', options))) {
      deleteDataSubject();
    }
  };

  const deleteDataSubject = () => {
    const options = {
      credentials: 'same-origin',
      method: 'delete',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]')
          .content,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const url = new URL(
      `processing_registers/data_subjects/${data_subject.id}`,
      `${location.protocol}//${location.host}`,
    );
    fetch(url, options).then((r) => {
      if (r.status === 200) {
        r.json().then((data) => {
          if (data.status === 'OK') {
            dataSubjectDispatcher.handleDeleteDataSubjectAction({
              actionType: DataSubjectConstants.SUBJECT_DELETE,
            });
          } else {
            alert(I18n.t('components.data_subjects.delete.failure'));
          }
        });
      } else {
        alert(I18n.t('components.data_subjects.delete.failure'));
      }
    });
  };

  return (
    <div className="flex-row">
      <div className="size-4 truncate">{data_subject.name}</div>
      <div className="size-2 truncate">
        {data_subject.in_use ? (
          <i className="fa fa-check text-success"></i>
        ) : (
          <i className="fa fa-times text-danger"></i>
        )}
      </div>
      <div className="size-1 truncate">
        <button
          className="btn btn-link py-0"
          data-toggle="modal"
          data-target="#newDataSubjectModal"
          data-id={data_subject.id}
          data-value={data_subject.name}
          data-title={I18n.t('modal.edit.title', options)}
        >
          <i className="fa fa-pencil"></i>
        </button>
        {!data_subject.in_use && (
          <button className="btn btn-link py-0" onClick={onDeleteClick}>
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

DataSubject.propTypes = {
  data_subject: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    in_use: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DataSubject;
