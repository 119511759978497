import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { request } from '../../actions';

const graphql = `
  query NormLinksQuery($manualParagraphId: ID!) {
    normLinkOrganisations(manualParagraphId: $manualParagraphId) {
      id status normOrganisationId
      normParagraph { id name number normId }
    }
  }
`;

const NormLinks = ({ currentMember, manualParagraph, normOrganisations }) => {
  const [{ loaded, normLinkOrganisations }, setNormLinkOrganisations] = useState({ loaded: false, normLinkOrganisations: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [normOrganisation, setNormOrganisation] = useState({});

  const container = useRef();
  const options = { scope: 'components.manual_paragraph_norm_links' };

  const fetchNormLinkOrganisations = async () => {
    const [response, error] = await request(graphql, { manualParagraphId: manualParagraph.id });

    if (error === null) {
      setNormLinkOrganisations({ loaded: true, ...response });
    }
  };

  const onDocumentClick = ({ target }) => {
    if (container.current.contains(target) && container.current !== target) {
      return;
    }
    setIsOpen(false);
  };

  const onClick = () => {
    if (loaded === false) {
      fetchNormLinkOrganisations();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  const renderNormOrganisation = (no) => {
    const onClick = () => setNormOrganisation(no.id === normOrganisation.id ? {} : no);

    return(
      <li key={no.id}>
        <button className={`btn btn-link btn-block status-${no.status}`} onClick={onClick}>
          <i className="fas fa-square"></i> {no.norm.name}
        </button>
      </li>
    );
  };

  const renderTextBlock = (textBlock) => (
    <li key={textBlock.id}>
      {
        currentMember.canManageTextBlock ?
          <a href={`${location.pathname}/manual_paragraphs/${manualParagraph.id}/text_blocks/${textBlock.id}/edit`}>
            <span className={`status-${textBlock.status}`}>
              <i className="fa fa-bookmark"></i>
            </span> {textBlock.name}
          </a>
          :
          <div>
            <span className={`status-${textBlock.status}`}>
              <i className="fa fa-bookmark"></i>
            </span> {textBlock.name}
          </div>
      }
    </li>
  );

  const renderNormLinkOrganisation = (normLinkOrganisation) => {
    if (currentMember.accessNorms) {
      return(
        <li key={normLinkOrganisation.id}>
          <a href={`/norms/${normLinkOrganisation.normParagraph.normId}#paragraaf-${normLinkOrganisation.normParagraph.id}`} title={normLinkOrganisation.normParagraph.name}>
            {normLinkOrganisation.normParagraph.number} {normLinkOrganisation.normParagraph.name}
          </a>
        </li>
      );
    } else {
      return(
        <li key={normLinkOrganisation.id}>
          <span className="text-muted" title={normLinkOrganisation.normParagraph.name}>
            {normLinkOrganisation.normParagraph.number} {normLinkOrganisation.normParagraph.name}
          </span>
        </li>
      );
    }
  };

  return(
    <li className="re-dropdown" ref={container}>
      <button className="btn btn-link px-1" onClick={onClick} type="button">
        {I18n.t('norms', options)} <span className="caret"></span>
      </button>
      <ul className="re-dropdown-menu re-dropdown-menu-right" hidden={!isOpen}>
        <h6 title={I18n.t('norm_dropdown.popover.header', options)} data-content={I18n.t('norm_dropdown.popover.content', options)} data-toggle="popover" data-placement="left">
          {I18n.t('norm_dropdown.header', options)}
        </h6>
        {normOrganisations.map(renderNormOrganisation)}
        {normOrganisation.id && (
          <>
            <hr/>
            <h6>{I18n.t('norm_links.text_blocks', options)}: {normOrganisation.norm.name}</h6>
            {manualParagraph.textBlocks.filter(tb => tb.normIds.length === 0 || tb.normIds.includes(normOrganisation.norm.id)).map(renderTextBlock)}
          </>
        )}
        <hr />
        <h6 title={I18n.t('norm_links.popover.header', options)} data-content={I18n.t('norm_links.popover.content', options)} data-toggle="popover" data-placement="left">
          {I18n.t('norm_links.header', options)}
        </h6>
        {normOrganisations.map(no => (
          <React.Fragment key={no.id}>
            <div style={{ textAlign: 'left', padding: '0 1rem' }}><b>{no.norm.name}</b></div>
            {normLinkOrganisations.filter(nlo => nlo.normOrganisationId === no.id).map(renderNormLinkOrganisation)}
          </React.Fragment>
        ))}
      </ul>
    </li>
  );
};


NormLinks.propTypes = {
  currentMember: PropTypes.shape({
    accessNorms: PropTypes.bool.isRequired,
    canManageTextBlock: PropTypes.bool.isRequired,
  }),
  manualParagraph: PropTypes.shape({
    id: PropTypes.string.isRequired,
    textBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        normIds: PropTypes.array.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  normOrganisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      norm: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};

export default NormLinks;
