import React, { useState, useEffect } from 'react';
import I18n from '../../i18n-js/index.js.erb';
import PropTypes from 'prop-types';

import { request } from '../../actions';

const graphql = `mutation DocumentFavoriteMutation($documentId: ID!) {
  toggleDocumentFavorite(input: { documentId: $documentId }) {
    documentFavorite { id }
  }
}`;

const scope = { scope: 'components.document_favorite' };

const DocumentFavorite = ({ documentId, ...props }) => {
  const [isFavorite, setIsFavorite] = useState(props.isFavorite);

  const onClick = async () => {
    setIsFavorite(!isFavorite);
    await request(graphql, { documentId });
  };

  return (
    <button
      className="btn btn-link px-1"
      data-toggle="tooltip"
      onClick={onClick}
      title={I18n.t(isFavorite ? 'unfavorite' : 'favorite', scope)}
      type="button"
    >
      <i className={`${isFavorite ? 'fa' : 'far'} fa-star`} />
    </button>
  );
};

DocumentFavorite.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
};

export default DocumentFavorite;
