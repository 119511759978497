import useDeepCompareEffect from 'use-deep-compare-effect';

export const request = async (query, variables, controller) => {
  const options = {
    body: JSON.stringify({
      query,
      variables,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    signal: controller !== undefined ? controller.signal : undefined,
  };

  try {
    const response = await fetch('/graphql', options);

    if (response.status === 200) {
      const json = await response.json();
      if (json.errors === undefined) {
        return [json.data, null];
      } else {
        console.error(json.errors);
        throw new Error(json.errors);
      }
    } else {
      return [null, response];
    }
  } catch (error) {
    return [null, error];
  }
};

export const useQuery = (graphql, variables, callback) => {
  useDeepCompareEffect(() => {
    const controller = new AbortController();

    (async () => {
      const [response, error] = await request(graphql, variables, controller);
      error === null && callback(response);
    })();

    return () => controller.abort();
  }, [graphql, variables, callback]);
};
