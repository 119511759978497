import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import moment from 'moment';

import AuditAnswerConstants from '../constants/audit-answer-constants';
import { auditAnswerDispatcher } from '../dispatcher/audit-answer-dispatcher';

import AuditAnswerAttachment from './AuditAnswerAttachment';

class AuditAnswerForm extends React.Component {
  constructor(props) {
    super(props);
    this.formName = 'audit_answer';
    this.scope = { scope: 'audits.questionnaires.edit' };

    this.onShowOtherAnswersClick = this.onShowOtherAnswersClick.bind(this);
    this.onDestroyClickEvent = this.onDestroyClickEvent.bind(this);
    this.onSubmitClickEvent = this.onSubmitClickEvent.bind(this);
    this.renderAttachment = this.renderAttachment.bind(this);
    this.destroyAuditAsnwerDocument = this.destroyAuditAsnwerDocument.bind(this);

    this.state = {
      errors: [],
      showQuestionInformation: false,
      showOtherAnswers: true,
      attachments: props.audit_answer.attachments,
    };
  }

  onShowOtherAnswersClick() {
    this.setState((p) => ({ showOtherAnswers: !p.showOtherAnswers }));
  }

  onSubmitClickEvent(event) {
    event.preventDefault();

    const form = event.target.form;

    $.ajax({
      url: form.action,
      type: form.method,
      data: new FormData(form),
      processData: false,
      contentType: false,
      dataType: 'json',
      success: this.onSuccessEvent.bind(this),
    });
  }

  onSuccessEvent(data) {
    let audit_answer;

    if (data.status === 'OK') {
      this.setState({
        errors: [],
        attachments: data.audit_answer.attachments,
      });

      this.props.visibleNextAuditAnswer(this.props.audit_answer.id);
      auditAnswerDispatcher.handleChangeAuditAnswerAction({
        actionType: AuditAnswerConstants.AUDIT_ANSWER_UPDATE,
        audit_answer: {
          ...this.props.audit_answer,
          answer: data.audit_answer.answer,
        },
      });
    } else {
      this.setState({
        errors: data.errors,
      });
    }
  }

  onDestroyClickEvent(event) {
    event.preventDefault();

    if (!confirm(I18n.t('confirm_destroy', this.scope))) return;

    $.ajax({
      url: `/audit_answers/${this.props.audit_answer.id}`,
      method: 'DELETE',
      success: () => {
        this.props.visibleNextAuditAnswer(this.props.audit_answer.id);
        auditAnswerDispatcher.handleChangeAuditAnswerAction({
          actionType: AuditAnswerConstants.AUDIT_ANSWER_DESTROY,
          audit_answer: this.props.audit_answer,
        });
      },
    });
  }

  destroyAuditAsnwerDocument(attachment) {
    this.setState((p) => ({
      attachments: p.attachments.map((iad) => (iad.id === attachment.id ? { ...attachment, _destroy: 1 } : iad)),
    }));
  }

  renderResources() {
    const { resources } = this.props.audit_answer;

    return (
      <div className="col-xs-6">
        <b>{I18n.t('resources', this.scope)}</b>
        <ul>
          {resources.map((r) => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderError(attribute) {
    if (this.state.errors[attribute] === undefined) return;

    return this.state.errors[attribute].map((error, index) => (
      <div key={`${this.props.audit_answer.id}-${attribute}-error-${index}`} className="alert alert-danger">
        {error}
      </div>
    ));
  }

  renderAttachment(attachment, index) {
    return (
      <AuditAnswerAttachment
        key={`${this.props.audit_answer.id}-document-${index}`}
        formName={this.formName}
        attachment={attachment}
        onDestroy={this.destroyAuditAsnwerDocument}
      />
    );
  }

  render() {
    const { showQuestionInformation, showOtherAnswers } = this.state;
    const { audit_answer } = this.props;

    return (
      <div className={`${this.props.active ? '' : ' hidden'}`}>
        <form action={`/audit_answers/${audit_answer.id}`} method="post" encType="multipart/form-data">
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="patch" />
          <div className="row">
            <div className="col-md-6 col-lg-7">
              <div className="question-title-block">
                <div className="circle answered-question with-margin">
                  <div className="question-number">{this.props.questionNumber + 1}</div>
                </div>
                <div className="inline">
                  <h3 className="wrap inline">
                    {audit_answer.manual_paragraph_number} {audit_answer.manual_paragraph_name}
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: audit_answer.question }} />
                </div>
              </div>

              <div className="mb-1" hidden={audit_answer.audit_question_description === ''}>
                <button
                  className="btn btn-link px-0"
                  onClick={() =>
                    this.setState({
                      showQuestionInformation: !showQuestionInformation,
                    })
                  }
                  type="button"
                >
                  <i className={`fa fa-angle-${showQuestionInformation ? 'up' : 'down'}`}></i>{' '}
                  {I18n.t('question_information', this.scope)}
                </button>
                {showQuestionInformation ? (
                  <i
                    className="help-block wrap"
                    dangerouslySetInnerHTML={{
                      __html: audit_answer.audit_question_description,
                    }}
                  />
                ) : (
                  ''
                )}
              </div>

              <div className="row">{this.props.audit_answer.resources.length > 0 && this.renderResources()}</div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="5"
                  name={`${this.formName}[answer]`}
                  defaultValue={audit_answer.answer}
                />
              </div>

              {audit_answer.audit_answers.length > 0 && (
                <div className="mb-1">
                  <button className="btn btn-link px-0" onClick={this.onShowOtherAnswersClick} type="button">
                    <i className={`fa fa-angle-${showOtherAnswers ? 'up' : 'down'}`}></i>{' '}
                    {I18n.t('other_answers', this.scope)}
                  </button>
                  <ul className="list-unstyled">
                    {showOtherAnswers &&
                      audit_answer.audit_answers.map((a) => (
                        <li key={a.index}>
                          <label>{a.interviewee_name}:</label> {a.answer}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="col-md-6 col-lg-5">
              <h3>{I18n.t('realisation', this.scope)}</h3>

              <div className="form-horizontal">
                <div className="form-group">
                  <label
                    htmlFor={`${this.formName}_documentation_realisation`}
                    className="control-label col-xs-4 col-lg-3"
                  >
                    {I18n.t('activerecord.attributes.audit_answer.documentation_realisation')}
                  </label>
                  <div className="col-xs-8 col-lg-7">
                    <div className="input-group">
                      <input
                        className="form-control"
                        defaultValue={this.props.audit_answer.documentation_realisation}
                        id={`${this.formName}_documentation_realisation`}
                        name={`${this.formName}[documentation_realisation]`}
                        max="100"
                        min="0"
                        type="number"
                      />
                      <span className="input-group-addon">%</span>
                    </div>
                    {this.renderError('documentation_realisation')}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor={`${this.formName}_implementation_realisation`}
                    className="control-label col-xs-4 col-lg-3"
                  >
                    {I18n.t('activerecord.attributes.audit_answer.implementation_realisation')}
                  </label>
                  <div className="col-xs-8 col-lg-7">
                    <div className="input-group">
                      <input
                        className="form-control"
                        defaultValue={this.props.audit_answer.implementation_realisation}
                        id={`${this.formName}_implementation_realisation`}
                        name={`${this.formName}[implementation_realisation]`}
                        max="100"
                        min="0"
                        type="number"
                      />
                      <span className="input-group-addon">%</span>
                    </div>
                    {this.renderError('implementation_realisation')}
                  </div>
                </div>
              </div>

              {this.state.attachments.map(this.renderAttachment)}

              <div className="form-group">
                <input name={`${this.formName}[attachments][]`} multiple="multiple" type="file" />
              </div>
              <div className="form-group">
                <div>
                  <button
                    className="btn btn-danger visible-xs-block btn-block"
                    onClick={this.onDestroyClickEvent}
                    type="button"
                  >
                    {I18n.t('helpers.submit.destroy', {
                      model: I18n.t('activerecord.models.audit_answer'),
                    })}
                  </button>
                  <button className="btn btn-danger hidden-xs" onClick={this.onDestroyClickEvent} type="button">
                    {I18n.t('helpers.submit.destroy', {
                      model: I18n.t('activerecord.models.audit_answer'),
                    })}
                  </button>
                  &nbsp;
                  <button className="btn btn-primary visible-xs-block btn-block" onClick={this.onSubmitClickEvent}>
                    {I18n.t('helpers.submit.next_and_save')}
                  </button>
                  <button className="btn btn-primary hidden-xs" onClick={this.onSubmitClickEvent}>
                    {I18n.t('helpers.submit.next_and_save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AuditAnswerForm.propTypes = {
  active: PropTypes.bool.isRequired,
  audit_answer: PropTypes.shape({
    answer: PropTypes.string,
    audit_question_description: PropTypes.string,
    documentation_realisation: PropTypes.number,
    id: PropTypes.number,
    implementation_realisation: PropTypes.number,
    manual_paragraph_name: PropTypes.string,
    manual_paragraph_number: PropTypes.string,
    question: PropTypes.string,
    resources: PropTypes.array.isRequired,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        _destroy: PropTypes.bool,
      }),
    ).isRequired,
    audit_answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string,
        interviewee_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  questionNumber: PropTypes.number,
  visibleNextAuditAnswer: PropTypes.func.isRequired,
};

export default AuditAnswerForm;
