import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

const SortLink = ({ children, onSort, sortBy, sortDirection, sorting }) => {
  const onClick = () => {
    onSort(sorting, sortDirection === 'asc' ? 'desc' : 'asc');
  };

  return(
    <button className="btn btn-link font-weight-bold" onClick={onClick} type="button">
      {children} {sorting === sortBy && (sortDirection === 'asc' ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />)}
    </button>
  );
};

SortLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sorting: PropTypes.string.isRequired,
};

export default SortLink;
