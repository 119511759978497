import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import Dropdown from '../dropdown/dropdown';
import DropdownFilter from '../dropdown-filter/dropdown-filter';

const options = { scope: 'components.documents' };

const Header = ({ canDownloadDocuments, businessUnitIds, businessUnits, onChange, ...props }) => {
  const onClick = (id) => {
    if (businessUnitIds.includes(id)) {
      onChange(businessUnitIds.filter((x) => x !== id));
    } else {
      onChange([...businessUnitIds, id]);
    }
  };

  return (
    <div className="heading">
      <h1 className="heading-title">
        <span
          data-toggle="popover"
          data-trigger="hover"
          title={I18n.t('popover.header', options)}
          data-content={I18n.t('popover.content', options)}
          data-placement="bottom"
        >
          {I18n.t('header', options)}
        </span>
      </h1>
      <ul className="list-links list-right">
        <li>
          <form action="/documents.pdf" className="form-inline">
            {businessUnitIds.map((id) => (
              <input key={id} name="business_unit_ids[]" type="hidden" value={id} />
            ))}
            <input name="sort" type="hidden" value={props.sortBy} />
            <input name="asc" type="hidden" value={props.sortDirection} />
            <button
              className="btn btn-link"
              data-placement="bottom"
              data-toggle="tooltip"
              title={I18n.t('export_pdf', options)}
            >
              <i className="far fa-print"></i>
            </button>
          </form>
        </li>
        {canDownloadDocuments && (
          <li className="dropdown">
            <button
              className="dropdown-toggle btn btn-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="far fa-file-archive" data-toggle="tooltip" title={I18n.t('download_archive', options)}></i>{' '}
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <form action="/documents/lasted_versions">
                  <button className="btn btn-link">
                    <i className="far fa-download"></i> {I18n.t('lasted_versions', options)}
                  </button>
                </form>
              </li>
              <li>
                <form action="/documents/all_versions">
                  <button className="btn btn-link">
                    <i className="far fa-download"></i> {I18n.t('all_versions', options)}
                  </button>
                </form>
              </li>
            </ul>
          </li>
        )}
        {businessUnits.length > 0 && (
          <li>
            <DropdownFilter
              button={(args) => (
                <button className="btn btn-link" type="button" {...args}>
                  {I18n.t('business_units', options)} <span className="caret"></span>
                </button>
              )}
              ids={businessUnitIds}
              items={businessUnits}
              onClick={onClick}
              onResetClick={() => onChange([])}
              right
              textReset={I18n.t('reset_business_units', options)}
            >
              <li>
                <Dropdown.Button onClick={onClick.bind(null, '')}>
                  <DropdownFilter.Item
                    ids={businessUnitIds}
                    item={{
                      id: '',
                      name: I18n.t('without_business_units', options),
                    }}
                  />
                </Dropdown.Button>
              </li>
            </DropdownFilter>
          </li>
        )}
      </ul>
    </div>
  );
};

Header.propTypes = {
  canDownloadDocuments: PropTypes.bool.isRequired,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  businessUnitIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

export default Header;
