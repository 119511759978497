import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import DpiaQuestionnaireConstants from 'constants/dpia-questionnaire-constants';
import { dpiaQuestionnaireDispatcher } from 'dispatcher/dpia-questionnaire-dispatcher';

class DpiaQuestionnaireModal {
  constructor(modal) {
    this.modal = modal;
    this.form = modal.querySelector('form');
    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      url: this.form.action,
      method: this.form.method,
      data: $(this.form).serialize(),
      dataType: 'json',
      success: this.onSuccesCallback.bind(this)
    });
  }

  onSuccesCallback(response) {
    Rails.enableElement(this.form);

    if (response.status == 'OK') {
      this.form.reset();
      $(this.modal).modal('hide');
      $('.container-fluid .heading:first').after(
        `<p class="alert alert-success success">${response.success}</p>`
      );
      setTimeout(() => { $('.alert').fadeOut('slow'); }, 4000);

      dpiaQuestionnaireDispatcher.handleCreateDpiaQuestionnaireAction({
        actionType: DpiaQuestionnaireConstants.DPIA_QUESTIONNAIRE_CREATE,
        dpia_questionnaire: response.dpia_questionnaire
      });
    } else {
      validationErrors($(this.form), response.errors);
    }
  }
}

$(document).on('turbolinks:load', () => {
  const modal = document.querySelector('#newDpiaQuestionnaireModel');

  if (modal) {
    new DpiaQuestionnaireModal(modal);
  }
});
