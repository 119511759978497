import Dispatcher from './dispatcher';

class DeviationDispatcher extends Dispatcher {
  handleCreateDeviationAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const deviationDispatcher = new DeviationDispatcher();
