import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import Papa from 'papaparse';

import BusinessUnitChart from './BusinessUnitChart';

class MemberCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      members: [],
    };
  }

  onCsvChange(event) {
    const nameIndex = 0;
    const emailIndex = 1;
    const roleIndex = 2;
    const jobTitlesIndex = 3;
    const businessUnitsIndex = 4;
    const { business_units, job_titles } = this.props;

    Papa.parse(event.target.files[0], {
      delimiter: ';',
      skipEmptyLines: true,
      complete: (r) => {
        const members = r.data.slice(1).map((d) => {
          const jobTitleIds = d[jobTitlesIndex]
            .trim()
            .split(',')
            .map((name) => job_titles.find((j) => j.name.toLowerCase() === name.toLowerCase())?.id)
            .filter((x) => x);

          let businessUnitIds = [];

          if (d[businessUnitsIndex]) {
            businessUnitIds = d[businessUnitsIndex]
              .trim()
              .split(',')
              .map((name) => business_units.find((b) => b.name.toLowerCase() === name.toLowerCase())?.id)
              .filter((x) => x);
          }

          return {
            name: d[nameIndex].trim(),
            email: d[emailIndex].trim(),
            role: d[roleIndex].trim(),
            businessUnitIds,
            jobTitleIds,
          };
        });

        this.setState({
          members: members,
        });
      },
    });
  }

  renderMember(member, index) {
    const { business_units, job_titles, roles } = this.props;
    const role = roles.find((r) => r.name === member.role);

    console.log(member);

    return (
      <div key={index} className="card card-chain">
        <div className="form-group">
          <label>{I18n.t('activemodel.attributes.member_creator.name')}</label>
          <input
            type="text"
            className="form-control"
            name={`member_creator[members_attributes][${index}][staff_attributes][name]`}
            defaultValue={member.name}
          />
        </div>

        <div className="form-group">
          <label>{I18n.t('activemodel.attributes.member_creator.email')}</label>
          <input
            type="email"
            className="form-control"
            name={`member_creator[members_attributes][${index}][staff_attributes][email]`}
            defaultValue={member.email}
          />
        </div>

        <div className="form-group">
          <label>{I18n.t('activemodel.attributes.member_creator.role_id')}</label>
          <select
            className="form-control"
            name={`member_creator[members_attributes][${index}][staff_role_id]`}
            defaultValue={role?.id || ''}
          >
            {roles.map((role, i) => {
              return (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group">
          <label>{I18n.t('activemodel.attributes.member_creator.job_title_ids')}</label>
          <div className="row">
            {job_titles.map((job_title) => (
              <div key={job_title.id} className="col-xs-12 col-sm-6 col-md-3">
                <div className="checkbox">
                  <label>
                    <input
                      defaultChecked={member.jobTitleIds.includes(job_title.id)}
                      name={`member_creator[members_attributes][${index}][job_title_ids][]`}
                      type="checkbox"
                      value={job_title.id}
                    />
                    {job_title.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        {business_units.length > 0 && (
          <div className="form-group">
            <label>{I18n.t('activemodel.attributes.member_creator.business_unit_ids')}</label>

            <BusinessUnitChart
              business_units={business_units}
              business_unit_ids={member.businessUnitIds}
              form_name={`member_creator[members_attributes][${index}][business_unit_ids][]`}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { members } = this.state;

    return (
      <div>
        <div className="form-group">
          <label htmlFor="csv">CSV</label>
          <input type="file" name="csv" id="csv" accept="text/csv" onChange={this.onCsvChange.bind(this)} />
        </div>

        <form action="/member_creator" method="POST">
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="authenticity_token" value={this.props.crsfToken} />

          <div className="checkbox">
            <label>
              <input type="checkbox" name={'member_creator[deactivate_not_listed_staff]'} defaultValue="1" />
              {I18n.t('activemodel.attributes.member_creator.deactivate_not_listed_staff')}
            </label>
          </div>

          {members.length > 2 ? (
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary"
                defaultValue={I18n.t('member_creator.create', { scope: 'helpers.submit' })}
              />
            </div>
          ) : null}

          {members.map(this.renderMember.bind(this))}

          {members.length ? (
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary"
                defaultValue={I18n.t('member_creator.create', { scope: 'helpers.submit' })}
              />
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

MemberCreator.propTypes = {
  crsfToken: PropTypes.string.isRequired,
  business_units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  job_titles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MemberCreator;
