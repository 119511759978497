class PreviewAuditQuestions {
  constructor(form) {
    this.form = form;
    this.questions = form.find('[data-element="questions"]');
    this.previewButton = form.find('[data-element="preview"]');
    this.bindEvents();
  }

  bindEvents() {
    this.previewButton.on('click', this.onPreviewButtonClickEvent.bind(this));
  }

  onPreviewButtonClickEvent(event) {
    event.preventDefault();
    this.questions.html('');
    this.previewButton.addClass('spinning');

    $.ajax({
      url: this.form.data('preview-url'),
      data: this.form.serialize(),
      dataType: 'json',
      success: (data) => { this.renderQuestions(data); }
    });
  }

  renderQuestions(data) {
    this.previewButton.removeClass('spinning');

    if (data.length === 0) {
      this.questions.html('Geen vragen');
      return;
    }
    data.forEach((question) => {
      this.questions.append($('<li>').html(`<span class="paragraph-number">${question.manual_paragraph.order}</span> ${question.question_text}`));
    });
  }
}

$(document).on('turbolinks:load', () => {
  if ($('#new_audit').length) {
    new PreviewAuditQuestions($('#new_audit'));
  }
});
