import KpiConstants from 'constants/kpi-constants';
import { kpiDispatcher } from 'dispatcher/kpi-dispatcher';

let _kpis = [];
let _listeners = [];

class KpiStore {
  static getAll() {
    return _kpis;
  }

  static setKpis(kpis) {
    _kpis = [...kpis];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default KpiStore;

kpiDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case KpiConstants.KPI_CREATE:
      _kpis.push(action.kpi);
      KpiStore.emitChange();
  }
});
