import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { dataOptionDispatcher } from '../dispatcher/data-option-dispatcher';
import DataOptionConstants from '../constants/data-option-constants';

const DataOption = ({ data_option }) => {
  const options = { scope: 'components.data_options' };

  const onDeleteClick = () => {
    if (confirm(I18n.t('confirm_destroy', options))) {
      deleteDataOption();
    }
  };

  const deleteDataOption = () => {
    const options = {
      credentials: 'same-origin',
      method: 'delete',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]')
          .content,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const url = new URL(
      `processing_registers/data_options/${data_option.id}`,
      `${location.protocol}//${location.host}`,
    );
    fetch(url, options).then((r) => {
      if (r.status === 200) {
        r.json().then((data) => {
          if (data.status === 'OK') {
            dataOptionDispatcher.handleDeleteDataOptionAction({
              actionType: DataOptionConstants.OPTION_DELETE,
            });
          } else {
            alert(I18n.t('components.data_options.delete.failure'));
          }
        });
      } else {
        alert(I18n.t('components.data_options.delete.failure'));
      }
    });
  };

  return (
    <div className="flex-row">
      <div className="size-4 truncate">{data_option.name}</div>
      <div className="size-2 truncate">
        {data_option.in_use ? (
          <i className="fa fa-check text-success"></i>
        ) : (
          <i className="fa fa-times text-danger"></i>
        )}
      </div>
      <div className="size-1 truncate">
        <button
          className="btn btn-link py-0"
          data-toggle="modal"
          data-target="#newDataOptionModal"
          data-id={data_option.id}
          data-value={data_option.name}
          data-title={I18n.t('modal.edit.title', options)}
        >
          <i className="fa fa-pencil"></i>
        </button>
        {!data_option.in_use && (
          <button className="btn btn-link py-0" onClick={onDeleteClick}>
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

DataOption.propTypes = {
  data_option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    in_use: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DataOption;
