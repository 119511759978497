import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

import DataSubjectConstants from '../../constants/data-subject-constants';
import { dataSubjectDispatcher } from '../../dispatcher/data-subject-dispatcher';

class DataSubjectsFormModal {
  constructor(modal) {
    this.modal = modal;
    this.form = modal.querySelector('form');
    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      url: this.form.action,
      method: this.form.method,
      data: $(this.form).serialize(),
      dataType: 'json',
      success: this.onSuccesCallback.bind(this),
      complete: () => Rails.enableElement(this.form),
    });
  }

  onSuccesCallback(response) {
    if (response.status === 'OK') {
      this.form.reset();
      $(this.modal).modal('hide');
      $('.container-fluid .heading:first').after(
        `<p class="alert alert-success success">${response.success}</p>`,
      );
      setTimeout(() => { $('.alert').fadeOut('slow'); }, 4000);

      dataSubjectDispatcher.handleCreateDataSubjectAction({
        actionType: DataSubjectConstants.SUBJECT_CREATE,
        subject: response.subject,
      });
    } else {
      validationErrors($(this.form), response.errors);
    }
  }
}

$(document).on('turbolinks:load', () => {
  const modal = document.querySelector('#newDataSubjectModal');

  if (modal) {
    const jModal = $('#newDataSubjectModal');
    jModal.on('show.bs.modal', (event) => {
      const button = $(event.relatedTarget);
      const id = button.data('id');
      $('#modalTitle').text(button.data('title'));

      if (id) {
        const value = button.data('value');
        const form = jModal.find('form');
        const method = $('<input >');
        method.attr('type','hidden');
        method.attr('name', '_method');
        method.attr('value', 'patch');
        form.append(method);
        jModal.find('#data_subject_name').val(value);

        const action = form.attr('action') + `/${id}`;
        form.attr('action', action);
      }
    });

    jModal.on('hide.bs.modal', (event) => {
      const form = jModal.find('form');

      if (form.children('input[name=\'_method\']').length > 0) {
        form.children('input[name=\'_method\']').remove();
        jModal.find('#data_subject_name').val('');
        const action = form.attr('action').slice(0,-2);
        form.attr('action', action);
      }
    });

    new DataSubjectsFormModal(modal);
  }
});
