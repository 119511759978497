import Dispatcher from './dispatcher';

class DpiaQuestionnaireDispatcher extends Dispatcher {
  handleCreateDpiaQuestionnaireAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const dpiaQuestionnaireDispatcher = new DpiaQuestionnaireDispatcher();
