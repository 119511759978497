import TaskCommentConstants from 'constants/task-comment-constants';
import { taskCommentDispatcher } from 'dispatcher/task-comment-dispatcher';

let _taskComments =[];
let _listeners = [];

class TaskCommentStore {
  static getAll() {
    return _taskComments;
  }

  static setTaskComments(taskComments) {
    _taskComments = [...taskComments];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default TaskCommentStore;

taskCommentDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case TaskCommentConstants.TASK_COMMENT_CREATE:
      _taskComments.push(action.taskComment);
      TaskCommentStore.emitChange();
  }
});
