import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from '../../i18n-js/index.js.erb';

import DocumentFavorite from './document-favorite';
import DocumentForm from '../document-form';
import OpenDocument from '../open-document';

const scope = { scope: 'components.documents' };

const iconForDocType = (type, provider) => {
  switch (type) {
    case 'activestorage':
      return 'fa fa-download';
    case 'url':
      return 'fa fa-link';
    default:
      return provider === 'microsoft_graph'
        ? 'fab fa-microsoft'
        : 'fab fa-google-drive';
  }
};

const Document = ({ dispatch, doc, ...props }) => {
  const [displayForm, setDisplayForm] = useState(doc.id === undefined);

  const onDestroy = () => {
    dispatch({ type: 'destroyDocument', payload: { id: doc.id } });
  };

  const onChange = (payload) => {
    dispatch({
      type: 'updateDocument',
      payload: { document: { id: doc.id, ...payload } },
    });
  };

  const onSave = (saveDocument) => {
    if (saveDocument.document !== null) {
      setDisplayForm(false);
      dispatch({
        type: doc.id ? 'updateDocument' : 'addDocument',
        payload: { document: { errors: [], ...saveDocument.document } },
      });
    } else {
      dispatch({
        type: 'updateDocument',
        payload: { document: { ...doc, errors: saveDocument.errors } },
      });
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    if (displayForm) {
      const form = document.querySelector(`[data-id="form-${doc.id}"]`);
      const { height, top } = form.getBoundingClientRect();
      const threshold = height * 0.75;

      if (top + height > window.innerHeight + threshold) {
        form.scrollIntoView(false);
      }
    }
  }, [doc.id, displayForm]);

  return (
    <>
      <tr>
        <td
          className={
            doc.status === 'concept' ? 'status-default' : 'status-done'
          }
        >
          <i className="fas fa-circle"></i> {doc.name}
        </td>
        <td>{props.staffs.find((s) => s.id === doc.staffId)?.name}</td>
        <td>
          {doc.classification &&
            I18n.t(`documents.classifications.${doc.classification}`)}
        </td>
        <td>
          {doc.revisionDate && moment(doc.revisionDate).format('DD-MM-YYYY')}
        </td>
        <td>
          {doc.id && (
            <>
              <DocumentFavorite
                isFavorite={doc.isFavorite}
                documentId={doc.id}
              />
              <OpenDocument
                data-toggle="tooltip"
                doc={doc}
                onDelete={onDestroy}
                title={I18n.t('open_document', scope)}
              >
                <i className={iconForDocType(doc.type, props.provider)} />
              </OpenDocument>
            </>
          )}
          <button
            className="btn btn-link px-1"
            data-toggle="tooltip"
            onClick={() => setDisplayForm(!displayForm)}
            title={I18n.t('edit_document', scope)}
          >
            <i className={`fa fa-${displayForm ? 'times' : 'edit'}`}></i>
          </button>
          <button
            className="btn btn-link px-1"
            data-toggle="tooltip"
            onClick={onDestroy}
            title={I18n.t('delete', scope)}
          >
            <i className="fa fa-trash-alt"></i>
          </button>
        </td>
      </tr>

      {displayForm && (
        <tr>
          <td colSpan="5">
            <div className="card">
              <DocumentForm
                doc={doc}
                errors={doc.errors}
                onChange={onChange}
                onSave={onSave}
                {...props}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

Document.propTypes = {
  dispatch: PropTypes.func.isRequired,
  doc: PropTypes.shape({
    classification: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        messages: PropTypes.array.isRequired,
      }).isRequired,
    ).isRequired,
    businessUnitIds: PropTypes.array.isRequired,
    externalId: PropTypes.string,
    id: PropTypes.string,
    isFavorite: PropTypes.bool.isRequired,
    jobTitleIds: PropTypes.array.isRequired,
    location: PropTypes.string,
    name: PropTypes.string,
    retentionPeriod: PropTypes.string,
    revisionDate: PropTypes.string,
    signedId: PropTypes.string,
    staffId: PropTypes.string,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  provider: PropTypes.string,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default Document;
