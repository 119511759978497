import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck } from '@fortawesome/pro-regular-svg-icons';

import { request } from '../../actions';

const options = { scope: 'components.self_assessment' };
const graphql = `
  mutation SaQuestionnaireReminderMutation($input: SaQuestionnaireReminderInput!) {
    saQuestionnaireReminder(input: $input) {
      saQuestionnaire { id }
    }
  }
`;

const ReminderButton = ({ id }) => {
  const [isInFlight, setIsInFlight] = useState(null);

  const onClick = async (event) => {
    event.stopPropagation();

    if (confirm(I18n.t('confirm_destroy', options))) {
      setIsInFlight(true);
      await request(graphql, { input: { id } });
    }
  };

  React.useEffect(() => { $('[data-toggle="tooltip"]').tooltip(); });

  return(
    <button className="btn btn-link py-0" data-toggle="tooltip" disabled={isInFlight} onClick={onClick} title={I18n.t('send_reminder', options)} type="button">
      <FontAwesomeIcon icon={isInFlight === null ? faPaperPlane : faCheck} />
    </button>
  );
};

ReminderButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ReminderButton;
