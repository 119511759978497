import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFolder,
  faLevelUp,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { request } from '../../../actions';

import { useDrive } from './reducer';
import CreateDirectory from './create-directory';
import Modal from '../../modal';
import ButtonRole from '../../ui/button-role';
import Dropdown from '../../dropdown';
import { query, mutation } from './queries';

async function getDriveItems(driveId, parentId) {
  if (driveId === null) {
    driveId = parentId;
    parentId = null;
  }

  const [response] = await request(query, { driveId, parentId });
  return response.driveItems;
}

const scope = { scope: 'components.text_block.modal' };

const SelectFileModal = ({ children, onAdd }) => {
  const [state, dispatch] = useDrive();

  const onClose = () => {
    dispatch({ type: 'close' });
  };

  const onClick = async (item) => {
    if (item.isDirectory) {
      if (item.children === undefined) {
        const children = await getDriveItems(item.driveId, item.externalId);
        dispatch({
          type: 'setAndUpdateItem',
          payload: { item: { ...item, children } },
        });
      } else {
        dispatch({ type: 'setItem', payload: { item } });
      }
    } else {
      dispatch({ type: 'setSelectedItem', payload: { item } });
    }
  };

  const onCreateDirectory = async (name) => {
    let { driveId } = state.item;
    let parentId = state.item.externalId;

    if (driveId === null) {
      driveId = parentId;
      parentId = null;
    }
    const [response] = await request(mutation, {
      input: { name, driveId, parentId },
    });
    dispatch({
      type: 'addDirectory',
      payload: { driveItem: response.createDriveDirectory.driveItem },
    });
  };

  const onParentClick = () => {
    dispatch({ type: 'directoryUp' });
  };

  const onSubmit = () => {
    onAdd(state.selectedItem);
    onClose();
  };

  useEffect(() => {
    if (state.isOpen && state.tree === null) {
      const controller = new AbortController();

      (async () => {
        const [response, error] = await request(query, {}, controller);
        if (error === null) {
          dispatch({ type: 'setTree', payload: response });
        }
      })();

      return () => controller.abort();
    }
  }, [dispatch, state.isOpen, state.tree]);

  const renderItem = (item) => (
    <ButtonRole
      key={item.externalId}
      className="mt-1"
      onClick={() => onClick(item)}
    >
      <FontAwesomeIcon icon={item.isDirectory ? faFolder : faFile} />{' '}
      {item.name}
    </ButtonRole>
  );

  return (
    <>
      <Dropdown.Button onClick={() => dispatch({ type: 'open' })}>
        {children}
      </Dropdown.Button>

      <Modal isOpen={state.isOpen} onClose={onClose}>
        <Modal.Header>
          <Modal.Header.Title>{I18n.t('title', scope)}</Modal.Header.Title>
          <Modal.Header.Close
            onClick={onClose}
            aria-label="Close"
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Modal.Header.Close>
        </Modal.Header>
        <Modal.Body>
          <CreateDirectory
            disabled={state.path.length == 0}
            onSubmit={onCreateDirectory}
          />
          {state.path.length > 0 && (
            <button
              className="btn btn-link p-0 text-black"
              onClick={onParentClick}
              type="button"
            >
              <FontAwesomeIcon
                icon={faLevelUp}
                className="fa-flip-horizontal"
              />{' '}
              ..
            </button>
          )}
          {state.item?.children.map(renderItem)}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-default" onClick={onClose} type="button">
            {I18n.t('cancel', scope)}
          </button>
          <button
            className="btn btn-primary"
            disabled={state.selectedItem === null}
            onClick={onSubmit}
            type="button"
          >
            {I18n.t('ok', scope)}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SelectFileModal.propTypes = {
  children: PropTypes.node,
  onAdd: PropTypes.func.isRequired,
};

export default SelectFileModal;
