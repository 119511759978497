class OpernationalDeviationsGetter extends Getter {
  constructor() {
    super();
    this.bindEvents();
  }

  bindEvents() {
    $('[data-getter="operational_deviations"]').on('change', this.onOperinationalDeviationChangeEvent.bind(this));
  }

  onOperinationalDeviationChangeEvent (event) {
    const id = event.target.value;
    const selectElement = document.querySelector(event.target.dataset.target);

    if (id !== '') {
      this.setOptions(selectElement, id);
    } else {
      this.removeOptions(selectElement);
    }
  }

  removeOptions(element) {
    this.setOptionFields(element, []);

    const sub_target = document.querySelector(element.dataset.target);
    if (sub_target != undefined) {
      this.removeOptions(sub_target);
    }
  }

  setOptions (element, id) {
    this.getData({
      url: `/operational_deviations/${id}`,
      success: (data) => {
        this.setOptionFields(element, data);

        const sub_target = document.querySelector(element.dataset.target);
        if (sub_target != undefined) {
          this.setOptionFields(sub_target, []);
          if (element.value !== '') {
            this.setOptions(sub_target, element.value);
          }
        }

      }
    });
  }
}


$(document).on('turbolinks:load', () => {
  if (document.querySelector('[data-getter="operational_deviations"]')) {
    new OpernationalDeviationsGetter();
  }
});
