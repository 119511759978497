import React from 'react';

class QuestionnaireQuestion extends React.Component {
  onQuestionClickEvent(event) {
    event.preventDefault();
    this.props.visibleAuditAnswer(this.props.audit_answer.id);
  }

  stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  render() {
    const { audit_answer } = this.props;
    return(
      <li className={`question-block ${this.props.active ? 'active' : ''}`}>
        <div className={this.props.audit_answer.answer ? 'circle answered-question with-margin' : 'circle with-margin'}>
          <div className="question-number">{this.props.questionNumber + 1}</div>
        </div>
        <button className="btn btn-link" onClick={this.onQuestionClickEvent.bind(this)} type="button">
          {audit_answer.manual_paragraph_number} {audit_answer.manual_paragraph_name}
          {this.stripHtml(audit_answer.question)}
        </button>
      </li>
    );
  }
}

export default QuestionnaireQuestion;
