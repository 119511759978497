import { useCallback, useReducer } from 'react';
import { useQuery } from '../../actions';

const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'update':
      return { ...state, ...payload };
    case 'updateDocument': {
      return {
        ...state,
        documents: state.documents.map((d) =>
          d.id === payload.id ? { ...d, ...payload } : d,
        ),
      };
    }
    case 'destroyDocument': {
      return {
        ...state,
        documents: state.documents.filter((d) => d.id !== payload),
      };
    }
    default:
      new Error('Unknown action type: ', type);
  }
};

const graphql = `
  query DocumentsQuery($businessUnitIds: [ID], $page: Int, $sortBy: String, $sortDirection: String) {
    documentQuery(businessUnitIds: $businessUnitIds, page: $page, sortBy: $sortBy, sortDirection: $sortDirection, limit: 50) {
      documents {
        businessUnitIds
        classification
        externalId
        hasRevisionTask
        id
        isFavorite
        jobTitleIds
        location
        manualChapterId
        name
        retentionPeriod
        revisionDate
        staffId
        status
        type
        updatedAt
        url
        textBlock { manualParagraph { number } }
      }
      totalCount
    }
  }
`;

export const useDocuments = () => {
  const [{ documents, ...state }, dispatch] = useReducer(reducer, {
    businessUnitIds: [],
    documents: [],
    page: 1,
    sortBy: 'paragraph_number',
    sortDirection: 'asc',
  });

  const callback = useCallback(
    ({ documentQuery }) => {
      if (documentQuery.documents.length === 0 && state.page > 1) {
        dispatch({ type: 'update', payload: { page: state.page - 1 } });
      } else {
        dispatch({ type: 'update', payload: documentQuery });
      }
    },
    [state.page],
  );

  useQuery(graphql, state, callback);

  return [{ documents, ...state }, dispatch];
};
