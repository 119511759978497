import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { request } from '../../actions';

import { DataCell, DataCellId, DataContainer, DataRow } from '../data-grid';
import Collapse from '../collapse';
import DocumentFavorite from '../text-block/document-favorite';
import DocumentForm from '../document-form';
import DocumentVersionModal from '../DocumentVersionModal';
import OpenDocument from '../open-document';

const mutation = `
  mutation DestroyDocumentMutation($input: DestroyDocumentInput!) {
    destroyDocument(input: $input) { document { id } }
  }
`;

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
const options = { scope: 'components.documents' };

const iconForDocType = (type, provider) => {
  switch (type) {
    case 'activestorage':
      return 'fa fa-download';
    case 'url':
      return 'fa fa-link';
    default:
      return provider === 'microsoft_graph' ? 'fab fa-microsoft' : 'fab fa-google-drive';
  }
};

const Document = ({ canCreateTask, canDownloadDocuments, canEditDocument, dispatch, taskMeasureTypeId, ...props }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [doc, setDoc] = useState(props.doc);
  const [errors, setErrors] = useState([]);
  const onToggleClick = () => setDisplayForm((x) => !x);

  const onChange = (payload) => {
    setDoc({ ...doc, ...payload });
  };

  const onDestroy = async () => {
    if (confirm('Are you sure?')) {
      await request(mutation, { input: { id: doc.id } });
      dispatch({ type: 'destroyDocument', payload: doc.id });
    }
  };

  const onDelete = (id) => {
    dispatch({ type: 'destroyDocument', payload: id });
  };

  const onSave = (saveDocument) => {
    if (saveDocument.document !== null) {
      setDisplayForm(false);
      setErrors([]);
      dispatch({ type: 'updateDocument', payload: saveDocument.document });
    } else {
      setErrors(saveDocument.errors);
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }, []);

  return (
    <DataContainer>
      <DataRow>
        <DataCellId>
          <a href={`/manual/manual_chapters/${doc.manualChapterId}#document-${doc.id}`}>
            {props.doc.textBlock.manualParagraph.number}
          </a>
        </DataCellId>
        <DataCell size="2">
          {props.doc.status === 'concept' ? (
            doc.name
          ) : (
            <OpenDocument doc={doc} onDelete={onDelete}>
              {doc.name}
            </OpenDocument>
          )}
        </DataCell>
        <DataCell>{new Date(props.doc.updatedAt).toLocaleDateString('nl', dateOptions)}</DataCell>
        <DataCell>{props.staffs.find((s) => s.id === props.doc.staffId)?.name}</DataCell>
        <DataCell>{I18n.t(`documents.classifications.${props.doc.classification}`)}</DataCell>
        <DataCell>
          {props.doc.revisionDate !== null && !props.doc.hasRevisionTask && canCreateTask && (
            <button
              className="btn-link px-0"
              data-target="#modal-nieuwe-taak"
              data-toggle="modal"
              data-name={`${I18n.t('documents.tasks.new.name')} ${doc.name}`}
              data-description={I18n.t('documents.tasks.new.description')}
              data-measure-type-id={taskMeasureTypeId}
              data-document-id={doc.id}
              data-revision-date={doc.revisionDate}
            >
              <i className="fa fa-exclamation-triangle" data-toggle="tooltip" title={I18n.t('add_task', options)}></i>
            </button>
          )}
          {props.doc.revisionDate && new Date(props.doc.revisionDate).toLocaleDateString('nl', dateOptions)}
        </DataCell>
        <DataCell>
          {doc.retentionPeriod && new Date(props.doc.retentionPeriod).toLocaleDateString('nl', dateOptions)}
        </DataCell>
        <DataCell>{props.doc.location}</DataCell>
        <DataCell>
          <DocumentVersionModal document={doc} />
          <DocumentFavorite isFavorite={doc.isFavorite} documentId={doc.id} />
          {(canEditDocument || canDownloadDocuments) && (
            <OpenDocument data-toggle="tooltip" doc={doc} onDelete={onDelete} title={I18n.t('open_document', options)}>
              <i className={iconForDocType(doc.type, props.provider)} />
            </OpenDocument>
          )}
          {canEditDocument && (
            <>
              <button
                className="btn btn-link px-1"
                data-toggle="tooltip"
                onClick={onToggleClick}
                title={I18n.t('edit_document', options)}
              >
                <i className={`fa fa-${displayForm ? 'times' : 'edit'}`}></i>
              </button>
              <button
                className="btn btn-link px-1"
                data-toggle="tooltip"
                onClick={onDestroy}
                title={I18n.t('delete', options)}
              >
                <i className="fa fa-trash-alt"></i>
              </button>
            </>
          )}
        </DataCell>
      </DataRow>
      <Collapse
        in={displayForm}
        timeout={{
          appear: 200,
          enter: 200,
          exit: 0,
        }}
      >
        <DocumentForm {...props} doc={doc} errors={errors} index={doc.id} onChange={onChange} onSave={onSave} />
      </Collapse>
    </DataContainer>
  );
};

Document.propTypes = {
  canCreateTask: PropTypes.bool.isRequired,
  canDownloadDocuments: PropTypes.bool.isRequired,
  canEditDocument: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  doc: PropTypes.shape({
    classification: PropTypes.string.isRequired,
    hasRevisionTask: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    location: PropTypes.string,
    manualChapterId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    retentionPeriod: PropTypes.string,
    revisionDate: PropTypes.string,
    staffId: PropTypes.string,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    textBlock: PropTypes.shape({
      manualParagraph: PropTypes.shape({
        number: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  provider: PropTypes.string,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  taskMeasureTypeId: PropTypes.number.isRequired,
};

export default Document;
