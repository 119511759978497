import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import OperationalDeviations from './OperationalDeviations';
import DeviationStore from 'stores/deviation-store';

class DeviationsContainer extends React.Component {
  constructor(props) {
    super(props);
    DeviationStore.setDeviations(props.deviations);
    this.state = { tab_active: 'deviations' };
  }

  componentWillMount(){
    var tab = window.location.hash.replace('#', '');
    if(tab != ''){
      this.setState({tab_active: tab });
    }
  }

  onClickHandler(event) {
    event.preventDefault();
    this.setState({tab_active: event.target.dataset.tabname});
  }

  isActive(tab) {
    if (this.state.tab_active == tab){
      return 'active';
    }
  }

  render() {
    const {
      allow_create, deviation_types, filters, organisationId,
      operation_message_id, operational_deviations, operational_filters
    } = this.props;

    return(
      <div>
        <ul className="nav nav-tabs">
          <li data-toggle="tab" className={this.isActive('deviations')}>
            <a href="#deviations" onClick={this.onClickHandler.bind(this)} data-tabname="deviations">{I18n.t('deviations.index.deviations')}</a>
          </li>
          <li data-toggle="tab" className={this.isActive('operational_deviations')}>
            <a href="#operational_deviations" onClick={this.onClickHandler.bind(this)} data-tabname="operational_deviations">{I18n.t('deviations.index.operational_deviations')}</a>
          </li>
        </ul>

        <div className="tab-content">
          <div id="deviations" className={`tab-pane ${this.isActive('deviations')}`}>
            <Deviations
              deviation_types={deviation_types}
              organisationId={organisationId}
              filters={filters}
              allow_create={allow_create}
            />
          </div>
          <div id="operational_deviations" className={`tab-pane ${this.isActive('operational_deviations')}`}>
            <OperationalDeviations
              organisationId={organisationId}
              operational_deviations={operational_deviations}
              filters={operational_filters}
              allow_create={allow_create}
              type="operational_deviations"
              operation_message_id={operation_message_id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DeviationsContainer;
