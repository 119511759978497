import React from 'react';
import PropTypes from 'prop-types';

import { request } from '../../actions';
import TableRow from './table-row';

const mutation = `
  mutation TableMutation($input: CreateTableRowInput!) {
    createTableRow(input: $input) {
      tableRow {
        id
        order
        tableCells {
          id
          value
          defaultAnswerId
          tableColumnId
        }
      }
    }
  }
`;

const Table = ({ dispatch, table }) => {
  const onAddTableRowClick = async () => {
    const [
      {
        createTableRow: { tableRow },
      },
    ] = await request(mutation, { input: { tableId: table.id } });
    dispatch({
      type: 'updateTable',
      payload: {
        table: { ...table, tableRows: [...table.tableRows, tableRow] },
      },
    });
  };

  const renderRow = (row, index) => {
    if (row._destroy) return null;

    return (
      <TableRow
        key={row.id}
        index={index}
        dispatch={dispatch}
        table={table}
        tableRow={row}
      />
    );
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th></th>
            {table.tableColumns.map((c) => (
              <th key={c.id}>{c.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.tableRows.map(renderRow)}
          <tr>
            <td>
              <button
                className="btn btn-default btn-xs"
                onClick={onAddTableRowClick}
                type="button"
              >
                <i className="fa fa-plus"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  dispatch: PropTypes.func.isRequired,
  table: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    tableRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default Table;
