import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';
import DocumentFavorite from './DocumentFavorite';
import FlexGrid from './FlexGrid';

class DashboardDocuments extends React.Component {
  columns() {
    const scope = { scope: 'components.dashboard_documents' };

    return [{
      header: I18n.t('name', scope),
      accessor: 'name',
      size: 4
    }, {
      header: I18n.t('status', scope),
      accessor: (doc) => I18n.t(doc.status, { scope: 'documents.status' })
    }, {
      header: I18n.t('actions', scope),
      accessor: (doc) => (
        <DocumentFavorite
          documentId={doc.id}
          favoriteId={doc.favorite_id}
        />
      ),
      size: 1
    }];
  }

  render() {
    return(
      <FlexGrid
        columns={this.columns()}
        records={this.props.documents}
      />
    );
  }
}

export default DashboardDocuments;
