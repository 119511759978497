class NewsItem {
  constructor(form) {
    this.form = form;
    this.imageTemplate = this.form.querySelector('[data-template="newsItemImage"]');

    this.bindEvents();
  }

  bindEvents() {
    $(this.form).on('click', '[data-action="deleteNewsItemDocument"]', this.onDeleteNewsItemDocumentClick.bind(this));

    this.form.querySelector('[data-action="newNewsItemImage"]').addEventListener('click', this.onAddNewsItemImageClick.bind(this));
    $(this.form).on('click', '[data-action="deleteNewsItemImage"]', this.onDeleteNewsItemImageClick.bind(this));
  }

  onDeleteNewsItemDocumentClick({ currentTarget }) {
    const { id } = currentTarget.dataset;

    const input = document.createElement('input');
    input.name = `news_item[attachments_attachments_attributes][${id}][id]`;
    input.type = 'hidden';
    input.value = id;
    const inputDestroy = document.createElement('input');
    inputDestroy.name = `news_item[attachments_attachments_attributes][${id}][_destroy]`;
    inputDestroy.type = 'hidden';
    inputDestroy.value = true;

    currentTarget.parentElement.replaceWith(inputDestroy);
    inputDestroy.parentElement.insertBefore(input, inputDestroy);
  }

  onAddNewsItemImageClick(event) {
    event.preventDefault();

    const template = this.imageTemplate.text.replace(/new_association/g, Date.now());
    $(this.imageTemplate).before(template);
  }

  onDeleteNewsItemImageClick(event) {
    $(event.target).parents('.col-sm-4').find('[data-element="delete"]').val('1');
    $(event.target).parents('.col-sm-4').hide();
    $(event.target).parents('.thumbnail').remove();
  }
}

$(document).on('turbolinks:load', () => {
  const form = document.querySelector('[data-form="newsItem"]');

  if (form) {
    new NewsItem(form);
  }

  if ($('#news_item_content').length === 0) { return; }

  if (window.CKEDITOR.instances['news_item_content']) {
    window.CKEDITOR.instances['news_item_content'].destroy();
  }
  window.CKEDITOR.replace('news_item_content', {
    language: 'nl',
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles'] },
      { name: 'paragraph',   groups: ['list', 'indent'] },
      { name: 'links,',      groups: ['links'] }
    ],
    removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript',
    removeDialogTabs: 'link:advanced'
  });
});
