import I18n from '../../i18n-js/index.js.erb';

$(document).on('turbolinks:load', () => {
  $('[data-action="delete-sa-question"]').on('click', ({ currentTarget }) => {
    const id = currentTarget.dataset.id;
    const selfAssessmentId = currentTarget.dataset.selfAssessmentId

    if (confirm(I18n.t('confirm_destroy', { scope: 'self_assessmets.edit' }))) {
      currentTarget.disabled = true;
      $.ajax({
        url: `/self_assessments/${selfAssessmentId}/questions/${id}`,
        method: 'DELETE',
        dataType: 'json',
        success: () => {
          $(currentTarget).parents('[data-element="sa-question"]').slideUp();
        },
      })
    }
  });
});
