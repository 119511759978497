import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import { useRecoilValue } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPrint } from '@fortawesome/pro-regular-svg-icons';

import { businessUnitIdsState } from './state';
import { request } from '../../actions';
import { textBlockRequest } from './queries';

import { FormCheckbox } from '../form';
import DropDownMenu from '../DropDownMenu';
import ManualParagraphTasks from '../ManualParagraphTasks';
import NormLinks from './norm-links';
import ParagraphFavorite from '../ParagraphFavorite';
import TextBlock from './text-block';

function filterTextBlocks(textBlocks, businessUnitIds) {
  if (businessUnitIds.length === 0) return textBlocks;

  const includeEmpty = businessUnitIds.includes(0);

  const filterItem = (item) => {
    return (
      item.businessUnitIds === undefined ||
      (includeEmpty && item.businessUnitIds.length === 0) ||
      item.businessUnitIds.some((id) => businessUnitIds.includes(id))
    );
  };

  return textBlocks.reduce((textBlocks, textBlock) => {
    const documents = textBlock.documents.filter(filterItem);
    if (documents.length > 0 || filterItem(textBlock)) {
      textBlocks = [...textBlocks, { ...textBlock, documents }];
    }
    return textBlocks;
  }, []);
}

const options = { scope: 'components.manual_paragraph' };

const ManualParagraph = ({
  currentMember,
  manualChapterId,
  manualParagraph,
  manualParagraphFavorite,
  normOrganisations,
  ...props
}) => {
  const businessUnitIds = useRecoilValue(businessUnitIdsState);
  const [textBlocks, setTextBlocks] = useState(manualParagraph.textBlocks);

  const onTextBlockChange = (textBlock) => {
    setTextBlocks(
      textBlocks.map((tb) => (tb.id === textBlock.id ? textBlock : tb)),
    );
  };

  let tick = false;
  const loadTextBlock = async (textBlock) => {
    if (!tick) {
      tick = true;
      const [response, error] = await request(textBlockRequest, {
        id: textBlock.id,
      });

      if (error === null) {
        onTextBlockChange({ ...textBlock, ...response.textBlock });
      }
    }
  };

  const renderTextBlock = (textBlock) => (
    <TextBlock
      key={textBlock.id}
      allowLoading={props.allowLoading}
      businessUnits={props.businessUnits}
      currentMember={currentMember}
      manualChapterId={manualChapterId}
      manualParagraph={manualParagraph}
      loadTextBlock={loadTextBlock}
      onTextBlockChange={onTextBlockChange}
      textBlock={textBlock}
      textBlockFavorite={props.textBlockFavorites.find(
        (f) => f.textBlockId === textBlock.id,
      )}
    />
  );

  const fTextBlocks = filterTextBlocks(textBlocks, businessUnitIds);
  if (fTextBlocks.length === 0) return null;

  return (
    <div className="card" id={`manual-paragraph-${manualParagraph.id}`}>
      <h2>
        {manualParagraph.number} {manualParagraph.name}
      </h2>

      <div className="row">
        <div className="col-xs-6">
          <ul className="list-links mb-0">
            <li>
              <a
                className="btn btn-link px-1"
                href={`/manual/manual_chapters/${manualChapterId}/manual_paragraphs/${manualParagraph.id}.pdf`}
                rel="noopener noreferrer"
                target="_blank"
                title={I18n.t('download_pdf', options)}
              >
                <i className="far fa-print"></i>
              </a>
            </li>
            {currentMember.canCreateTask && (
              <ManualParagraphTasks
                manualChapterId={manualChapterId}
                manualParagraphId={manualParagraph.id}
                measure_type_id={props.measure_type_id}
                textBlockIds={manualParagraph.textBlocks.map((t) =>
                  parseInt(t.id),
                )}
              />
            )}

            {currentMember.canManageTextBlock && (
              <DropDownMenu
                icon={faPencil}
                title={I18n.t('edit_text_blocks', options)}
              >
                {fTextBlocks.map((t) => (
                  <li key={t.id}>
                    <a
                      href={`${location.pathname}/manual_paragraphs/${manualParagraph.id}/text_blocks/${t.id}/edit`}
                    >
                      {t.name}
                    </a>
                  </li>
                ))}
              </DropDownMenu>
            )}

            <li>
              <ParagraphFavorite
                paragraphId={manualParagraph.id}
                favoriteId={manualParagraphFavorite.id}
              />
            </li>
          </ul>
        </div>

        <div className="col-xs-6">
          <ul className="list-links list-links-right mb-0">
            {manualParagraph.resources.length > 0 && (
              <DropDownMenu label={I18n.t('resources', options)} right>
                {manualParagraph.resources.map((r) => (
                  <li key={r.id}>
                    <span className="text-muted">{r.name}</span>
                  </li>
                ))}
              </DropDownMenu>
            )}

            <NormLinks
              currentMember={currentMember}
              normOrganisations={normOrganisations}
              manualParagraph={manualParagraph}
            />
          </ul>
        </div>
      </div>

      <hr />
      {fTextBlocks.map(renderTextBlock)}
    </div>
  );
};

ManualParagraph.defaultProps = {
  manualParagraphFavorite: {},
};

ManualParagraph.propTypes = {
  allowLoading: PropTypes.bool,
  businessUnits: PropTypes.array.isRequired,
  currentMember: PropTypes.shape({
    canCreateTask: PropTypes.bool.isRequired,
    canManageTextBlock: PropTypes.bool.isRequired,
  }),
  normOrganisations: PropTypes.array.isRequired,
  manualChapterId: PropTypes.string.isRequired,
  manualParagraph: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    textBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }),
  manualParagraphFavorite: PropTypes.shape({
    id: PropTypes.string,
  }),
  measure_type_id: PropTypes.number.isRequired,
  textBlockFavorites: PropTypes.array.isRequired,
};

export default memo(ManualParagraph);
