import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { request, useQuery } from '../../actions';
import Document from './document';

const graphql = `
  query DocumentsConainterQuery($id: ID!) {
    businessUnits { id name }
    classifications
    jobTitles { id name }
    staffs { id name }
    task(id: $id)  {
      document {
        classification
        businessUnitIds
        driveId
        externalId
        id
        isFavorite
        jobTitleIds
        location
        name
        retentionPeriod
        revisionDate
        staffId
        status
        type
        url
      }
    }
  }
`;

const Container = (props) => {
  const [state, setState] = useState(null);
  useQuery(graphql, { id: props.id }, setState);

  if (state === null) return null;

  return <Document {...state} {...props} doc={state.task.document} />;
};

Container.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Container;
