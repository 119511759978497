class DeviationCategories {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents() {
    this.container.on('click', '[data-action="add"]', this.onAddClickEvent.bind(this));
    this.container.on('click', '[data-action="delete"]', this.onDeleteClickEvent.bind(this));
    this.container.on('change', '[data-action="enable"]', this.onToggleChangeEvent.bind(this));
  }

  onAddClickEvent(event) {
    event.preventDefault();

    let template = $('[data-id="deviation_category_template_' + event.currentTarget.id + '"]').html().replace(/new_association/g, Date.now());
    $('[data-id="deviation_category_template_' + event.currentTarget.id + '"]').before(template);
  }

  onDeleteClickEvent(event) {
    event.preventDefault();

    let el =  $(event.target).parents('.removable').parent();

    if (el.prev().data('element') === 'destroy') {
      el.prev().val(1);
    }
    el.remove();
  }

  onToggleChangeEvent(event) {
    event.preventDefault();

    if (event.currentTarget.checked === true) {
      this.addDeviationTypeOrganisationRecord(event.currentTarget.value, event);
    } else {
      this.deleteDeviationTypeOrganisationRecord(event.currentTarget.id, event.currentTarget.value);
    }
  }

  addDeviationTypeOrganisationRecord(value, event) {
    $.ajax({
      url: '/organisation/deviation_types_organisations',
      method: 'post',
      data: {
        deviation_types_organisation: {
          deviation_type: value
        }
      },
      dataType: 'json',
      success: (data) => {
        event.currentTarget.id = data.deviation_types_organisation.id;
      }
    });
  }

  deleteDeviationTypeOrganisationRecord(id) {
    $.ajax({
      url: `/organisation/deviation_types_organisations/${id}`,
      method: 'delete',
      data: {
        id: id
      },
      dataType: 'json'
    });
  }
}

$(document).on('turbolinks:load', () => {
  $('[data-container="deviation_categories"]').each((_, container) => {
    new DeviationCategories(container);
  });
});
