class ManualParagraphGetter extends Getter {
  constructor(fetchInitial) {
    super();

    this.onParagraphChangeEvent = this.onParagraphChangeEvent.bind(this);
    this.onTextBlocksChangeEvent = this.onTextBlocksChangeEvent.bind(this);
    this.bindevents();

    if (fetchInitial && $('[data-element="paragraphs"]').val() === null) {
      $('[data-getter="paragraphs"]').trigger('change');
    }
  }

  setIds(intialIds) {
    $('[data-element="chapters"]').val(intialIds.manualChapterId);
    this.fetchParagraphs(intialIds.manualChapterId, () => {
      $('[data-element="paragraphs"]').val(intialIds.manualParagraphId);
    });
  }

  bindevents() {
    if (document.querySelector('[data-getter="paragraphs"]')) {
      $('[data-getter="paragraphs"]').on('change', this.onParagraphChangeEvent);
    }
    if (document.querySelector('[data-getter="text_blocks"]')) {
      $('[data-getter="text_blocks"]').on('change', this.onTextBlocksChangeEvent);
    }
  }

  onParagraphChangeEvent(event) {
    const id = event.target.value;

    if (id !== '') {
      this.fetchParagraphs(id);
    }
  }

  fetchParagraphs(id, callback) {
    const selectElement = document.querySelector('[data-element=paragraphs]');

    this.getData({
      url: `/manual/manual_chapters/${id}/manual_paragraphs`,
      success: data => {
        this.setOptionFields(selectElement, data);
        if (callback) callback();
        $(selectElement).trigger('change');
      }
    });
  }

  onTextBlocksChangeEvent(event) {
    const chapter_id = document.querySelector('[data-element=chapters]').value;
    const id = event.target.value;
    const selectElement = document.querySelector('[data-element=text_blocks]');

    if (id !== '') {
      this.getData({
        url: '/text_blocks',
        data: { manual_chapter_id: chapter_id, manual_paragraph_id: id },
        success: data => {
          this.setOptionFields(selectElement, data);
        }
      });
    }
  }
}

export default ManualParagraphGetter;

$(document).on('turbolinks:load', () => {
  if (document.querySelector('[data-getter="paragraphs"]') || document.querySelector('[data-getter="text_blocks"]')) {
    new ManualParagraphGetter();
  }
});
