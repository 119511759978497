import DpiaQuestionnaireConstants from 'constants/dpia-questionnaire-constants';
import { dpiaQuestionnaireDispatcher } from 'dispatcher/dpia-questionnaire-dispatcher';

let _dpia_questionnaires = [];
let _listeners = [];

class DpiaQuestionnaireStore {
  static getAll() {
    return _dpia_questionnaires;
  }

  static set(dpia_questionnaires) {
    _dpia_questionnaires = [...dpia_questionnaires];
  }

  static emitChange() {
    _listeners.forEach((callback) => {
      callback();
    });
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default DpiaQuestionnaireStore;

dpiaQuestionnaireDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case DpiaQuestionnaireConstants.DPIA_QUESTIONNAIRE_CREATE:
      _dpia_questionnaires.push(action.dpia_questionnaire);
      DpiaQuestionnaireStore.emitChange();
  }
});
