$(document).on('turbolinks:load', () => {
  $('[data-toggle="doubleClick"]').on('dblclick', (e) => { window.location.href = e.currentTarget.dataset.url; });

  $('.dropdown-submenu a').on('click', (e) => {
    if ($(e.currentTarget).next('ul').length) {
      if ($(e.currentTarget).parent().hasClass('open')) {
        $(e.currentTarget).parent().removeClass('open');
      } else {
        $(e.currentTarget).parents('ul').first().find('.open').removeClass('open');
        $(e.currentTarget).parent().addClass('open');
      }
      e.stopPropagation();
      e.preventDefault();
    }
  });
});
