import Dispatcher from './dispatcher';

class AuditDispatcher extends Dispatcher {
  handleCreateAuditAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
  handleUpdateAuditAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }

  handleDestroyAuditAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const auditDispatcher = new AuditDispatcher();
