import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import { RecoilRoot, useRecoilState } from 'recoil';

import { businessUnitIdsState } from './state';

import { FormCheckbox } from '../form';
import Grid from '../grid';

function storeFilters(memberId, filters) {
  sessionStorage.setItem(`manual_filters_${memberId}`, JSON.stringify({ businessUnitIds: filters }));
}

const Filters = ({ currentMember, businessUnits }) => {
  const options = { scope: 'components.manual_chapter' };
  const [businessUnitIds, setBusinessUnitIds] = useRecoilState(businessUnitIdsState);

  const onNoneChange = ({ target: { checked } }) => {
    const ids = checked ? [...businessUnitIds, 0] : businessUnitIds.filter(id => id !== 0);
    setBusinessUnitIds(ids);
    storeFilters(currentMember.id, ids);
  };

  const onBusinessUnitChange = (businessUnit) => {
    let ids = [];

    if (businessUnitIds.includes(businessUnit.id)) {
      ids = businessUnitIds.filter(id => id !== businessUnit.id);
    } else {
      ids = [...businessUnitIds, businessUnit.id];
    }

    setBusinessUnitIds(ids);
    storeFilters(currentMember.id, ids);
  };

  return(
    <Grid.Content.Filter.MultiSelect
      id="businessUnits"
      items={businessUnits}
      label={I18n.t('business_units', options)}
      onChange={onBusinessUnitChange}
      placeholder="Filter"
      selectedIds={businessUnitIds}
    >
      <FormCheckbox checked={businessUnitIds.includes(0)} id="business_unit_none" name="businessUnitIds" onChange={onNoneChange} value="">
        {I18n.t('none', options)}
      </FormCheckbox>
    </Grid.Content.Filter.MultiSelect>
  );
};

Filters.propTypes = {
  currentMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default Filters;
