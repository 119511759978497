import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import moment from 'moment';
import Turbolinks from 'turbolinks';
import Datetime from 'react-datetime';

import TaskStore from '../stores/task-store';

import Filtering from './Filtering';
import Pagination from './Pagination';

class Tasks extends React.Component {
  constructor(props) {
    super(props);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.fetchExcel = this.fetchExcel.bind(this);
    this.fetchPDF = this.fetchPDF.bind(this);

    let activeFilters = {};
    this.filters = {};
    if (window.sessionStorage) {
      activeFilters = JSON.parse(window.sessionStorage.getItem('task_filters_' + this.props.organisationId));

      if (activeFilters === null) {
        this.filters = {
          business_units: [],
          staffs: [],
          statuses: props.statuses.filter((s) => s.position < 4).map((s) => s.id),
        };
        activeFilters = {};
      } else {
        ['business_units', 'staffs', 'statuses'].forEach((key) => (this.filters[key] = activeFilters[key] || []));
      }
    }

    this.state = {
      page: activeFilters.page || 1,
      sort: activeFilters.sort || 'id',
      sortAsc: activeFilters.sortAsc || false,
      startDate: activeFilters.startDate ? moment(activeFilters.startDate) : moment().add(1, 'M'),
      tasks: [],
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.fetchTasks();
    TaskStore.addChangeListener(this.onStoreChange);
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  componentWillUnmount() {
    TaskStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.fetchTasks();
  }

  fetchExcel(event) {
    event.preventDefault();
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    };
    this.fetching(options)
      .then((r) => r.blob())
      .then((blob) => {
        const filename = I18n.t('components.tasks.export_filename', { date: moment().format('YYYYMMDD'), ext: 'xlsx' });
        window.downloadBlob(blob, filename);
      });
  }

  fetchPDF(event) {
    event.preventDefault();
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/pdf',
      },
    };
    this.fetching(options)
      .then((r) => r.blob())
      .then((blob) => {
        const filename = I18n.t('components.tasks.export_filename', { date: moment().format('YYYYMMDD'), ext: 'pdf' });
        window.downloadBlob(blob, filename);
      });
  }

  fetchTasks() {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    };
    this.fetching(options)
      .then((r) => r.json())
      .then((data) => {
        if (this.state.page <= data.query.total_pages) {
          let state = {
            tasks: data.query.tasks,
            totalPages: data.query.total_pages,
          };
          this.setState(state);
        } else {
          this.setState({ page: data.query.total_pages }, this.fetchTasks);
        }
      });

    const filters = {
      ...this.filters,
      page: this.state.page,
      startDate: this.state.startDate.format(),
      sort: this.state.sort,
      sortAsc: this.state.sortAsc,
    };
    window.sessionStorage.setItem('task_filters_' + this.props.organisationId, JSON.stringify(filters));
  }

  fetching(options) {
    const url = new URL('/tasks', `${location.protocol}//${location.host}`);
    url.searchParams.append('start_date', this.state.startDate.format('YYYY-MM-DD'));
    url.searchParams.append('page', this.state.page);
    url.searchParams.append('sort', this.state.sort);
    url.searchParams.append('asc', this.state.sortAsc ? 'asc' : 'desc');
    this.filters.business_units.forEach((l) => url.searchParams.append('business_unit_ids[]', l));
    this.filters.staffs.forEach((s) => url.searchParams.append('staff_ids[]', s));
    this.filters.statuses.forEach((s) => url.searchParams.append('status_ids[]', s));

    return fetch(url, options);
  }

  onFilterChange(name, items) {
    this.filters[name] = items;
    this.fetchTasks();
  }

  onStartDateChange(startDate) {
    if (typeof startDate !== 'string') {
      this.setState({ startDate }, this.fetchTasks);
    }
  }

  onSortClick(event) {
    event.preventDefault();
    if (this.state.sort === event.target.dataset.sort) {
      this.setState((prevState) => ({ sortAsc: !prevState.sortAsc }), this.fetchTasks);
    } else {
      this.setState({ sort: event.target.dataset.sort, sortAsc: true }, this.fetchTasks);
    }
  }

  onPageClick(page) {
    $.scrollTo($('[data-react-class="Tasks"]'), 75);
    this.setState({ page }, this.fetchTasks);
  }

  render() {
    const options = { scope: 'components.tasks' };
    const { allow_create, business_units, staffs, statuses } = this.props;
    const { page, sort, sortAsc, startDate, tasks, totalPages } = this.state;

    return (
      <React.Fragment>
        <div className="heading">
          <div className="header-grouping">
            <h1 className="heading-title">{I18n.t('header', options)}</h1>
            <button className="btn btn-link" data-toggle="modal" data-target="#image_modal" type="button">
              <span data-toggle="tooltip" title={I18n.t('tooltip.title', options)}>
                <i className="far fa-info-circle"></i>
              </span>
            </button>
          </div>
          <div className="form-inline">
            <div className="form-group">
              <label
                htmlFor="start_date"
                data-toggle="popover"
                title={I18n.t('popover.header', options)}
                data-content={I18n.t('popover.content', options)}
                data-placement="bottom"
              >
                {I18n.t('filter_start_date', options)}
              </label>
              &nbsp;
              <Datetime
                dateFormat="DD-MM-YYYY"
                inputProps={{ id: 'start_date', name: 'start_date', placeholder: 'DD-MM-YYYY' }}
                locale={I18n.locale}
                onChange={this.onStartDateChange}
                timeFormat={false}
                value={startDate}
              />
            </div>
          </div>
          <ul className="list-links">
            <li>
              <form
                className="form-inline"
                action={`${location.pathname}.pdf`}
                target="_blank"
                onSubmit={this.fetchPDF}
              >
                <button
                  type="submit"
                  className="btn btn-link"
                  data-toggle="tooltip"
                  title={I18n.t('export_pdf', options)}
                  data-placement="bottom"
                >
                  <i className="far fa-print"></i>
                </button>
              </form>
            </li>
            <li>
              <form
                className="form-inline"
                action={`${location.pathname}.xlsx`}
                target="_blank"
                onSubmit={this.fetchExcel}
              >
                <button
                  type="submit"
                  className="btn btn-link"
                  data-toggle="tooltip"
                  title={I18n.t('export_excel', options)}
                  data-placement="bottom"
                >
                  <i className="far fa-file-excel"></i>
                </button>
              </form>
            </li>
            <Filtering
              initialState={this.filters.business_units}
              onChange={this.onFilterChange}
              name="business_units"
              items={business_units}
            />
            <Filtering initialState={this.filters.staffs} onChange={this.onFilterChange} name="staffs" items={staffs} />
            <Filtering
              initialState={this.filters.statuses}
              onChange={this.onFilterChange}
              name="statuses"
              items={statuses}
            />
            {allow_create && (
              <li>
                <button className="btn btn-link" data-toggle="modal" data-target="#modal-nieuwe-taak" type="button">
                  {I18n.t('new_task', options)}
                </button>
              </li>
            )}
          </ul>
        </div>

        <div className="card">
          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-1">
                  <a
                    href="#"
                    className={`${sort === 'id' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="id"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('id', options)}
                  </a>
                </div>
                <div className="size-4">
                  <a
                    href="#"
                    className={`${sort === 'name' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="name"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('name', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'start_date' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="start_date"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('start_date', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'end_date' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="end_date"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('end_date', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'deviation' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="deviation"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('deviation', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'assigned_to' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="assigned_to"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('assigned_to', options)}
                  </a>
                </div>
                <div className="size-2">
                  <a
                    href="#"
                    className={`${sort === 'status' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="status"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('status', options)}
                  </a>
                </div>
              </div>
            </div>
            <div className="body">
              {tasks.map((t) => (
                <Task key={t.id} task={t} />
              ))}
            </div>
          </div>

          <Pagination onPageClick={this.onPageClick} page={page} totalPages={totalPages} />
        </div>
      </React.Fragment>
    );
  }
}

const Task = ({ task }) => {
  const onClick = () => Turbolinks.visit(`/tasks/${task.id}`);

  return (
    <div data-href={`/tasks/${task.id}`} className="flex-row" onClick={onClick}>
      <div title={task.id} className="size-1 truncate">
        {task.id}
      </div>
      <div title={task.name} className="size-4 truncate">
        {task.name} {task.has_attachments && <i className="fas fa-paperclip"></i>}
      </div>
      <div title={task.start_date} className="size-2 truncate">
        {task.start_date}
      </div>
      <div title={task.end_date} className="size-2 truncate">
        {task.end_date}
      </div>
      <div title={task.deviation_title} className="size-2 truncate">
        {task.deviation_title}
      </div>
      <div title={task.assigned_to_name} className="size-2 truncate">
        {task.assigned_to_name}
      </div>
      <div title={task.status_name} className="size-2 truncate">
        {task.status_name}
      </div>
    </div>
  );
};

Task.propTypes = {
  task: PropTypes.shape({
    assigned_to_name: PropTypes.string,
    deviation_title: PropTypes.string,
    end_date: PropTypes.string,
    has_attachments: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    start_date: PropTypes.string,
    status_name: PropTypes.string.isRequired,
  }).isRequired,
};

Tasks.propTypes = {
  allow_create: PropTypes.bool.isRequired,
  business_units: PropTypes.array.isRequired,
  organisationId: PropTypes.number.isRequired,
  staffs: PropTypes.array.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default Tasks;
