import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import DeviationStore from 'stores/deviation-store';
import TaskStore from 'stores/task-store';

class DpiaComplianceForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.deviation) {
      DeviationStore.addDeviations([props.deviation]);
    }

    this.bindChangeEvent = this.onChange.bind(this);
    this.state = {
      deviation: props.deviation,
      tasks: props.tasks,
      compliance_answer: props.dpia_answer.compliance_answer,
    };
  }

  componentDidMount() {
    this.form = document.forms[`edit_dpia_answer_${this.props.dpia_answer.id}`];
    DeviationStore.addChangeListener(this.bindChangeEvent);
    TaskStore.addChangeListener(this.bindChangeEvent);
  }

  componentWillUnmount() {
    DeviationStore.removeChangeListener(this.bindChangeEvent);
    TaskStore.removeChangeListener(this.bindChangeEvent);
  }

  onChange() {
    const deviation = DeviationStore.getAll().find((d) => d.dpia_answer_id === this.props.dpia_answer.id);

    if (deviation) {
      const tasks = TaskStore.getAll().filter((task) => task.deviation_id === deviation.id);

      this.setState(p => ({
        deviation,
        tasks: [...p.tasks, ...tasks],
      }));
    }
  }

  onTrueChangeEvent() {
    this.setState({
      compliance_answer: true,
    });
    this.submitForm();
  }

  onFalseChangeEvent() {
    this.setState({
      compliance_answer: false,
    });
    this.submitForm();
  }

  submitForm() {
    const options = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: this.form.method,
      body: $(this.form).serialize(),
      credentials: 'same-origin',
    };
    fetch(this.form.action, options);
  }

  stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  render() {
    const options = { scope: 'dpia_questionnaires.show' };
    const { compliance_answer, deviation, tasks } = this.state;

    const deviation_description = `${I18n.t('deviation_description', options)} \n${this.stripHtml(this.props.dpia_answer[`${this.props.dpia_answer.answer}_measure`]) || ''}`;

    return(
      <div>
        {I18n.t('compliance_answer', options)}
        <div className="radio">
          <label>
            <input
              checked={compliance_answer}
              disabled={deviation}
              name="dpia_answer[compliance_answer]"
              onChange={this.onTrueChangeEvent.bind(this)}
              type="radio"
              value="1"
            />
            {I18n.t('yes', options)}
          </label>
        </div>

        <div className="radio">
          <label>
            <input
              checked={compliance_answer == false}
              disabled={deviation}
              name="dpia_answer[compliance_answer]"
              onChange={this.onFalseChangeEvent.bind(this)}
              type="radio"
              value="0"
            />
            {I18n.t('no', options)}
          </label>
        </div>
        {
          deviation === null ?
            compliance_answer === false && (
              <div>
                <a
                  href="#new-deviation-modal"
                  data-toggle="modal"
                  data-deviation-type={this.props.deviation_type}
                  data-deviation-focus-area={this.props.focus_area}
                  data-dpia-answer-id={this.props.dpia_answer.id}
                  data-description={deviation_description}
                >
                  {I18n.t('create_deviation', options)}
                </a>
              </div>
            )
            :
            <div>
              <a href={`${location.pathname}/deviations/${deviation.id}`}>{I18n.t('deviation', options)}: {deviation.title}</a>
              <h6 className='mb-6'>{I18n.t('tasks', options)}</h6>
            </div>
        }
        {
          tasks.map(task =>
            <div key={task.id}><a href={`${location.pathname}/deviations/${deviation.id}/tasks/${task.id}`}>{task.name}</a></div>,
          )
        }
        {
          deviation !== null && compliance_answer === false &&
            <div>
              <a
                href="#modal-nieuwe-taak"
                data-toggle="modal"
                data-deviation-id={deviation.id}
                data-description={this.stripHtml(this.props.dpia_answer[`${this.props.dpia_answer.answer}_measure`])}
              >
                {I18n.t('new_task', options)}
              </a>
            </div>
        }
      </div>
    );
  }
}

export default DpiaComplianceForm;
