import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import FlexGrid from './FlexGrid';
import ManualParagraphFavorite from './ManualParagraphFavorite';
import TextBlockFavorite from './TextBlockFavorite';

class DashboardTextBlocks extends React.Component {
  constructor(props) {
    super(props);
  }

  columns() {
    const scope = { scope: 'components.dashboard_text_blocks' };

    return [{
      header: I18n.t('name', scope),
      accessor: 'name',
      size: 4
    }, {
      header: I18n.t('actions', scope),
      accessor: (row) => {
        if (row.paragraph) {
          return(
            <ManualParagraphFavorite
              manualParagraphId={row.id}
              favoriteId={row.favorite_id}
            />
          );
        } else {
          return(
            <TextBlockFavorite
              textBlockId={row.id}
              favoriteId={row.favorite_id}
            />
          );
        }
      },
      size: 1
    }];
  }

  render() {
    return (
      <FlexGrid
        columns={this.columns()}
        records={this.props.text_blocks.concat(this.props.manual_paragraphs)}
      />
    );
  }
}

export default DashboardTextBlocks;
