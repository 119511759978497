import React from 'react';
import I18n from '../i18n-js/index.js.erb';
import styled from 'styled-components';

const Grid = styled.div`
  columns: 200px 4;
`;

class JobTitleForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDestroyClick = this.onDestroyClick.bind(this);
    this.onManualsJobTitleChanage = this.onManualsJobTitleChanage.bind(this);
    this.onSucces = this.onSucces.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.renderManualsJobTitle = this.renderManualsJobTitle.bind(this);

    this.state = {
      errors: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  onChange({ target: { name, value } }) {
    const { job_title, manuals_job_titles } = this.props;

    this.props.onChange({
      job_title: { ...job_title, [name]: value },
      manuals_job_titles,
    });
  }

  onManualsJobTitleChanage({ target: { value } }) {
    const id = parseInt(value);
    const { job_title, manuals_job_titles } = this.props;

    if (job_title.manuals_job_title_ids.includes(id)) {
      this.props.onChange({
        job_title: {
          ...job_title,
          manuals_job_title_ids: job_title.manuals_job_title_ids.filter(
            (i) => i !== id,
          ),
        },
        manuals_job_titles: manuals_job_titles.map((j) =>
          j.id === id
            ? {
              ...j,
              job_title_ids: j.job_title_ids.filter(
                (id) => id !== job_title.id,
              ),
            }
            : j,
        ),
      });
    } else {
      this.props.onChange({
        job_title: {
          ...job_title,
          manuals_job_title_ids: [...job_title.manuals_job_title_ids, id],
        },
        manuals_job_titles: manuals_job_titles.map((j) =>
          j.id === id
            ? { ...j, job_title_ids: [...j.job_title_ids, job_title.id] }
            : j,
        ),
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const { job_title } = this.props;
    const url = `${location.pathname}/${job_title.id}`;
    const options = {
      body: JSON.stringify({
        authenticity_token: this.props.csrf_token,
        job_title,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    };

    fetch(url, options)
      .then((r) => r.json())
      .then(this.onSucces);
  }

  onSucces(data) {
    if (data.status === 'OK') {
      $(`#edit-job-title-${this.props.index}`).collapse('hide');
      this.setState({ ...this.state, errors: [] });
    } else {
      this.setState({
        ...this.state,
        errors: data.validation_errors,
      });
    }
  }

  onDestroyClick(event) {
    const { job_title } = this.props;

    if (!confirm(I18n.t('components.job_title_form.confirm_destroy'))) {
      return;
    }

    if (job_title.id !== '') {
      const url = `${location.pathname}/${job_title.id}`;
      const options = {
        body: JSON.stringify({
          authenticity_token: this.props.csrf_token,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      };

      fetch(url, options);
    }

    $(this.container).fadeOut(() => {
      this.props.destroyJobTitle(this.props.job_title, this.props.index);
    });
  }

  renderManualsJobTitle(manuals_job_title) {
    return (
      <div key={manuals_job_title.id}>
        <div className="checkbox">
          <label>
            <input
              checked={this.props.job_title.manuals_job_title_ids.includes(
                manuals_job_title.id,
              )}
              onChange={this.onManualsJobTitleChanage}
              type="checkbox"
              value={manuals_job_title.id}
            />
            <i
              className="fal fa-exclamation-triangle"
              data-toggle="tooltip"
              title={I18n.t(
                'components.job_title_form.not_used_manuals_job_title',
              )}
              hidden={manuals_job_title.job_title_ids.length > 0}
            />{' '}
            {manuals_job_title.name}
          </label>
        </div>
      </div>
    );
  }

  renderError(attribute, i) {
    if (this.state.errors[attribute] === undefined) return;

    return this.state.errors[attribute].map((error, index) => (
      <div
        key={`${i}-${attribute}-error-${index}`}
        className="alert alert-danger"
      >
        {error}
      </div>
    ));
  }

  render() {
    const { index, job_title, manuals_job_titles } = this.props;

    return (
      <div className="card card-chain" ref={(el) => (this.container = el)}>
        <h5>
          <a
            href={`#edit-job-title-${index}`}
            data-toggle="collapse"
            className="collapsed"
            onClick={() =>
              this.setState({ ...this.state, isOpen: !this.state.isOpen })
            }
          >
            {job_title.name}
          </a>
        </h5>

        <div className="collapse" id={`edit-job-title-${index}`}>
          {this.state.isOpen && (
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor={`job_title_name_${index}`}>
                  {I18n.t('activerecord.attributes.job_title.name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`job_title_name_${index}`}
                  name="name"
                  onChange={this.onChange}
                  value={job_title.name}
                />
                {this.renderError('name')}
              </div>
              <div className="form-group">
                <label
                  data-toggle="popover"
                  title={I18n.t(
                    'activerecord.attributes.job_title.manuals_job_title_ids',
                  )}
                  data-content={I18n.t(
                    'components.job_title_form.manuals_job_title_ids.popover',
                  )}
                >
                  {I18n.t(
                    'activerecord.attributes.job_title.manuals_job_title_ids',
                  )}
                </label>
                <Grid>
                  {manuals_job_titles.map(this.renderManualsJobTitle)}
                </Grid>
              </div>
              <button className="btn btn-primary">
                {I18n.t('helpers.submit.update', {
                  model: I18n.t('activerecord.models.job_title'),
                })}
              </button>
              &nbsp;
              <button
                className="btn btn-danger"
                onClick={this.onDestroyClick}
                type="button"
              >
                {I18n.t('helpers.submit.destroy', {
                  model: I18n.t('activerecord.models.job_title'),
                })}
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default JobTitleForm;
