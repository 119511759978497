import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { request } from '../../actions';

import DocumentFavorite from '../text-block/document-favorite';
import DocumentForm from '../document-form';

const options = { scope: 'components.documents' };

const iconForDocType = (type, provider) => {
  switch (type) {
    case 'activestorage':
      return 'fa fa-download';
    case 'url':
      return 'fa fa-link';
    default:
      return provider === 'microsoft_graph'
        ? 'fab fa-microsoft'
        : 'fab fa-google-drive';
  }
};

const Document = (props) => {
  const [doc, setDoc] = useState(props.doc);
  const [errors, setErrors] = useState([]);

  const onChange = (payload) => {
    setDoc({ ...doc, ...payload });
  };

  const onSave = (saveDocument) => {
    if (saveDocument.document !== null) {
      setErrors([]);
      window.location = props.backUrl;
    } else {
      setErrors(saveDocument.errors);
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }, []);

  return (
    <div className="card">
      <DocumentForm
        {...props}
        doc={doc}
        errors={errors}
        index={doc.id}
        onChange={onChange}
        onSave={onSave}
        showRevistionDate={false}
      />
    </div>
  );
};

Document.propTypes = {
  backUrl: PropTypes.string.isRequired,
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  provider: PropTypes.string,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Document;
