import Turbolinks from 'turbolinks';

class AuditAnswers {
  constructor(form) {
    this.form = form;
    this.answersContainer = form.find('.answers-container');
    this.navigationContainer = form.find('nav');

    this.showQuestion(0);

    this.bindEvents();
  }

  bindEvents() {
    this.navigationContainer.find('a').on('click', this.navigationQuestionClickEvent.bind(this));
    this.answersContainer.find('[data-action=previous]').on('click', this.previousQuestionClickEvent.bind(this));
    this.answersContainer.find('[data-action=next]').on('click', this.nextQuestionClickEvent.bind(this));
  }

  navigationQuestionClickEvent(event) {
    event.preventDefault();
    this.showQuestion(+$(event.target).html() - 1);
  }

  previousQuestionClickEvent(event) {
    event.preventDefault();
    this.showQuestion(+$(event.target).data('index') - 1);
  }

  nextQuestionClickEvent(event) {
    event.preventDefault();
    this.showQuestion(+$(event.target).data('index') + 1);
  }

  showQuestion(index) {
    var answer = this.answersContainer.find('.card').hide()[index];
    $(answer).show();
    var navigation = this.navigationContainer.find('li').removeClass('active')[index];
    $(navigation).addClass('active');
  }
}

$(document).on('turbolinks:load', () => {
  var form = $('[data-element=audit_answers]');
  if (form.length > 0) {
    new AuditAnswers(form);
  }

  $('[data-role="changeType"]').on ('click', () => {
    Turbolinks.visit(window.location.pathname + '?type=summary');
  });
});
