import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import {
  DataColumn,
  DataColumnId,
  DataGrid,
  DataRow,
  SortButton,
} from '../data-grid';

const options = { scope: 'components.documents' };

const Table = ({ children, onClick, sortBy, sortDirection }) => (
  <DataGrid>
    <DataRow>
      <DataColumnId>
        <SortButton
          onClick={onClick}
          name="paragraph_number"
          sortDirection={
            sortBy === 'paragraph_number' ? sortDirection : undefined
          }
        >
          {I18n.t('paragraph_number', options)}
        </SortButton>
      </DataColumnId>
      <DataColumn size="2">
        <SortButton
          onClick={onClick}
          name="name"
          sortDirection={sortBy === 'name' ? sortDirection : undefined}
        >
          {I18n.t('name', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="updated_at"
          sortDirection={sortBy === 'updated_at' ? sortDirection : undefined}
        >
          {I18n.t('updated_at', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="staff"
          sortDirection={sortBy === 'staff' ? sortDirection : undefined}
        >
          {I18n.t('staff', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="classification"
          sortDirection={
            sortBy === 'classification' ? sortDirection : undefined
          }
        >
          {I18n.t('classification', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="revision_date"
          sortDirection={sortBy === 'revision_date' ? sortDirection : undefined}
        >
          {I18n.t('revision_date', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="retention_period"
          sortDirection={
            sortBy === 'retention_period' ? sortDirection : undefined
          }
        >
          {I18n.t('retention_period', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>
        <SortButton
          onClick={onClick}
          name="location"
          sortDirection={sortBy === 'location' ? sortDirection : undefined}
        >
          {I18n.t('location', options)}
        </SortButton>
      </DataColumn>
      <DataColumn>{I18n.t('actions', options)}</DataColumn>
    </DataRow>
    {children}
  </DataGrid>
);

Table.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

export default Table;
