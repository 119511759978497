import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import ClustorAnswerStore from 'stores/clustor-answer-store';

import AuditAnswer from './AuditAnswer';

class AuditQuesionContainer extends React.Component {
  constructor(props) {
    super(props);
    ClustorAnswerStore.setClustorAnswers(props.clustorAnswers);

    this.renderAuditAnswer = this.renderAuditAnswer.bind(this);
    this.renderAuditQuestion = this.renderAuditQuestion.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true
    });

    if (location.hash !== '') {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        scrollTo(0, anchor.getBoundingClientRect().top - 105);
      }
    }
  }

  get scope() { return { scope: 'components.audit_question_container' }; }

  renderAuditAnswer(auditAnswer) {
    return(
      <AuditAnswer
        key={auditAnswer.id}
        auditAnswer={auditAnswer}
      />
    );
  }

  renderAuditQuestion(auditQuestion) {
    return(
      <div key={`auditQuestion-${auditQuestion.id}`} className="card" id={`audit-question-${auditQuestion.id}`}>
        <p>
          {auditQuestion.manual_paragraph.number} <span className="wrap" dangerouslySetInnerHTML={{ __html: auditQuestion.question }} />
        </p>
        <div className="table-flex">
          <div className="head">
            <div className="flex-row">
              <div className="size-1">{I18n.t('id', this.scope)}</div>
              <div className="size-3">
                <span title={I18n.t('popover.header', this.scope)} data-content={I18n.t('popover.content', this.scope)} data-toggle="popover">
                  {I18n.t('answer', this.scope)}
                </span>
              </div>
              <div className="size-2">{I18n.t('staff_name', this.scope)}</div>
              <div className="size-1">{I18n.t('documents', this.scope)}</div>
              <div className="size-2">{I18n.t('clustor_answer', this.scope)}</div>
              <div className="size-2">{I18n.t('created_at', this.scope)}</div>
            </div>
          </div>

          <div className="body">
            {auditQuestion.auditAnswers.map(this.renderAuditAnswer)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return(
      <div>
        {this.props.auditQuestions.map(this.renderAuditQuestion)}
      </div>
    );
  }
}

export default AuditQuesionContainer;
