import React from 'react';

const AuditAnswerAttachment = ({ attachment, ...props }) => {
  const onDestroyClickEvent = () => props.onDestroy(attachment);

  if (attachment._destroy === 1) {
    return(
      <>
        <input type="hidden" name={`${props.formName}[attachments_attachments_attributes][id]`} value={attachment.id} />
        <input type="hidden" name={`${props.formName}[attachments_attachments_attributes][_destroy]`} value="true" />
      </>
    );
  }

  return(
    <div className="form-group">
      <button className="btn btn-link" onClick={onDestroyClickEvent} type="button"><i className="fa fa-trash" /></button>
      <a className="btn btn-link" href={attachment.url} download={attachment.filename}>
        {attachment.name}
      </a>
    </div>
  );
};

export default AuditAnswerAttachment;
