class ToggleShow {
  constructor(btn) {
    this.btn = $(btn);
    this.showElement = $(btn.dataset.show);
    this.hideElement = $(btn.dataset.hide);

    this.bindEvents();
  }

  bindEvents() {
    this.btn.on('click', this.onClickEvent.bind(this));
  }

  onClickEvent(event) {
    event.preventDefault();

    this.showElement.show();
    this.hideElement.hide();
  }
}


$(document).on('turbolinks:load', () => {
  $('[data-toggle="show"]').each((i, e) => {
    new ToggleShow(e);
  });
});

