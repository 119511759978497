import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import ButtonRole from '../ui/button-role';

import { Cell, HeaderCell, ScrollBox, LineClamp } from './elements';

const QuestionList = ({ progress, saAnswer, saAnswers, setSaAnswer }) => {
  const options = { scope: 'components.sa_questionnaire' };

  const onClick = async () => {
    await fetch(location.pathname.replace('/edit', ''), {
      method: 'PATCH',
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        sa_questionnaire: { status: 'done' },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    Turbolinks.visit('/');
  };

  useEffect(() => {
    if (saAnswer && saAnswer.id) {
      document.querySelector(`#saAnswer-${saAnswer.id}`).scrollIntoView();
    }
  }, [saAnswer]);

  const renderQuestion = (answer) => {
    const question = answer.saQuestion.questionFields.map(q => q.value).join('\n');

    return(
      <ButtonRole key={answer.id} id={`saAnswer-${answer.id}`} className="row" onClick={() => setSaAnswer(answer)}>
        <div className="col-xs-8">
          <Cell>
            <LineClamp data-title={question} data-toggle="tooltip">{question}</LineClamp>
          </Cell>
        </div>
        <div className="col-xs-4">{answer.answer !== null && <FontAwesomeIcon icon={faCheck} />}</div>
      </ButtonRole>
    );
  };

  return(
    <div className="card">
      <button className="btn btn-primary pull-right ml-3" disabled={progress < 100} onClick={onClick} type="button">
        {I18n.t('helpers.submit.submit')}
      </button>
      <div className="progress mb-3 mt-2">
        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
          {progress}%
        </div>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <HeaderCell>{I18n.t('question', options)}</HeaderCell>
        </div>
        <div className="col-xs-4">
          <HeaderCell>{I18n.t('answer', options)}</HeaderCell>
        </div>
      </div>
      <ScrollBox>
        {saAnswers.map(renderQuestion)}
      </ScrollBox>
    </div>
  );
};

QuestionList.propTypes = {
  progress: PropTypes.number.isRequired,
  saAnswer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  saAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      answer: PropTypes.string,
      saQuestion: PropTypes.shape({
        questionFields: PropTypes.arrayOf(
          PropTypes.shape({
            field: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            position: PropTypes.number.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  setSaAnswer: PropTypes.func.isRequired,
};

export default QuestionList;
