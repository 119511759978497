import Dispatcher from './dispatcher';

class TaskCommentDispatcher extends Dispatcher {
  handleCreateTaskCommentAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const taskCommentDispatcher = new TaskCommentDispatcher();
