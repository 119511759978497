import Highcharts from 'highcharts';

document.addEventListener('turbolinks:load', () => {
  if (window.auditCharts === undefined) return;

  for (let i = 0; i < window.auditCharts.length; i++) {
    const { container, config } = window.auditCharts[i];
    Highcharts.chart(container, config);
  }
  window.auditCharts = [];
});
