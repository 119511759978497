export const reducer = (state, action) => {
  switch(action.type) {
    case 'toggle': {
      const { name, value } = action.payload;
      let payload;

      if (state[name].includes(value)) {
        payload = state[name].filter(i => i !== value);
      } else {
        payload = [...state[name], value];
      }
      return { ...state, [name]: payload };
    }
    case 'update': return { ...state, ...action.payload };
    default: new Error('Unknown action type: ', action);
  }
};
