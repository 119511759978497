import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import Context from '@ckeditor/ckeditor5-core/src/context';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';

const Editor = (props) => {
  const [data, setData] = useState(props.content);

  const onChange = (event, editor) => {
    props.onChange({
      target: { name: props.name, value: editor.getData() },
    });
  };

  return (
    <CKEditorContext context={Context}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [
            Bold,
            Essentials,
            Image,
            Indent,
            Italic,
            Link,
            List,
            Paragraph,
            Underline,
          ],
          toolbar: {
            items: [
              '|',
              'bold',
              'italic',
              'underline',
              '|',
              'numberedList',
              'bulletedList',
              '|',
              'indent',
              'outdent',
              '|',
              'link',
              '|',
              'undo',
              'redo',
            ],
          },
        }}
        data={props.content}
        onChange={onChange}
      />
    </CKEditorContext>
  );
};
Editor.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Editor;
