import Sortable from 'sortablejs';

$(document).on('turbolinks:load', () => {
  const answerlist = document.getElementById('sa-answer-list');
  if (answerlist === null) return;

  Sortable.create(answerlist, {
    animation: 100,
    onUpdate: () => {
      const answers = answerlist.querySelectorAll('.item');

      for (let i = 0; i <  answers.length; i++) {
        answers[i].querySelector('[data-element="position"]').value = i;
      }
    },
  });
});
