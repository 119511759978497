import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';
import isHotkey from 'is-hotkey';

const isEnter = isHotkey('enter');

const ProcessingRegister = ({ privacy_officers, processing_register, manual_paragraphs }) => {
  const onClick = () => Turbolinks.visit(`/processing_registers/${processing_register.id}`);
  const onKeyDown = (event) => isEnter(event) && onClick();
  const options = { scope: 'processing_registers' };

  return (
    <div className="flex-row" data-href="#" onClick={onClick} role="button" tabIndex="0" onKeyDown={onKeyDown}>
      <div className="size-1">{processing_register.id}</div>
      <div className="size-2 truncate">{processing_register.name}</div>
      <div className="size-2 truncate">
        {processing_register.process_links.map((p) => (
          <div key={p.id} className="truncate">
            {manual_paragraphs.find((mp) => mp.id === p.manual_paragraph_id).name}
          </div>
        ))}
      </div>
      <div className="size-2 truncate">{processing_register.processing_aim}</div>
      <div className="size-2 truncate">
        {processing_register.lawfulness_of_processing &&
          I18n.t('lawfulness.' + processing_register.lawfulness_of_processing, options)}
      </div>
      <div className="size-2 truncate">
        {processing_register.responsible_for_processing
          ? I18n.t(
            `processing_registers.responsible_for_processing_opts.${processing_register.responsible_for_processing}`,
          )
          : '-'}
      </div>
      <div className="size-1 truncate">{I18n.t('compliant_opts.' + processing_register.compliant, options)}</div>
    </div>
  );
};

ProcessingRegister.propTypes = {
  manual_paragraphs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  processing_register: PropTypes.shape({
    id: PropTypes.number.isRequired,
    compliant: PropTypes.bool.isRequired,
    lawfulness_of_processing: PropTypes.string,
    processing_aim: PropTypes.string,
    processor: PropTypes.string,
    responsible_for_processing: PropTypes.string,
    data_option_links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    process_links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        manual_paragraph_id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default ProcessingRegister;
