class OperationalDeviationsForm {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents() {
    this.container.on('click', '[data-action="primary"]', this.onAddPrimaryClickEvent.bind(this));
    this.container.on('click', '[data-action="secondary"]', this.onAddSecondaryClickEvent.bind(this));
    this.container.on('click', '[data-action="tertiary"]', this.onAddTertiaryClickEvent.bind(this));

    this.container.on('click', '[data-action="primary-delete"]', this.onPrimaryDeleteClickEvent.bind(this));
    this.container.on('click', '[data-action="secondary-delete"]', this.onSecondaryDeleteClickEvent.bind(this));
    this.container.on('click', '[data-action="tertiary-delete"]', this.onTertiaryDeleteClickEvent.bind(this));

  }

  onAddPrimaryClickEvent(event) {
    event.preventDefault();

    const template = $('#primary_template').html().replace(/primary_association/g, Date.now());
    $(event.target).parents('.form-group').before(template);

    const form_group = $(event.target).parents('.form-group').prev();
    const secondary = form_group.find('.secondary-association')[0].innerHTML;
    form_group.find('.secondary-association')[0].remove();
    form_group.append($(`<script type="html/template"><div class="secondary-association">${secondary}</div></script>`));
  }

  onAddSecondaryClickEvent(event) {
    event.preventDefault();

    const template = $(event.target).parents('.primary-association').children('script').html().replace(/secondary_association/g, Date.now());
    $(event.target).parents('.row').before(template);

    const form_group = $(event.target).parents('.row').prev();
    const tertiary = form_group.find('.tertiary-association')[0].innerHTML;
    form_group.find('.tertiary-association')[0].remove();
    form_group.append($(`<script type="html/template"><div class="tertiary-association">${tertiary}</div></script>`));
  }

  onAddTertiaryClickEvent(event) {
    event.preventDefault();

    const template = $(event.target).parents('.secondary-association').children('script').html().replace(/tertiary_association/g, Date.now());
    $(event.target).parents('.row').before(template);
  }

  onPrimaryDeleteClickEvent(event) {
    event.preventDefault();
    $(event.target).parents('.form-group').first().find('[data-element="destroy"]').val(1);
    $(event.target).parents('.form-group').first().parent().hide();
  }

  onSecondaryDeleteClickEvent(event) {
    event.preventDefault();
    $(event.target).parents('.form-group').first().find('[data-element="destroy"]').val(1);
    $(event.target).parents('.form-group').first().parent().hide();
  }

  onTertiaryDeleteClickEvent(event) {
    event.preventDefault();
    $(event.target).parents('.form-group').first().find('[data-element="destroy"]').val(1);
    $(event.target).parents('.form-group').first().hide();
  }

}

$(document).on('turbolinks:load', () => {
  $('[data-container="operation"]').each((_, container) => {
    new OperationalDeviationsForm(container);
  });
});
