export const graphql = `
  businessUnits { id name }
  classifications
  staffs { id name }
  jobTitles { id name }
`;
export const textBlockQuery = `
  id
  name
  description
  status
  manualParagraph {
    name
    number
  }
  comments { id message createdAt staff { avatar name } }
  documents {
    businessUnitIds
    classification
    driveId
    externalId
    id
    isFavorite
    jobTitleIds
    location
    name
    retentionPeriod
    revisionDate
    staffId
    status
    type
    url
  }
  tables {
    id
    tableColumns {
      id name
      defaultAnswers { id name url }
    }
    tableRows {
      id
      order
      tableCells {
        id
        value
        defaultAnswerId
        tableColumnId
      }
    }
  }
  timelines {
    id description status createdAt
    staff { avatar name }
  }
  versions(last: 1) {
    nodes {
      name
      description
    }
  }
`;

export const updateTextBlockMutation = `
  mutation TextBlockMutation($input: UpdateTextBlockInput!) {
    updateTextBlock(input: $input) {
      errors { field messages }
    }
  }
`;

export const createCommentMutation = `
  mutation CreateCommentMutation($input: CreateCommentInput!) {
    createComment(input: $input) {
      comment { id message createdAt staff { avatar name } }
      errors { field messages }
    }
  }
`;
