import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

const DataleakDataOption = ({ onChange, dataleakDataOption, dataOptions, ...props }) => {
  const dataOption = dataOptions.find(d => d.id === dataleakDataOption.data_option_id);
  const formName = `deviation[dataleak_data_options_attributes][${dataleakDataOption.id || dataleakDataOption.uid}]`;
  const onDelete = () => props.onDelete(dataleakDataOption);

  const onDataOptionChange = ({ target: { value }}) => {
    onChange({ ...dataleakDataOption, data_option_id: parseInt(value) });
  };

  return (
    <div>
      <div className='d-flex form-group justify-content-between' >
        <select
          className='form-control'
          name={`${formName}[data_option_id]`}
          onChange={onDataOptionChange}
          value={dataOption ? dataOption.id : ''}
        >
          <option disabled={true} value=''>{I18n.t('processing_registers.select_type')}</option>
          {dataOptions.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
        </select>
        <input name={`${formName}[id]`} value={dataleakDataOption.id} type="hidden" />
        <button className="btn btn-link-danger" onClick={onDelete} type="button">
          <i className="far fa-times"></i>
        </button>
      </div>
    </div>
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.id && !props.uid) {
    return new Error(`One of 'id' or 'uid' is required by '${componentName}' component.`);
  }
};

DataleakDataOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dataleakDataOption: PropTypes.shape({
    id: requiredPropsCheck,
    uid: requiredPropsCheck,
    data_option_id: PropTypes.number,
  }).isRequired,
  dataOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DataleakDataOption;
