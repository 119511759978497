import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb'

import ReceiverLink from './ReceiverLink';

const ReceiverLinks = (props) =>  {
  const [receiverLinks, setReceiverLinks] = useState(props.receiverLinks.length ? props.receiverLinks : [{ uid: Date.now(), non_eu: '' }]);

  const onAdd = () => setReceiverLinks([...receiverLinks, { uid: Date.now(), non_eu: '' }]);

  const onChange = (receiverLink) => {
    const mapFn = (p) => (
      (p.id !== undefined && p.id === receiverLink.id) || (p.uid !== undefined && p.uid === receiverLink.uid) ? receiverLink : p
    );
    setReceiverLinks(receiverLinks.map(mapFn));
  };

  const onDelete = (receiverLink) => {
    if (receiverLink.uid) {
      setReceiverLinks(receiverLinks.filter(p => p.uid !== receiverLink.uid));
    } else {
      setReceiverLinks(receiverLinks.map(p => p.id === receiverLink.id ? { ...p, destroy: true } : p));
    }
  };

  const renderReceiverLink = (receiverLink) => {
    if (receiverLink.destroy) {
      const formName = `processing_register[receiver_links_attributes][${receiverLink.id}]`;

      return(
        <div key={receiverLink.id || receiverLink.uid}>
          <input name={`${formName}[id]`} value={receiverLink.id} type="hidden" />
          <input name={`${formName}[_destroy]`} value={receiverLink.destroy} type="hidden" />
        </div>
      );
    }

    return(
      <ReceiverLink
        key={receiverLink.id || receiverLink.uid}
        onChange={onChange}
        documents={props.documents}
        countries={props.countries}
        receiverLink={receiverLink}
        onDelete={onDelete}
      />
    );
  };

  return(
    <div>
      {receiverLinks.map(renderReceiverLink)}
      <div className='row' >
        <div className='col-sm-12' >
          <button className='btn btn-default mb-3' onClick={onAdd} type="button">
            <i className="far fa-plus"></i> {I18n.t('processing_registers.add_receiver')}
          </button>
        </div>
      </div>
    </div>
  );
};

ReceiverLinks.propTypes = {
  receiverLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default ReceiverLinks;
