import { request } from '../actions';

const mutation = `
  mutation DriveUploadMutation($input: CreateUploadSessionInput!) {
    createUploadSession(input: $input) {
      url 
    }
  }
`;

export const microsoftUpload = async ({
  file,
  driveId,
  parentId,
  ...events
}) => {
  const variables = {
    input: {
      name: file.name,
      driveId,
      parentId,
    },
  };
  const [response] = await request(mutation, variables);
  upload(file, response.createUploadSession, events);
};

const upload = (file, response, events) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', response.url, true);
  xhr.responseType = 'json';
  xhr.setRequestHeader(
    'Content-Range',
    'bytes 0-' + (file.size - 1) + '/' + file.size,
  );

  if (events.onProgress) {
    xhr.upload.addEventListener('progress', events.onProgress, false);
  }

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      events.onSuccess({ name: file.name, externalId: xhr.response.id });
    }
  };
  xhr.upload.addEventListener('error', events.onError);

  xhr.send(file.slice());
};
