import React from 'react';

import BusinessUnitModal from './BusinessUnitModal';

class OrganisationChartNode extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderBusinesUnit = this.renderBusinesUnit.bind(this);
    this.renderLines = this.renderLines.bind(this);

    this.state = {
      showModal: false,
    };
  }

  onClick(event) {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  renderBusinesUnit(business_unit) {
    return(
      <td key={business_unit.id} colSpan="2">
        <OrganisationChartNode
          business_unit={business_unit}
          business_units={this.props.business_units}
          onSubmit={this.props.onSubmit}
        />
      </td>
    );
  }

  renderLines(_, index, array) {
    let className = 'org-chart__line ';
    className += index % 2 == 0 ? 'org-chart__line--left' : 'org-chart__line--right';
    return(
      <td key={index} className={className} style={{ width: `${100 / array.length}%`}}></td>
    );
  }

  render() {
    const { business_unit } = this.props;
    const { showModal } = this.state;
    const business_units = this.props.business_units.filter(b => b.business_unit_id === business_unit.id && b._destroy !== true);
    const colSpan = business_units.length * 2;

    return(
      <React.Fragment>
        <table className="org-chart__table">
          <tbody>
            <tr>
              <td colSpan={colSpan}>
                <div className="org-chart__node" onClick={this.onClick}>
                  {[business_unit.number, business_unit.name].filter(x => x).join(' - ')}
                </div>
              </td>
            </tr>
            {
              business_units.length > 0 ?
                <React.Fragment>
                  <tr>
                    <td colSpan={colSpan}><div className="org-chart__line-down"></div></td>
                  </tr>
                  {
                    business_units.length > 1 ?
                      <tr>
                        {[...Array(business_units.length * 2)].map(this.renderLines)}
                      </tr>
                      : null
                  }
                  <tr>
                    {business_units.map(this.renderBusinesUnit)}
                  </tr>
                </React.Fragment>
                : null
            }
          </tbody>
        </table>
        {
          showModal && (
            <BusinessUnitModal
              business_unit={business_unit}
              business_units={this.props.business_units.filter(b => b.id !== business_unit.id)}
              closeModal={this.closeModal}
              onSubmit={this.props.onSubmit}
            />
          )
        }
      </React.Fragment>
    );
  }
}

export default OrganisationChartNode;
