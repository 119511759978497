class RefereneTables {
  constructor(container, header) {
    this.container = container;
    this.header = header;


    this.header.style.width = `${container.offsetWidth}px`;
    this.bindEvents();
  }

  bindEvents() {
    document.addEventListener('scroll', this.toggleHeaderEvent.bind(this));
  }

  toggleHeaderEvent(event) {
    if (this.header.className.includes('fixed') && window.scrollY < 247) {
      this.header.className = 'head';
    } else if (!this.header.className.includes('fixed') && window.scrollY > 247) {
      this.header.className = 'head fixed';
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  const container = document.querySelector('[data-fixed="container"]');
  const header = document.querySelector('[data-fixed="header"]');
  
  if (container && header) {
    new RefereneTables(container, header);
  }
});
