function initialAutoHeigh() {
  $('[data-height="auto"]').each((_, container) => {
    const offset = 125;
    const height = $(window).height() - offset;

    if (!$(container).hasClass('auto-height')) {
      if (height < container.scrollHeight) {
        $(container).addClass('auto-height');
      }
    } else if (height >= container.scollHeight) {
      $(container).removeClass('auto-height');
    }

    if ($(container).hasClass('auto-height')) {
      $(container).height(height);
    }
  });
}

window.initialAutoHeigh = initialAutoHeigh;

$(document).on('turbolinks:load', initialAutoHeigh);
$(window).on('resize', initialAutoHeigh);
