import Dispatcher from './dispatcher';

class DataOptionDispatcher extends Dispatcher {
  handleCreateDataOptionAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }

  handleDeleteDataOptionAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const dataOptionDispatcher = new DataOptionDispatcher();
