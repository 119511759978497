import AuditConstants from 'constants/audit-constants';
import { auditDispatcher } from 'dispatcher/audit-dispatcher';

let _audits = [];
let _listeners = [];

class AuditStore {
  static getAll() {
    return _audits;
  }

  static setAudits(audits) {
    _audits = [...audits];
  }

  static destroyAudits(audits) {
    const ids = audits.map(i => i.id);
    _audits = _audits.filter(i => !ids.includes(i.id));
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default AuditStore;

auditDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case AuditConstants.AUDIT_CREATE:
      _audits = [].concat(_audits, action.audits);
      AuditStore.emitChange();
      break;
    case AuditConstants.AUDIT_UPDATE:
      _audits = _audits.map((i) => i.id === action.audit.id ? action.audit : i);
      AuditStore.emitChange();
      break;
    case AuditConstants.AUDIT_DESTROY:
      AuditStore.destroyAudits(action.audits);
      AuditStore.emitChange();
  }
});
