import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

class AuditDeviationForm {
  constructor(modal) {
    this.model = modal;
    this.form = modal.querySelector('form');

    this.onSubmitEvent = this.onSubmitEvent.bind(this);
    this.onSuccessCallback = this.onSuccessCallback.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.onSubmitEvent);
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      type: 'POST',
      url: '/deviations',
      data: $(this.form).serialize(),
      dataType: 'json',
      success: this.onSuccessCallback
    });
  }

  onSuccessCallback(data) {
    Rails.enableElement(this.form);

    if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
      setTimeout(function() {
        $('.error').fadeOut('slow');
      }, 3000);
    } else if (typeof(data.success) !== 'undefined') {
      window.location.reload();
    }
  }
}

$(document).on('turbolinks:load', () => {
  const modal = document.querySelector('#new-audit-deviation-modal');
  if (modal) { new AuditDeviationForm(modal); }
});
