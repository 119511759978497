import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../../i18n-js/index.js.erb';

import { FormGroup } from '../../form';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'error':
      return { ...state, hasError: true };
    case 'onChange':
      return { ...state, name: payload };
    case 'reset':
      return { name: '', hasError: false, showForm: false };
    case 'toggleShowForm':
      return { ...state, showForm: !state.showForm };
  }
};

const CreateDirectory = ({ disabled, ...props }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    hasError: false,
    name: '',
    showForm: false,
  });

  const toggleShowForm = () => dispatch({ type: 'toggleShowForm' });

  if (state.showForm) {
    const onChange = ({ target: { value } }) => dispatch({ type: 'onChange', payload: value });

    const onSubmit = (event) => {
      event.preventDefault();

      if (state.name.length) {
        props.onSubmit(state.name);
        dispatch({ type: 'reset' });
      } else {
        dispatch({ type: 'error' });
      }
    };

    return (
      <form onSubmit={onSubmit}>
        <FormGroup id="folder_name" label="Name" onChange={onChange} value={state.name} required />
        <button className="btn btn-default mr-3" onClick={toggleShowForm} type="button">
          Cancel
        </button>
        <button className="btn btn-primary">Submit</button>
        <hr />
      </form>
    );
  } else {
    return (
      <button className="d-block btn btn-default mb-2" disabled={disabled} onClick={toggleShowForm} type="button">
        + {I18n.t('components.create_directory.create')}
      </button>
    );
  }
};

CreateDirectory.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateDirectory;
