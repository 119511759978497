import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import Filter from './Filter';

class Filtering extends React.Component {
  constructor(props) {
    super(props);

    this.onResetChange = this.onResetChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    this.state = {
      items: this.sortItems(props.items.map(item => (
        { ...item, active: props.initialState.includes(item.id) }
      ))),
    };
  }

  sortItems(items) {
    if (items.length === 0) return items;

    if (items[0].position !== undefined) {
      return items.sortByKey('position').sortByKey('active', true);
    } else {
      return items.sortByKey('name').sortByKey('active', true);
    }
  }

  onResetChange() {
    this.setState(p => (
      { items: this.sortItems(p.items.map(i => ({ ...i, active: false }))) }
    ), this.callOnChange);
  }

  onFilterChange(id) {
    this.setState(p => {
      let items = this.sortItems(p.items.map(i => i.id === id ? {...i, active: !i.active } : i));
      return { items };
    }, this.callOnChange);
  }

  callOnChange() {
    this.props.onChange(
      this.props.name,
      this.state.items.filter(i => i.active).map(i => i.id),
    );
  }

  render() {
    const { items } = this.state;
    if (items.length === 0) return null;
    const activeItems = items.filter(i => i.active);
    const options = { scope: 'components.filtering' };

    return(
      <Filter
        items={items}
        name={this.props.name}
        onReset={this.onResetChange}
        onChange={this.onFilterChange}
      >
        {
          activeItems.length ?
            activeItems.map(s => s.name).join(', ')
            :
            I18n.t(this.props.name, options)
        } <span className="caret"></span>
      </Filter>
    );
  }
}

export default Filtering;
