class DataSubjectConstants {
  static get SUBJECT_CREATE() {
    return 'subject-create';
  }

  static get SUBJECT_DELETE() {
    return 'subject-delete';
  }
}

export default DataSubjectConstants;
