import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import JobTitleForm from './JobTitleForm';

class JobTitlesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onAddClick = this.onAddClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDestroy = this.onDestroy.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderJobTitle = this.renderJobTitle.bind(this);

    this.state = {
      errors: [],
      job_titles: props.job_titles,
      name: '',
      manuals_job_titles: props.manuals_job_titles,
      showForm: false,
    };
  }

  onAddClick() {
    this.setState({
      showForm: true,
    });
  }

  onChange({ job_title, manuals_job_titles }) {
    this.setState((p) => ({
      job_titles: p.job_titles.map((j) => (j.id === job_title.id ? job_title : j)),
      manuals_job_titles,
    }));
  }

  onDestroy(job_title) {
    this.setState((p) => ({
      job_titles: p.job_titles.filter((j) => j !== job_title.id),
    }));
  }

  onFormChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  onSubmit(event) {
    event.preventDefault();

    const options = {
      body: JSON.stringify({
        authenticity_token: this.props.csrf_token,
        job_title: { name: this.state.name },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };

    fetch(location.pathname, options)
      .then((r) => r.json())
      .then((response) => {
        const { status, job_title, validation_errors } = response;

        if (status === 'OK') {
          this.setState({
            errors: [],
            job_titles: [job_title, ...this.state.job_titles],
            showForm: false,
          });
        } else {
          this.setState({
            errors: validation_errors,
          });
        }
      });
  }

  renderJobTitle(job_title, index) {
    return (
      <JobTitleForm
        key={`${index}-${job_title.id}`}
        csrf_token={this.props.csrf_token}
        destroyJobTitle={this.onDestroy}
        index={index}
        job_title={job_title}
        manuals_job_titles={this.state.manuals_job_titles}
        onChange={this.onChange}
      />
    );
  }

  renderError(attribute) {
    if (this.state.errors[attribute] === undefined) return;

    return this.state.errors[attribute].map((error, index) => (
      <div key={index} className="alert alert-danger">
        {error}
      </div>
    ));
  }

  render() {
    const { job_titles, name, showForm } = this.state;

    return (
      <div>
        <div className="card">
          {showForm ? (
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="name">{I18n.t('components.job_titles.name')}</label>
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={this.onFormChange}
                  value={name}
                  type="text"
                />
                {this.renderError('name')}
              </div>
              <button className="btn btn-primary mr-3">
                {I18n.t('helpers.submit.create', { model: I18n.t('activerecord.models.job_title') })}
              </button>
              <button className="btn btn-default" onClick={() => this.setState({ showForm: false })} type="button">
                {I18n.t('helpers.submit.cancel')}
              </button>
            </form>
          ) : (
            <button className="btn btn-default" onClick={this.onAddClick}>
              + {I18n.t('components.job_titles.add_job_title')}
            </button>
          )}
        </div>
        {job_titles.map(this.renderJobTitle)}
      </div>
    );
  }
}

export default JobTitlesContainer;
