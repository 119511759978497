import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ alt, height, maxWidth, src, width, ...props }) => {
  let variant1x = '';
  let variant2x = '';
  let srcSet;

  if (maxWidth > 0 && width > maxWidth) {
    height = height / (width / maxWidth);
    width = maxWidth;

    variant1x = `?variant_key%5Bresize_to_limit%5D%5B%5D=${width}`;
    variant1x += `&variant_key%5Bresize_to_limit%5D%5B%5D=${height}`;
    variant2x = `?variant_key%5Bresize_to_limit%5D%5B%5D=${width * 2}`;
    variant2x += `&variant_key%5Bresize_to_limit%5D%5B%5D=${height * 2}`;
    srcSet = `${src}${variant1x} 1x, ${src}${variant2x} 2x`;
  }

  return(
    <img alt={alt} height={height} src={src} srcSet={srcSet} width={width} style={{ objectFit: 'contain' }} {...props} />
  );
};

Image.defaultProps = {
  maxWidth: 0,
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  maxWidth: PropTypes.number,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Image;
