class QuestionFields {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents(){
    this.container.on('click', '[data-action="add"]', this.onAddQuestionFieldClickEvent.bind(this));
    this.container.on('click', '[data-action="delete"]', this.onDeleteQuestionFieldClickEvent.bind(this));
  }

  onAddQuestionFieldClickEvent(event) {
    event.preventDefault();

    const template = $('#question_field_template').html().replace(/new_association/g, Date.now());
    $(event.target).parents('.form-group').before(template);
  }

  onDeleteQuestionFieldClickEvent(event) {
    event.preventDefault();
    const el = $(event.target).parents('.form-group').parent();

    if (el.prev().data('element') === 'destroy') {
      el.prev().val(1);
    }
    el.remove();
  }
}

$(document).on('turbolinks:load', () => {
  $('[data-container="question_fields"]').each((_, container) => {
    new QuestionFields(container);
  });
});
