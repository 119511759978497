import React from 'react';
import Turbolinks from 'turbolinks';

class FlexGrid extends React.Component {
  constructor(props) {
    super(props);

    this.changeSortByClickEvent = this.changeSortByClickEvent.bind(this);
    this.renderColumn = this.renderColumn.bind(this);
    this.renderRow = this.renderRow.bind(this);

    this.state = {
      sortBy: props.sortBy || props.columns[0].sortBy || props.columns[0].accessor,
      asc: props.asc ? true : false
    };
  }

  changeSortByClickEvent(event) {
    event.preventDefault();

    const column = this.props.columns.filter((c) => { return c.header === event.target.innerText; })[0];
    const asc = this.state.asc ? this.state.sortBy !== (column.sortBy || column.accessor) : !this.state.asc;

    this.setState({
      sortBy: column.sortBy || column.accessor,
      asc: asc
    });
  }

  sortRecords() {
    if (this.state.sortBy) {
      const nullRecords = this.props.records.filter((record) => {
        return record[this.state.sortBy] === null;
      });
      let records = this.props.records.filter((record) => {
        return record[this.state.sortBy] !== null;
      });
      records = records.sortByKey(this.state.sortBy, !this.state.asc);

      if (this.state.asc) {
        return records.concat(nullRecords);
      } else {
        return nullRecords.concat(records);
      }
    } else {
      return this.props.records;
    }
  }

  renderColumn(column, index) {
    const { sortBy, asc } = this.state;
    let className = '';

    if (sortBy === column.accessor || sortBy === column.sortBy) {
      className = asc ? 'sort asc' : 'sort desc';
    }

    return(
      <div key={index} className={`size-${column.size || 2}`}>
        <a onClick={this.changeSortByClickEvent} className={className}>
          {column.header}
        </a>
      </div>
    );
  }

  renderRow(record, index) {
    const { columns } = this.props;
    return(
      <FlexGridRow
        key={record.id || index}
        columns={columns}
        record={record}
      />
    );
  }

  render() {
    return(
      <div className="table-flex">
        <div className="head">
          <div className="flex-row">
            {this.props.columns.map(this.renderColumn)}
          </div>
        </div>
        <div className="body">
          {this.sortRecords().map(this.renderRow)}
        </div>
      </div>
    );
  }
}

export default FlexGrid;

class FlexGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.renderCell = this.renderCell.bind(this);
  }

  onRowClickEvent(event) {
    Turbolinks.visit(event.target.parentElement.dataset.href);
  }

  renderCell(column, index) {
    const { record } = this.props;
    const value = typeof(column.accessor) === 'function' ? column.accessor(record) : record[column.accessor];
    return(
      <div title={value} key={index} className={`size-${column.size || 2} truncate`}>
        {value}
      </div>
    );
  }

  render() {
    const { columns, record } = this.props;
    return(
      <div data-href={record.url} onClick={this.onRowClickEvent} className="flex-row">
        {columns.map(this.renderCell)}
      </div>
    );
  }
}
