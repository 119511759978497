import React from 'react';
import * as roles from '../constants/roles';

const reducer = (state, { payload, type }) => {
  let newState;

  switch(type) {
    case 'response': {
      let filter = JSON.parse(sessionStorage.getItem(`manual_filters_${payload.currentMember.id}`));

      if (filter === null) {
        if (
          [roles.AUTHOR, roles.WRITER, roles.SUBSCRIBER].includes(payload.currentMember.role) &&
          payload.currentMember.accessibleBusinessUnitIds.length
        ) {
          let businessUnitIds;

          if (payload.defaultNone) {
            businessUnitIds = payload.currentMember.accessibleBusinessUnitIds;
          } else {
            businessUnitIds = [0, ...payload.currentMember.accessibleBusinessUnitIds];
          }

          return {
            ...state,
            ...payload,
            businessUnitIds,
            loading: false,
          };
        } else {
          return { ...state, ...payload, loading: false };
        }
      } else {
        return { ...state, ...payload, ...filter, loading: false };
      }
    }
    case 'toggleNone': {
      if (payload.checked) {
        newState = { ...state, businessUnitIds: [...state.businessUnitIds, 0] };
      } else {
        newState = { ...state, businessUnitIds: state.businessUnitIds.filter(id => id !== 0) };
      }
      storeFilters(state.currentMember.id, { businessUnitIds: newState.businessUnitIds });
      return newState;
    }
    case 'toggleBusinessUnit': {
      let businessUnitIds;

      if (state.businessUnitIds.includes(payload.businessUnit.id)) {
        businessUnitIds = state.businessUnitIds.filter(id => id !== payload.businessUnit.id);
      } else {
        businessUnitIds = [...state.businessUnitIds, payload.businessUnit.id];
      }
      storeFilters(state.currentMember.id, { businessUnitIds });
      return {
        ...state,
        businessUnitIds,
      };
    }
    case 'clearFilter': {
      sessionStorage.removeItem(`manual_filters_${state.currentMember.id}`);
      return { ...state, businessUnitIds: [] }
    }
  }
};

function storeFilters(memberId, filters) {
  sessionStorage.setItem(`manual_filters_${memberId}`, JSON.stringify(filters));
}

const useManual = (initialState) => {
  return React.useReducer(reducer, {
    businessUnitIds: [],
    businessUnits: [],
    currentMember: {},
    loading: true,
    ...initialState,
  });
};

export default useManual;
