import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

const DeviationTypeFilter = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    deviation_categories: props.deviation_categories.map((d) => ({
      ...d,
      active: props.initialState.deviation_category_ids.includes(d.id),
    })),
    deviation_types: props.deviation_types.map((d) => ({
      ...d,
      active: props.initialState.deviation_types.includes(d.id),
    })),
  });
  const container = useRef();

  const options = { scope: 'components.deviation_type_filter' };

  const onDocumentClick = ({ target }) => {
    if (container.current !== null && !container.current.contains(target) && container.current !== target) {
      setIsOpen(false);
    }
  };

  const onReset = () => {
    setState((p) => ({
      deviation_categories: p.deviation_categories.map((d) => ({ ...d, active: false })),
      deviation_types: p.deviation_types.map((d) => ({ ...d, active: false })),
    }));
    props.onReset({ deviation_category_ids: [], deviation_types: [] });
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  const renderItem = (deviation_type) => {
    const onClick = () => {
      const deviation_types = state.deviation_types.map((d) =>
        d.id === deviation_type.id ? { ...d, active: !deviation_type.active } : d,
      );

      setState({ ...state, deviation_types });
      props.onChange(
        'deviation_types',
        deviation_types.filter((d) => d.active).map((d) => d.id),
      );
    };

    return (
      <li key={deviation_type.id} className="filtering__menu__item">
        <button className="btn btn-link" onClick={() => onClick(deviation_type.id)} type="button">
          <span className="check">{deviation_type.active && <i className="fa fa-check"></i>}</span>
          <span className="name">{deviation_type.name}</span>
        </button>
      </li>
    );
  };

  const renderDeviationCategory = (deviation_category) => {
    const onClick = () => {
      const deviation_categories = state.deviation_categories.map((d) =>
        d.id === deviation_category.id ? { ...d, active: !deviation_category.active } : d,
      );
      setState({ ...state, deviation_categories });
      props.onChange(
        'deviation_category_ids',
        deviation_categories.filter((d) => d.active).map((d) => d.id),
      );
    };

    return (
      <li key={deviation_category.id} className="filtering__menu__item">
        <button className="btn btn-link" onClick={() => onClick(deviation_category.id)} type="button">
          <span className="check">{deviation_category.active && <i className="fa fa-check"></i>}</span>
          <span className="name">{deviation_category.name}</span>
        </button>
      </li>
    );
  };

  const activeDeviationTypes = state.deviation_types.filter((d) => d.active);
  const types = activeDeviationTypes.map((d) => d.id);
  const deviation_categories = state.deviation_categories.filter((d) => types.includes(d.deviation_type));

  const activeItems = [...activeDeviationTypes, ...state.deviation_categories.filter((d) => d.active)];

  return (
    <li className="filtering right" ref={container}>
      <button className="btn btn-link" onClick={() => setIsOpen(!isOpen)} type="button">
        {activeItems.length ? activeItems.map((s) => s.name).join(', ') : I18n.t('deviation_type', options)}{' '}
        <span className="caret"></span>
      </button>
      {isOpen && (
        <ul className="filtering__menu">
          <li className="filtering__menu__item">
            <button className="btn btn-link" onClick={onReset} type="button">
              <span className="check"></span>
              <span className="name">{I18n.t('deviation_category_reset', options)}</span>
            </button>
          </li>
          <li className="divider"></li>
          <div className="items">{state.deviation_types.map(renderItem)}</div>

          {deviation_categories.length > 0 && (
            <>
              <li className="divider"></li>
              <div className="items">{deviation_categories.map(renderDeviationCategory)}</div>
            </>
          )}
        </ul>
      )}
    </li>
  );
};

DeviationTypeFilter.propTypes = {
  deviation_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  deviation_types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  initialState: PropTypes.shape({
    deviation_category_ids: PropTypes.array.isRequired,
    deviation_types: PropTypes.array.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default DeviationTypeFilter;
