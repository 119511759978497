import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px dashed;
  ${({ dragging }) => dragging && 'background-color: #eee;'}
  padding: 2rem;
  text-align: center;
`;

const FileInput = styled.input.attrs(() => ({ type: 'file' }))`
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
`;

const Label = styled.label`
  cursor: pointer;
  white-space: pre-wrap;
`;

const FileUpload = ({ children, ...props }) => {
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);
    props.onChange(event.dataTransfer.files[0]);
  };

  const onChange = (event) => {
    props.onChange(event.target.files[0]);
  };

  return (
    <Container
      dragging={dragging}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <FileInput {...props} onChange={onChange} />
      <Label htmlFor={props.id}>{children}</Label>
    </Container>
  );
};

FileUpload.defaultProps = {
  id: 'file',
};

FileUpload.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FileUpload;
