class StaffGetter extends Getter {
  constructor(selectElement, triggerElement) {
    super();

    this.selectElement = selectElement;
    this.triggerElement = triggerElement;
    this.onChange = this.onChange.bind(this);

    this.triggerElement.addEventListener('change', this.onChange);
  }

  onChange(event) {
    this.fetchStaff(event.target.value);
  }

  fetchStaff(value) {
    this.getData({
      url: `/staffs/${this.selectElement.dataset.type}`,
      data: { business_unit_id: value },
      success: data => { 
        this.setOptionFields(this.selectElement, data);
      }
    });
  }
}

window.loadStaffGetter = () => {
  const selectElement = document.querySelector('[data-getter="staff"]');
  const triggerElement = document.querySelector('[data-trigger="staff"]');

  if (selectElement && triggerElement) {
    new StaffGetter(selectElement, triggerElement);
  }
};
document.addEventListener('turbolinks:load', window.loadStaffGetter);
