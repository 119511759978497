import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import TaskCommentStore from 'stores/task-comment-store';
import TaskCommentConstants from 'constants/task-comment-constants';
import { taskCommentDispatcher } from 'dispatcher/task-comment-dispatcher';

import { validationErrors } from '../src/utils';
import DefaultAvatar from '../images/default-avatar.png';

class TaskComments extends React.Component {
  constructor(props) {
    super(props);

    this.action = '/tasks/' + props.task_id + '/task_comments';

    this.bindChangeEvent = this.onChange.bind(this);
    this.state = {
      task_comments: props.task_comments
    };
  }

  componentDidMount() {
    TaskCommentStore.setTaskComments(this.props.task_comments);
    TaskCommentStore.addChangeListener(this.bindChangeEvent);
  }

  componentWillUnmount() {
    TaskCommentStore.removeChangeListener(this.bindChangeEvent);
  }

  onChange() {
    this.setState({task_comments: TaskCommentStore.getAll()});
  }

  handleSubmit(event) {
    event.preventDefault();
    let form = event.target;

    $.ajax({
      url: form.action,
      method: form.method,
      data: $(form).serialize(),
      dataType: 'json',
      success: (data) => {
        if (data.success === 'OK') {
          form.reset();
          taskCommentDispatcher.handleCreateTaskCommentAction({
            actionType: TaskCommentConstants.TASK_COMMENT_CREATE,
            taskComment: data.task_comment
          });
        } else {
          validationErrors($(form), data.errors);
        }
      }
    });
  }

  render() {
    return(
      <div className="card">
        <div className="card-body">
          <h3>{I18n.t('tasks.task_comments.header')}</h3>
          <hr/>
          <ul className="timeline">
            {this.state.task_comments.map((c, i) => <Comment comment={c} key={i} />)}
          </ul>
          <hr/>
          {this.props.allow_create && (
            <form action={this.action} method="post" onSubmit={this.handleSubmit} id="form_task_comment">
              <div className="row">
                <div className="col-sm-9 col-lg-19">
                  <div className="form-group">
                    <textarea id="task_comment_message" name="task_comment[message]" className="form-control" placeholder={I18n.t('activerecord.placeholders.task_comment.message')} />
                  </div>
                  <div className="col-sm-3 col-lg-2">
                    <div className="form-group">
                      <input type="submit" className="btn btn-default btn-block" value={I18n.t('helpers.submit.task_comment.create')} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

const Comment = ({ comment }) => (
  <li>
    <span className="when">{comment.created_at}</span>
    <div className="mini-profile">
      <img alt={comment.staff_name} className="avatar" src={comment.avatar || DefaultAvatar} />
      <div className="profile-name">{comment.staff_name}</div>
    </div>
    <p className="wrap">{comment.message}</p>
  </li>
);

export default TaskComments;
