import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';
import moment from 'moment';

class DocumentVersionModal extends React.Component {
  constructor(props) {
    super(props);

    this.loadDocumentVersions = this.loadDocumentVersions.bind(this);
    this.renderDocumentVersion = this.renderDocumentVersion.bind(this);

    this.state = {
      documentVersions: [],
    };
  }

  componentDidMount() {
    $('.modal-dialog').draggable();
    $(`#modal-archive-${this.props.document.id}`).on('show.bs.modal', this.loadDocumentVersions);
  }

  componentWillUnmount() {
    $(`#modal-archive-${this.props.document.id}`).off('show.bs.modal', this.loadDocumentVersions);
  }

  loadDocumentVersions() {
    if (this.state.documentVersions.length) return;

    const url = `/documents/${this.props.document.id}/versions`;
    const options = {
      headers: {
        Accept: 'application/json',
      },
      method: 'GET',
      credentials: 'same-origin',
    };
    fetch(url, options)
      .then((r) => r.json())
      .then((data) => {
        this.setState({
          documentVersions: data.documentVersion,
        });
      });
  }

  renderDocumentVersion(documentVersion) {
    return (
      <tr key={`version-${documentVersion.created_at}`}>
        <td>{documentVersion.staff_name}</td>
        <td>{documentVersion.whodunnit}</td>
        <td>{moment(documentVersion.created_at).format('L')}</td>
        <td>
          <a download={documentVersion.name} href={documentVersion.file_url} rel="noreferrer" target="_blank">
            {documentVersion.name}
          </a>
        </td>
      </tr>
    );
  }

  render() {
    const { document } = this.props;
    const { documentVersions } = this.state;
    const options = { scope: 'components.document_version_modal' };

    return (
      <React.Fragment>
        <button
          className="btn btn-link px-1"
          disabled={document.type !== 'activestorage'}
          aria-haspopup="true"
          aria-expanded="false"
          data-toggle="modal"
          data-target={`#modal-archive-${document.id}`}
          type="button"
        >
          <span
            data-toggle="tooltip"
            title={I18n.t(document.type === 'activestorage' ? 'archive' : 'no_archive_available', options)}
          >
            <i className="far fa-archive"></i>
          </span>
        </button>
        <div className="modal fade" id={`modal-archive-${document.id}`} role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">{I18n.t('header', options)}</h4>
              </div>
              <div className="modal-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{I18n.t('owner', options)}</th>
                      <th>{I18n.t('whodunnit', options)}</th>
                      <th>{I18n.t('updated_at', options)}</th>
                      <th>{I18n.t('file', options)}</th>
                    </tr>
                  </thead>
                  <tbody>{documentVersions.map(this.renderDocumentVersion)}</tbody>
                </table>
                <div className="clearfix"></div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">
                  {I18n.t('close', options)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DocumentVersionModal.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentVersionModal;
