import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';

class RegisterBusinessUnits extends React.Component {
  constructor(props) {
    super(props);

    this.addRegisterBusinessUnit = this.addRegisterBusinessUnit.bind(this);
    this.removeRegisterBusinessUnit = this.removeRegisterBusinessUnit.bind(this);

    this.state = {
      register_business_units: props.register_business_units,
      business_units: props.business_units,
      business_unit_ids: props.register_business_units.map(r => r.id),
    };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true
    });
  }

  addRegisterBusinessUnit(_, businessUnitId) {
    this.setState(p => ({
      register_business_units: [ ...p.register_business_units, p.business_units.find(b => b.id === businessUnitId) ],
      business_unit_ids: [ ...p.business_unit_ids, parseInt(businessUnitId) ]
    }));
  }

  removeRegisterBusinessUnit(_, businessUnitId) {
    const register_business_units = this.state.register_business_units.filter(r => r.id !== businessUnitId);
    this.setState(p => ({
      register_business_units: register_business_units,
      business_unit_ids: register_business_units.map(r => r.id)
    }));
  }

  render() {
    const { register_business_units, business_units, business_unit_ids } = this.state;

    return (
      <div className="col-sm-6">
        <label>{I18n.t(`processing_registers.business_units`)}</label>
        <div className="form-group">
          <AutoComplete
            addValue={this.addRegisterBusinessUnit}
            removeValue={this.removeRegisterBusinessUnit}
            selectValues={business_units.filter(b => register_business_units.find(r => r.id === b.id))}
            values={business_units.filter(bu => register_business_units.find(c => c.id === bu.id) === undefined)}
          />
          {business_unit_ids.map(id => <input name="processing_register[business_unit_ids][]" value={id} type="hidden" key={id} />)}
        </div>
      </div>
    );
  }
}

export default RegisterBusinessUnits;
