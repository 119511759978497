import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropDownMenu = ({ caret, children, icon, label, right, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef();

  const onDocumentClick = ({ target }) => {
    if (container.current.contains(target) && container.current !== target) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  return(
    <li className="re-dropdown" ref={container}>
      <button className="btn btn-link px-1" {...props} onClick={() => setIsOpen(!isOpen)} type="button">
        {icon && <FontAwesomeIcon icon={icon} />} {label} {caret && <span className="caret"></span>}
      </button>
      <ul className={`re-dropdown-menu${right ? ' re-dropdown-menu-right' : ''}`} hidden={!isOpen}>
        {children}
      </ul>
    </li>
  );
};

DropDownMenu.defaultProps = {
  caret: true,
  right: false,
};

DropDownMenu.propTypes = {
  caret: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  icon: PropTypes.object,
  label: PropTypes.string,
  right: PropTypes.bool,
};

export default DropDownMenu;
