import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import moment from 'moment';

import ClustorAnswerConstants from 'constants/clustor-answer-constants';
import { clustorAnswerDispatcher } from 'dispatcher/clustor-answer-dispatcher';
import ClustorAnswerStore from 'stores/clustor-answer-store';
import { validationErrors } from '../src/utils';

class AuditAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.onAnswerDoubleClick = this.onAnswerDoubleClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClustorAnswerChange = this.onClustorAnswerChange.bind(this);
    this.onAuditAnswerSubmit = this.onAuditAnswerSubmit.bind(this);
    this.renderAttachment = this.renderAttachment.bind(this);

    this.state = {
      answer: props.auditAnswer.answer,
      clustorAnswerId: props.auditAnswer.clustorAnswerId || '',
      clusterAnswers: ClustorAnswerStore.getAll()
    };
  }

  get scope() { return { scope: 'components.audit_answer' }; }
  get clustorAnswerFormScope() { return { scope: 'activerecord.attributes.clustor_answer' }; }
  get auditAnswerFormScope() { return { scope: 'activerecord.attributes.audit_answer' }; }

  componentDidMount() {
    $('.modal-dialog').draggable();
    ClustorAnswerStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    ClustorAnswerStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      clusterAnswers: ClustorAnswerStore.getAll()
    });
  }

  onAnswerDoubleClick(event) {
    $(`#${this.props.auditAnswer.id}-modal-answer`).modal('show');
  }

  onAuditAnswerSubmit(event) {
    event.preventDefault();

    this.setState({
      answer: event.target.elements.audit_answer_answer.value
    }, this.updateAuditAnswer);

    $(`#${this.props.auditAnswer.id}-modal-answer`).modal('hide');
  }

  onClustorAnswerChange(event) {
    this.setState({clustorAnswerId: event.target.value}, this.updateAuditAnswer);
  }

  onSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    formData.append('authenticity_token', document.querySelector('[name="csrf-token"]').content);

    const options = {
      headers: { 'Accept': 'application/json' },
      method: 'POST',
      body: formData,
      credentials: 'same-origin'
    };

    fetch(form.action, options).then((r) => r.json()).then((data) => {
      if (data.status === 'OK') {
        form.reset();
        $(form).parents('.modal').modal('hide');

        this.setState({
          clusterAnswers: [].concat(this.state.clusterAnswers, [data.clustor_answer]),
          clustorAnswerId: data.clustor_answer.id
        }, this.updateAuditAnswer);

        clustorAnswerDispatcher.handleCreateClustorAnswerAction({
          actionType: ClustorAnswerConstants.CLUSTOR_ANSWER_CREATE,
          clustor_answer: data.clustor_answer
        });
      } else {
        validationErrors($(form), data.validation_errors);
      }
    });
  }

  updateAuditAnswer() {
    const formData = new FormData();
    formData.append('authenticity_token', document.querySelector('[name="csrf-token"]').content);
    formData.append('audit_answer[answer]', this.state.answer);
    formData.append('audit_answer[clustor_answer_id]', this.state.clustorAnswerId);

    const options = {
      headers: { 'Accept': 'application/json' },
      method: 'PATCH',
      body: formData,
      credentials: 'same-origin'
    };

    fetch(`/audits/${this.props.auditAnswer.auditId}/audit_answers/${this.props.auditAnswer.id}`, options);
  }

  renderAttachment(attachment) {
    return(
      <li key={attachment.id}>
        <a href={attachment.url} download={attachment.filename}>{attachment.name}</a>
      </li>
    );
  }

  render() {
    return(
      <div className="flex-row">
        <div className="size-1">{this.props.auditAnswer.id}</div>
        <div className="size-3">
          <span onDoubleClick={this.onAnswerDoubleClick}>{this.state.answer}</span>

          <div className="modal fade" id={`${this.props.auditAnswer.id}-modal-answer`} tabIndex="-1" style={{display: 'none'}}>
            <div className="modal-dialog">
              <div className="modal-content">
                <form className="form-horizontal" onSubmit={this.onAuditAnswerSubmit} id="edit_audit_answer">
                  <div className="modal-header">
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">x</span>
                    </button>
                    <h4 className="modal-title">{I18n.t('audit_answer.header', this.scope)}</h4>
                  </div>

                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="audit_answer_answer" className="control-label col-sm-3">
                        {I18n.t('answer', this.auditAnswerFormScope)}
                      </label>
                      <div className="col-sm-9">
                        <textarea className="form-control" id="audit_answer_answer" defaultValue={this.state.answer} name="audit_answer[answer]" />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">{I18n.t('helpers.submit.create', { model: 'audit_answer' })}</button>
                    <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="size-2">{this.props.auditAnswer.staffName}</div>
        <div className="size-1">
          {
            this.props.auditAnswer.attachments.length > 0 && (
              <div className="dropdown text-right">
                <button className="btn btn-default" data-toggle="dropdown" type="button"><span className="caret"></span></button>
                <ul className="dropdown-menu dropdown-menu-right">
                  {this.props.auditAnswer.attachments.map(this.renderAttachment)}
                </ul>
              </div>
            )
          }
        </div>
        <div className="size-2">
          <select value={this.state.clustorAnswerId} className="form-control" onChange={this.onClustorAnswerChange}>
            <option value=""></option>
            {this.state.clusterAnswers.map((clustorAnswer, index) => {
              return(<option key={`${this.props.auditAnswer.id}-option-${index}`} value={clustorAnswer.id}>{clustorAnswer.name}</option>);
            })}
          </select>

          <a href={`#${this.props.auditAnswer.id}-modal-answer-clustor`} data-toggle="modal">+ {I18n.t('add_clustor_answer', this.scope)}</a>

          <div className="modal fade" id={`${this.props.auditAnswer.id}-modal-answer-clustor`} tabIndex="-1" style={{display: 'none'}}>
            <div className="modal-dialog">
              <div className="modal-content">
                <form action={`/audits/${this.props.auditAnswer.auditId}/clustor_answers`} method="post" className="form-horizontal" onSubmit={this.onSubmit.bind(this)} id="new_clustor_answer">
                  <div className="modal-header">
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">x</span>
                    </button>
                    <h4 className="modal-title">{I18n.t('clustor_answer.header', this.scope)}</h4>
                  </div>

                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="clustor_answer_name" className="control-label col-sm-3">
                        {I18n.t('name', this.clustorAnswerFormScope)}
                      </label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" placeholder={I18n.t('name', this.clustorAnswerFormScope)} id="clustor_answer_name" name="clustor_answer[name]" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="clustor_answer_description" className="control-label col-sm-3">
                        {I18n.t('description', this.clustorAnswerFormScope)}
                      </label>
                      <div className="col-sm-9">
                        <textarea className="form-control" placeholder={I18n.t('description', this.clustorAnswerFormScope)} id="clustor_answer_description" name="clustor_answer[description]" />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">{I18n.t('helpers.submit.create', { model: 'clustor' })}</button>
                    <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="size-2">
          <span title={this.props.auditAnswer.createdAt}>{moment(this.props.auditAnswer.createdAt).format('D MMMM YYYY')}</span>
        </div>
      </div>
    );
  }
}

export default AuditAnswer;
