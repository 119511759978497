import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import DataOptionStore from './../stores/data-option-store';

import DataOption from './DataOption';
import Pagination from './Pagination';

class DataOptions extends React.Component {
  constructor(props) {
    super(props);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onPageClick = this.onPageClick.bind(this);

    this.state = {
      page: 1,
      sort: 'name',
      sortAsc: true,
      data_options: [],
      totalPages: 1
    };
  }

  componentDidMount() {
    this.fetchDataOptions();
    DataOptionStore.addChangeListener(this.onStoreChange);
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true
    });
  }

  componentWillUnmount() {
    DataOptionStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.fetchDataOptions();
  }

  fetchDataOptions() {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json'
      }
    };

    this.fetching(options).then(r => r.json()).then(data => {
      if (this.state.page <= data.query.totalPages) {
        let state = {
          data_options: data.query.data_options,
          totalPages: data.query.totalPages,
        };
        this.setState(state);
      } else {
        this.setState({page: data.query.totalPages}, this.fetchDataOptions)
      };
    });
  }

  fetching(options) {
    const url = new URL('processing_registers/data_options', `${location.protocol}//${location.host}`);

    url.searchParams.append('page', this.state.page);
    url.searchParams.append('sort', this.state.sort);
    url.searchParams.append('asc', this.state.sortAsc ? 'asc' : 'desc');

    return fetch(url, options);
  }

  onPageClick(page) {
    $.scrollTo($('[data-react-class="DataOptions"]'), 75);
    this.setState({ page }, this.fetchDataOptions);
  }

  onSortClick(event) {
    event.preventDefault();

    if (this.state.sort === event.target.dataset.sort) {
      this.setState(prevState => ({sortAsc: !prevState.sortAsc}), this.fetchDataOptions);
    } else {
      this.setState({ sort: event.target.dataset.sort, sortAsc: true }, this.fetchDataOptions);
    }
  }

  render() {
    const { data_options, totalPages, page, sort, sortAsc } = this.state;
    const options = { scope: 'components.data_options' };
    let backClick
    if ( this.props.deviationId ) {
      backClick = () => Turbolinks.visit(`/data_leak_deviations/${this.props.deviationId}`)
    } else {
      backClick = () => Turbolinks.visit('/processing_registers');
    }

    return(
      <React.Fragment>
        <p>
          { this.props.deviationId ?
            <button className='btn btn-link' onClick={backClick}>
              <i className="far fa-chevron-left"></i> {I18n.t('back_to_dataleak', options)}
            </button>
            :
            <button className='btn btn-link' onClick={backClick}>
              <i className="far fa-chevron-left"></i> {I18n.t('back_to_processing_registers', options)}
            </button>
          }
        </p>
        <div className="heading">
          <h1 className="heading-title">{I18n.t('page_title', options)}</h1>
          <ul className="list-links">
            {this.props.allow_create && (
              <button className="btn btn-link" data-toggle="modal" data-target="#newDataOptionModal" data-title={I18n.t('modal.new.title', options)} type="button">
                {I18n.t('new_option', options)}
              </button>
            )}
          </ul>
        </div>

        <div className="card">
          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-4">
                  <button className={`${sort === 'name' ? 'sort': ''} ${sortAsc ? 'asc' : 'desc'} btn btn-link`} data-sort="name" onClick={this.onSortClick}>
                    {I18n.t('name', options)}
                  </button>
                </div>
                <div className="size-2">
                  {I18n.t('in_use', options)}
                </div>
                <div className="size-1">
                  {I18n.t('actions', options)}
                </div>
              </div>
            </div>
            <div className="body">
              {data_options.map(d => <DataOption key={d.id} data_option={d} />)}
            </div>
          </div>

          <Pagination
            onPageClick={this.onPageClick}
            page={page}
            totalPages={totalPages}
          />
        </div>
      </React.Fragment>
    );
  }
}

DataOptions.propTypes = {
  allow_create: PropTypes.bool.isRequired,
  deviationId: PropTypes.number,
};



export default DataOptions;
