import Sortable from 'sortablejs';

$(document).on('turbolinks:load', () => {
  const questionFieldList = document.getElementById('sa-question-field-list');
  if (questionFieldList === null) return;

  Sortable.create(questionFieldList, {
    animation: 100,
    onUpdate: () => {
      const questionFields = document.querySelectorAll('.item');

      for (let i = 0; i < questionFields.length; i++) {
        questionFields[i].querySelector('[data-element="position"]').value = i;
      }
    }
  });
});
