import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import TableGrid from './TableGrid';

class Staffs extends React.Component {
  columns() {
    return [{
      header: I18n.t('activemodel.attributes.staff.name'),
      accessor: 'name',
    }, {
      header: I18n.t('activemodel.attributes.staff.email'),
      accessor: 'email'
    }, {
      header: I18n.t('activemodel.attributes.staff.phone_number'),
      accessor: 'phone_number'
    }, {
      header: I18n.t('activemodel.attributes.member.staff_role_id'),
      accessor: 'role'
    }, {
      header: I18n.t('activemodel.attributes.member.job_title_ids'),
      accessor: 'job_titles'
    }, {
      header: I18n.t('activemodel.attributes.member.business_unit_ids'),
      accessor: 'business_units'
    }];
  }

  render() {
    return(
      <div className="card">
        <TableGrid
          asc="true"
          sortBy='name'
          columns={this.columns()}
          records={this.props.staffs}
        />
      </div>
    );
  }
}

export default Staffs;
