import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class TextBlockFavorite extends React.Component {
  constructor(props) {
    super(props);

    this.favoriteId = this.props.favoriteId;
    this.scope = { scope: 'components.text_block_favorite' };

    this.state = {
      isFavorite: this.props.favoriteId > 0,
    };
  }

  componentDidUpdate() {
    $('.tooltip').tooltip('fixTitle');
  }

  unFavoriteClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ isFavorite: false });
    this.destroyFavorite();
  }

  favoriteClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ isFavorite: true });
    this.createFavorite();
  }

  createFavorite() {
    const formData = new FormData();
    formData.append('authenticity_token', document.querySelector('[name="csrf-token"]').content);
    formData.append('text_block_favorite[text_block_id]', this.props.textBlockId);

    const url = '/text_block_favorites';
    const options = {
      headers: { Accept: 'application/json' },
      method: 'POST',
      body: formData,
      credentials: 'same-origin',
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((data) => (this.favoriteId = data.text_block_favorite.id));
  }

  destroyFavorite() {
    const formData = new FormData();
    formData.append('authenticity_token', document.querySelector('[name="csrf-token"]').content);

    const url = `/text_block_favorites/${this.favoriteId}`;
    const options = {
      headers: { Accept: 'application/json' },
      method: 'DELETE',
      body: formData,
      credentials: 'same-origin',
    };

    fetch(url, options);
  }

  renderUnfavorite() {
    return (
      <button
        className="btn btn-link px-1"
        onClick={this.unFavoriteClick.bind(this)}
        data-toggle="tooltip"
        data-trigger="hover"
        title={I18n.t('unfavorite', this.scope)}
      >
        <i className="fa fa-star" />
      </button>
    );
  }

  renderFavorite() {
    return (
      <button
        className="btn btn-link px-1"
        onClick={this.favoriteClick.bind(this)}
        data-toggle="tooltip"
        data-trigger="hover"
        title={I18n.t('favorite', this.scope)}
      >
        <i className="far fa-star" />
      </button>
    );
  }

  render() {
    return this.state.isFavorite ? this.renderUnfavorite() : this.renderFavorite();
  }
}

export default TextBlockFavorite;
