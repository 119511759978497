import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import DeviationStore from '../../stores/deviation-store';
import TaskStore from '../../stores/task-store';

const options = { scope: 'components.sa_complaince' };

const SaComplaince = (props) => {
  const [complianceAnswer, setComplianceAnswer] = useState(props.saQuestion.compliance_answer);
  const [deviation, setDeviation] = useState(props.deviation);
  const [tasks, setTasks] = useState(props.tasks);

  const onChange = ({ target: { value } }) => {
    setComplianceAnswer(value === 'true');

    fetch(`/self_assessments/${props.saQuestion.self_assessment_id}/questions/${props.saQuestion.id}`, {
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        sa_question: { compliance_answer: value === 'true' },
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
  };

  useEffect(() => {
    const onChange = () => {
      const d = DeviationStore.getAll().find(d => d.sa_question_id === props.saQuestion.id);
      if (d) setDeviation(d);
    };

    if (props.deviation) {
      DeviationStore.addDeviations([props.deviation]);
    }
    DeviationStore.addChangeListener(onChange);
    return () => DeviationStore.removeChangeListener(onChange);
  }, [props.saQuestion.id, props.deviation]);

  const deviationId = deviation && deviation.id;

  useEffect(() => {
    const onChange = () => setTasks(TaskStore.getAll().filter(t => t.deviation_id === deviationId));

    TaskStore.addTasks(props.tasks);
    TaskStore.addChangeListener(onChange);
    return () => TaskStore.removeChangeListener(onChange);
  }, [props.tasks, deviationId]);

  return(
    <>
      <div className="form-group">
        {I18n.t('compliance_answer', options)}
        <div className="radio">
          <label>
            <input
              checked={complianceAnswer === true}
              disabled={deviation}
              onChange={onChange}
              type="radio"
              value={true}
            />
            {I18n.t('yes', options)}
          </label>
        </div>

        <div className="radio">
          <label>
            <input
              checked={complianceAnswer === false}
              disabled={deviation}
              onChange={onChange}
              type="radio"
              value={false}
            />
            {I18n.t('no', options)}
          </label>
        </div>
      </div>
      {deviation === null ?
        complianceAnswer === false && (
          <div>
            <a
              href="#new-deviation-modal"
              data-toggle="modal"
              data-sa-question-id={props.saQuestion.id}
              data-deviation-type={props.deviationType}
            >
              {I18n.t('new_deviation', options)}
            </a>
          </div>
        )
        :
        <>
          <a className="d-block mb-1" href={`${location.pathname}/deviations/${deviation.id}`}>{I18n.t('deviation', options)}: {deviation.title}</a>
          <label className="d-block"><b>{I18n.t('tasks', options)}</b></label>
        </>
      }
      {tasks.map(task => (
        <div key={task.id} className="mb-1">
          <a href={`${location.pathname}/deviations/${deviation.id}/tasks/${task.id}`}>{task.name}</a>
        </div>
      ))}
      {deviation !== null && complianceAnswer === false && (
        <a
          href="#modal-nieuwe-taak"
          data-toggle="modal"
          data-deviation-id={deviation.id}
        >
          {I18n.t('new_task', options)}
        </a>
      )}
    </>
  );
};

SaComplaince.propTypes = {
  saQuestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    compliance_answer: PropTypes.bool,
    self_assessment_id: PropTypes.number.isRequired,
  }).isRequired,
  deviation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  deviationType: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default SaComplaince;
