import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

export const DataGrid = styled.div`
  background-color: #fff;
  padding: 15px;
`;
export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DataCell = styled.div`
  flex: ${(props) => props.size || '1'};
  padding: 15px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DataContainer = styled.div`
  &:nth-child(n + 1) {
    border-top: 1px solid #e5e5e5;
  }
  &:nth-child(even) {
    background-color: rgba(85, 99, 170, 0.05);
  }
`;
export const DataColumn = styled(DataCell)`
  border-bottom: 2px solid #e5e5e5;
`;
export const DataCellId = styled(DataCell)`
  max-width: 70px;
`;
export const DataColumnId = styled(DataColumn)`
  max-width: 70px;
`;

export const DataCollapse = styled.div`
  min-height: 0;
  height: ${({ state }) => (state === 'entered' ? 'auto' : 0)};
  transition: max-height 200ms ease;
  overflow: hidden;
`;

const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: #5563aa;
  display: flex;
  font-weight: bold;
  padding: 0;

  svg {
    margin-left: 0.25rem;
  }
`;

export const SortButton = ({ children, name, sortDirection, ...props }) => {
  const onClick = () =>
    props.onClick(name, sortDirection === 'asc' ? 'dsc' : 'asc');

  return (
    <Button onClick={onClick} type="button">
      {children}
      {sortDirection && (
        <FontAwesomeIcon
          icon={sortDirection == 'asc' ? faCaretDown : faCaretUp}
        />
      )}
    </Button>
  );
};
