import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

class SsoConnection {
  constructor(form) {
    this.form = form;
    this.onSubmit = this.onSubmit.bind(this);

    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.onSubmit);
  }

  onSubmit(event) {
    event.preventDefault();
    fetch(this.form.action, {
      headers: {
        Accept: 'application/json',
      },
      method: this.form.method,
      body: new FormData(this.form),
    }).then(r => r.json()).then(data => {
      Rails.enableElement(this.form);


      if (data.status === 'OK') {
        location.reload();
      } else {
        validationErrors(this.form, data.validation_errors);
      }
    });
  }
}

$(document).on('turbolinks:load', () => {
  const form = document.querySelector('[data-form="sso_connection"]');
  const addCertificateButtons = document.querySelectorAll('[data-element="addCertificate"]');
  const removeCertificateButtons = document.querySelectorAll('[data-element="removeCertificate"]');

  for (let c of addCertificateButtons) {
    c.addEventListener('click', addCertificate);
  }

  function addCertificate(event) {
    const row = $('<div class="input-group"><input value="" class="form-control" placeholder="Certificate finger print" multiple="multiple" type="text" name="sso_connection[cert_finger_prints][]" id="sso_connection_cert_finger_prints"><div class="input-group-addon p-0"><button class="btn btn-primary" data-element="removeCertificate" type="button">Verwijderen</button></div></div>');

    $('#certificateInput').append(row);

    const removeCertificateButtons = document.querySelectorAll('[data-element="removeCertificate"]');
    removeCertificateButtons[removeCertificateButtons.length - 1].addEventListener('click', removeCertificate);
  }

  for (let c of removeCertificateButtons) {
    c.addEventListener('click', removeCertificate);
  }

  function removeCertificate(event) {
    $(this).parents('.input-group').remove();
  }

  if (form) {
    new SsoConnection(form);
  }
});
