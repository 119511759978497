import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import isHotkey from 'is-hotkey';

import DeviationAnswerForm from './DeviationAnswerForm';
import DeviationAnswerOverview from './DeviationAnswerOverview';

const isEnter = isHotkey('enter');

class DeviationAnswers extends React.Component {
  constructor(props) {
    super(props);

    const deviation_answer = this.nextDeviationAnswer(props.deviation_answers);

    const deviation_answers = props.deviation_answers.map(d => {
      if (d.answer === null && d.position < (deviation_answer ? deviation_answer.position : props.deviation_answers.length + 1)) {
        return {...d, ...{ skipped: true }};
      } else {
        return {...d, ...{ skipped: null }};
      }
    });

    this.scope = { scope: 'components.deviation_answers' };
    this.updateDeviationAnswer = this.updateDeviationAnswer.bind(this);
    this.renderDeviationAnswer = this.renderDeviationAnswer.bind(this);
    this.showDeviationAnswer = this.showDeviationAnswer.bind(this);

    this.state = {
      deviation_answer,
      deviation_answers,
    };
  }

  nextDeviationAnswer(deviation_answers, currentDeviationAnswer) {
    if (currentDeviationAnswer === undefined) {
      const answeredAnswers = deviation_answers.filter(d => d.answer !== null && d.answer !== '');

      if (answeredAnswers.length) {
        currentDeviationAnswer = answeredAnswers[answeredAnswers.length - 1];
      } else {
        return deviation_answers[0];
      }
    }

    const answer = currentDeviationAnswer.answer;

    if (currentDeviationAnswer[`${answer}_to_end`]) {
      return null;
    } else if (currentDeviationAnswer[`${answer}_next_answer_id`] !== null) {
      return deviation_answers.find(d => d.id === currentDeviationAnswer[`${answer}_next_answer_id`]);
    } else {
      const index = deviation_answers.indexOf(currentDeviationAnswer);
      return deviation_answers.length === (index + 1) ? null : deviation_answers[index + 1];
    }
  }

  updateDeviationAnswer(deviation_answer) {
    let currentDeviationAnswer;
    let deviation_answers = this.state.deviation_answers.map(d => {
      if (d.id === deviation_answer.id) {
        return currentDeviationAnswer = {...d, ...deviation_answer};
      } else {
        return d;
      }
    });

    const nextDeviationAnswer = this.nextDeviationAnswer(deviation_answers, currentDeviationAnswer);

    if (nextDeviationAnswer !== null) {
      deviation_answers = this.skipAndClearDeviationAnswers(deviation_answers, nextDeviationAnswer.position, currentDeviationAnswer.position);
    } else {
      deviation_answers = this.skipAndClearDeviationAnswers(deviation_answers, deviation_answers.length + 1, currentDeviationAnswer.position);
    }

    this.setState({
      deviation_answer: nextDeviationAnswer,
      deviation_answers
    });
  }

  skipAndClearDeviationAnswers(deviation_answers, nextDeviationAnswerPosition, currentDeviationAnswerPosition) {
    if (nextDeviationAnswerPosition <= currentDeviationAnswerPosition + 1) {
      return deviation_answers;
    }

    return deviation_answers.map(d => {
      if (d.position > currentDeviationAnswerPosition && d.position < nextDeviationAnswerPosition) {
        return {...d, ...{ answer: null, skipped: true }};
      } else {
        return d;
      }
    });
  }

  showDeviationAnswer(deviation_answer) {
    this.setState({ deviation_answer });
  }

  renderDeviationAnswer(deviation_answer) {
    let className, onClick;

    if (this.state.deviation_answer.id === deviation_answer.id) {
      className = 'deviation-questions__question deviation-questions__question--active';
      onClick = this.showDeviationAnswer.bind(this, deviation_answer);
    } else if (deviation_answer.answer === null && deviation_answer.skipped) {
      className = 'deviation-questions__question deviation-questions__question--skipped';
    } else {
      onClick = this.showDeviationAnswer.bind(this, deviation_answer);
      className = 'deviation-questions__question';
    }

    return(
      <div key={deviation_answer.id} className={className} onClick={onClick} onKeyUp={(e) => isEnter(e) && onClick() } role="button" tabIndex="0">
        <p>{deviation_answer.position }.&nbsp;</p>
        <span className="deviation-questions__question__question" dangerouslySetInnerHTML={{ __html: deviation_answer.question }} />
        <span className="deviation-questions__question__answer">
          {deviation_answer.answer && I18n.t(`components.deviation_answers.${deviation_answer.answer}`)}
        </span>
      </div>
    );
  }

  render() {
    const { can_update, deviation } = this.props;
    const { deviation_answer, deviation_answers, showForm } = this.state;

    if (can_update && deviation_answer !== null) {
      return(
        <div className="deviation_answers">
          <DeviationAnswerForm
            deviation_answer={deviation_answer}
            onSubmit={this.updateDeviationAnswer}
          />
          <div className="deviation-questions overflow-auto">
            {deviation_answers.map(this.renderDeviationAnswer)}
          </div>
        </div>
      );
    } else {
      return(
        <DeviationAnswerOverview
          can_update={can_update}
          deviation={deviation}
          deviation_answers={deviation_answers.filter(d => !d.skipped)}
          editDeviationAnswer={this.showDeviationAnswer}
        />
      );
    }
  }
}

export default DeviationAnswers;
