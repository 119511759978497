import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import FlexGrid from './FlexGrid';

class DashboardTasks extends React.Component {
  columns() {
    const scope = { scope: 'components.dashboard_tasks' };

    return [{
      header: I18n.t('id', scope),
      accessor: 'id',
      size: 1
    }, {
      header: I18n.t('name', scope),
      accessor: 'name',
      size: 4
    }, {
      header: I18n.t('status', scope),
      accessor: 'status_name',
      size: 3
    }];
  }

  render() {
    return(
      <FlexGrid
        columns={this.columns()}
        records={this.props.tasks.map(task => ({ ...task, url: `/tasks/${task.id}` }))}
      />
    );
  }
}

export default DashboardTasks;
