class EditTask {
  constructor(form) {
    this.form = $(form);
    this.onDelete = this.onDelete.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('click', '[data-action="delete"]', this.onDelete);
  }

  onDelete({ currentTarget }) {
    const { id } = currentTarget.dataset;

    const input = document.createElement('input');
    input.name = `task[attachments_attachments_attributes][${id}][id]`;
    input.type = 'hidden';
    input.value = id;
    const inputDestroy = document.createElement('input');
    inputDestroy.name = `task[attachments_attachments_attributes][${id}][_destroy]`;
    inputDestroy.type = 'hidden';
    inputDestroy.value = true;

    currentTarget.parentElement.replaceWith(inputDestroy);
    inputDestroy.parentElement.insertBefore(input, inputDestroy);
  }
}

$(document).on('turbolinks:load', () => {
  $('#edit_task_form').each((_, form) => {
    new EditTask(form);
  });
});
