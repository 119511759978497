import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { request } from '../../actions';

const options = { scope: 'components.sa_questionnaire' };
const graphql = `
  mutation UpdateSaAnswerMutation($id: ID!, $answer: String!, $score: Int!) {
    updateSaAnswer(input: { id: $id, answer: $answer, score: $score }) {
      saAnswer {
        saQuestionnaire {
          progress
        }
      }
    }
  }
`;

const Form = ({ selfAssessment, saAnswer, ...props }) => {
  const [saAnswerOption, setSaAnswerOption] = useState(
    saAnswer.saQuestion.saAnswerOptions.find(
      (a) => a.answer === saAnswer.answer,
    ),
  );

  const onSubmit = async (event) => {
    event.preventDefault();

    const variables = {
      id: saAnswer.id,
      answer: saAnswerOption.answer,
      score: saAnswerOption.score,
    };
    const [response] = await request(graphql, variables);

    props.onChange(
      { ...saAnswer, answer: saAnswerOption.answer },
      response.updateSaAnswer.saAnswer.saQuestionnaire.progress,
    );
  };

  useEffect(() => {
    setSaAnswerOption(
      saAnswer.saQuestion.saAnswerOptions.find(
        (a) => a.answer === saAnswer.answer,
      ),
    );
  }, [saAnswer]);

  const renderQuestion = (questionField) => (
    <p key={questionField.position}>
      <b>{questionField.field}</b>
      <br />
      {questionField.value}
    </p>
  );

  const renderSaAnswerOption = (option) => {
    return (
      <div key={option.id} className="radio">
        <label className="wrap">
          <input
            checked={
              saAnswerOption !== undefined &&
              option.answer === saAnswerOption.answer
            }
            name="answer"
            onChange={() => setSaAnswerOption(option)}
            type="radio"
            value={option.answer}
          />
          {option.answer}
        </label>
      </div>
    );
  };

  return (
    <form id="answer" onSubmit={onSubmit}>
      {saAnswer.saQuestion.questionFields.map(renderQuestion)}

      <fieldset>
        <legend className="mb-1">{selfAssessment.answerFieldName}</legend>
        {saAnswer.saQuestion.saAnswerOptions.map(renderSaAnswerOption)}
      </fieldset>
      <button
        className="btn btn-primary"
        disabled={saAnswerOption === undefined}
      >
        {I18n.t('helpers.submit.next')}
      </button>
    </form>
  );
};

Form.propTypes = {
  saAnswer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    answer: PropTypes.string,
    saQuestion: PropTypes.shape({
      saAnswerOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          answer: PropTypes.string,
        }),
      ).isRequired,
      questionFields: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          position: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selfAssessment: PropTypes.shape({
    answerFieldName: PropTypes.string,
  }).isRequired,
};

export default Form;
