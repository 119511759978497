$(document).on('turbolinks:load', () => {
  const role_button = document.querySelector('#role-button');
  if (role_button === null) return;

  role_button.addEventListener('click', (event) => {
    const select = document.querySelector('#member_staff_role_id');

    if (select) {
      const image = document.querySelector(`[data-staff-role-id="${select.value}"]`);
      document.querySelector(`${role_button.dataset.target} img`).src = image.src;
    }
  });
});
