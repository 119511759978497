export const initialRequest = `
  query ManualChapterQuery($manualChapterId: ID!) {
    businessUnits { id name }
    currentMember { id accessNorms accessibleBusinessUnitIds canCreateTask canManageTextBlock role }
    normOrganisations { id status norm { id name } }
    manualChapter(id: $manualChapterId) {
      id name number
      manualParagraphs {
        id name number
        textBlocks {
          id name description showName status
          businessUnitIds
          normIds
          documents { id businessUnitIds }
        }
        resources { id name }
      }
    }
    manualChapters { id name number }
    manualParagraphFavorites(manualChapterId: $manualChapterId) { id manualParagraphId }
    textBlockFavorites(manualChapterId: $manualChapterId) { id textBlockId }
  }
`;

export const textBlockRequest = `
  query TextBlockQuery($id: ID!) {
    textBlock(id: $id) {
      documents {
        businessUnitIds
        classification
        height
        id
        isImage
        location
        name
        retentionPeriod
        revisionDate
        status
        type
        width
        staff { name }
      }
      tables {
        id name
        tableColumns { id name widthPercentage }
        tableRows {
          id
          tableCells { id value defaultAnswer { height name url width } }
        }
      }
    }
  }
`;
