class Checker {
  constructor(checkbox) {
    this.parentCheckBox = checkbox;
    this.childrenCheckBox = document.querySelector(checkbox.dataset.target).querySelectorAll('[data-check="child"]');
    this.bindEvents();
  }

  bindEvents() {
    this.parentCheckBox.addEventListener('click', this.onParentClickEvent.bind(this));
    [].forEach.call(this.childrenCheckBox, (child) => {
      child.addEventListener('click', this.onChildClickEvent.bind(this));
    });
  }

  onParentClickEvent() {
    if (this.parentCheckBox.checked) {
      [].forEach.call(this.childrenCheckBox, (child) => {
        child.checked = true;
      });
    } else {
      [].forEach.call(this.childrenCheckBox, (child) => {
        child.checked = false;
      });
    }
  }

  onChildClickEvent(event) {
    if (!event.target.check) {
      this.parentCheckBox.checked = false;
    }
  }
}

$(document).on('turbolinks:load', () => {
  const checks = document.querySelectorAll('[data-check="auto"]');
  [].forEach.call(checks, (checker) => {
    new Checker(checker);
  });
});
