import React, { useState } from 'react';
import queryString from 'query-string';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

const SearchForm = () => {
  const [inProp, setInProp] = useState(false);
  const [value, setValue] = useState(queryString.parse(location.search).q);

  const onBlur = () => setInProp(false);
  const onChange = ({ target: { value }}) => setValue(value);
  const onFocus = () => setInProp(true);

  const defaultStyle = {
    float: 'right',
    transition: 'width 200ms ease-in-out',
  };

  const transitionStyles = {
    entering: { width: '40%' },
    entered:  { width: '100%' },
    exiting:  { width: '100%' },
    exited:  { width: '40%' },
  };

  return (
    <form className="form-inline py-3" action="/search" method="get">
      <div className="input-group">
        <Transition in={inProp} timeout={0}>
          {state => (
            <input
              className="form-control"
              name="q"
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              style={{ ...defaultStyle, ...transitionStyles[state] }}
              required
              value={value}
              type="search" />
          )}
        </Transition>
        <span className="input-group-btn">
          <button className="btn btn-default">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </span>
      </div>
    </form>
  );
};

export default SearchForm;
