import React, { useReducer, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions/index';
import { reducer } from '../reducer/index';

import DataSubjectLink from './DataSubjectLink';

const graphql = `mutation DataSubject($name: String!) {
  createDataSubject(input: { name: $name }) {
    dataSubject { id name }
    errors { field messages }
  }
}`;

const DataSubjectLinks = ( props ) => {
  const dataSubjectLinks = props.dataSubjectLinks.length ? props.dataSubjectLinks : [{ uid: Date.now() }];

  const container = useRef(null);
  const [name, setName] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    dataSubjectLinks,
    dataSubjects: props.dataSubjects,
    errors: [],
    addMode: false,
  });

  const onAdd = () => {
    dispatch({ type: 'update', payload: { dataSubjectLinks: [...state.dataSubjectLinks, { uid: Date.now() }] } });
  };

  const onChange = (dataSubjectLink) => {
    const mapFn = (p) => (
      (p.id !== undefined && p.id === dataSubjectLink.id) || (p.uid !== undefined && p.uid === dataSubjectLink.uid) ? dataSubjectLink : p );
    dispatch({ type: 'update' , payload: { dataSubjectLinks: state.dataSubjectLinks.map(mapFn) } });
  };

  const onDelete = (dataSubjectLink) => {
    const filterFn = (p) => (p.uid === undefined || p.uid !== dataSubjectLink.uid);
    const mapFn = (p) => (p.id !== undefined && p.id === dataSubjectLink.id ? { ...p, destroy: true } : p);

    dispatch({ type: 'update' , payload: { dataSubjectLinks: state.dataSubjectLinks.filter(filterFn).map(mapFn) } });
  };

  const toggleAddMode = () => {
    dispatch({ type: 'update' , payload: { addMode: true } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const [response] = await request(graphql, { name });
    const { dataSubject, errors } = response.createDataSubject;

    if (errors.length) {
      dispatch({ type: 'update', payload: { errors } });
    } else {
      const dataSubjectLink = { uid: Date.now(), data_subject_id: dataSubject.id };

      dispatch({
        type: 'update',
        payload: {
          dataSubjectLinks: [...state.dataSubjectLinks.filter(d => d.data_subject_id !== undefined), dataSubjectLink],
          dataSubjects: [...state.dataSubjects, dataSubject],
          errors: [],
          addMode: false,
        },
      });
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const renderDataSubjectLink = (dataSubjectLink) => {
    if (dataSubjectLink.destroy) {
      const formName = `processing_register[data_subject_links_attributes][${dataSubjectLink.id}]`;

      return(
        <div>
          <input name={`${formName}[id]`} value={dataSubjectLink.id} type="hidden" />
          <input name={`${formName}[_destroy]`} value={dataSubjectLink.destroy} type="hidden" />
        </div>
      );
    }

    return(
      <DataSubjectLink
        key={dataSubjectLink.id || dataSubjectLink.uid}
        dataSubjects={state.dataSubjects}
        onChange={onChange}
        dataSubjectLink={dataSubjectLink}
        onDelete={onDelete}
      />
    );
  };

  return(
    <div ref={container}>
      <label>{I18n.t('processing_registers.data_subjects')}</label>
      <div>
        {state.dataSubjectLinks.map(renderDataSubjectLink)}

        {state.addMode && (
          <form onSubmit={onSubmit}>
            <div className='d-flex form-group justify-content-between'>
              <input
                className='form-control'
                name='name'
                onChange={e => setName(e.target.value)}
                placeholder={I18n.t('processing_registers.placeholders.new_data_subject')}
                value={state.name}
                type='text'
              />
              <button className='btn btn-primary ml-2'>
                {I18n.t('processing_registers.save')}
              </button>
            </div>
            {state.errors.length > 0 && <div className='alert alert-danger'>{state.errors[0].messages[0]}</div>}
          </form>
        )}
        <div className='d-flex justify-content-between'>
          <button className='btn btn-default' onClick={onAdd} type="button">
            <i className="far fa-plus"></i> {I18n.t('processing_registers.add_data_subject_link')}
          </button>
          <button className="btn btn-default" data-toggle='tooltip' title={I18n.t('processing_registers.add_data_subject')} onClick={toggleAddMode} type="button">
            <i className="far fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

DataSubjectLinks.propTypes = {
  dataSubjectLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      data_subject_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dataSubjects: PropTypes.array.isRequired,
};

export default DataSubjectLinks;
