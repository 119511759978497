import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AuditConstants from 'constants/audit-constants';
import { auditDispatcher } from 'dispatcher/audit-dispatcher';
import AuditStore from 'stores/audit-store';

import ManualAudit from './ManualAudit';

class ManualAudits extends React.Component {
  constructor(props) {
    super(props);

    let numberOfAudits = 3;
    let audits = this.initializeAudits();

    if (props.audits_form.audits.length) {
      numberOfAudits = props.audits_form.audits.length;
      audits = props.audits_form.audits.map((audit) => ({ ...this.defaultAudit(), ...audit }));
    }

    this.bindChangeEvent = this.onChangeEvent.bind(this);
    const manual_chapters = this.initializeManualChapter(audits, props.manual_chapters);
    const norms = this.initializeNorms(audits, props.norms);

    AuditStore.setAudits(audits);

    this.state = {
      numberOfAudits: numberOfAudits,
      audits: audits,
      norms: norms,
      manual_chapters: manual_chapters
    };
  }

  defaultAudit() {
    return {
      id: parseInt(Math.random() * 10000),
      errors: {},
      name: null,
      description: null,
      manual_chapter_ids: [],
      manual_paragraph_ids: [],
      norm_ids: []
    };
  }

  initializeAudits() {
    return [
      { ...this.defaultAudit() },
      { ...this.defaultAudit() },
      { ...this.defaultAudit() }
    ];
  }

  initializeManualChapter(audits, manual_chapters) {
    const manual_paragraph_ids = audits.map((i) => i.manual_paragraph_ids).reduce((a, b) => a.concat(b));

    return manual_chapters.map((manual_chapter) => {
      const manual_paragraphs = manual_chapter.manual_paragraphs.map((manual_paragraph) => (
        { ...manual_paragraph, checked: manual_paragraph_ids.includes(manual_paragraph.id) }
      ));
      const checked = audits.some((a) => a.manual_chapter_ids.includes(manual_chapter.id));
      return { ...manual_chapter, manual_paragraphs, checked };
    });
  }

  initializeNorms(audits, norms) {
    return norms.map(norm => (
      { ...norm, checked: audits.some(i => i.norm_ids.includes(norm.id))}
    ));
  }

  componentDidMount() {
    AuditStore.addChangeListener(this.bindChangeEvent);
  }

  componentWillUnmount() {
    AuditStore.removeChangeListener(this.bindChangeEvent);
  }

  onChangeEvent() {
    const audits = [...AuditStore.getAll()];
    const manual_chapters = this.state.manual_chapters.map((manual_chapter) => {
      const checked = audits.some(audit => audit.manual_chapter_ids.includes(manual_chapter.id));

      const manual_paragraphs = manual_chapter.manual_paragraphs.map((manual_paragraph) => (
        {
          ...manual_paragraph,
          checked: audits.some(audit => audit.manual_paragraph_ids.includes(manual_paragraph.id))
        }
      ));

      return { ...manual_chapter, checked, manual_paragraphs };
    });

    const norms = this.state.norms.map((norm) => (
      { ...norm, checked: audits.some(audit => audit.norm_ids.includes(norm.id)) }
    ));

    this.setState({ manual_chapters, audits, norms });
  }

  onNumberOfAudits(event) {
    this.setState({
      numberOfAudits: +event.currentTarget.value
    });

    if (+event.currentTarget.value > this.state.audits.length) {
      const number = +event.currentTarget.value - this.state.audits.length;
      const audits = [...Array(number)].map(() => Object.assign({}, this.defaultAudit()));

      auditDispatcher.handleCreateAuditAction({
        actionType: AuditConstants.AUDIT_CREATE,
        audits: audits
      });
    } else if (+event.currentTarget.value < this.state.audits.length) {
      auditDispatcher.handleDestroyAuditAction({
        actionType: AuditConstants.AUDIT_DESTROY,
        audits: this.state.audits.filter((audit, i) => i >= +event.currentTarget.value)
      });
    }
  }

  renderAudit(_, index) {
    const formName = `audits[${index}]`;
    return(
      <ManualAudit
        key={index}
        index={index}
        audit={this.state.audits[index]}
        formName={formName}
        manual_chapters={this.state.manual_chapters}
        norms={this.state.norms}
      />
    );
  }

  render() {
    return(
      <div>
        <div className="card">
          <div className="form-group">
            <label htmlFor="number_of_audits">{I18n.t('activemodel.attributes.audit.number_of_audits')}</label>
            <input className="form-control" type="number" id="number_of_audits" value={this.state.numberOfAudits} onChange={this.onNumberOfAudits.bind(this)} min="1" />
          </div>
        </div>
        <div className="row">
          {[...Array(this.state.numberOfAudits)].map(this.renderAudit.bind(this))}
        </div>
      </div>
    );
  }
}

export default ManualAudits;
