import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { businessUnitIdsState } from './state';
import { onAnchorIntoViewLinkClick } from '../../utils';

function filterTextBlocks(textBlocks, businessUnitIds) {
  if (businessUnitIds.length === 0) return textBlocks;

  const includeEmpty = businessUnitIds.includes(0);

  const filterItem = (item) => {
    return (item.businessUnitIds === undefined) || (includeEmpty && item.businessUnitIds.length === 0) || (item.businessUnitIds.some(id => businessUnitIds.includes(id)));
  };

  return textBlocks.reduce((textBlocks, textBlock) => {
    const documents = textBlock.documents.filter(filterItem);
    if (documents.length > 0 || filterItem(textBlock)) {
      textBlocks = [...textBlocks, { ...textBlock, documents }];
    }
    return textBlocks;
  }, []);
}

const ManualChapterList = ({ manualChapter }) => {
  const businessUnitIds = useRecoilValue(businessUnitIdsState);

  const renderTextBlockList = (textBlock) => (
    <a key={textBlock.id} href={`#text-block-${textBlock.id}`} onClick={onAnchorIntoViewLinkClick} title={textBlock.name}>
      {textBlock.name}
    </a>
  );

  const renderManualParagraphList = (manualParagraph) => {
    const textBlocks = filterTextBlocks(manualParagraph.textBlocks, businessUnitIds);
    if (textBlocks.length === 0)  return null;
    return(
      <li key={manualParagraph.id} className="panel">
        <a
          className="collapsed"
          onClick={onAnchorIntoViewLinkClick}
          href={`#manual-paragraph-${manualParagraph.id}`}
          data-parent="#list"
          data-toggle="collapse"
          data-target={`#list-paragraph-${manualParagraph.id}`}
          title={manualParagraph.name}
        >
          <FontAwesomeIcon icon={faCaretDown} />
          <span className="paragraph-number">{manualParagraph.number}</span> {manualParagraph.name}
        </a>
        <div className="list-text-blocks collapse" id={`list-paragraph-${manualParagraph.id}`}>
          {textBlocks.map(renderTextBlockList)}
        </div>
      </li>
    );
  };

  return(
    <>
      <li key={manualChapter.id}>
        <a href={`#manual-chapter-${manualChapter.id}`}>
          <span className="paragraph-number">{manualChapter.number}</span>
          <h6 className="d-inline-block mb-0">{manualChapter.name}</h6>
        </a>
      </li>
      {manualChapter.manualParagraphs.map(renderManualParagraphList)}
    </>
  );
};

ManualChapterList.propTypes = {
  manualChapter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    manualParagraphs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        textBlocks: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default ManualChapterList;
