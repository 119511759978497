import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import { request } from '../../actions';

import AddQuestionanire from './add-questionnnaire';
import ButtonRole from '../ui/button-role';
import DeleteButton from './delete-button';
import ReminderButton from './reminder-button';
import SortLink from '../ui/sort-link';

const options = { scope: 'components.self_assessment' };
const graphql = `
  query SaQuestionnairesQuery($selfAssessmentId: ID!, $sortBy: String, $sortDirection: String) {
    saQuestionnaires(selfAssessmentId: $selfAssessmentId, sortBy: $sortBy, sortDirection: $sortDirection) {
      id progress status staff { id name }
    }
  }
`;

const SaQuestionnaires = ({ selfAssessmentId }) => {
  const [state, setState] = useState({
    saQuestionnaires: [],
    sortBy: 'id',
    sortDirection: 'desc',
  });

  const onSort = (sortBy, sortDirection) => setState({ ...state, sortBy, sortDirection });

  const fetchSaQuestions = useCallback(async (controller) => {
    const variables = {
      selfAssessmentId,
      sortBy: state.sortBy,
      sortDirection: state.sortDirection,
    };
    const [response, error] = await request(graphql, variables, controller);

    if (error === null) {
      setState(s => ({ ...s, ...response }));
    }
  }, [selfAssessmentId, state.sortBy, state.sortDirection]);

  useEffect(() => {
    const controller = new AbortController();
    fetchSaQuestions(controller);

    return () => controller.abort();
  }, [fetchSaQuestions]);

  const renderSaQuestionnaire = (saQuestionnaire) => (
    <ButtonRole key={saQuestionnaire.id} className="flex-row" onClick={() => Turbolinks.visit(`/self_assessments/${selfAssessmentId}/questionnaires/${saQuestionnaire.id}`)}>
      <div className="size-1">{saQuestionnaire.id}</div>
      <div className="size-3">{saQuestionnaire.staff.name}</div>
      <div className="size-3">
        {saQuestionnaire.status === 'new' ? (
          <div className="progress mb-0">
            <div
              aria-valuenow={saQuestionnaire.progress}
              aria-valuemin="0"
              aria-valuemax="100"
              className="progress-bar"
              role="progressbar"
              style={{width: saQuestionnaire.progress + '%'}}
            >
              {saQuestionnaire.progress}%
            </div>
          </div>
        ) : (
          I18n.t('completed', options)
        )}
      </div>
      <div className="size-2">
        {saQuestionnaire.status === 'new' && <ReminderButton id={saQuestionnaire.id} />}
        <DeleteButton id={saQuestionnaire.id} onDelete={fetchSaQuestions} />
      </div>
    </ButtonRole>
  );

  return(
    <>
      <AddQuestionanire selfAssessmentId={selfAssessmentId} excludeStaffs={state.saQuestionnaires.map(q => q.staff)} refreshCallback={fetchSaQuestions} />
      <div className="card">
        <div className="table-flex">
          <div className="head">
            <div className="flex-row">
              <div className="size-1">
                <SortLink sorting="id" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                  {I18n.t('activerecord.attributes.sa_questionnaire.id')}
                </SortLink>
              </div>
              <div className="size-3">
                <SortLink sorting="staff" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                  {I18n.t('activerecord.attributes.sa_questionnaire.staff_id')}
                </SortLink>
              </div>
              <div className="size-3">
                <SortLink sorting="progress" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                  {I18n.t('activerecord.attributes.sa_questionnaire.progress')}
                </SortLink>
              </div>
              <div className="size-2">
                {I18n.t('actions', options)}
              </div>
            </div>
          </div>
          <div className="body">
            {state.saQuestionnaires.map(renderSaQuestionnaire)}
          </div>
        </div>
      </div>
    </>
  );
};

SaQuestionnaires.propTypes = {
  selfAssessmentId: PropTypes.number.isRequired,
};

export default SaQuestionnaires;
