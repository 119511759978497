import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions';
import Modal from './modal';

const graphql = `
  query OpenDocumentQuery($id: ID!) {
    currentMember { canDestroyDocument }
    document(id: $id) {
      redirectUrl
    }
  }
`;

const mutation = `
  mutation OpenDocumentMutation($input: DestroyDocumentInput!) {
    destroyDocument(input: $input) { document { id } }
  }
`;

const scope = { scope: 'components.open_document' };

const OpenDocument = ({ children, doc: { id, type }, onDelete, ...props }) => {
  const link = useRef(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [currentMember, setCurrentMember] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const onClick = async () => {
    if (redirectUrl) return link.current.click();

    const [res] = await request(graphql, { id });

    if (res.document.redirectUrl) {
      setRedirectUrl(res.document.redirectUrl);
    } else {
      setCurrentMember(res.currentMember);
      setIsOpen(true);
    }
  };

  const onClose = () => setIsOpen(false);

  const onDestroy = async () => {
    await request(mutation, { input: { id } });
    onClose();
    onDelete && onDelete(id);
  };

  useEffect(() => {
    if (redirectUrl && link.current) {
      link.current.click();
    }
  }, [redirectUrl]);

  if (type === 'external') {
    return (
      <>
        <button className="btn btn-link p-0" {...props} onClick={onClick} type="button">
          {children}
        </button>
        {redirectUrl && (
          <a className="hidden" href={redirectUrl} ref={link} rel="noreferrer" target="_blank">
            Open document
          </a>
        )}
        {isOpen && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
              <Modal.Header.Title>{I18n.t('header', scope)}</Modal.Header.Title>
              <Modal.Header.Close />
            </Modal.Header>
            <Modal.Body>
              <p>{I18n.t(currentMember.canDestroyDocument ? 'description_destroy' : 'description', scope)}</p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-default" onClick={onClose} type="button">
                {I18n.t('close', scope)}
              </button>
              {currentMember.canDestroyDocument && (
                <button className="btn btn-danger" onClick={onDestroy} type="button">
                  {I18n.t('destroy', scope)}
                </button>
              )}
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  } else {
    return (
      <a className="btn btn-link px-1" href={`/documents/${id}`} rel="noreferrer" target="_blank" {...props}>
        {children}
      </a>
    );
  }
};

OpenDocument.propTypes = {
  children: PropTypes.node,
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
};

export default OpenDocument;
