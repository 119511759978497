class DataOptionConstants {
  static get OPTION_CREATE() {
    return 'option-create';
  }

  static get OPTION_DELETE() {
    return 'option-delete';
  }
}

export default DataOptionConstants;
