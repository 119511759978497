import React, { useReducer, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions/index';
import { reducer } from '../reducer/index';

import DataleakDataSubject from './DataleakDataSubject';

const graphql = `mutation DataSubject($name: String!) {
  createDataSubject(input: { name: $name }) {
    dataSubject { id name }
    errors { field messages }
  }
}`;

const DataleakDataSubjects = (props) => {
  const dataleakDataSubjects = props.dataleakDataSubjects.length
    ? props.dataleakDataSubjects
    : [{ uid: Date.now() }];

  const container = useRef(null);
  const [name, setName] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    dataleakDataSubjects,
    dataSubjects: props.dataSubjects,
    errors: [],
    addMode: false,
  });

  const onAdd = () => {
    dispatch({
      type: 'update',
      payload: {
        dataleakDataSubjects: [
          ...state.dataleakDataSubjects,
          { uid: Date.now() },
        ],
      },
    });
  };

  const onChange = (dataleakDataSubject) => {
    const mapFn = (p) =>
      (p.id !== undefined && p.id === dataleakDataSubject.id) ||
      (p.uid !== undefined && p.uid === dataleakDataSubject.uid)
        ? dataleakDataSubject
        : p;
    dispatch({
      type: 'update',
      payload: { dataleakDataSubjects: state.dataleakDataSubjects.map(mapFn) },
    });
  };

  const onDelete = (dataleakDataSubject) => {
    const filterFn = (p) =>
      p.uid === undefined || p.uid !== dataleakDataSubject.uid;
    const mapFn = (p) =>
      p.id !== undefined && p.id === dataleakDataSubject.id
        ? { ...p, destroy: true }
        : p;

    dispatch({
      type: 'update',
      payload: {
        dataleakDataSubjects: state.dataleakDataSubjects
          .filter(filterFn)
          .map(mapFn),
      },
    });
  };

  const toggleAddMode = () => {
    dispatch({ type: 'update', payload: { addMode: true } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const [response] = await request(graphql, { name });
    const { dataSubject, errors } = response.createDataSubject;

    if (errors.length) {
      dispatch({ type: 'update', payload: { errors } });
    } else {
      const dataleakDataSubject = {
        uid: Date.now(),
        data_subject_id: dataSubject.id,
      };

      dispatch({
        type: 'update',
        payload: {
          dataleakDataSubjects: [
            ...state.dataleakDataSubjects.filter(
              (d) => d.data_subject_id !== undefined,
            ),
            {
              ...dataleakDataSubject,
              data_subject_id: parseInt(dataleakDataSubject.data_subject_id),
            },
          ],
          dataSubjects: [
            ...state.dataSubjects,
            { ...dataSubject, id: parseInt(dataSubject.id) },
          ],
          errors: [],
          addMode: false,
        },
      });
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const renderDataleakDataSubject = (dataleakDataSubject) => {
    if (dataleakDataSubject.destroy) {
      const formName = `deviation[dataleak_data_subjects_attributes][${dataleakDataSubject.id}]`;

      return (
        <div>
          <input
            name={`${formName}[id]`}
            value={dataleakDataSubject.id}
            type="hidden"
          />
          <input
            name={`${formName}[_destroy]`}
            value={dataleakDataSubject.destroy}
            type="hidden"
          />
        </div>
      );
    }

    return (
      <DataleakDataSubject
        key={dataleakDataSubject.id || dataleakDataSubject.uid}
        dataSubjects={state.dataSubjects}
        onChange={onChange}
        dataleakDataSubject={dataleakDataSubject}
        onDelete={onDelete}
      />
    );
  };

  return (
    <div ref={container}>
      <label>{I18n.t('processing_registers.data_subjects')}</label>
      <div>
        {state.dataleakDataSubjects.map(renderDataleakDataSubject)}

        {state.addMode && (
          <form onSubmit={onSubmit}>
            <div className="d-flex form-group justify-content-between">
              <input
                className="form-control"
                name="name"
                onChange={(e) => setName(e.target.value)}
                placeholder={I18n.t(
                  'processing_registers.placeholders.new_data_subject',
                )}
                value={state.name}
                type="text"
              />
              <button className="btn btn-primary ml-2">
                {I18n.t('processing_registers.save')}
              </button>
            </div>
            {state.errors.length > 0 && (
              <div className="alert alert-danger">
                {state.errors[0].messages[0]}
              </div>
            )}
          </form>
        )}
        <div className="d-flex justify-content-between">
          <div className="buttons-left">
            <button className="btn btn-default" onClick={onAdd} type="button">
              <i className="far fa-plus"></i>{' '}
              {I18n.t('processing_registers.add_data_subject_link')}
            </button>
            {props.deviationId && (
              <a
                className="btn btn-link"
                href={'./' + props.deviationId + '/data_subjects'}
              >
                <i className="far fa-wrench"></i>{' '}
                {I18n.t('deviations.manage_data_subjects')}
              </a>
            )}
          </div>
          <button
            className="btn btn-default"
            data-toggle="tooltip"
            title={I18n.t('processing_registers.add_data_subject')}
            onClick={toggleAddMode}
            type="button"
          >
            <i className="far fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

DataleakDataSubjects.propTypes = {
  dataleakDataSubjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data_subject_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dataSubjects: PropTypes.array.isRequired,
};

export default DataleakDataSubjects;
