import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import TaskStore from 'stores/task-store';

import TableGrid from './TableGrid';

class DeviationTasks extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    const tasks = this.addUrlToTasks(props.tasks);
    TaskStore.setTasks(tasks);
    this.state = { tasks };
  }

  componentDidMount() {
    TaskStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    TaskStore.removeChangeListener(this.onChange);
  }

  onChange() {
    const tasks = this.addUrlToTasks(TaskStore.getAll());
    this.setState({ tasks });
  }

  addUrlToTasks(tasks) {
    if (this.props.allow_open) {
      return tasks.map(task => ({ ...task, url: `${location.pathname}/tasks/${task.id}`}));
    } else {
      return tasks;
    }
  }

  columns() {
    return [{
      header: I18n.t('activerecord.attributes.task.id'),
      accessor: 'id'
    }, {
      header: I18n.t('activerecord.attributes.task.name'),
      accessor: 'name'
    }, {
      header: I18n.t('activerecord.attributes.task.staff_id'),
      accessor: 'assigned_to_name'
    }, {
      header: I18n.t('activerecord.attributes.task.status_id'),
      accessor: 'status_name'
    }];
  }

  render() {
    return(
      <div className="card">
        <TableGrid
          columns={this.columns()}
          records={this.state.tasks}
          url={this.props.allow_open ? 'url' : 'undefined'}
        />
      </div>
    );
  }
}

export default DeviationTasks;
