class TimeManual {
  constructor(form) {
    this.form = $(form);
    this.onDocumentCLickEventBind = this.onDocumentClick.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    this.form.find('[data-action="datetimepicker"]').on('click', this.onDateClickEvent.bind(this));
    this.form.find('[name="date"]').on('dp.change', this.onDateChangeEvent.bind(this));
  }

  onDateClickEvent(event) {
    event.stopPropagation();
    this.form.find('[name="date"]').data('DateTimePicker').show();
    $(document).on('click', this.onDocumentCLickEventBind);
  }

  onDocumentClick(event) {
    if ($(event.target).parents('.bootstrap-datetimepicker-widget').length === 0) {
      $(document).off('click', this.onDocumentCLickEventBind);
      this.form.find('[name="date"]').data('DateTimePicker').hide();
    }
  }

  onDateChangeEvent(event) {
    this.form.submit();
  }
}

$(document).on('turbolinks:load', () => {
  $('[data-form="time_manual"]').each((i, f) => { new TimeManual(f); });
});
