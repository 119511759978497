$(document).on('turbolinks:load', () => {
  const navItems = $('ul.nav.nav-pills.nav-stacked li a').filter((i, navItem) => {
    return (
      location.pathname.match('^' + navItem.pathname + '(/|$)') !== null
      || (
        navItem.dataset.nav && navItem.dataset.nav.split(',').filter((nav) => {
          return location.pathname.match('^' + nav + '(/|$)') !== null;
        }).length
      )
    );
  });

  if (navItems.length) {
    navItems.parent('li').addClass('active');
  } else {
    $('ul.nav.nav-pills.nav-stacked li').first().addClass('active');
  }
});
