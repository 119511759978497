import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AuditConstants from 'constants/audit-constants';
import { auditDispatcher } from 'dispatcher/audit-dispatcher';

import NormCheckbox from './NormCheckbox';
import ManualChapterCheckbox from './ManualChapterCheckbox';

class ManualAudit extends React.Component {
  onManualChange(payLoad) {
    let manual_chapter_ids = [].concat(this.props.audit.manual_chapter_ids);
    let manual_paragraph_ids = this.props.audit.manual_paragraph_ids;

    switch (payLoad.actionType) {
      case 'manual-chapter':
        if (payLoad.checked) {
          manual_chapter_ids = [].concat(this.props.audit.manual_chapter_ids, [
            payLoad.manual_chapter_id,
          ]);
          manual_paragraph_ids = []
            .concat(
              this.props.audit.manual_paragraph_ids,
              this.props.manual_chapters
                .find((x) => x.id === payLoad.manual_chapter_id)
                .manual_paragraphs.map((x) => x.id),
            )
            .unique();
        } else {
          manual_chapter_ids = this.props.audit.manual_chapter_ids.filter(
            (x) => x != payLoad.manual_chapter_id,
          );
          const ids = this.props.manual_chapters
            .find((x) => x.id === payLoad.manual_chapter_id)
            .manual_paragraphs.map((x) => x.id);
          manual_paragraph_ids = this.props.audit.manual_paragraph_ids.filter(
            (x) => !ids.includes(x),
          );
        }
        break;
      case 'manual-paragraph':
        if (payLoad.checked) {
          manual_paragraph_ids = [].concat(
            this.props.audit.manual_paragraph_ids,
            [payLoad.manual_paragraph_id],
          );

          if (
            this.props.manual_chapters.find(
              (x) => x.id === payLoad.manual_chapter_id,
            ).manual_paragraphs.length === manual_paragraph_ids.length
          ) {
            manual_chapter_ids = [].concat(
              this.props.audit.manual_chapter_ids,
              [payLoad.manual_chapter_id],
            );
          }
        } else {
          manual_chapter_ids = this.props.audit.manual_chapter_ids.filter(
            (x) => x != payLoad.manual_chapter_id,
          );
          manual_paragraph_ids = this.props.audit.manual_paragraph_ids.filter(
            (x) => x != payLoad.manual_paragraph_id,
          );
        }
    }

    const audit = Object.assign({}, this.props.audit, {
      manual_chapter_ids: manual_chapter_ids,
      manual_paragraph_ids: manual_paragraph_ids,
    });

    auditDispatcher.handleUpdateAuditAction({
      actionType: AuditConstants.AUDIT_UPDATE,
      audit: audit,
    });
  }

  onNormChange(payLoad) {
    let norm_ids = [].concat(this.props.audit.norm_ids);

    if (payLoad.checked) {
      norm_ids = [].concat(this.props.audit.norm_ids, [payLoad.norm_id]);
    } else {
      norm_ids = this.props.audit.norm_ids.filter((x) => x != payLoad.norm_id);
    }

    const audit = { ...this.props.audit, norm_ids };

    auditDispatcher.handleUpdateAuditAction({
      actionType: AuditConstants.AUDIT_UPDATE,
      audit: audit,
    });
  }

  renderValidation(errors, attribute) {
    return (
      <div className="alert alert-danger">
        {errors.map((error) => {
          return `${I18n.t(
            `activemodel.attributes.audit.${attribute}`,
          )} ${error}`;
        })}
      </div>
    );
  }

  renderNorm(norm, index) {
    return (
      <NormCheckbox
        key={`norm-${index}`}
        index={this.props.index}
        formName={this.props.formName}
        norm={norm}
        norm_ids={this.props.audit.norm_ids}
        onChange={this.onNormChange.bind(this)}
      />
    );
  }

  renderManualChapter(manual_chapter, index) {
    return (
      <ManualChapterCheckbox
        key={`manual-chapter-${index}`}
        index={this.props.index}
        formName={this.props.formName}
        manual_chapter={manual_chapter}
        manual_chapter_ids={this.props.audit.manual_chapter_ids}
        manual_paragraph_ids={this.props.audit.manual_paragraph_ids}
        onChange={this.onManualChange.bind(this)}
      />
    );
  }

  render() {
    return (
      <div key={`audit-${this.props.index}`} className="col-md-6 col-lg-4">
        <div className="card">
          <div className="form-group">
            <label
              htmlFor={`${this.props.formName.replace(/[[]]/g, '_')}_name`}
            >
              {I18n.t('activemodel.attributes.audit.name')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={this.props.audit.name}
              name={`${this.props.formName}[name]`}
              id={`${this.props.formName.replace(/[[]]/g, '_')}_name`}
            />
            {this.props.audit.errors.name !== undefined
              ? this.renderValidation(this.props.audit.errors.name, 'name')
              : null}
          </div>
          <div className="form-group">
            <label
              htmlFor={`${this.props.formName.replace(
                /[[]]/g,
                '_',
              )}_description`}
            >
              {I18n.t('activemodel.attributes.audit.description')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={this.props.audit.description}
              name={`${this.props.formName}[description]`}
              id={`${this.props.formName.replace(/[[]]/g, '_')}_description`}
            />
            {this.props.audit.errors.description !== undefined
              ? this.renderValidation(
                this.props.audit.errors.description,
                'name',
              )
              : null}
          </div>

          <div className="row">
            <div className="col-sm-6">
              <h3>{I18n.t('components.manual_audit.norms')}</h3>
              {this.props.norms.map(this.renderNorm.bind(this))}
            </div>
            <div className="col-sm-6">
              <h3>{I18n.t('components.manual_audit.manual_chapters')}</h3>
              {this.props.manual_chapters.map(
                this.renderManualChapter.bind(this),
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualAudit;
