import React, { useReducer, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import { request } from '../actions/index';
import { reducer } from '../reducer/index';

import DataleakDataOption from './DataleakDataOption';

const graphql = `mutation DataOption($name: String!) {
  createDataOption(input: { name: $name }) {
    dataOption { id name }
    errors { field messages }
  }
}`;

const DataleakDataOptions = ( props ) => {
  const dataleakDataOptions = props.dataleakDataOptions.length ? props.dataleakDataOptions : [{ uid: Date.now() }];

  const container = useRef(null);
  const [name, setName] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    dataleakDataOptions,
    dataOptions: props.dataOptions,
    errors: [],
    addMode: false,
  });

  const onAdd = () => {
    dispatch({ type: 'update', payload: { dataleakDataOptions: [...state.dataleakDataOptions, { uid: Date.now() }] } });
  };

  const onChange = (dataleakDataOption) => {
    const mapFn = (p) => (
      (p.id !== undefined && p.id === dataleakDataOption.id) || (p.uid !== undefined && p.uid === dataleakDataOption.uid) ? dataleakDataOption : p );
    dispatch({ type: 'update' , payload: { dataleakDataOptions: state.dataleakDataOptions.map(mapFn) } });
  };

  const onDelete = (dataleakDataOption) => {
    const filterFn = (p) => (p.uid === undefined || p.uid !== dataleakDataOption.uid);
    const mapFn = (p) => (p.id !== undefined && p.id === dataleakDataOption.id ? { ...p, destroy: true } : p);

    dispatch({ type: 'update' , payload: { dataleakDataOptions: state.dataleakDataOptions.filter(filterFn).map(mapFn) } });
  };

  const toggleAddMode = () => {
    dispatch({ type: 'update' , payload: { addMode: true } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const [response] = await request(graphql, { name });
    const { dataOption, errors } = response.createDataOption;

    if (errors.length) {
      dispatch({ type: 'update', payload: { errors } });
    } else {
      const dataleakDataOption = { uid: Date.now(), data_option_id: dataOption.id };

      dispatch({
        type: 'update',
        payload: {
          dataleakDataOptions: [...state.dataleakDataOptions.filter(d => d.data_option_id !== undefined), {...dataleakDataOption, data_option_id: parseInt(dataleakDataOption.data_option_id)}],
          dataOptions: [...state.dataOptions, { ...dataOption, id: parseInt(dataOption.id)}],
          errors: [],
          addMode: false,
        },
      });
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const renderDataleakDataOption = (dataleakDataOption) => {
    if (dataleakDataOption.destroy) {
      const formName = `deviation[dataleak_data_options_attributes][${dataleakDataOption.id}]`;

      return(
        <div>
          <input name={`${formName}[id]`} value={dataleakDataOption.id} type="hidden" />
          <input name={`${formName}[_destroy]`} value={dataleakDataOption.destroy} type="hidden" />
        </div>
      );
    }

    return(
      <DataleakDataOption
        key={dataleakDataOption.id || dataleakDataOption.uid}
        dataOptions={state.dataOptions}
        onChange={onChange}
        dataleakDataOption={dataleakDataOption}
        onDelete={onDelete}
      />
    );
  };

  return(
    <div ref={container}>
      <label>{I18n.t('processing_registers.description_data_name')}</label>
      <div>
        {state.dataleakDataOptions.map(renderDataleakDataOption)}

        {state.addMode && (
          <form onSubmit={onSubmit}>
            <div className='d-flex form-group justify-content-between'>
              <input
                className='form-control'
                name='name'
                onChange={e => setName(e.target.value)}
                placeholder={I18n.t('processing_registers.placeholders.new_option')}
                value={state.name}
                type='text'
              />
              <button className='btn btn-primary ml-2'>
                {I18n.t('deviations.save')}
              </button>
            </div>
            {state.errors.length > 0 && <div className='alert alert-danger'>{state.errors[0].messages[0]}</div>}
          </form>
        )}
        <div className='d-flex justify-content-between'>
          <div className='buttons-left'>
            <button className='btn btn-default' onClick={onAdd} type="button">
              <i className="far fa-plus"></i> {I18n.t('processing_registers.add_data_option_link')}
            </button>
            { props.deviationId &&
              <a className='btn btn-link' href={"./" + props.deviationId + "/data_options"}>
                <i className="far fa-wrench"></i> {I18n.t('deviations.manage_data_options')}
              </a>
            }
            </div>
          <button className="btn btn-default" data-toggle='tooltip' title={I18n.t('processing_registers.add_data_option')} onClick={toggleAddMode} type="button">
            <i className="far fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

DataleakDataOptions.propTypes = {
  dataleakDataOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      data_option_id: PropTypes.number.isRequired
    }),
  ).isRequired,
  dataOptions: PropTypes.array.isRequired,
};

export default DataleakDataOptions;
