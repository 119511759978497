import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';
import moment from 'moment';

import Filtering from './Filtering';
import Pagination from './Pagination';
import ProcessingRegister from './ProcessingRegister';

const complaintOptions = [
  {
    id: 'true',
    name: I18n.t('processing_registers.compliant_opts.true'),
    position: 0,
  },
  {
    id: 'false',
    name: I18n.t('processing_registers.compliant_opts.false'),
    position: 1,
  },
];

class ProcessingRegisters extends React.Component {
  constructor(props) {
    super(props);

    this.sessionStorageKey = 'processing_registers_filters_' + this.props.organisationId;

    this.onSortClick = this.onSortClick.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.fetchExcel = this.fetchExcel.bind(this);

    let activeFilters = {};
    this.filters = {};

    if (window.sessionStorage) {
      activeFilters = JSON.parse(window.sessionStorage.getItem(this.sessionStorageKey));

      if (activeFilters === null) {
        this.filters = {
          manual_paragraphs: [],
          lawfulness_of_processing: [],
          compliant: [],
          business_unit_ids: [],
          member_id: [],
          responsible_for_processing: [],
        };
        activeFilters = {};
      } else {
        [
          'manual_paragraphs',
          'lawfulness_of_processing',
          'compliant',
          'member_id',
          'business_unit_ids',
          'responsible_for_processing',
        ].forEach((key) => (this.filters[key] = activeFilters[key] || []));
      }
    }

    this.state = {
      page: activeFilters.page || 1,
      sort: activeFilters.sort || 'id',
      sortAsc: activeFilters.sortAsc || true,
      processing_registers: [],
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.fetchRegisters();
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      html: true,
    });
  }

  fetchExcel(event) {
    event.preventDefault();
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    };
    this.fetching(options)
      .then((r) => r.blob())
      .then((blob) => {
        const filename = I18n.t('components.processing_registers.export_filename', {
          date: moment().format('YYYYMMDD'),
          ext: 'xlsx',
        });
        window.downloadBlob(blob, filename);
      });
  }

  fetchRegisters() {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    };

    this.fetching(options)
      .then((r) => r.json())
      .then((data) => {
        if (this.state.page <= data.query.totalPages) {
          let state = {
            processing_registers: data.query.processing_registers,
            totalPages: data.query.totalPages,
          };
          this.setState(state);
        } else {
          this.setState({ page: data.query.totalPages }, this.fetchRegisters);
        }
      });

    const filters = {
      ...this.filters,
      page: this.state.page,
      sort: this.state.sort,
      sortAsc: this.state.sortAsc,
    };
    window.sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(filters));
  }

  fetching(options) {
    const url = new URL('/processing_registers', `${location.protocol}//${location.host}`);

    url.searchParams.append('page', this.state.page);
    url.searchParams.append('sort', this.state.sort);
    url.searchParams.append('asc', this.state.sortAsc ? 'asc' : 'desc');
    this.filters.manual_paragraphs.forEach((m) => url.searchParams.append('manual_paragraph_ids[]', m));
    this.filters.lawfulness_of_processing.forEach((l) => url.searchParams.append('lawfulness_of_processing[]', l));
    this.filters.member_id.forEach((m) => url.searchParams.append('member_id[]', m));
    this.filters.business_unit_ids.forEach((bu) => url.searchParams.append('business_unit_ids[]', bu));
    this.filters.responsible_for_processing.forEach((r) => url.searchParams.append('responsible_for_processing[]', r));
    this.filters.compliant.forEach((c) => url.searchParams.append('compliant[]', c));
    return fetch(url, options);
  }

  onFilterChange(name, items) {
    this.filters = { ...this.filters, [name]: items };
    this.fetchRegisters();
  }

  onSortClick(event) {
    event.preventDefault();
    if (this.state.sort === event.target.dataset.sort) {
      this.setState((prevState) => ({ sortAsc: !prevState.sortAsc }), this.fetchRegisters);
    } else {
      this.setState({ sort: event.target.dataset.sort, sortAsc: true }, this.fetchRegisters);
    }
  }

  onPageClick(page) {
    $.scrollTo($('[data-react-class="ProcessingRegister"]'), 75);
    this.setState({ page }, this.fetchRegisters);
  }

  render() {
    const { processing_registers, totalPages, page, sort, sortAsc } = this.state;
    const { allow_create, manual_paragraphs, lawfulness_of_processing, business_units, responsible_for_processing } =
      this.props;
    const options = { scope: 'components.processing_registers' };
    const click = () => Turbolinks.visit('/processing_registers/new');
    const clickDataOptions = () => Turbolinks.visit('/processing_registers/data_options');
    const clickDataSubjects = () => Turbolinks.visit('/processing_registers/data_subjects');

    return (
      <React.Fragment>
        <div className="heading">
          <h1 className="heading-title">{I18n.t('page_title', options)}</h1>

          <ul className="list-links">
            <li>
              <form
                className="form-inline"
                action={`${location.pathname}.xlsx`}
                target="_blank"
                onSubmit={this.fetchExcel}
              >
                <button
                  type="submit"
                  className="btn btn-link"
                  data-toggle="tooltip"
                  title={I18n.t('processing_registers.export_excel')}
                  data-placement="bottom"
                >
                  <i className="far fa-file-excel"></i>
                </button>
              </form>
            </li>
            <Filtering
              initialState={this.filters.manual_paragraphs}
              onChange={this.onFilterChange}
              name="manual_paragraphs"
              items={manual_paragraphs}
            />
            <Filtering
              initialState={this.filters.business_unit_ids}
              onChange={this.onFilterChange}
              name="business_unit_ids"
              items={business_units}
            />
            <Filtering
              initialState={this.filters.responsible_for_processing}
              onChange={this.onFilterChange}
              name="responsible_for_processing"
              items={responsible_for_processing}
            />
            <Filtering
              initialState={this.filters.lawfulness_of_processing}
              onChange={this.onFilterChange}
              name="lawfulness_of_processing"
              items={lawfulness_of_processing}
            />
            <Filtering
              initialState={this.filters.compliant}
              onChange={this.onFilterChange}
              name="compliant"
              items={complaintOptions}
            />
          </ul>

          {allow_create && (
            <ul className="list-links ml-2">
              <li className="dropdown">
                <button className="btn btn-link dropdown-toggle" data-toggle="dropdown" type="button">
                  <i className="far fa-wrench"></i> {I18n.t('types', options)} <span className="caret"></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li>
                    <button className="btn btn-link" onClick={clickDataOptions}>
                      {I18n.t('data_options', options)}
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" onClick={clickDataSubjects}>
                      {I18n.t('data_subjects', options)}
                    </button>
                  </li>
                </ul>
              </li>
              <li>
                <button className="btn btn-link" onClick={click}>
                  <i className="far fa-plus"></i> {I18n.t('new_register', options)}
                </button>
              </li>
            </ul>
          )}
        </div>

        <div className="card">
          <div className="table-flex">
            <div className="head">
              <div className="flex-row">
                <div className="size-1">
                  <a
                    href="#"
                    className={`${sort === 'id' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="id"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('id', options)}
                  </a>
                </div>
                <div className="size-2 truncate">{I18n.t('name', options)}</div>
                <div className="size-2 truncate">{I18n.t('process', options)}</div>
                <div className="size-2 truncate">
                  <a
                    href="#"
                    className={`${sort === 'processing_aim' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="processing_aim"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('purpose', options)}
                  </a>
                </div>
                <div className="size-2 truncate">
                  <a
                    href="#"
                    className={`${sort === 'lawfulness_of_processing' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="lawfulness_of_processing"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('lawful', options)}
                  </a>
                </div>
                <div className="size-2 truncate">
                  <a
                    href="#"
                    className={`${sort === 'responsible_for_processing' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'}`}
                    data-sort="responsible_for_processing"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('responsible', options)}
                  </a>
                </div>
                <div className="size-1 truncate">
                  <a
                    href="#"
                    className={`${sort === 'compliant' ? 'sort' : ''} ${sortAsc ? 'asc' : 'desc'} `}
                    data-sort="compliant"
                    onClick={this.onSortClick}
                  >
                    {I18n.t('compliant', options)}
                  </a>
                </div>
              </div>
            </div>
            <div className="body">
              {processing_registers.map((p) => (
                <ProcessingRegister
                  key={p.id}
                  processing_register={p}
                  manual_paragraphs={manual_paragraphs}
                  privacy_officers={this.props.privacy_officers}
                />
              ))}
            </div>
          </div>
          <Pagination onPageClick={this.onPageClick} page={page} totalPages={totalPages} />
        </div>
      </React.Fragment>
    );
  }
}

ProcessingRegisters.propTypes = {
  organisationId: PropTypes.number.isRequired,
  allow_create: PropTypes.bool.isRequired,
  manual_paragraphs: PropTypes.array.isRequired,
  lawfulness_of_processing: PropTypes.array.isRequired,
};

export default ProcessingRegisters;
