import React, { useEffect, useRef, useState } from 'react';
import I18n from '../i18n-js/index.js.erb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';

import { request } from '../actions';

import TaskStore from '../stores/task-store';
import TableGrid from './TableGrid';

const graphql = `
  query ManualParagraphTasksQuery($textBlockIds: [ID!]!) {
    tasks(textBlockIds: $textBlockIds) {
      id name status textBlockId assignedTo { name }
    }
  }
`;

const ManualParagraphTasks = ({ textBlockIds, ...props }) => {
  const [{ loaded, tasks }, setTasks] = useState({ loaded: false, tasks: [] });
  const [isOpen, setIsOpen] = useState(false);

  const container = useRef();
  const options = { scope: 'components.manual_paragraph' };

  const fetchTasks = async () => {
    const [response, error] = await request(graphql, { textBlockIds });

    if (error === null) {
      const tasks = response.tasks.map((task) => ({
        ...task,
        url: `/manual/manual_chapters/${props.manualChapterId}/tasks/${task.id}`,
      }));
      setTasks({ loaded: true, tasks });
    }
  };

  const onDocumentClick = ({ target }) => {
    if (container.current.contains(target) && container.current !== target) {
      return;
    }
    setIsOpen(false);
  };

  const onClick = () => {
    if (loaded === false) {
      fetchTasks();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onDocumentClick);
    } else {
      document.removeEventListener('click', onDocumentClick);
    }
    return () => document.removeEventListener('click', onDocumentClick);
  }, [isOpen]);

  useEffect(() => {
    TaskStore.addChangeListener(onChange);
    return () => TaskStore.removeChangeListener(onChange);
  });

  const onChange = () => {
    const tasks = TaskStore.getAll()
      .filter((task) => textBlockIds.includes(task.textBlockId))
      .map((task) => ({
        ...task,
        url: `/manual/manual_chapters/${props.manualChapterId}/tasks/${task.id}`,
      }));
    setTasks({ loaded, tasks });
  };

  const columns = [
    {
      header: I18n.t('manuals.manual_chapters.show.tasks.id'),
      accessor: 'id',
    },
    {
      header: I18n.t('manuals.manual_chapters.show.tasks.name'),
      accessor: 'name',
    },
    {
      header: I18n.t('manuals.manual_chapters.show.tasks.assigned_to'),
      accessor: (r) => r.assignedTo && r.assignedTo.name,
    },
    {
      header: I18n.t('manuals.manual_chapters.show.tasks.status'),
      accessor: (r) => r.status && I18n.t(`tasks.status.${r.status}`),
    },
  ];

  return (
    <li className="re-dropdown" ref={container}>
      <button className="btn btn-link px-1" onClick={onClick} type="button">
        <FontAwesomeIcon icon={faCalendarCheck} />
      </button>
      <ul className="re-dropdown-menu" hidden={!isOpen}>
        <h6>{I18n.t('tasks.header', options)}</h6>
        <TableGrid columns={columns} records={tasks} />
        <li>
          <a
            href="#modal-nieuwe-taak"
            data-measure-type-id={props.measure_type_id}
            data-toggle="modal"
            data-manual-chapter-id={props.manualChapterId}
            data-manual-paragraph-id={props.manualParagraphId}
          >
            {I18n.t('tasks.new', options)}
          </a>
        </li>
      </ul>
    </li>
  );
};

export default ManualParagraphTasks;
