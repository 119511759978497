import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';

const isUpKey = isHotkey('up');
const isDownKey = isHotkey('down');
const isEnter = isHotkey('enter');

const AutoComplete = ({ items, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const input = useRef();

  const onBlur = () => {
    setVisible(false);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const onFocus = () => {
    setVisible(true);
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onKeyDown = (event) => {
    if (items.length === 0) return;

    if (isUpKey(event)) {
      event.preventDefault();

      if (selectedItem.id) {
        const index = items.findIndex(i => i.id === selectedItem.id);

        if (index > 0) {
          setSelectedItem(items[index - 1]);
        }
      } else {
        setSelectedItem(items[items.length - 1]);
      }
    } else if (isDownKey(event)) {
      event.preventDefault();

      if (selectedItem.id) {
        const index = items.findIndex(i => i.id === selectedItem.id);

        if (items.length > index + 1 ) {
          setSelectedItem(items[index + 1]);
        }
      } else {
        setSelectedItem(items[0]);
      }
    } else if (isEnter(event)) {
      event.preventDefault();
      if (selectedItem.id){
        onItemSelect(selectedItem);
      }
    }
  };

  const onItemSelect = (item) => {
    props.onItemSelect(item);
    input.current.blur();
  };

  useEffect(() => {
    setSelectedItem({});
  }, [items]);

  const renderItem = (item) => {
    const className = selectedItem.id === item.id ? 'active' : '';

    return(
      <li key={item.id} className={className} data-item={item.id}>
        <button onMouseDown={() => onItemSelect(item)} type="button">
          {props.displayFn ? props.displayFn(item) : item.name}
        </button>
      </li>
    );
  };

  return(
    <div className="auto-complete mb-3">
      <input
        autoComplete="off"
        className={props.className}
        id={props.id}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onChange={props.onChange}
        placeholder={props.placeholder}
        ref={input}
        type="text" 
        value={props.searchValue}
      />
      {props.children}
      <ul hidden={!visible}>
        {items.map(renderItem)}
      </ul>

    </div>
  );
};

AutoComplete.defaultProps = {
  className: 'form-control',
};

AutoComplete.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  displayFn: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.array.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onItemSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
};

export default AutoComplete;
