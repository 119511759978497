import React, { useEffect, useState } from 'react';
import { request, useQuery } from '../../actions';
import Documents from './documents';

const graphql = `
  query DocumentsConainterQuery {
    businessUnits { id name }
    classifications
    jobTitles { id name }
    staffs { id name }
  }
`;

const Container = (props) => {
  const [state, setState] = useState(null);
  useQuery(graphql, {}, setState);

  if (state === null) return null;

  return <Documents {...state} {...props} />;
};

export default Container;
