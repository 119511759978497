import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n-js/index.js.erb';

import { request } from '../../actions';

import Form from './form';
import QuestionList from './question-list';

const options = { scope: 'components.sa_questionnaire' };

const graphql = `
  query SaQuestionQuery($id: ID!) {
    saQuestionnaire(id: $id) {
      progress
      saAnswers {
        id
        answer
        saQuestion {
          questionFields
          saAnswerOptions { id answer score }
        }
      }
      selfAssessment { name description answerFieldName }
    }
  }
`;

const SaQuestionnaire = ({ id }) => {
  const [state, setState] = useState({
    progress: 0,
    saAnswer: null,
    saAnswers: [],
    selfAssessment: {},
  });

  const onChange = (saAnswer, progress) => {
    const saAnswers = state.saAnswers.map(a => a.id === saAnswer.id ? saAnswer : a);
    let nextSaAnswer = null;

    const index = saAnswers.findIndex(a => a.id === saAnswer.id) + 1;

    if (saAnswers.length > index) {
      nextSaAnswer = saAnswers[index];
    }

    setState({
      ...state,
      progress,
      saAnswers,
      saAnswer: nextSaAnswer,
    });
  };

  const setSaAnswer = (saAnswer) => {
    setState({ ...state, saAnswer });
  };

  useEffect(() => {
    scrollTo(0, 0);
  }, [state.saAnswer]);

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const [response, error] = await request(graphql, { id }, controller);

      if (error === null) {
        let { progress, saAnswers, selfAssessment } = response.saQuestionnaire;
        saAnswers = saAnswers.map(a => ({
          ...a,
          saQuestion: {
            ...a.saQuestion,
            questionFields: a.saQuestion.questionFields.sort((a, b) => a.position - b.position),
          },
        }));
        const saAnswer = saAnswers.find(a => a.answer === null);
        setState({ progress, saAnswer, saAnswers, selfAssessment });
      }
    })();

    return () => controller.abort();
  }, [id]);

  return(
    <>
      <h1>{state.selfAssessment.name}</h1>
      <p>{state.selfAssessment.description}</p>
      <div className="row">
        <div className="col-sm-8">
          <div className="card">
            {state.saAnswer ? (
              <Form
                key={state.saAnswer.id}
                saAnswer={state.saAnswer}
                onChange={onChange}
                selfAssessment={state.selfAssessment}
              />
            ) : (
              <p>{I18n.t('confirmation', options)}</p>
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <QuestionList
            progress={state.progress}
            saAnswer={state.saAnswer}
            saAnswers={state.saAnswers}
            saQuestionnaire={state.saQuestionnaire}
            setSaAnswer={setSaAnswer}
          />
        </div>
      </div>
    </>
  );
};

SaQuestionnaire.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SaQuestionnaire;
