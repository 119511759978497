class Answers {
  constructor(container) {
    this.container = $(container);
    this.bindEvents();
  }

  bindEvents(){
    this.container.on('click', '[data-action="add"]', this.onAddAnswerClickEvent.bind(this));
    this.container.on('click', '[data-action="delete"]', this.onDeleteAnswerClickEvent.bind(this));
  }

  onAddAnswerClickEvent(event) {
    event.preventDefault();

    const template = $('#answer_template').html().replace(/new_association/g, Date.now());
    $(event.target).parents('.form-group').before(template);

    const positionElements = event.target.parentNode.parentNode.querySelectorAll('[data-element="position"]');
    const size = positionElements.length - 1;

    positionElements[size].value = size;
  }

  onDeleteAnswerClickEvent(event) {
    event.preventDefault();
    const el = $(event.target).parents('.form-group').parent();

    if (el.prev().data('element') === 'destroy') {
      el.prev().val(1);
    }
    el.remove();
  }
}

$(document).on('turbolinks:load', () => {
  $('[data-container="answers"]').each((_, container) => {
    new Answers(container);
  });
});
