import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import NormExplanationModal from './NormExplanationModal';

class ReferenceTable extends React.Component {
  constructor(props) {
    super(props);
    this.form = document.querySelector('.content form');

    this.onEditClickEvent = this.onEditClickEvent.bind(this);
    this.onModalChangeEvent = this.onModalChangeEvent.bind(this);
    this.onReasonChangeEvent = this.onReasonChangeEvent.bind(this);
    this.renderManualParagraphName = this.renderManualParagraphName.bind(this);
    this.renderManualParagraphNr = this.renderManualParagraphNr.bind(this);
    this.renderManualParagraphStatus = this.renderManualParagraphStatus.bind(this);
    this.renderRow = this.renderRow.bind(this);

    const norm_explanations = props.norm_paragraphs
      .filter((np) => np.norm_explanation)
      .map((np) => np.norm_explanation);

    this.state = {
      edit_mode: false,
      norm_explanations,
    };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editMode === false && this.state.editMode) {
      $('[data-toggle="tooltip"]').tooltip();
      $('[data-toggle="popover"]').popover();
    }
  }

  onEditClickEvent(event) {
    event.preventDefault();
    this.setState({
      edit_mode: !this.state.edit_mode,
    });
  }

  onReasonChangeEvent(event) {
    const norm_explanations = this.state.norm_explanations.map((explanation) => {
      explanation = Object.assign({}, explanation);

      if (explanation.id == event.target.dataset.id) {
        if (event.target.type === 'checkbox') {
          explanation[event.target.dataset.name] = !explanation[event.target.dataset.name];
        } else {
          explanation[event.target.dataset.name] = event.target.value;
        }
      }
      return explanation;
    });

    this.setState({ norm_explanations }, this.persistState);
  }

  onModalChangeEvent(data) {
    const norm_explanations = this.state.norm_explanations.map((explanation) =>
      explanation.id === data.id ? { ...explanation, ...data } : explanation,
    );

    this.setState({ norm_explanations }, this.persistState);
  }

  persistState() {
    $.ajax({
      url: this.form.action,
      type: this.form.method,
      data: $(this.form).serialize(),
      dataType: 'json',
    });
  }

  renderRow(norm_paragraph, index) {
    const { can_read_norm } = this.props;
    const norm_explanation = this.state.norm_explanations.find(
      (x) => norm_paragraph.norm_explanation && norm_paragraph.norm_explanation.id === x.id,
    );

    return (
      <div key={`norm-paragraph-${index}`} className="flex-row">
        <div className="size-1">
          {can_read_norm ? <a href={norm_paragraph.url}>{norm_paragraph.number}</a> : norm_paragraph.number}
        </div>
        <div className="size-4">
          {can_read_norm ? (
            <a href={norm_paragraph.url}>
              <span className="truncate" title={norm_paragraph.name}>
                {norm_paragraph.name}
              </span>
            </a>
          ) : (
            norm_paragraph.name
          )}
        </div>
        <div className="size-1">
          <span>&nbsp;</span>
          {norm_paragraph.norm_link_organisations.map(this.renderManualParagraphNr)}
        </div>
        <div className="size-1">
          <span>&nbsp;</span>
          {norm_paragraph.norm_link_organisations.map(this.renderManualParagraphStatus)}
        </div>
        <div className="size-4">
          <span>&nbsp;</span>
          {norm_paragraph.norm_link_organisations.map(this.renderManualParagraphName)}
        </div>
        <div className="size-3">
          {norm_explanation &&
            (this.state.edit_mode
              ? this.renderEditReasons(norm_explanation)
              : this.renderViewReasons(norm_explanation))}
        </div>
      </div>
    );
  }

  renderManualParagraphNr(norm_link_organisation) {
    return (
      <div key={`nr-${norm_link_organisation.id}`}>
        <a href={norm_link_organisation.manual_paragraph.url}>{norm_link_organisation.manual_paragraph.number}</a>
      </div>
    );
  }

  renderManualParagraphStatus(norm_link_organisation) {
    return (
      <div
        key={`status-${norm_link_organisation.id}`}
        className={`status status-${norm_link_organisation.manual_paragraph.status_name}`}
      >
        {norm_link_organisation.manual_paragraph.status_name === 'concept' ? (
          <i className="fal fa-square"></i>
        ) : (
          <i className="fas fa-check-square"></i>
        )}
      </div>
    );
  }

  renderManualParagraphName(norm_link_organisation) {
    return (
      <a key={`name-${norm_link_organisation.id}`} href={norm_link_organisation.manual_paragraph.url}>
        <span className="truncate" title={norm_link_organisation.manual_paragraph.name}>
          {norm_link_organisation.manual_paragraph.name}
        </span>
      </a>
    );
  }

  renderEditReasons(norm_explanation) {
    const formName = `reference_table[norm_explanations][${norm_explanation.id}]`;

    return (
      <div className="reason-connection">
        <input type="hidden" name={`${formName}[id]`} value={norm_explanation.id} />
        <div className="size-1">
          <input type="hidden" name={`${formName}[reason_law]`} value="0" />
          <input
            type="checkbox"
            onChange={this.onReasonChangeEvent}
            name={`${formName}[reason_law]`}
            value={norm_explanation.reason_law ? 1 : 0}
            checked={norm_explanation.reason_law}
            data-id={norm_explanation.id}
            data-name="reason_law"
          />
        </div>
        <div className="size-1">
          <input type="hidden" name={`${formName}][reason_contract]`} value="0" />
          <input
            type="checkbox"
            onChange={this.onReasonChangeEvent}
            name={`${formName}][reason_contract]`}
            value={norm_explanation.reason_contract ? 1 : 0}
            checked={norm_explanation.reason_contract}
            data-id={norm_explanation.id}
            data-name="reason_contract"
          />
        </div>
        <div className="size-1">
          <input type="hidden" name={`${formName}][reason_best_practices]`} value="0" />
          <input
            type="checkbox"
            onChange={this.onReasonChangeEvent}
            name={`${formName}][reason_best_practices]`}
            value={norm_explanation.reason_best_practices ? 1 : 0}
            checked={norm_explanation.reason_best_practices}
            data-id={norm_explanation.id}
            data-name="reason_best_practices"
          />
        </div>
        <div className="size-1">
          <input type="hidden" name={`${formName}][reason_risk]`} value="0" />
          <input
            type="checkbox"
            onChange={this.onReasonChangeEvent}
            name={`${formName}][reason_risk]`}
            value={norm_explanation.reason_risk ? 1 : 0}
            checked={norm_explanation.reason_risk}
            data-id={norm_explanation.id}
            data-name="reason_risk"
          />
        </div>
        <div className="size-1">
          <NormExplanationModal
            formName={formName}
            norm_explanation={norm_explanation}
            event={this.onModalChangeEvent}
          />
        </div>
      </div>
    );
  }

  renderViewReasons(norm_explanation) {
    return (
      <div className="reason-connection">
        <div className="size-1">
          {norm_explanation.reason_law ? <i className="fa fa-check" aria-hidden="true"></i> : null}
        </div>
        <div className="size-1">
          {norm_explanation.reason_contract ? <i className="fa fa-check" aria-hidden="true"></i> : null}
        </div>
        <div className="size-1">
          {norm_explanation.reason_best_practices ? <i className="fa fa-check" aria-hidden="true"></i> : null}
        </div>
        <div className="size-1">
          {norm_explanation.reason_risk ? <i className="fa fa-check" aria-hidden="true"></i> : null}
        </div>
        <div className="size-1">
          {norm_explanation.content && (
            <a
              className="btn btn-link px-1 py-0"
              data-container="body"
              data-content={norm_explanation.content}
              data-html="true"
              data-placement="left"
              data-toggle="popover"
              data-trigger="focus"
              role="button"
              tabIndex="0"
            >
              {norm_explanation.has_deviated ? (
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              ) : (
                <i className="far fa-info-circle" aria-hidden="true"></i>
              )}
            </a>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.allow_edit_mode ? (
          <ul className="list-links text-right">
            <li>
              <button className="btn btn-link" onClick={this.onEditClickEvent} type="button">
                {this.state.edit_mode
                  ? I18n.t('reference_tables.show.view_mode')
                  : I18n.t('reference_tables.show.edit_mode')}
              </button>
            </li>
          </ul>
        ) : null}
        <div className="card">
          <div className="table-responsive">
            <div className="table-flex table table-striped reference-tables" data-fixed="container">
              <div className="head" data-fixed="header">
                <div className="flex-row">
                  <div className="size-1">{I18n.t('reference_tables.show.table.id')}</div>
                  <div className="size-4">{I18n.t('reference_tables.show.table.norm')}</div>
                  <div className="size-1">{I18n.t('reference_tables.show.table.id')}</div>
                  <div className="size-1">{I18n.t('reference_tables.show.table.status')}</div>
                  <div className="size-4">{I18n.t('reference_tables.show.table.manual')}</div>
                  <div className="size-3">
                    <span>{I18n.t('reference_tables.show.table.reason_link')}</span>
                    <div className="reason-connection">
                      <div className="size-1">
                        <span
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={I18n.t('reference_tables.show.table.law')}
                        >
                          {I18n.locale === 'nl' ? 'W' : 'L'}
                        </span>
                      </div>
                      <div className="size-1">
                        <span
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={I18n.t('reference_tables.show.table.contract')}
                        >
                          C
                        </span>
                      </div>
                      <div className="size-1">
                        <span
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={I18n.t('reference_tables.show.table.best_practices')}
                        >
                          B
                        </span>
                      </div>
                      <div className="size-1">
                        <span
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={I18n.t('reference_tables.show.table.risk')}
                        >
                          R
                        </span>
                      </div>
                      <div className="size-1">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body">{this.props.norm_paragraphs.map(this.renderRow)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReferenceTable.propTypes = {
  allow_edit_mode: PropTypes.bool.isRequired,
  can_read_norm: PropTypes.bool.isRequired,
  norm_paragraphs: PropTypes.arrayOf(
    PropTypes.shape({
      norm_explanation: PropTypes.shape({
        content: PropTypes.string,
        has_deviated: PropTypes.bool.isRequired,
      }),
    }),
  ).isRequired,
};

export default ReferenceTable;
