import Dispatcher from './dispatcher';

class QuestionnaireDispatcher extends Dispatcher {
  handleCreateQuestionnaireAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const questionnaireDispatcher = new QuestionnaireDispatcher();
