import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

const ProcessLink = ({ onChange, processLink, manualParagraphs, ...props }) => {
  const formName = `processing_register[process_links_attributes][${
    processLink.id || processLink.uid
  }]`;
  const manualParagraph = manualParagraphs.find(
    (p) => p.id === processLink.manual_paragraph_id,
  );

  const onManualParagraphChange = ({ target: { value } }) => {
    onChange({
      ...processLink,
      manual_paragraph_id: parseInt(value),
      resource_id: null,
    });
  };

  const onResourceChange = ({ target: { value } }) => {
    onChange({ ...processLink, resource_id: parseInt(value) });
  };

  return (
    <div className="row">
      <div className="col-sm-6 d-flex form-group">
        <select
          className="form-control"
          name={`${formName}[manual_paragraph_id]`}
          onChange={onManualParagraphChange}
          value={processLink.manual_paragraph_id || ''}
        >
          <option disabled={true} value="">
            {I18n.t('processing_registers.select_process')}
          </option>
          {manualParagraphs.map((p) => (
            <option key={p.id} value={p.id}>
              {p.number} {p.name}
            </option>
          ))}
        </select>
        <input name={`${formName}[id]`} value={processLink.id} type="hidden" />
      </div>
      <div className="col-sm-6 form-group">
        {manualParagraph ? (
          <div className="d-flex">
            <select
              className="form-control"
              disabled={manualParagraph.resources.length === 0}
              name={`${formName}[resource_id]`}
              onChange={onResourceChange}
              value={processLink.resource_id || ''}
            >
              {manualParagraph.resources.length === 0 ? (
                <option disabled={true} value="">
                  {I18n.t('processing_registers.no_resources_available')}
                </option>
              ) : (
                <>
                  <option value=""></option>
                  {manualParagraph.resources.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
                </>
              )}
            </select>
            <button
              className="btn btn-link-danger"
              onClick={props.onDelete.bind(null, processLink)}
              type="button"
            >
              <i className="far fa-times"></i>
            </button>
          </div>
        ) : (
          <button
            className="btn btn-link-danger pull-right"
            onClick={props.onDelete.bind(null, processLink)}
            type="button"
          >
            <i className="far fa-times"></i>
          </button>
        )}
      </div>
    </div>
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.id && !props.uid) {
    return new Error(
      `One of 'id' or 'uid' is required by '${componentName}' component.`,
    );
  }
};

ProcessLink.propTypes = {
  onChange: PropTypes.func.isRequired,
  manualParagraphs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  processLink: PropTypes.shape({
    id: requiredPropsCheck,
    uid: requiredPropsCheck,
    manual_paragraph_id: PropTypes.number,
    resource_id: PropTypes.number,
  }).isRequired,
};

export default ProcessLink;
