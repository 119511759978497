import Turbolinks from 'turbolinks';

class TaskEffect {
  constructor(form) {
    this.form = form;

    this.onSubmit = this.onSubmit.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmit);
  }

  onSubmit(event) {
    event.preventDefault();
    $.ajax({
      url: this.form[0].action,
      type: this.form[0].method,
      data: new FormData(this.form[0]),
      processData: false,
      contentType: false,
      dataType: 'json',
      success: () => Turbolinks.visit(window.location)
    });
  }
}

$(document).on('turbolinks:load', () => {
  const form = $('[data-form=update-effect]');
  if (form) { new TaskEffect(form); }
});
