import Sortable from 'sortablejs';

$(document).on('turbolinks:load', () => {
  const list = document.getElementById('sa-question-list');
  if (list === null) return;

  Sortable.create(list, {
    animation: 100,
    onUpdate: () => {
      const questionList = Array.from(list.querySelectorAll('.item'));

      for (let i = 0; i < questionList.length; i++) {
       questionList[i].querySelector('[data-element="position"]').value = i;
       questionList[i].querySelector('.position').innerHTML = i + 1
      }
    },
  });
});
