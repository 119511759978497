import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import TableRowConstants from '../constants/table-row-constants';
import { tableRowDispatcher } from '../dispatcher/table-row-dispatcher';

import TableCell from './TableCell';
import TableRowModal from './TableRowModal';

class TableRow extends React.Component {
  constructor(props) {
    super(props);
    this.formName = `${props.formName}[table_rows_attributes][${props.row.id}]`;
  }

  onUpClickEvent(event) {
    event.preventDefault();
    this.props.rowUp(this.props.row);
  }

  onDownClickEvent(event) {
    event.preventDefault();
    this.props.rowDown(this.props.row);
  }

  onDestroyClickEvent(event) {
    event.preventDefault();

    if (confirm(I18n.t('text_blocks.edit.destroy_table_row_confirm'))) {
      tableRowDispatcher.handleChangeTableRowAction({
        actionType: TableRowConstants.TABLE_ROW_UPDATE,
        table_row: Object.assign(this.props.row, { destroy: true }),
      });
    }
  }

  renderCell(cell, formName, index) {
    return(
      <TableCell key={cell.id} cell={cell} formName={formName} />
    );
  }

  render() {
    const { row } = this.props;

    return(
      <tr className={row.destroy ? 'hidden' : ''}>
        {row.cells.map((c, i) => this.renderCell(c, this.formName, i))}
        <td>
          <div className="dropdown text-right">
            <button className="btn btn-default btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a href={`#modal-edit-row-${row.id}`} data-toggle="modal" data-target={`#modal-edit-row-${row.id}`}>
                  {I18n.t('text_blocks.edit.tables.edit')}
                </a>
              </li>
              <li className={this.props.first ? 'disabled' : ''}>
                <a href="#up" onClick={this.onUpClickEvent.bind(this)}>
                  {I18n.t('text_blocks.edit.tables.up')}
                </a>
              </li>
              <li className={this.props.last ? 'disabled' : ''}>
                <a href="#down" onClick={this.onDownClickEvent.bind(this)}>
                  {I18n.t('text_blocks.edit.tables.down')}
                </a>
              </li>
              <li className="divider"></li>
              <li>
                <a href="#delete" onClick={this.onDestroyClickEvent.bind(this)}>
                  {I18n.t('text_blocks.edit.tables.delete')}
                </a>
              </li>
            </ul>
          </div>

          {row.id ? <input type="hidden" name={`${this.formName}[id]`} value={row.id} /> : ''}
          <input type="hidden" name={`${this.formName}[order]`} value={row.order} />
          <input type="hidden" name={`${this.formName}[_destroy]`} value={row.destroy} />

          <TableRowModal row={row} columns={this.props.columns} />
        </td>
      </tr>
    );
  }
}

TableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  first: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  last: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    order: PropTypes.number.isRequired,
    destroy: PropTypes.bool,
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  rowDown: PropTypes.func.isRequired,
  rowUp: PropTypes.func.isRequired,
};

export default TableRow;
