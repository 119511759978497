import React from 'react';
import I18n from 'i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import DocumentForm from './DocumentForm';

class TaskDocumentForm extends React.Component {
  constructor(props) {
    super(props);

    this.onDocumentChange = this.onDocumentChange.bind(this);
    this.onDocumentDestroy = this.onDocumentDestroy.bind(this);
    this.onDocumentSubmit = this.onDocumentSubmit.bind(this);
    this.onDocumentFinalSubmit = this.onDocumentFinalSubmit.bind(this);

    this.state = {
      document: {...props.document, uid: props.document.id, showForm: true, errors: [] }
    };
  }

  onDocumentChange(attr) {
    this.setState(prevState => ({ document: {...prevState.document, ...attr} }));
  }

  onDocumentDestroy() {
    this.setState(prevState => ({ document: {...prevState.document, destroy: true }}));
  }

  onDocumentSubmit(event) {
    event.preventDefault();
    this.updateDocument(false);
  }

  onDocumentFinalSubmit(event) {
    event.preventDefault();
    this.updateDocument(true);
  }

  updateDocument(markedAsFinal) {
    const { task } = this.props;
    const doc = this.state.document;
    const url = `/tasks/${task.id}/document`;

    const formData = new FormData();
    formData.append('authenticity_token', document.querySelector('[name="csrf-token"]').content);
    formData.append('document[name]', doc.name);
    formData.append('document[classification]', doc.classification);
    formData.append('document[retention_period]', doc.retention_period);
    formData.append('document[revision_date]', doc.revision_date);
    formData.append('document[location]', doc.location);
    formData.append('document[staff_id]', doc.staff_id || '');
    doc.business_unit_ids.forEach(id => formData.append('document[business_unit_ids][]', id));
    doc.job_title_ids.forEach(id => formData.append('document[job_title_ids][]', id));

    if (doc.file) {
      formData.append('document[file]', doc.file);
    }

    if (markedAsFinal) {
      formData.append('document[mark_as_final]', '1');
    }

    const options = {
      body: formData,
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json'
      },
      method: doc.id ? 'PATCH' : 'POST'
    };

    fetch(url, options).then(r => r.json()).then(r => {
      if (r.status === 'OK') {
        Turbolinks.visit(`/tasks/${task.id}`);
      } else {
        this.setState(prevState => ({ document: {...prevState.document, errors: r.validation_errors } }));
      }
    });
  }

  render() {
    const scope = { scope: 'components.task_document_form' };
    const doc = this.state.document;
    const { business_units, classifications, job_titles, task, staffs } = this.props;

    if (doc.destroy) {
      return(
        <form action={`/tasks/${task.id}/document`} method="post">
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="delete" />
          <input
            name="authenticity_token"
            value={document.querySelector('[name="csrf-token"]').content}
            type="hidden"
          />
          <p>{I18n.t('destroy_confirmation', scope)}</p>
          <div className="form-group">
            <button className="btn btn-danger" data-disable-with={I18n.t('deleting', scope)}>
              {I18n.t('delete', scope)}
            </button>
          </div>
        </form>
      );
    } else {
      return(
        <form>
          <DocumentForm
            doc={doc}
            staffs={staffs}
            business_units={business_units || []}
            classifications={classifications}
            formName="document"
            job_titles={job_titles}
            can_mark_as_final={false}
            onChange={this.onDocumentChange}
            onDestroy={this.onDocumentDestroy}
            onSubmit={this.onDocumentSubmit}
            onFinalSubmit={this.onDocumentFinalSubmit}
            showRevisionDate={false}
          />
        </form>
      );
    }
  }
}

export default TaskDocumentForm;
