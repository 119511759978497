import moment from 'moment';

const defaultDocumentProps = {
  name: '',
  classification: 'public',
  errors: [],
  isFavorite: false,
  location: '',
  retentionPeriod: null,
  revisionDate: moment().add(1, 'year').format('YYYY-MM-DD'),
  status: 'concept',
  url: '',
  businessUnitIds: [],
  jobTitleIds: [],
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'request':
      return {
        ...state,
        ...payload,
        textBlock: {
          ...payload.textBlock,
          documents: payload.textBlock.documents.map((d) => ({
            ...d,
            errors: [],
          })),
        },
      };
    case 'newDocument': {
      const doc = {
        ...defaultDocumentProps,
        ...payload,
        type: 'activestorage',
      };
      return {
        ...state,
        newDocument: doc,
      };
    }
    case 'newDocumentLink': {
      const doc = {
        ...defaultDocumentProps,
        ...payload,
        type: 'url',
      };
      return {
        ...state,
        newDocument: doc,
      };
    }
    case 'addDocument': {
      return {
        ...state,
        newDocument: null,
        textBlock: {
          ...state.textBlock,
          documents: [...state.textBlock.documents, payload.document],
        },
      };
    }
    case 'updateDocument': {
      if (payload.document.id) {
        return {
          ...state,
          textBlock: {
            ...state.textBlock,
            documents: state.textBlock.documents.map((d) =>
              d.id === payload.document.id ? { ...d, ...payload.document } : d,
            ),
          },
        };
      } else {
        return {
          ...state,
          newDocument: { ...state.newDocument, ...payload.document },
        };
      }
    }
    case 'destroyDocument': {
      if (payload.id) {
        return {
          ...state,
          textBlock: {
            ...state.textBlock,
            documents: state.textBlock.documents.map((d) =>
              d.id === payload.id ? { ...d, _destroy: true } : d,
            ),
          },
        };
      } else {
        return {
          ...state,
          newDocument: null,
        };
      }
    }
    case 'uploadDocument': {
      if (payload.id) {
        return {
          ...state,
          textBlock: {
            ...state.textBlock,
            documents: state.textBlock.documents.map((d) =>
              d.id === payload.id ? { ...d, ...payload } : d,
            ),
          },
        };
      } else {
        return { ...state, newDocument: { ...state.newDocument, ...payload } };
      }
    }
    case 'changeValue': {
      return {
        ...state,
        textBlock: {
          ...state.textBlock,
          ...payload,
        },
      };
    }
    case 'newDocumentDrive': {
      return {
        ...state,
        newDocument: {
          ...defaultDocumentProps,
          ...payload,
          type: 'external',
        },
      };
    }
    case 'errors': {
      let {
        newDocument,
        textBlock: { documents },
      } = state;

      if (newDocument !== null) {
        newDocument = {
          ...newDocument,
          errors: payload.errors
            .filter((x) => x.field.startsWith(`documents.${documents.length}`))
            .map((error) => ({
              ...error,
              field: error.field.split('.').pop(),
            })),
        };
      }

      return {
        ...state,
        isSubmiting: false,
        errors: payload.errors,
        textBlock: {
          ...state.textBlock,
          documents: documents.map((doc, index) => ({
            ...doc,
            errors: payload.errors
              .filter((x, i) => x.field.startsWith(`documents.${0}`))
              .map((error) => ({
                ...error,
                field: error.field.split('.').pop(),
              })),
          })),
        },
        newDocument,
      };
    }
    case 'startSubmit':
      return { ...state, isSubmiting: true };
    case 'updateTable': {
      return {
        ...state,
        textBlock: {
          ...state.textBlock,
          tables: state.textBlock.tables.map((t) =>
            t.id === payload.table.id ? payload.table : t,
          ),
        },
      };
    }
    case 'commentErrors': {
      return { ...state, ...payload };
    }
    case 'addComment': {
      return {
        ...state,
        message: '',
        commentErrors: [],
        textBlock: {
          ...state.textBlock,
          comments: [...state.textBlock.comments, payload.comment],
        },
      };
    }
    case 'updateMessage': {
      return { ...state, ...payload };
    }
    default: {
      new Error(`Unkown action: ${type}`);
    }
  }
};
