import { request } from '../../actions/index'

const graphql = `
  query DeviationCategories($deviationType: String) {
    deviationCategories(deviationType: $deviationType) {
      id name
    }
  }
`;

class DeviationCategoriesGetter extends Getter {
  constructor() {
    super();
    this.bindEvents();
  }

  bindEvents() {
    $('[data-getter="deviation_categories"]').on('change', this.onDeviationTypeChangeEvent.bind(this));
  }

  onDeviationTypeChangeEvent (event) {
    const deviationType = event.target.value;
    this.fetchDeviationCategories(deviationType);
  }

  fetchDeviationCategories = async (deviationType) => {
    const selectElement = document.querySelector('[data-element=deviation_categories]')
    const [response] = await request(graphql, { deviationType: deviationType});
    if (response.deviationCategories.length > 0) {
      this.setOptionFields(selectElement, response.deviationCategories)
      $('[data-element=categories]').collapse('show')
    }
    else {
      $('[data-element=categories]').collapse('hide')
    }
  }
}

$(document).on('turbolinks:load', () => {
  if (document.querySelector('[data-getter="deviation_categories"]')) {
    new DeviationCategoriesGetter();
  }
});
