import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';

class AuthorizationFg extends React.Component {
  constructor(props) {
    super(props);

    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);

    this.state = {
      members: props.members
    };
  }

  addMember(_, id) {
    if (this.state.members.filter(m => m.is_po).length >= 10) {
      return;
    }

    this.setState(p => {
      return { members: p.members.map(m => m.id === id ?  { ...m, is_po: true } : m) };
    }, this.updateMembers);
  }

  removeMember(_, id) {
    this.setState(p => {
      return { members: p.members.map(m => m.id === id ?  { ...m, is_po: false } : m) };
    }, this.updateMembers);
  }

  updateMembers() {
    const url = '/organisation/authorizations';
    const options = {
      body: JSON.stringify({
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        authorization: {
          member_ids: this.state.members.filter(m => m.is_po).map(m => m.id),
          type: "is_po"
        }
      }),
      credentials: 'same-origin',
      headers: {
        'Accepts': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    };
    fetch(url, options);
  }

  formatMembers(member) {
    return {
      id: member.id,
      name: member.staff.name
    };
  }

  render() {
    const { members } = this.state;

    return(
      <div>
        <AutoComplete
          addValue={this.addMember}
          removeValue={this.removeMember}
          selectValues={members.filter(m => m.is_po).map(this.formatMembers)}
          values={members.filter(m => !m.is_po).map(this.formatMembers)}
        />
      </div>
    );
  }
}

export default AuthorizationFg;
