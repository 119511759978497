import React from 'react';
import I18n from '../i18n-js/index.js.erb';

import NormCheckbox from './NormCheckbox';
import ManualChapterCheckbox from './ManualChapterCheckbox';

class EditManualAudit extends React.Component {
  constructor(props) {
    super(props);
    this.formName = 'audit';

    this.state = {
      audit: props.audit,
      selected_questions: props.questions,
      avaliable_questions: [],
      searchForQuestions: false,
    };
  }

  componentDidMount() {
    this.getQuestions();
  }

  onManualChange(payLoad) {
    let manual_chapter_ids = [].concat(this.state.audit.manual_chapter_ids);
    let manual_paragraph_ids = this.state.audit.manual_paragraph_ids;

    switch (payLoad.actionType) {
      case 'manual-chapter':
        if (payLoad.checked) {
          manual_chapter_ids = [].concat(this.state.audit.manual_chapter_ids, [
            payLoad.manual_chapter_id,
          ]);
          manual_paragraph_ids = []
            .concat(
              this.state.audit.manual_paragraph_ids,
              this.props.manual_chapters
                .find((x) => x.id === payLoad.manual_chapter_id)
                .manual_paragraphs.map((x) => x.id),
            )
            .unique();
        } else {
          manual_chapter_ids = this.state.audit.manual_chapter_ids.filter(
            (x) => x != payLoad.manual_chapter_id,
          );
          const ids = this.props.manual_chapters
            .find((x) => x.id === payLoad.manual_chapter_id)
            .manual_paragraphs.map((x) => x.id);
          manual_paragraph_ids = this.state.audit.manual_paragraph_ids.filter(
            (x) => !ids.includes(x),
          );
        }
        break;
      case 'manual-paragraph':
        if (payLoad.checked) {
          manual_paragraph_ids = [].concat(
            this.state.audit.manual_paragraph_ids,
            [payLoad.manual_paragraph_id],
          );

          if (
            this.props.manual_chapters.find(
              (x) => x.id === payLoad.manual_chapter_id,
            ).manual_paragraphs.length === manual_paragraph_ids.length
          ) {
            manual_chapter_ids = [].concat(
              this.state.audit.manual_chapter_ids,
              [payLoad.manual_chapter_id],
            );
          }
        } else {
          manual_chapter_ids = this.state.audit.manual_chapter_ids.filter(
            (x) => x != payLoad.manual_chapter_id,
          );
          manual_paragraph_ids = this.state.audit.manual_paragraph_ids.filter(
            (x) => x != payLoad.manual_paragraph_id,
          );
        }
    }

    const audit = {
      ...this.state.audit,
      manual_chapter_ids,
      manual_paragraph_ids,
    };
    this.setState({ audit }, () => this.getQuestions());
  }

  onNormChange(payLoad) {
    let norm_ids = [].concat(this.state.audit.norm_ids);

    if (payLoad.checked) {
      norm_ids = [].concat(norm_ids, [payLoad.norm_id]);
    } else {
      norm_ids = norm_ids.filter((x) => x != payLoad.norm_id);
    }

    const audit = { ...this.state.audit, norm_ids };
    this.setState({ audit }, () => this.getQuestions());
  }

  getQuestions() {
    this.setState({
      avaliable_questions: [],
      searchForQuestions: true,
    });

    const data = {
      norm_ids: this.state.audit.norm_ids,
      manual_paragraph_ids: this.state.audit.manual_paragraph_ids,
    };

    if (this.request && this.request.readyState != 4) {
      this.request.abort();
    }

    this.request = $.ajax({
      url: `/audits/${this.state.audit.id}/questions`,
      data: { audit: data },
      dataType: 'JSON',
      success: this.onSuccess.bind(this),
    });
  }

  onSuccess({ audit_questions }) {
    const { selected_questions } = this.state;

    const avaliable_questions = audit_questions.filter(
      (audit_question) =>
        selected_questions.findIndex(
          (q) =>
            q.manual_paragraph_id === audit_question.manual_paragraph_id &&
            q.manuals_audit_question_id ===
              audit_question.manuals_audit_question_id,
        ) === -1,
    );

    this.setState({
      avaliable_questions: avaliable_questions.sortByKey('mp_position'),
      searchForQuestions: false,
    });
  }

  onAddQuestionEvent(event, question_id) {
    event.preventDefault();

    let question;
    const avaliable_questions = this.state.avaliable_questions
      .filter((q) => {
        if (q.manuals_audit_question_id === question_id) {
          question = q;
          return false;
        } else {
          return true;
        }
      })
      .sortByKey('mp_position');
    const selected_questions = [
      ...this.state.selected_questions,
      question,
    ].sortByKey('mp_position');

    this.setState({ avaliable_questions, selected_questions });
  }

  renderNorm(norm, index) {
    return (
      <NormCheckbox
        key={norm.id}
        formName={this.formName}
        norm={norm}
        norm_ids={this.state.audit.norm_ids}
        onChange={this.onNormChange.bind(this)}
      />
    );
  }

  renderManualChapter(manual_chapter, index) {
    return (
      <div key={manual_chapter.id} className="checkbox">
        <ManualChapterCheckbox
          index={index}
          formName={this.formName}
          manual_chapter={manual_chapter}
          manual_chapter_ids={this.state.audit.manual_chapter_ids}
          manual_paragraph_ids={this.state.audit.manual_paragraph_ids}
          onChange={this.onManualChange.bind(this)}
        />
      </div>
    );
  }

  stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  renderSelectedQuestion(question, index) {
    const onClick = () => {
      const selected_questions = this.state.selected_questions
        .filter(
          (q) =>
            !(
              q.manual_paragraph_id === question.manual_paragraph_id &&
              q.manuals_audit_question_id === question.manuals_audit_question_id
            ),
        )
        .sortByKey('mp_position');
      const avaliable_questions = [
        ...this.state.avaliable_questions,
        question,
      ].sortByKey('mp_position');

      this.setState({ avaliable_questions, selected_questions });
    };

    return (
      <div
        key={`${question.id}-${question.manual_paragraph_id}-${question.manuals_audit_question_id}`}
      >
        <div className="row">
          <div className="col-xs-10">
            {question.manual_paragraph_number}{' '}
            {this.stripHtml(question.question)}
          </div>
          <div className="col-xs-2">
            <button
              className="btn btn-link btn-link-border"
              onClick={onClick}
              type="button"
            >
              <i className="fa fa-angle-double-right"></i>
            </button>
          </div>
        </div>
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][id]`}
          value={question.id}
        />
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][question]`}
          value={question.question}
        />
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][description]`}
          value={question.description}
        />
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][manuals_audit_question_id]`}
          value={question.manuals_audit_question_id || ''}
        />
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][manual_paragraph_id]`}
          value={question.manual_paragraph_id}
        />
        <input
          type="hidden"
          name={`${this.formName}[audit_questions_attributes][${index}][position]`}
          value={question.position}
        />
        {question.audit_question_job_roles &&
          question.audit_question_job_roles.map((aqjr, i) => (
            <input
              key={i}
              type="hidden"
              name={`${this.formName}[audit_questions_attributes][${index}][audit_question_job_roles_attributes][${i}][job_role_id]`}
              value={aqjr.job_role_id}
            />
          ))}
        <hr />
      </div>
    );
  }

  renderAvailableQuestion(question, index) {
    const onClick = () => {
      const avaliable_questions = this.state.avaliable_questions.filter(
        (q) =>
          !(
            q.manual_paragraph_id === question.manual_paragraph_id &&
            q.manuals_audit_question_id === question.manuals_audit_question_id
          ),
      );
      const selected_questions = [...this.state.selected_questions, question];

      this.setState({
        avaliable_questions: avaliable_questions.sortByKey('mp_position'),
        selected_questions: selected_questions.sortByKey('mp_position'),
      });
    };

    return (
      <div
        key={`${question.manual_paragraph_id}-${question.manuals_audit_question_id}`}
      >
        <div className="row">
          <div className="col-xs-2">
            <button
              className="btn btn-link btn-link-border"
              onClick={onClick}
              type="button"
            >
              <i className="fa fa-angle-double-left"></i>
            </button>
          </div>
          <div className="col-xs-10">
            {question.manual_paragraph_number}{' '}
            {this.stripHtml(question.question)}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <h3>{I18n.t('manual_audits.edit.norms')}</h3>
              {this.props.norms.map(this.renderNorm.bind(this))}
            </div>
            <div className="col-sm-6">
              <h3>{I18n.t('manual_audits.edit.manual_chapters')}</h3>
              {this.props.manual_chapters.map(
                this.renderManualChapter.bind(this),
              )}
            </div>
          </div>
        </div>

        <div className="form-group questions">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-heading">
                  <h4>{I18n.t('manual_audits.edit.questions')}</h4>
                </div>
              </div>
              <div className="scroll card card-chain">
                {this.state.selected_questions.map(
                  this.renderSelectedQuestion.bind(this),
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-heading">
                  <h4>{I18n.t('manual_audits.edit.extended_questions')}</h4>
                  {this.state.searchForQuestions && (
                    <div className="spinner spinning">
                      <i className="fa fa-spinner"></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="scroll card card-chain">
                {this.state.avaliable_questions.map(
                  this.renderAvailableQuestion.bind(this),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditManualAudit;
