import Dispatcher from './dispatcher';

class TaskDispatcher extends Dispatcher {
  handleCreateTaskAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const taskDispatcher = new TaskDispatcher();
