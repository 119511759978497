import Dispatcher from './dispatcher';

class AuditAnswerDispatcher extends Dispatcher {
  handleChangeAuditAnswerAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  }
}

export const auditAnswerDispatcher = new AuditAnswerDispatcher();
