import React from 'react';
import PropTypes from 'prop-types';

const PaginationItems = ({ onPageClick, page, totalPages }) => {
  const numberOfMiddleItems = 7;
  const halveNumberOfItems = parseInt(numberOfMiddleItems / 2);

  const renderItem = (pageNumber, text) => {
    const onClick = (event) => {
      event.preventDefault();
      onPageClick(pageNumber);
    };

    return (
      <li
        key={`${page}-${text || pageNumber}`}
        className={`page-item ${page === pageNumber ? 'active' : ''}`}
      >
        <a
          className="page-link"
          dangerouslySetInnerHTML={{ __html: text || pageNumber }}
          data-page={pageNumber}
          href={`#page-${pageNumber}`}
          onClick={onClick}
        ></a>
      </li>
    );
  };

  const renderEllipsis = (i = 1) => {
    return (
      <li key={`ellipsis-${i}`} className="page-item">
        <span className="page-link disabled">{'\u2026'}</span>
      </li>
    );
  };

  if (totalPages <= numberOfMiddleItems + 4) {
    let pagination = [];

    if (page > 1) {
      pagination = [...pagination, renderItem(page - 1, '&laquo;')];
    }

    pagination = [
      ...pagination,
      ...[...Array(totalPages)].map((_, i) => renderItem(i + 1)),
    ];

    if (page !== totalPages) {
      pagination = [...pagination, renderItem(page + 1, '&raquo')];
    }

    return pagination;
  }

  if (halveNumberOfItems + 2 >= page) {
    return [
      ...[...Array(numberOfMiddleItems)].map((_, i) => renderItem(i + 1)),
      renderEllipsis(),
      renderItem(totalPages - 1),
      renderItem(totalPages),
      renderItem(page + 1, '&raquo'),
    ];
  } else if (totalPages - halveNumberOfItems - 2 <= page) {
    return [
      renderItem(page - 1, '&laquo;'),
      renderItem(1),
      renderItem(2),
      renderEllipsis(),
      ...[...Array(numberOfMiddleItems)].map((_, i) =>
        renderItem(totalPages - numberOfMiddleItems + i + 1),
      ),
    ];
  } else {
    return [
      renderItem(page - 1, '&laquo;'),
      renderItem(1),
      renderItem(2),
      renderEllipsis(),
      [...Array(numberOfMiddleItems)].map((_, i) =>
        renderItem(page - halveNumberOfItems + i),
      ),
      renderEllipsis(2),
      renderItem(totalPages - 1),
      renderItem(totalPages),
      renderItem(page + 1, '&raquo'),
    ];
  }
};

PaginationItems.propTypes = {
  onPageClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const Pagination = ({ totalPages, ...props }) => {
  if (totalPages > 1) {
    return (
      <nav className="mt-5 nav-pagination">
        <ul className="pagination justify-content-center">
          <PaginationItems totalPages={totalPages} {...props} />
        </ul>
      </nav>
    );
  }
  return null;
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;
