import TaskConstants from 'constants/task-constants';
import { taskDispatcher } from 'dispatcher/task-dispatcher';

let _tasks = [];
let _taskListeners = [];

class TaskStore {
  static getAll() {
    return _tasks;
  }

  static setTasks(tasks) {
    _tasks = [...tasks];
  }

  static addTasks(tasks) {
    _tasks = [..._tasks, ...tasks];
  }

  static emitChange() {
    _taskListeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _taskListeners = [..._taskListeners, callback];
  }

  static removeChangeListener(callback) {
    _taskListeners = _taskListeners.filter(l => l !== callback);
  }
}

export default TaskStore;

taskDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case TaskConstants.TASK_CREATE:
      _tasks = [..._tasks, action.task];
      TaskStore.emitChange();
  }
});
