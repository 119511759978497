import Rails from '@rails/ujs';
import { validationErrors } from '../utils';

class DpiaQuestionnaireForm {
  constructor(form) {
    this.form = form;
    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('submit', this.onSubmitEvent.bind(this));
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      url: this.form.action,
      method: this.form.method,
      data: $(this.form).serialize(),
      dataType: 'json',
      success: this.onSuccesCallback.bind(this)
    });
  }

  onSuccesCallback(response) {
    if (response.status === 'NOT-OK') {
      validationErrors($(this.form), response.validation_errors);
    } else {
      Rails.enableElement(this.form);
      $('#dpia_questionnaire_form').hide();
      $('#dpia_questionnaire_section').show();

      for (let attribute of ['name', 'description', 'participants']) {
        let userInput = document.getElementById(`dpia_questionnaire_${attribute}`).value;
        document.getElementById(`dpia_questionnaire_section_${attribute}`).innerText = userInput;
      }

      let id = document.querySelector('#dpia_questionnaire_staff_id').value;
      let userInput = document.querySelector(`#dpia_questionnaire_staff_id [value="${id}"]`).innerText;
      document.getElementById('dpia_questionnaire_section_staff_id').innerText = userInput;
    }
  }
}

$(document).on('turbolinks:load', () => {
  const form = document.querySelector('[data-form="dpia_questionnaire"]');

  if (form) {
    new DpiaQuestionnaireForm(form);
  }
});
