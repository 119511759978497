import React from 'react';
import PropTypes from 'prop-types';

const TextBlockVersion = ({ textBlock }) => {
  const version = textBlock.versions.nodes[0];
  if (
    version === undefined ||
    (version.name === textBlock.name &&
      version.description === textBlock.description)
  )
    return null;

  return (
    <div className="card card-chain card-task status-done">
      <div className="card-heading">
        <h2 className="text-muted">{version.name}</h2>
      </div>
      <div
        className="card-body"
        dangerouslySetInnerHTML={{ __html: version.description }}
      />
    </div>
  );
};

TextBlockVersion.propTypes = {
  textBlock: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    versions: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default TextBlockVersion;
