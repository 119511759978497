document.addEventListener('turbolinks:load', () => {
  if ($('[data-date-schedule="startDate"]').length) {
    $('[data-date-schedule="startDate"]').on('dp.change', ({ date, oldDate }) => {
      const endDatePicker = $('[data-date-schedule="endDate"]').data('DateTimePicker');

      if (date === false) {
        endDatePicker.date(null);
      } else if (oldDate === null || endDatePicker.date() === null) {
        endDatePicker.date(date.add(1, 'hour'));
      } else {
        const endDate = date.add(endDatePicker.date() - oldDate, 'millisecond');
        endDatePicker.date(endDate);
      }
    });
  }
});
