import { dataOptionDispatcher } from '../dispatcher/data-option-dispatcher';
import DataOptionConstants from '../constants/data-option-constants';

var _options = [];
var _listeners = [];

class DataOptionStore {
  static getAll() {
    return _options;
  }

  static setOptions(options) {
    _options = options;
  }

  static addOptions(options) {
    _options = [..._options, ...options];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners = [..._listeners, callback];
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default DataOptionStore;

dataOptionDispatcher.register((payload) => {
  var action = payload.action;

  switch (action.actionType) {
    case DataOptionConstants.OPTION_CREATE:
      _options = [..._options, action.option];
      DataOptionStore.emitChange();
      break;
    case DataOptionConstants.OPTION_DELETE:
      DataOptionStore.emitChange();
      break;
  }
});
