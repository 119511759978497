import styled from 'styled-components';

export const Cell = styled.div`
  padding: 0.5rem 0;
`;
export const HeaderCell = styled(Cell)`
  font-weight: 600;
`;
export const ScrollBox = styled.div`
  max-height: calc(100vh - 286px);
  overflow: scroll;

  & > div {
    border-top: 1px solid #E5E5E5;
    &:focus {
      outline: none;
    }
  }
`;
export const LineClamp = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: pre-line
`;
