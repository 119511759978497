import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import DeviationAnswerTask from './DeviationAnswerTask';
import TaskStore from 'stores/task-store';

class DeviationAnswerOverview extends React.Component {
  constructor(props) {
    super(props);

    this.scope = { scope: 'components.deviation_answer_overview', defaultValue: '' };
    this.renderDeviationAnswer = this.renderDeviationAnswer.bind(this);
    this.onTasksChange = this.onTasksChange.bind(this);

    this.state = {
      tasks: []
    };
  }

  componentDidMount() {
    TaskStore.addChangeListener(this.onTasksChange);
  }

  componentWillUnmount() {
    TaskStore.removeChangeListener(this.onTasksChange);
  }

  onTasksChange() {
    this.setState({
      tasks: TaskStore.getAll()
    });
  }

  onEditDeviationAnswerClick(deviation_answer) {
    this.props.editDeviationAnswer(deviation_answer);
  }

  renderCompliance(deviation_answer) {
    const { can_update, deviation } = this.props;
    const { tasks } = this.state;
    const task = tasks.find(t => t.deviation_answer_id === deviation_answer.id);

    return(
      <DeviationAnswerTask
        can_update={can_update}
        deviation={deviation}
        deviation_answer={deviation_answer}
        task={task}
      />
    );
  }

  renderDeviationAnswer(deviation_answer) {
    const { can_update } = this.props;

    return(
      <div key={deviation_answer.id} className="flex-row">
        {
          can_update && (
            <div className="wrap size-1">
              <button className="btn btn-link" type="button" onClick={this.onEditDeviationAnswerClick.bind(this, deviation_answer)}>
                <i className="fa fa-edit"></i>
              </button>
            </div>
          )
        }
        <div
          className="wrap size-3"
          dangerouslySetInnerHTML={{ __html: deviation_answer.question }}
        />
        <div className="wrap size-3">
          {deviation_answer.description}
        </div>
        <div className="wrap size-3">
          {I18n.t(deviation_answer.answer, this.scope)}:
          <div dangerouslySetInnerHTML={{__html: deviation_answer[`${deviation_answer.answer}_description`]}} />
        </div>
        <div
          className="wrap size-3"
          dangerouslySetInnerHTML={{ __html: deviation_answer[`${deviation_answer.answer}_measure`]}}
        />
        <div className="wrap size-2">{this.renderCompliance(deviation_answer)}</div>
      </div>
    );
  }

  render() {
    const { can_update, deviation_answers } = this.props;

    return(
      <div className="table-responsive">
        <div className="table-flex">
          <div className="head">
            <div className="flex-row">
              {can_update ? <div className="size-1"></div> : null}
              <div className="size-3">{I18n.t('question', this.scope)}</div>
              <div className="size-3">{I18n.t('description', this.scope)}</div>
              <div className="size-3">{I18n.t('answer', this.scope)}</div>
              <div className="size-3">{I18n.t('measure', this.scope)}</div>
              <div className="size-2">{I18n.t('actions', this.scope)}</div>
            </div>
          </div>

          <div className="body">
            {deviation_answers.map(this.renderDeviationAnswer)}
          </div>
        </div>
      </div>
    );
  }
}

export default DeviationAnswerOverview;
