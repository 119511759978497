import React from 'react';

class ManualChapterCheckbox extends React.Component {
  onParentChange(event) {
    this.props.onChange({
      actionType: 'manual-chapter',
      manual_chapter_id: +event.target.value,
      checked: event.target.checked
    });
  }

  onChildChange(event) {
    this.props.onChange({
      actionType: 'manual-paragraph',
      manual_paragraph_id: +event.target.value,
      manual_chapter_id: this.props.manual_chapter.id,
      checked: event.target.checked
    });
  }

  renderManualParagraph(manual_paragraph) {
    return(
      <div key={`manualParagraph-${manual_paragraph.id}`} className="checkbox">
        <label>
          <input type="checkbox" name={`${this.props.formName}[manual_paragraph_ids][]`} checked={this.props.manual_paragraph_ids.includes(manual_paragraph.id)}  value={manual_paragraph.id} onChange={this.onChildChange.bind(this)} />
          <i className={`fal ${manual_paragraph.checked ? 'fa-check-square' : ''}`}></i>
          {manual_paragraph.name}
        </label>
      </div>
    );
  }

  render() {
    return(
      <div className="checkbox">
        <label>
          <input type="checkbox" name={`${this.props.formName}[manual_chapter_ids][]`} checked={this.props.manual_chapter_ids.includes(this.props.manual_chapter.id)} value={this.props.manual_chapter.id} onChange={this.onParentChange.bind(this)} />
          <i className={`fal ${this.props.manual_chapter.checked ? 'fa-check-square' : ''}`}></i>
          &nbsp;{this.props.manual_chapter.name}&nbsp;
        </label>
        <a href={`#${this.props.index}-manual_chapters_${this.props.manual_chapter.id}`} className="collapse-btn collapsed" data-toggle="collapse">
          <i className="fa fa-plus"></i>
          <i className="fa fa-minus"></i>
        </a>
        <div className="collapse" id={`${this.props.index}-manual_chapters_${this.props.manual_chapter.id}`}>
          {this.props.manual_chapter.manual_paragraphs.map(this.renderManualParagraph.bind(this))}
        </div>
      </div>
    );
  }
}

export default ManualChapterCheckbox;
