export const onAnchorLinkClick = (event) => {
  event.preventDefault();

  scrollToAnchor(event.target.hash);
};

export const scrollToAnchor = (hash) => {
  const yOffset = -65;
  const element = document.querySelector(hash);

  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y });
    history.replaceState(null, null, event.target.hash);
  }
};

export const onAnchorIntoViewLinkClick = (event) => {
  event.preventDefault();
  const element = document.querySelector(event.target.hash);
  element && scrollElementIntoView(element);
};

export const scrollElementIntoView = (element) => {
  element.scrollIntoView();
};
