import QuestionnaireConstants from 'constants/questionnaire-constants';
import { questionnaireDispatcher } from 'dispatcher/questionnaire-dispatcher';

let _questionnaires = [];
let _listeners = [];

class QuestionnaireStore {
  static getAll() {
    return _questionnaires;
  }

  static setQuestionnaires(questionnaires) {
    _questionnaires = [...questionnaires];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default QuestionnaireStore;

questionnaireDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case QuestionnaireConstants.QUESTIONNAIRE_CREATE:
      _questionnaires.push(action.questionnaire);
      QuestionnaireStore.emitChange();
  }
});
