class ReviewerGetter extends Getter {
  constructor(selectElement, triggerElement) {
    super();

    this.selectElement = selectElement;
    this.triggerElement = triggerElement;
    this.onChange = this.onChange.bind(this);

    this.triggerElement.addEventListener('change', this.onChange);
  }

  onChange(event) {
    this.fetchReviewer(event.target.value);
  }

  fetchReviewer(value) {
    this.getData({
      url: '/staffs/reviewers',
      data: { business_unit_id: value },
      success: (data) => {
        this.setOptionFields(this.selectElement, data);
      },
    });
  }
}

window.loadReviewerGetter = () => {
  const selectElement = document.querySelector('[data-getter="reviewer"]');
  const triggerElement = document.querySelector('[data-trigger="reviewer"]');

  if (selectElement && triggerElement) {
    new ReviewerGetter(selectElement, triggerElement);
  }
};
document.addEventListener('turbolinks:load', window.loadReviewerGetter);
