import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import TableRowConstants from 'constants/table-row-constants';
import { tableRowDispatcher } from 'dispatcher/table-row-dispatcher';

class TableRowModal extends React.Component {
  constructor(props) {
    super(props);

    this.modal;
  }

  componentDidMount() {
    $('.modal-dialog').draggable();
    $(this.modal).on('show.bs.modal', () => {
      let textareas = this.modal.querySelectorAll('textarea');

      for (let i = 0; i < textareas.length; ++i) {
        if (window.CKEDITOR.instances[textareas[i].id] === undefined) {
          window.CKEDITOR.replace(textareas[i].id, {
            language: I18n.locale,
            toolbarGroups: [
              { name: 'basicstyles', groups: ['basicstyles'] },
              { name: 'paragraph',   groups: ['list', 'indent'] },
              { name: 'links,',      groups: ['links'] }
            ],
            removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript',
            removeDialogTabs: 'link:advanced'
          });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.row.order != this.props.row.order) {
      let textareas = this.modal.querySelectorAll('textarea');

      for (let i = 0; i < textareas.length; ++i) {
        if (window.CKEDITOR.instances[textareas[i].id] !== undefined) {
          window.CKEDITOR.instances[textareas[i].id].destroy();
        }
      }
    }
  }

  componentDidUpdate() {
    let textareas = this.modal.querySelectorAll('textarea');

    for (let i = 0; i < textareas.length; ++i) {
      if (window.CKEDITOR.instances[textareas[i].id] === undefined) {
        window.CKEDITOR.replace(textareas[i].id, {
          language: 'nl',
          toolbarGroups: [
            { name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph',   groups: ['list', 'indent'] },
            { name: 'links,',      groups: ['links'] }
          ],
          removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript',
          removeDialogTabs: 'link:advanced'
        });
      }
    }
  }

  onSaveClickEvent() {
    let cells = [...this.props.row.cells];
    const textareas = this.modal.querySelectorAll('textarea');
    const selects = this.modal.querySelectorAll('select');

    for (let i = 0; i < textareas.length; ++i) {
      let cell = cells.find(c => c.id === +textareas[i].dataset.id);
      cell = {
        ...cell,
        value: window.CKEDITOR.instances[textareas[i].id].getData()
      };

      cells = cells.map(c => c.id === cell.id ? cell : c );
    }

    for (let i = 0; i < selects.length; i++) {
      let cell = cells.find(c => c.id === +selects[i].dataset.id);
      const column = this.props.columns.find(c => c.id === cell.column_id);
      const default_answer = column.default_answers.find(d => d.id === +selects[i].value);

      cell = {
        ...cell,
        default_answer_id: default_answer.id,
        image: default_answer.image,
        value: default_answer.answer
      };

      cells = cells.map(c => c.id === cell.id ? cell : c );
    }

    tableRowDispatcher.handleChangeTableRowAction({
      actionType: TableRowConstants.TABLE_ROW_UPDATE,
      table_row: { ...this.props.row, cells }
    });
  }

  renderCell(cell) {
    const column = this.props.columns.find(c => c.id === cell.column_id);

    return(
      <div key={`input-cell-${cell.id}`} className="form-group">
        <label>{column.name}</label>
        {cell.default_answer_id ?
          this.renderSelect(cell, column)
          :
          <textarea id={`textarea-${cell.id}`} data-id={cell.id} defaultValue={cell.value} />
        }
      </div>
    );
  }

  renderSelect(cell, column) {
    return(
      <select data-id={cell.id} className="form-control" defaultValue={cell.default_answer_id}>
        {column.default_answers.map(this.renderDefaultAnswer.bind(this))}
      </select>
    );
  }

  renderDefaultAnswer(default_answer) {
    return(
      <option key={`default-answer-${default_answer.id}`} value={default_answer.id}>{default_answer.answer}</option>
    );
  }

  render() {
    return(
      <div ref={(modal) => {this.modal = modal;}} id={`modal-edit-row-${this.props.row.id}`} className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">{I18n.t('text_blocks.edit.modals.table_row.header')}</h4>
            </div>

            <div className="modal-body">
              {this.props.row.cells.map(this.renderCell.bind(this))}
            </div>

            <div className="modal-footer">
              <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
              <button onClick={this.onSaveClickEvent.bind(this)} data-dismiss="modal" className="button btn btn-primary">{I18n.t('helpers.submit.save')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableRowModal;
