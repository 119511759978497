class Getter {
  getData(options) {
    options.dataType = 'json';
    $.ajax(options);
  }

  setOptionFields(selectElement, data) {
    let options = [];

    if (selectElement.dataset.includeBlank) {
      options = [$('<option>')];
    }
    data.forEach(item => {
      const option = $('<option>').attr('value', item.id).html(item.name);
      options = [...options, option];
    });

    $(selectElement).html(options);
  }
}

window.Getter = Getter;
