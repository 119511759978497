import { useReducer } from 'react';
import {
  activeStorageUpload,
  googleUpload,
  microsoftUpload,
} from '../../upload-provider';

const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'startUpload': {
      return {
        ...state,
        isUploading: true,
        hasError: false,
        file: payload.file,
        progress: 0,
      };
    }
    case 'stopUpload': {
      return {
        ...state,
        isUploading: false,
      };
    }
    case 'errorUpload': {
      return {
        ...state,
        isUploading: false,
        hasError: true,
      };
    }
    case 'updateProgress': {
      return {
        ...state,
        progress: payload.progress,
      };
    }
    default: {
      new Error(`Unkown action: ${type}`);
    }
  }
};

export const useUploadFile = (onSuccessUpload, hasDrive = false) => {
  const [state, dispatch] = useReducer(reducer, {
    hasError: false,
    file: null,
    progress: 0,
    isUploading: false,
  });

  const startUpload = (file) => {
    dispatch({ type: 'startUpload', payload: { file } });
    activeStorageUpload({ file, onError, onProgress, onSuccess });
  };

  const startGoogleUpload = (file, parentId, externalId) => {
    dispatch({ type: 'startUpload', payload: { file } });
    googleUpload({
      file,
      parentId,
      externalId,
      onError,
      onProgress,
      onSuccess,
    });
  };

  const startMicrosoftUpload = (file, driveId, parentId) => {
    dispatch({ type: 'startUpload', payload: { file } });
    microsoftUpload({
      file,
      driveId,
      parentId,
      onError,
      onProgress,
      onSuccess,
    });
  };

  const onError = (error) => {
    dispatch({ type: 'errorUpload' });
  };

  const onProgress = (event) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    dispatch({ type: 'updateProgress', payload: { progress } });
  };

  const onSuccess = (response) => {
    dispatch({ type: 'stopUpload' });
    onSuccessUpload(response);
  };

  return [state, { startUpload, startGoogleUpload, startMicrosoftUpload }];
};
