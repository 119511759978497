import React from 'react';
import PropTypes from 'prop-types';

import { useDocuments } from './reducer';

import Document from './document';
import Header from './header';
import Pagination from '../Pagination';
import Table from './table';

const Documents = (props) => {
  const [state, dispatch] = useDocuments();
  const totalPages = Math.ceil(state.totalCount / 50);

  const onBusinessUnitChange = (businessUnitIds) => {
    dispatch({ type: 'update', payload: { businessUnitIds } });
  };

  const onSortChange = (sortBy, sortDirection) => {
    dispatch({ type: 'update', payload: { sortBy, sortDirection } });
  };

  const onPageClick = (page) => {
    $.scrollTo($('[data-react-class="documents/container"]'), 75);
    dispatch({ type: 'update', payload: { page } });
  };

  return (
    <>
      <Header
        businessUnitIds={state.businessUnitIds}
        businessUnits={props.businessUnits}
        canDownloadDocuments={props.canDownloadDocuments}
        onChange={onBusinessUnitChange}
        sortBy={state.sortBy}
        sortDirection={state.sortDirection}
      />
      <Table onClick={onSortChange} sortBy={state.sortBy} sortDirection={state.sortDirection}>
        {state.documents.map((doc) => (
          <Document key={doc.id} dispatch={dispatch} doc={doc} {...props} />
        ))}
      </Table>

      <Pagination onPageClick={onPageClick} page={state.page} totalPages={totalPages} />
    </>
  );
};

Documents.propTypes = {
  canDownloadDocuments: PropTypes.bool.isRequired,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Documents;
