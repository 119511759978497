import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import ProcessLink from './ProcessLink';

const ProcessLinks = (props) =>  {
  const [processLinks, setProcessLinks] = useState(props.processLinks.length ? props.processLinks : [{ uid: Date.now() }]);

  const onAdd = () => setProcessLinks([...processLinks, { uid: Date.now() }]);

  const onChange = (processLink) => {
    const mapFn = (p) => (
      (p.id !== undefined && p.id === processLink.id) || (p.uid !== undefined && p.uid === processLink.uid) ? processLink : p
    );
    setProcessLinks(processLinks.map(mapFn));
  };

  const onDelete = (processLink) => {
    if (processLink.uid) {
      setProcessLinks(processLinks.filter(p => p.uid !== processLink.uid));
    } else {
      setProcessLinks(processLinks.map(p => p.id === processLink.id ? { ...p, destroy: true } : p));
    }
  };

  const renderprocessLink = (processLink) => {
    if (processLink.destroy) {
      const formName = `processing_register[process_links_attributes][${processLink.id}]`;

      return(
        <div key={processLink.id || processLink.uid}>
          <input name={`${formName}[id]`} value={processLink.id} type="hidden" />
          <input name={`${formName}[_destroy]`} value={processLink.destroy} type="hidden" />
        </div>
      );
    }

    return(
      <ProcessLink
        key={processLink.id || processLink.uid}
        manualParagraphs={props.manualParagraphs}
        onChange={onChange}
        processLink={processLink}
        onDelete={onDelete}
      />
    );
  };

  return(
    <div>
      <div className='row' >
        <div className='col-sm-6'>
          <label>{I18n.t('processing_registers.manual_paragraph')}</label>
        </div>
        <div className='col-sm-6'>
          <label>{I18n.t('processing_registers.resource')}</label>
        </div>
      </div>
      {processLinks.map(renderprocessLink)}
      <div className='row' >
        <div className='col-sm-12' >
          <button className='btn btn-default mb-3' onClick={onAdd} type="button">
            <i className="far fa-plus"></i> {I18n.t('processing_registers.add_process')}
          </button>
        </div>
      </div>
    </div>
  );
};

ProcessLinks.propTypes = {
  manualParagraphs: PropTypes.array.isRequired,
  processLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      manual_paragraph_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default ProcessLinks;
