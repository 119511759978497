import React from 'react';
import I18n from 'i18n-js/index.js.erb';

class TableColumnModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: props.columns
    };
  }

  componentDidMount() {
    $('.modal-dialog').draggable();
  }

  onSaveClickEvent(event) {
    event.preventDefault();

    this.props.onSave(this.state.columns);
  }

  onChangeEvent(event) {
    const columns = this.state.columns.map((c) => {
      if (c.id == event.target.dataset.id) {
        c.name = event.target.value;
      }
      return c;
    });

    this.setState({ columns });
  }

  renderColumn(column) {
    return(
      <div key={column.id} className="form-group">
        <label htmlFor={`column-${column.id}`}>{I18n.t('text_blocks.edit.modals.table_column.column_name')}</label>
        <input id={`column-${column.id}`} type="text" className="form-control" data-id={column.id} value={column.name} onChange={this.onChangeEvent.bind(this)} />
      </div>
    );
  }

  render() {
    return(
      <div id={`modal-edit-columns-${this.props.table_id}`} className="modal fade" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">{I18n.t('text_blocks.edit.modals.table_column.header')}</h4>
            </div>

            <div className="modal-body">
              {this.state.columns.map(this.renderColumn.bind(this))}
            </div>

            <div className="modal-footer">
              <button className="btn btn-default pull-left" type="button" data-dismiss="modal">{I18n.t('helpers.submit.cancel')}</button>
              <button onClick={this.onSaveClickEvent.bind(this)} data-dismiss="modal" className="button btn btn-primary">{I18n.t('helpers.submit.save')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableColumnModal;
