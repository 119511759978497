import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import BusinessUnitChart from './BusinessUnitChart';
import Datetime from 'react-datetime';
import DocumentJobTitles from './DocumentJobTitles';

class DocumentForm extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();

    this.onFileChange = this.onFileChange.bind(this);
    this.onRevistionDateChange = this.onRevistionDateChange.bind(this);
    this.onRetentionPeriodChange = this.onRetentionPeriodChange.bind(this);

    this.scope = { scope: 'components.document_form' };
    this.scopeForm = { scope: 'activerecord.attributes.document' };
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover();
  }

  componentDidUpdate(prevProps) {
    if (this.props.doc.file === undefined && this.props.doc.file !== prevProps.doc.file) {
      this.fileInput.current.value = '';
    }
  }

  onFileChange(event) {
    const { doc, onChange } = this.props;

    const file = event.target.files[0];
    const name = doc.name === '' ? file.name.split('.').slice(0, -1).join('.') : doc.name;

    onChange({ file, name });
  }

  onRevistionDateChange(date) {
    const { onChange } = this.props;

    if (typeof date === 'string') {
      onChange({ revision_date: '' });
    } else {
      onChange({ revision_date: date.format('DD-MM-YYYY') });
    }
  }

  onRetentionPeriodChange(date) {
    const { onChange } = this.props;

    if (typeof date === 'string') {
      onChange({ retention_period: '' });
    } else {
      onChange({ retention_period: date.format('DD-MM-YYYY') });
    }
  }

  renderError(attribute) {
    const { errors } = this.props.doc;

    if (errors[attribute]) {
      return errors[attribute].map((error, index) => (
        <div key={index} className="alert alert-danger">
          {error}
        </div>
      ));
    }
  }

  renderSubmitButton() {
    const { scope, scopeForm } = this;
    const { can_mark_as_final, onSubmit, onFinalSubmit } = this.props;

    if (can_mark_as_final) {
      return (
        <div className="form-group text-right">
          <div className="btn-group">
            <button className="btn btn-default" data-disable-with={I18n.t('save', scope)} onClick={onSubmit}>
              {I18n.t('save', scope)}
            </button>
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              type="button"
            >
              <span className="caret"></span>
              <span className="sr-only"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <button className="btn btn-link" onClick={onFinalSubmit} type="button">
                  {I18n.t('mark_as_final', scope)}
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-group text-right">
          <button className="btn btn-default" data-disable-with={I18n.t('save', scope)} onClick={onSubmit}>
            {I18n.t('save', scope)}
          </button>
        </div>
      );
    }
  }

  render() {
    const { scope, scopeForm } = this;
    const {
      business_units,
      classifications,
      doc,
      formName,
      job_titles,
      onChange,
      onDestroy,
      showRevisionDate,
      staffs,
      showDeleteButton,
    } = this.props;

    return (
      <div hidden={!doc.showForm || doc.destroy}>
        <div className="card">
          <input type="hidden" name={`${formName}[id]`} value={doc.id} />
          <input type="hidden" name={`${formName}[_destroy]`} value={doc.destroy} />

          <div className="row form-group">
            <label htmlFor={`${formName}[name]`} className="control-label col-sm-2">
              {I18n.t('name', scopeForm)}
            </label>
            <div className="col-sm-10">
              <div className="row">
                {doc.url === null ? (
                  <React.Fragment>
                    <div className="col-sm-5">
                      <input
                        autoComplete="off"
                        className="form-control"
                        name={`${formName}[name]`}
                        onChange={(e) => onChange({ name: e.target.value })}
                        placeholder={I18n.t('activerecord.placeholders.document.name')}
                        type="text"
                        value={doc.name}
                      />
                    </div>

                    <div className="col-sm-3">
                      <a
                        className="btn btn-default btn-block"
                        download={doc.filename}
                        href={doc.file_url}
                        title={I18n.t('download', scope)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download"></i> {I18n.t('download', scope)}
                      </a>
                    </div>

                    <div className="col-sm-3">
                      <input
                        className="hidden"
                        id={`${formName}_file`}
                        name={`${formName}[file]`}
                        onChange={this.onFileChange}
                        ref={this.fileInput}
                        type="file"
                      />
                      <label
                        className="btn btn-default btn-block"
                        htmlFor={`${formName}_file`}
                        title={I18n.t('upload', scope)}
                      >
                        <i className="fa fa-upload"></i> {I18n.t('upload', scope)}
                      </label>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="col-sm-11">
                    <input
                      autoComplete="off"
                      className="form-control"
                      name={`${formName}[name]`}
                      onChange={(e) => onChange({ name: e.target.value })}
                      placeholder={I18n.t('activerecord.placeholders.document.name')}
                      type="text"
                      value={doc.name}
                    />
                  </div>
                )}

                {showDeleteButton ? (
                  <div className="col-sm-1">
                    <button className="btn btn-link" title={I18n.t('delete', scope)} onClick={onDestroy} type="button">
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                ) : null}
              </div>

              {doc.url === null ? this.renderError('file') : null}
              {this.renderError('name')}
            </div>
          </div>

          {doc.file !== undefined ? (
            <div className="row form-group">
              <div className="col-sm-10 col-sm-offset-2">
                <i className="far fa-file-alt"></i> {doc.file.name}
              </div>
            </div>
          ) : null}

          {doc.url !== null ? (
            <React.Fragment>
              <div className="form-group row">
                <label htmlFor={`${formName}[url]`} className="control-label col-sm-2">
                  {I18n.t('url', scopeForm)}
                </label>
                <div className="col-sm-10">
                  <input
                    autoComplete="off"
                    className="form-control"
                    id={`${formName}_url`}
                    name={`${formName}[url]`}
                    onChange={(e) => onChange({ url: e.target.value })}
                    value={doc.url || ''}
                    type="text"
                  />
                  {this.renderError('url')}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10 col-sm-offset-2">
                  <a className="wrap" href={doc.url} target="blank">
                    {doc.url}
                  </a>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          <div className="row form-group">
            <label
              htmlFor={`${formName}[staff_id]`}
              className="control-label col-sm-2"
              data-trigger="hover"
              data-toggle="popover"
              title={I18n.t('staff_id', scopeForm)}
              data-content={I18n.t('staff_popover', scope)}
              data-placement="bottom"
            >
              {I18n.t('staff_id', scopeForm)}
            </label>
            <div className="col-sm-10">
              <select
                className="form-control"
                name={`${formName}[staff_id]`}
                onChange={(e) => onChange({ staff_id: parseInt(e.target.value) })}
                value={doc.staff_id}
              >
                <option value=""></option>
                {staffs.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
              {this.renderError('staff_id')}
            </div>
          </div>

          <div className="row form-group document_form">
            <label
              htmlFor={`${formName}[classification]`}
              className="control-label col-sm-2"
              data-trigger="hover"
              data-toggle="popover"
              title={I18n.t('classification', scopeForm)}
              data-content={I18n.t('classification_popover', scope)}
              data-placement="bottom"
            >
              {I18n.t('classification', scopeForm)}
            </label>
            <div className="col-sm-10">
              <select
                className="form-control"
                name={`${formName}[classification]`}
                onChange={(e) => onChange({ classification: e.target.value })}
                value={doc.classification || I18n.t(`documents.classifications.${classifications[0]}`)}
              >
                {classifications.map((c) => (
                  <option key={c} value={c}>
                    {I18n.t(`documents.classifications.${c}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={`collapse${doc.classification === 'confidential' ? ' in' : ''}`}>
            <div className="row form-group">
              <label htmlFor={`${formName}_deparments`} className="col-sm-2 control-label">
                {I18n.t('job_title_ids', scopeForm)}
              </label>
              <div className="col-xs-10">
                <DocumentJobTitles
                  name={`${formName}[job_title_ids][]`}
                  doc={doc}
                  job_titles={job_titles}
                  onChange={onChange}
                  selected_job_title_ids={doc.job_title_ids}
                />
                {this.renderError('job_title_ids')}
              </div>
            </div>
          </div>

          {showRevisionDate ? (
            <div className="row form-group">
              <label htmlFor={`${formName}[revision_date]`} className="control-label col-sm-2">
                {I18n.t('revision_date', scopeForm)}
              </label>
              <div className="col-sm-10">
                <Datetime
                  locale={I18n.locale}
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  inputProps={{ autoComplete: 'off', name: `${formName}[revision_date]`, placeholder: 'DD-MM-YYYY' }}
                  onChange={this.onRevistionDateChange}
                  value={doc.revision_date || ''}
                />
                {this.renderError('revision_date')}
              </div>
            </div>
          ) : null}

          <div className="row form-group">
            <label htmlFor={`${formName}[retention_period]`} className="control-label col-sm-2">
              {I18n.t('retention_period', scopeForm)}
            </label>
            <div className="col-sm-10">
              <Datetime
                locale={I18n.locale}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ autoComplete: 'off', name: `${formName}[retention_period]`, placeholder: 'DD-MM-YYYY' }}
                onChange={this.onRetentionPeriodChange}
                value={doc.retention_period || ''}
              />
              {this.renderError('retention_period')}
            </div>
          </div>

          <div className="row form-group">
            <label htmlFor={`${formName}[location]`} className="control-label col-sm-2">
              {I18n.t('location', scopeForm)}
            </label>
            <div className="col-sm-10 text-right">
              <input
                autoComplete="off"
                className="form-control"
                id={`${formName}[location]`}
                name={`${formName}[location]`}
                onChange={(e) => onChange({ location: e.target.value })}
                type="text"
                value={doc.location || ''}
              />
            </div>
          </div>

          {business_units.length > 0 && (
            <div className="row form-group">
              <label htmlFor={`${formName}[business_unit_ids]`} className="control-label col-sm-2">
                {I18n.t('business_unit_ids', scopeForm)}
              </label>
              <div className="col-sm-10">
                <BusinessUnitChart
                  business_unit_ids={doc.business_unit_ids}
                  business_units={business_units}
                  form_name={`${formName}[business_unit_ids][]`}
                  onChange={onChange}
                />
              </div>
            </div>
          )}

          {this.renderSubmitButton()}
        </div>
      </div>
    );
  }
}

export default DocumentForm;
