import DeviationCommentConstants from 'constants/deviation-comment-constants';
import { deviationCommentDispatcher } from 'dispatcher/deviation-comment-dispatcher';

let _deviationComments =[];
let _listeners = [];

class DeviationCommentStore {
  static getAll() {
    return _deviationComments;
  }

  static setDeviationComments(deviationComments) {
    _deviationComments = deviationComments;
  }

  static emitChange() {
    _listeners.forEach((callback) => {
      callback();
    });
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default DeviationCommentStore;

deviationCommentDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case DeviationCommentConstants.TASK_COMMENT_CREATE:
      _deviationComments.push(action.deviationComment);
      DeviationCommentStore.emitChange();
  }
});
