import React, { useEffect, useState } from 'react';
import I18n from '../../i18n-js/index.js.erb';
import Turbolinks from 'turbolinks';

import { request } from '../../actions';

import SortLink from '../ui/sort-link';
import ButtonRole from '../ui/button-role';

const graphql = `
  query SelfAssessmentsQuery($sortBy: String, $sortDirection: String) {
    selfAssessments(sortBy: $sortBy, sortDirection: $sortDirection) {
      id name description endDate progress selfAssessmentTemplate { name }
    }
  }
`;

const SelfAssessments = () => {
  const options = { scope: 'components.self_assessments' };
  const [state, setState] = useState({
    selfAssessments: [],
    sortBy: 'id',
    sortDirection: 'desc',
  });

  const onSort = (sortBy, sortDirection) => setState({ ...state, sortBy, sortDirection });

  useEffect(() => {
    const controller = new AbortController();
    const variables = {
      sortBy: state.sortBy,
      sortDirection: state.sortDirection,
    };

    (async () => {
      const [response, error] = await request(graphql, variables, controller);

      if (error === null) {
        setState(s => ({ ...s, ...response }));
      }
    })();

    return () => controller.abort();
  }, [state.sortBy, state.sortDirection]);

  const renderSelfAssessment = (selfAssessment) => (
    <ButtonRole key={selfAssessment.id} className="flex-row" onClick={() => Turbolinks.visit(`/self_assessments/${selfAssessment.id}`)}>
      <div className="size-1">{selfAssessment.id}</div>
      <div className="size-2">{selfAssessment.name}</div>
      <div className="size-3">{selfAssessment.description}</div>
      <div className="size-2">{selfAssessment.selfAssessmentTemplate && selfAssessment.selfAssessmentTemplate.name}</div>
      <div className="size-1">{(new Date(selfAssessment.endDate)).toLocaleDateString('nl', { day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
      <div className="size-1">
        <div className="progress mb-0">
          <div
            aria-valuenow={selfAssessment.progress}
            aria-valuemin="0"
            aria-valuemax="100"
            className="progress-bar"
            role="progressbar"
            style={{width: selfAssessment.progress + '%'}}
          >
            {selfAssessment.progress}%
          </div>
        </div>
      </div>
    </ButtonRole>
  );

  return(
    <div className="card">
      <div className="table-flex">
        <div className="head">
          <div className="flex-row">
            <div className="size-1">
              <SortLink sorting="id" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.id')}
              </SortLink>
            </div>
            <div className="size-2">
              <SortLink sorting="name" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.name')}
              </SortLink>
            </div>
            <div className="size-3">
              <SortLink sorting="description" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.description')}
              </SortLink>
            </div>
            <div className="size-2">
              <SortLink sorting="self_assessment_template_id" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.self_assessment_template_id')}
              </SortLink>
            </div>
            <div className="size-1">
              <SortLink sorting="end_date" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.end_date')}
              </SortLink>
            </div>
            <div className="size-1">
              <SortLink sorting="progress" onSort={onSort} sortBy={state.sortBy} sortDirection={state.sortDirection}>
                {I18n.t('activerecord.attributes.self_assessment.progress')}
              </SortLink>
            </div>
          </div>
        </div>
        <div className="body">
          {state.selfAssessments.map(renderSelfAssessment)}
        </div>
      </div>
    </div>
  );
};

export default SelfAssessments;
