import { request } from '../actions';

const query = `
  query GoogleComponent {
    staff { oauthToken }
  }
`;

export const googleUpload = async ({
  file,
  externalId,
  parentId,
  ...events
}) => {
  const [{ staff }] = await request(query);
  let url;

  if (parentId) {
    url = await fetchNewUrl(staff.oauthToken, file, parentId);
  } else {
    url = await fetchExistingUrl(staff.oauthToken, file, externalId);
  }

  upload(file, { url }, events);
};

async function fetchNewUrl(oauthToken, file, parentId) {
  const metadata = {
    mimeType: file.type,
    name: file.name,
    parents: [parentId],
  };
  const response = await fetch(
    'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&supportsAllDrives=true',
    {
      method: 'POST',
      body: JSON.stringify(metadata),
      headers: {
        Authorization: 'Bearer ' + oauthToken,
        'Content-Type': 'application/json',
      },
    },
  );

  return response.headers.get('location');
}

async function fetchExistingUrl(oauthToken, file, externalId) {
  const metadata = {
    mimeType: file.type,
    name: file.name,
  };
  const response = await fetch(
    `https://www.googleapis.com/upload/drive/v3/files/${externalId}?uploadType=resumable&supportsAllDrives=true`,
    {
      method: 'PATCH',
      body: JSON.stringify(metadata),
      headers: {
        Authorization: 'Bearer ' + oauthToken,
        'Content-Type': 'application/json',
      },
    },
  );

  return response.headers.get('location');
}

const upload = (file, response, events) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', response.url, true);
  xhr.responseType = 'json';
  xhr.setRequestHeader(
    'Content-Range',
    'bytes 0-' + (file.size - 1) + '/' + file.size,
  );

  if (events.onProgress) {
    xhr.upload.addEventListener('progress', events.onProgress, false);
  }

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      events.onSuccess({ name: file.name, externalId: xhr.response.id });
    }
  };
  xhr.upload.addEventListener('error', events.onError);

  xhr.send(file.slice());
};
