$(document).on('turbolinks:load', () => {
  $('#image_modal').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget);
    const modal = $(this);
    
    if (button.data('imageUrl') !== undefined) {
      modal.find('img').attr('src', button.data('imageUrl'));
    }
  });
});
