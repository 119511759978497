import React from 'react';

class FilterList extends React.Component {
  render() {
    const { allItems, current, items, filterKey, name, onClickHandler } = this.props;

    var listItem = (filterValue, index) => {
      return(
        <li key={index}>
          <a href={'?'+ this.props.filterKey + '=' + filterValue} onClick={this.props.onClickHandler} data-filter-key={this.props.filterKey} data-filter-value={filterValue}>
            {filterValue}
          </a>
        </li>
      );
    };

    return(
      <li>
        <div className="dropdown">
          <a href="#" className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            {current || name}
            <span className="caret"></span>
          </a>
          <ul className="dropdown-menu dropdown-menu-right">
            <li><a href="" onClick={onClickHandler} data-filter-key={filterKey} data-filter-value="">{allItems}</a></li>
            <li className="divider"></li>
            {items.map(listItem)}
          </ul>
        </div>
      </li>
    );
  }
}

export default FilterList;
