import React from 'react';
import FlexGrid from './FlexGrid';

import I18n from 'i18n-js/index.js.erb';

class DashboardDeviations extends React.Component {
  columns() {
    const scope = { scope: 'components.dashboard_deviations' };

    return [{
      header: I18n.t('id', scope),
      accessor: 'id',
      size: 1,
    }, {
      header: I18n.t('title', scope),
      accessor: 'title',
      size: 4,
    }, {
      header: I18n.t('status', scope),
      accessor: 'status_name',
      size: 3
    }];
  }

  render() {
    return(
      <FlexGrid
        columns={this.columns()}
        records={this.props.deviations}
      />
    );
  }
}

export default DashboardDeviations;
