import React, { useReducer, useRef } from 'react';
import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Container, Badge, Button, Input, Item, List, StyledInput } from './components';
import { reducer } from './reducer';

const isDownKey = isHotkey('down');
const isEnter = isHotkey('enter');
const isUpKey = isHotkey('up');

const AutoComplete = ({ items, errors, selectedItemIds, ...props }) => {
  const input = useRef();
  const error = errors.find((e) => e.field === (props.field || props.name));
  const [state, dispatch] = useReducer(reducer, {
    filteredItems: items,
    focus: false,
    focusItem: {},
    value: props.value,
  });

  const onBlur = () => dispatch({ type: 'onBlur' });

  const onChange = ({ target: { value } }) => {
    let filteredItems;

    if (value === '') {
      filteredItems = [...items];
      !props.multiple && props.onChange(value);
    } else {
      filteredItems = items.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    }

    dispatch({
      type: 'inputChange',
      payload: { filteredItems, value },
    });
  };

  const onClick = () => input.current.focus();
  const onFocus = () => dispatch({ type: 'onFocus' });

  const onKeyDown = (event) => {
    if (state.filteredItems.length === 0) return;

    if (isUpKey(event)) {
      event.preventDefault();
      dispatch({ type: 'moveUp' });
    } else if (isDownKey(event)) {
      event.preventDefault();
      dispatch({ type: 'moveDown' });
    } else if (isEnter(event)) {
      event.preventDefault();

      if (state.focusItem.id) {
        onItemSelect(state.focusItem);
      }
    }
  };

  const onItemSelect = (item) => {
    if (props.multiple) {
      if (selectedItemIds.includes(item.id)) {
        props.onChange(selectedItemIds.filter((i) => i !== item.id));
      } else {
        props.onChange([...selectedItemIds, item.id]);
      }
      dispatch({ type: 'inputChange', payload: { value: '' } });
    } else {
      dispatch({ type: 'inputChange', payload: { value: item.name } });
      props.onChange(item.id);
    }

    input.current.blur();
  };

  const onDeselectItem = (item) => {
    props.onChange(selectedItemIds.filter((i) => i !== item.id));
  };

  const renderSelectedItem = (item) => (
    <Badge key={item.id}>
      {item.name}{' '}
      <Button onClick={onDeselectItem.bind(this, item)} type="button">
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </Badge>
  );

  const renderFilterItem = (item) => (
    <Item
      key={item.id}
      data-id={item.id}
      focus={state.focusItem.id === item.id}
      onMouseDown={() => onItemSelect(item)}
      role="button"
      tabIndex="-1"
    >
      {props.displayFn ? props.displayFn(item) : item.name}
    </Item>
  );

  return (
    <>
      {props.multiple ? (
        <Container as="div" focus={state.focus} hasErrors={error !== undefined} onClick={onClick}>
          {items.filter((i) => selectedItemIds.includes(i.id)).map(renderSelectedItem)}
          <Input
            autoComplete="off"
            id={props.id}
            name={props.name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            ref={input}
            value={state.value}
            type="text"
          />
        </Container>
      ) : (
        <StyledInput
          autoComplete="off"
          hasErrors={error !== undefined}
          id={props.id}
          name={props.name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={input}
          type="text"
          value={state.value}
        />
      )}
      <List hidden={!state.focus}>{state.filteredItems.map(renderFilterItem)}</List>
    </>
  );
};

AutoComplete.defaultProps = {
  errors: [],
  multiple: false,
  selectedItemIds: [],
  value: '',
};

AutoComplete.propTypes = {
  displayFn: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    }),
  ),
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`,
      );
    }
  },
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedItemIds: PropTypes.array,
  value: PropTypes.string,
};

export default AutoComplete;
