class ShowQuestionnaires {
  constructor(form, list) {
    this.form = form;
    this.list = list;

    this.bindEvents();
    this.list.find('li').first().addClass('active');
    this.form.find('.card').first().show();
  }

  bindEvents() {
    this.form.on('click', 'a', this.onNextQuestionClickEvent.bind(this));
    this.list.on('click', 'a', this.onQuestionClickEvent.bind(this));
  }

  onNextQuestionClickEvent(event) {
    event.preventDefault();
    this.nextQuestion();
  }

  onQuestionClickEvent(event) {
    event.preventDefault();
    this.nextQuestion($($(event.target).attr('href')));
  }

  nextQuestion(question) {
    let currentQuestion = this.form.find('.card:visible');
    currentQuestion.hide();

    if (question == undefined) {
      question = currentQuestion.next();
    }

    question.show();
    this.list.find('.active').removeClass('active');
    this.list.find(`[href="#${question.attr('id')}"]`).parent().addClass('active');
  }
}

$(document).on('turbolinks:load', () => {
  let form = $('[data-form="show_questionnaires"]');
  let list = $('[data-list="questions"]');

  if (form.length) {
    new ShowQuestionnaires(form, list);
  }
});
