import ClustorAnswerConstants from 'constants/clustor-answer-constants';
import { clustorAnswerDispatcher } from 'dispatcher/clustor-answer-dispatcher';

let _clustor_answers = [];
let _listeners = [];

class ClustorAnswerStore {
  static getAll() {
    return _clustor_answers;
  }

  static setClustorAnswers(clustor_answers) {
    _clustor_answers = clustor_answers;
  }

  static emitChange() {
    _listeners.forEach((callback) => {
      callback();
    });
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default ClustorAnswerStore;

clustorAnswerDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case ClustorAnswerConstants.CLUSTOR_ANSWER_CREATE:
      _clustor_answers.push(action.clustor_answer);
      ClustorAnswerStore.emitChange();
  }
});
