import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import BusinessUnitModal from './BusinessUnitModal';
import OrganisationChartNode from './OrganisationChartNode';

class OrganisationChart extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderBusinesUnit = this.renderBusinesUnit.bind(this);

    this.state = {
      business_units: props.business_units,
      saving: false,
      showModal: false
    };
  }

  onClick() {
    this.setState({ showModal: true });
  }

  onSave() {
    this.setState({ saving: true });
    const body = {
      authenticity_token: document.querySelector('[name="csrf-token"]').content,
      business_units_attributes: this.state.business_units
    };
    fetch('/organisation/business_units', {
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    }).then(() => {
      this.setState(p => ({
        business_units: p.business_units.filter(b => b._destroy !== true),
        saving: false
      }));
    });
  }

  onSubmit(business_unit) {
    if (business_unit.id) {
      this.setState(p => ({
        business_units: p.business_units.map(b => b.id === business_unit.id ? business_unit : b)
      }));
    } else {
      const body = {
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        business_unit
      };

      fetch('/organisation/business_units', {
        credentials: 'same-origin',
        body: JSON.stringify(body),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }).then(r => r.json()).then(r => {
        this.setState(p => ({
          business_units: [...p.business_units, r.business_unit]
        }));
      });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  renderBusinesUnit(business_unit) {
    return(
      <OrganisationChartNode
        key={business_unit.id}
        business_unit={business_unit}
        business_units={this.state.business_units}
        onSubmit={this.onSubmit}
      />
    );
  }

  render() {
    const business_units = this.state.business_units.filter(b => b.business_unit_id === null && b._destroy !== true);
    const { saving, showModal } = this.state;
    return(
      <div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-default" onClick={this.onClick} type="button">
            {I18n.t('components.organisation_chart.new_business_unit')}
          </button>
          <button className="btn btn-primary" disabled={saving} onClick={this.onSave} type="button">
            {saving ? I18n.t('components.organisation_chart.saving') : I18n.t('components.organisation_chart.save')}
          </button>
        </div>
        {business_units.map(this.renderBusinesUnit)}
        {
          showModal && (
            <BusinessUnitModal
              business_units={this.state.business_units}
              closeModal={this.closeModal}
              onSubmit={this.onSubmit}
            />
          )
        }
      </div>
    );
  }
}

export default OrganisationChart;
