import AuditAnswerConstants from 'constants/audit-answer-constants';
import { auditAnswerDispatcher } from 'dispatcher/audit-answer-dispatcher';

let _audit_answers = [];
let _listeners = [];

class AuditAnswerStore {
  static getAll() {
    return _audit_answers;
  }

  static addAuditAnswers(audit_answers) {
    _audit_answers = [...audit_answers];
  }

  static emitChange() {
    _listeners.forEach(callback => callback());
  }

  static update(audit_answer) {
    _audit_answers = _audit_answers.map(ia => ia.id === audit_answer.id ? audit_answer : ia);
  }

  static addChangeListener(callback) {
    _listeners.push(callback);
  }

  static removeChangeListener(callback) {
    _listeners = _listeners.filter(l => l !== callback);
  }
}

export default AuditAnswerStore;

auditAnswerDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case AuditAnswerConstants.AUDIT_ANSWER_UPDATE:
      AuditAnswerStore.update(action.audit_answer);
      AuditAnswerStore.emitChange();
      break;
    case AuditAnswerConstants.AUDIT_ANSWER_DESTROY:
      _audit_answers = _audit_answers.filter(ia => ia.id !== action.audit_answer.id);
      AuditAnswerStore.emitChange();
  }
});
