import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../i18n-js/index.js.erb';

import TableRowConstants from '../constants/table-row-constants';
import { tableRowDispatcher } from '../dispatcher/table-row-dispatcher';
import TableRowStore from '../stores/table-row-store';

import TableColumnModal from './TableColumnModal';
import TableRow from './TableRow';

const arrayMoveTo = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

class Table extends React.Component {
  constructor(props) {
    super(props);

    TableRowStore.addTableRows(props.rows);

    this.formName = `text_block[tables_attributes][${props.table.id}]`;
    this.onTableColumnChange = this.onTableColumnChange.bind(this);
    this.onTableRowChange = this.onTableRowChange.bind(this);

    this.state = {
      columns: props.columns,
      rows: props.rows,
    };
  }

  componentDidMount() {
    TableRowStore.addChangeListener(this.onTableRowChange);
  }

  componentWillUnmount() {
    TableRowStore.removeChangeListener(this.onTableRowChange);
  }

  onTableColumnChange(columns) {
    this.setState({ columns });
  }

  onTableRowChange() {
    let rows = TableRowStore.getAll().filter(r => r.table_id === this.props.table.id);
    this.setState({ rows });
  }

  rowUp(row) {
    const index = this.state.rows.findIndex(r => r.id === row.id);
    const rows = arrayMoveTo(this.state.rows, index, index - 1);
    this.updateRows(rows.map((r, i) => ({ ...r, order: i })));
  }

  rowDown(row) {
    const index = this.state.rows.findIndex(r => r.id === row.id);
    const rows = arrayMoveTo(this.state.rows, index, index + 1);
    this.updateRows(rows.map((r, i) => ({ ...r, order: i })));
  }

  updateRows(rows) {
    tableRowDispatcher.handleChangeTableRowAction({
      actionType: TableRowConstants.TABLE_ROWS_UPDATE,
      table_rows: rows,
    });
  }

  onAddTableRowClickEvent(event) {
    event.preventDefault();

    const row = {
      id: null,
      destroy: false,
      order: this.state.rows.length,
      cells: this.state.columns.map((column, index) => {
        let cell = {
          column_id: column.id,
        };

        if (column.default_answers.length) {
          cell.value = column.default_answers[0].answer;
          cell.image = column.default_answers[0].image;
          cell.default_answer_id = column.default_answers[0].id;
        } else {
          cell.value = '';
          cell.image = null;
          cell.default_answer_id = null;
        }
        return cell;
      }),
    };

    tableRowDispatcher.handleChangeTableRowAction({
      actionType: TableRowConstants.TABLE_ROW_CREATE,
      table_row: row,
    });
  }

  renderColumn(column) {
    return(
      <th key={column.id}>
        {column.name}
        <input type="hidden" name={`${this.formName}[table_columns_attributes][${column.id}][id]`} value={column.id} />
        <input type="hidden" name={`${this.formName}[table_columns_attributes][${column.id}][name]`} value={column.name} />
      </th>
    );
  }

  renderRow(row, index) {
    const first = index === 0;
    const last = index === this.state.rows.length - 1;
    return(
      <TableRow
        key={row.id}
        row={row}
        formName={this.formName}
        first={first}
        last={last}
        rowUp={this.rowUp.bind(this)}
        rowDown={this.rowDown.bind(this)}
        columns={this.state.columns}
      />
    );
  }

  renderEmptyCells(count) {
    const cells = [];

    for (let i = 0; i < count; i++) {
      cells.push(<td key={`empty-${i}`}></td>);
    }
    return cells;
  }

  render() {
    return(
      <div className="table-responsive">
        <input type="hidden" name={`${this.formName}[id]`} defaultValue={this.props.table.id} />
        <table className="table table-editable">
          <thead>
            <tr>
              {this.state.columns.map(this.renderColumn.bind(this))}
              <th>
                <div className="dropdown text-right">
                  <button className="btn btn-default btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <li><a href={`#modal-edit-columns-${this.props.table.id}`} data-toggle="modal" data-target={`#modal-edit-columns-${this.props.table.id}`}>{I18n.t('text_blocks.edit.tables.edit')}</a></li>
                  </ul>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {this.state.rows.sortByKey('order').map(this.renderRow.bind(this))}

            <tr>
              {this.renderEmptyCells(this.state.columns.length)}
              <td className="text-right">
                <button className="btn btn-default btn-xs" type="button" onClick={this.onAddTableRowClickEvent.bind(this)}>+</button>
              </td>
            </tr>
          </tbody>
        </table>

        <TableColumnModal table_id={this.props.table.id} columns={this.state.columns} onSave={this.onTableColumnChange} />
      </div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  table: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default Table;
