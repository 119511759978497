import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import { validationErrors } from '../utils';

class SelfAssessmentModal {
  constructor(modal) {
    this.modal = modal;
    this.form = modal.find('form');

    this.onSubmitEvent = this.onSubmitEvent.bind(this);
    this.successEvent = this.successEvent.bind(this);

    this.bindEvents();
  }

  bindEvents() {
    this.form.on('submit', this.onSubmitEvent);
  }

  onSubmitEvent(event) {
    event.preventDefault();

    $.ajax({
      type : 'POST',
      url : this.form.attr('action'),
      data : new FormData(this.form[0]),
      processData: false,
      contentType: false,
      dataType: 'json',
      success: this.successEvent,
    });
  }

  successEvent(data) {
    Rails.enableElement(this.form[0]);

    if (typeof(data.errors) !== 'undefined') {
      $('#modal-nieuwe-taak .modal-body').append('<p class="alert alert-danger error">'+data.errors+'</p>');
      setTimeout(function() {
        $('.error').fadeOut('slow');
      }, 4000);
    } else if (typeof(data.validation_errors) !== 'undefined') {
      validationErrors(this.form, data.validation_errors);
    } else {
      this.form[0].reset();

      $('#newSelfAssessmentModal').modal('hide');
      Turbolinks.visit(`/self_assessments/${data.self_assessment.id}`);
    }
  }
}


$(document).on('turbolinks:load', () => {
  const modal = $('#newSelfAssessmentModal');

  if (modal) {
    new SelfAssessmentModal(modal);
  }
});
