import React from 'react';
import I18n from 'i18n-js/index.js.erb';

import AutoComplete from './AutoComplete';

class Resources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resource_categories: props.resource_categories,
      manual_paragraphs: props.manual_paragraphs
    };
  }

  addNewResource(manual_paragraph_id, resource_category_id, resource_name) {
    let resource_category = this.state.resource_categories.find((c) => {
      return c.id === resource_category_id;
    });

    const resource = resource_category.resources.find((resource) => {
      return resource.name === resource_name;
    });

    if (resource) {
      this.addResource(manual_paragraph_id, resource.id);
    } else {
      $.ajax({
        url: '/organisation/resources',
        method: 'post',
        data: {
          authenticity_token: document.querySelector('[name="csrf-token"]').content,
          resource: {
            name: resource_name,
            category_id: resource_category_id
          }
        },
        dataType: 'json',
        success: (data) => {
          resource_category = Object.assign({}, resource_category, { resources: [].concat(resource_category.resources, [data.resource]) });
          this.setState({
            resource_categories: this.state.resource_categories.map((rc) => rc.id === resource_category.id ? resource_category : rc),
          });
          this.addResource(manual_paragraph_id, data.resource.id);
        }
      });
    }
  }

  addResource(manual_paragraph_id, resource_id) {
    const resource_category = this.state.resource_categories.find((c) => {
      return c.resources.find((r) => { return r.id === resource_id; }) !== undefined;
    });

    const resource = Object.assign(resource_category.resources.find((r) => {
      return r.id === resource_id;
    }), { category_id: resource_category.id });

    const manual_paragraphs = this.state.manual_paragraphs.map((manual_paragraph) => {
      if (manual_paragraph.id === manual_paragraph_id) {
        return Object.assign({}, manual_paragraph, { resources: [].concat(manual_paragraph.resources, [resource]) });
      } else {
        return manual_paragraph;
      }
    });

    this.setState({
      manual_paragraphs: manual_paragraphs
    });

    const manual_paragraph = manual_paragraphs.find((m) => { return m.id === manual_paragraph_id; });
    this.updateManualParagraph(manual_paragraph);
  }

  removeSelectedResource(manual_paragraph_id, resource_id) {
    const manual_paragraphs = this.state.manual_paragraphs.map((manual_paragraph) => {
      if (manual_paragraph.id === manual_paragraph_id) {
        return Object.assign({}, manual_paragraph, { resources: manual_paragraph.resources.filter((resource) => resource.id !== resource_id) });
      } else {
        return manual_paragraph;
      }
    });

    this.setState({
      manual_paragraphs: manual_paragraphs
    });

    const manual_paragraph = manual_paragraphs.find((m) => { return m.id === manual_paragraph_id; });
    this.updateManualParagraph(manual_paragraph);
  }

  removeResource(resource_id) {
    const manual_paragraphs = this.state.manual_paragraphs.map((manual_paragraph) => {
      if (manual_paragraph.resources.find((r) => r.id === resource_id) !== undefined) {
        return Object.assign({}, manual_paragraph, { resources: manual_paragraph.resources.filter((resource) => resource.id !== resource_id) });
      } else {
        return manual_paragraph;
      }
    });

    const resource_categories = this.state.resource_categories.map((resource_category) => {
      if (resource_category.resources.find((r) => r.id === resource_id)) {
        return Object.assign({}, resource_category, { resources: resource_category.resources.filter((r) => r.id !== resource_id) });
      } else {
        return resource_category;
      }
    });

    this.setState({
      resource_categories: resource_categories,
      manual_paragraphs: manual_paragraphs
    });

    $.ajax({
      url: `/organisation/resources/${resource_id}`,
      method: 'delete',
      dataType: 'json'
    });
  }

  updateManualParagraph(manual_paragraph) {
    $.ajax({
      url: `/manual_paragraphs/${manual_paragraph.id}`,
      method: 'patch',
      data: {
        authenticity_token: document.querySelector('[name="csrf-token"]').content,
        manual_paragraph: {
          resource_ids: [''].concat(manual_paragraph.resources.map((r) => { return r.id; }))
        }
      },
      dataType: 'json'
    });
  }

  renderReourcesCategory(resource_category) {
    return(
      <div key={`resource-${resource_category.id}`} className="col-xs-4">
        {resource_category.name}
      </div>
    );
  }

  renderManualParagraph(manual_paragraph) {
    return(
      <tr key={`paragraph-${manual_paragraph.id}`}>
        <td>{manual_paragraph.number}</td>
        <td>{manual_paragraph.name}</td>
        <td>
          <div className="row">
            {this.state.resource_categories.map((c) => this.renderParagraphCategory(manual_paragraph, c)) }
          </div>
        </td>
      </tr>
    );
  }

  renderParagraphCategory(manual_paragraph, resource_category) {
    const selectedValues= manual_paragraph.resources.filter((r) => r.category_id === resource_category.id);

    const values = resource_category.resources.filter((r) => {
      return selectedValues.find((r2) => { return r.id === r2.id; }) === undefined;
    });

    return(
      <div key={`${manual_paragraph.id}-autocomplete-${resource_category.id}`} className="col-xs-4">
        <AutoComplete
          id={manual_paragraph.id}
          selectValues={selectedValues}
          values={values}
          addNewValue={(manual_paragraph_id, resource_name) => this.addNewResource(manual_paragraph_id, resource_category.id, resource_name)}
          addValue={this.addResource.bind(this)}
          removeValue={this.removeSelectedResource.bind(this)}
          removeResource={this.removeResource.bind(this)}
        />
      </div>
    );
  }

  render() {
    return(
      <div className="card">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="id">{I18n.t('organisations.resources.index.id')}</th>
                <th>{I18n.t('organisations.resources.index.paragraph')}</th>
                <th className="text-center">{I18n.t('organisations.resources.index.resources')}</th>
              </tr>
              <tr>
                <th className="id"></th>
                <th></th>
                <th>
                  <div className="row">
                    {this.state.resource_categories.map(this.renderReourcesCategory.bind(this))}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.manual_paragraphs.map(this.renderManualParagraph.bind(this))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Resources;
